import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { TextArea, TextBox, SelectBox, Button, NumberBox } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import { makePostRequest } from '../../../Api/requestMethods';
import { ADDONCREATE_API } from '../../../Api/api';
import { addonValidationSchema,questionValidationSchema } from './Validation/Auth';
import { Addoncodetype } from '../../../global/constants';
import { DURATION, ERROR, SUCCESS } from '../../../global/ToastMsgConstants.tsx';
import {jwtDecode} from 'jwt-decode';

const initialValues = {
    addon_code: "",
    description: "",
    addon_type: "",
    orginal_value: "",
    amount_inr: "",
    amount_usd: "",
    gst_value:"",
    question: ""
};

const CreateAddOnCode = () => {
    const [selectedOption, setSelectOption] = useState("");

    const user = localStorage.getItem("MDnD_auth") ? jwtDecode(localStorage.getItem("MDnD_auth")) : null;
    const role_id = user ? user.role_id : null;

    const {
        values, handleBlur, handleChange, handleSubmit, errors, touched, isSubmitting, setFieldValue, resetForm
    } = useFormik({
        initialValues: initialValues,
        validationSchema: selectedOption === "Question" ? questionValidationSchema : addonValidationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            let updatedValues = {
                addon_code: values.addon_code,
                addon_type: values.addon_type,
                description: values.description,
                role_id: role_id,
            };

            if (values.addon_type === 'Question') {
                updatedValues = {
                    ...updatedValues,
                    question: values.question,
                };
            } else {
                updatedValues = {
                    ...updatedValues,
                    orginal_value: values.orginal_value,
                    amount_inr: values.amount_inr,
                    amount_usd: values.amount_usd,
                    gst_value: values.gst_value,
                };
            }

            console.log("onSubmit called");
            console.log(updatedValues, "UpdateValues");
            try {
                await addonCodeDetailMutation.mutateAsync(updatedValues);
            } catch (error) {
                console.log(error);
            } finally {
                setSubmitting(false);
                resetForm();
            }
        }
    });

    console.log(values, "Values");

    const handleOptionChange = (option) => {
        setSelectOption(option);
        setFieldValue("addon_type", option); // Correct way to set formik field value
    };

    const addonOnFuncton = async (data) => {
        try {
            const response = await makePostRequest(ADDONCREATE_API(), data);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const addonCodeDetailMutation = useMutation({
        mutationKey: ["addondetail"],
        mutationFn: (addondata) => addonOnFuncton(addondata),
        onSuccess: (data) => {
            if (data.status === 'failed') {
                notify(data.message, ERROR, DURATION);
            } else if (data.status === 'success') {
                notify(data.message, SUCCESS,DURATION);
            }
        },
        onError: (error) => {
            notify("Submit Unsuccessful...!!", ERROR, DURATION);
        }
    });

    return (
        <form className="tab-content tabBodyBg" onSubmit={(e) => {
            e.preventDefault();
            console.log("Form onSubmit triggered");
            handleSubmit(e);
        }}>
            <div className="tab-content tabBodyBg">
                <div id="createevent" className="tab-pane animated fadeInUp active show" role="tabpanel">
                    <div className="description-content">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group mb-2">
                                    <TextBox
                                        type="text"
                                        name="addon_code"
                                        className="dx-field-item-required"
                                        required="required"
                                        labelMode='floating'
                                        label="Add-on-Code"
                                        value={values.addon_code}
                                        onValueChanged={(e) => handleChange({
                                            target: { name: "addon_code", value: e.value }
                                        })}
                                        onFocusOut={(e) => handleBlur({
                                            target: { name: "addon_code", value: e.value }
                                        })}
                                    />
                                    {errors.addon_code && touched.addon_code && <div className="text-danger">{errors.addon_code}</div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-2">
                                    <SelectBox
                                        className="dx-field-item-required"
                                        dataSource={Addoncodetype}
                                        displayExpr="type"
                                        valueExpr="value"
                                        labelMode='floating'
                                        label="Add-On-Type"
                                        value={values.addon_type}
                                        onValueChanged={(e) => handleOptionChange(e.value)}
                                        onFocusOut={(e) => handleBlur({
                                            target: { name: "addon_type", value: e.value }
                                        })}
                                    />
                                    {errors.addon_type && touched.addon_type && <div className="text-danger">{errors.addon_type}</div>}
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group mb-2">
                                    <TextArea
                                        height={100}
                                        valueChangeEvent='input'
                                        maxLength={100}
                                        labelMode='floating'
                                        label='Description'
                                        value={values.description}
                                        onValueChanged={(e) => handleChange({
                                            target: { name: 'description', value: e.value }
                                        })}
                                        onFocusOut={(e) => handleBlur({
                                            target: { name: "description", value: e.value }
                                        })}
                                    />
                                    {errors.description && touched.description && <div className="text-danger">{errors.description}</div>}
                                </div>
                            </div>
                            {selectedOption !== "Question" && (
                                <>
                                    <div className="col-lg-6">
                                        <div className="form-group mb-2">
                                            <NumberBox
                                                type="text"
                                                name="orginal_value"
                                                className="dx-field-item-required"
                                                required="required"
                                                label="Original Value"
                                                labelMode='floating'
                                                value={values.orginal_value}
                                                onValueChanged={(e) => handleChange({
                                                    target: { name: 'orginal_value', value: e.value }
                                                })}
                                                onFocusOut={(e) => handleBlur({
                                                    target: { name: "orginal_value", value: e.value }
                                                })}
                                            />
                                            {errors.orginal_value && touched.orginal_value && <div className="text-danger">{errors.orginal_value}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mb-2">
                                            <NumberBox
                                                type="text"
                                                name="amount_inr"
                                                className="dx-field-item-required"
                                                required="required"
                                                labelMode='floating'
                                                label="Add-on-INR(Rupees) values"
                                                value={values.amount_inr}
                                                onValueChanged={(e) => handleChange({
                                                    target: { name: 'amount_inr', value: e.value }
                                                })}
                                                onFocusOut={(e) => handleBlur({
                                                    target: { name: "amount_inr", value: e.value }
                                                })}
                                            />
                                            {errors.amount_inr && touched.amount_inr && <div className="text-danger">{errors.amount_inr}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mb-2">
                                            <NumberBox
                                                type="text"
                                                name="amount_usd"
                                                className="dx-field-item-required"
                                                required="required"
                                                labelMode='floating'
                                                label="Add-on-USD(Dollars) values"
                                                value={values.amount_usd}
                                                onValueChanged={(e) => handleChange({
                                                    target: { name: 'amount_usd', value: e.value }
                                                })}
                                                onFocusOut={(e) => handleBlur({
                                                    target: { name: "amount_usd", value: e.value }
                                                })}
                                            />
                                            {errors.amount_usd && touched.amount_usd && <div className="text-danger">{errors.amount_usd}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mb-2">
                                            <NumberBox
                                                type="text"
                                                name="gst_value"
                                                className="dx-field-item-required"
                                                required="required"
                                                labelMode='floating'
                                                label="GST"
                                                value={values.gst_value}
                                                onValueChanged={(e) => handleChange({
                                                    target: { name: 'gst_value', value: e.value }
                                                })}
                                                onFocusOut={(e) => handleBlur({
                                                    target: { name: "gst_value", value: e.value }
                                                })}
                                            />
                                            {errors.gst_value && touched.gst_value && <div className="text-danger">{errors.gst_value}</div>}
                                        </div>
                                    </div>
                                </>
                            )}
                            {selectedOption === "Question" && (
                                <div className="col-lg-12">
                                    <h4>Question</h4>
                                    <div className="form-group mb-2">
                                        <TextArea
                                            height={100}
                                            valueChangeEvent='input'
                                            maxLength={100}
                                            labelMode='floating'
                                            label='Enter your question'
                                            value={values.question}
                                            onValueChanged={(e) => handleChange({
                                                target: { name: 'question', value: e.value }
                                            })}
                                            onFocusOut={(e) => handleBlur({
                                                target: { name: "question", value: e.value }
                                            })}
                                        />
                                        {errors.question && touched.question && <div className="text-danger">{errors.question}</div>}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="iq-button">
                    <Button
                        type="submit"
                        text="Submit"
                        className="btn position-relative h-auto"
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </form>
    );
};

export default CreateAddOnCode;
