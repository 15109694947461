const CREATE_EVENTS_SCHEMA = (MDnDLocation, dateAndTime, settings) => {
  return {
    event_title: "",
    main_catid: "",
    sub_catid: "",
    event_desc: "",
    event_date: "",
    event_time: "",
    timezone: "",
    country_id: "",
    state_id: "",
    city_id: "",
    artist_ids: "",
    accompanist_ids: "",
    organiser_id: "",
    quickadd_artists: "",
    quickadd_accompanists: "",
    quickadd_organisers: "",
    event_image: "",
    event_type: "",
    ticketing_option: "",
    event_mode: "",
    button_text: "",
    venue_name: "",
    location: "",
    button_action: "",
    button_url: "",
    totalqty: "",
    row_name: "",

    addsamedaycheck: false,
    bookingWillOpenSoon: false,
    seating_option: "",
    isGstChecked: false,

    gstAmount: null,
    hcGstAmount: null,
    ticket_details: [],

    show_to_public: "N",

    dateAndTime: dateAndTime,

    sameDayMultipleEvent: [
      {
        eventArtists: "",
        sub_catid: "",
        event_time: "",
        artist_ids: "",
        accompanist_ids: "",
        event_desc: "",
      },
    ],
    ticket_limitations: "",
    role_id: null,

    is_promo_code: false,
    promo_codes: [],
    is_addon_code: false,
    addon_codes: [],

    isSubCategoryValid: false,
    isButtonActionValid: false,
    isButtonUrlEnabled: false,
    isTicketLimitation: false,
    isRowAndTicketQty: false,
    isTicketLimitationEnabled: false,
    isINREnabled: false,
    isUSDEnabled: false,
    isButtonTextEnabled: false,
    isSeatingEnabled: false,
    isArtistEnabled: false,

    isStreamLinkEnabled : false,

    is_season_event: "N",
    tempLocationData: "",
    venue_id: null,
  };
};

const CREATE_SEASON_SCHEMA = {
  is_season_event: "Y", // string
  season_event_title: "", // string
  season_start_date: "", // string
  season_end_date: "", // string
  season_description: "", // string
  season_event_ids: "", // string
  season_organiser_id: null, // number
  country_id: null, // number
  state_id: null, // number
  city_id: null, // number

  ticketing_option: "", // string
  event_mode: "",

  seating_option: "non_numbered", // string
  multiple_tickets: false, // bool
  is_booking_open: false,
  location: "",
  venue_name: "",

  season_button_text: "", // string
  ticket_details: [], // array
  event_image: "", // string

  show_to_public: "", // string
  stream_link: "",

  is_promo_code: false,
  is_addon_code: false,
  promo_codes: [],
  addon_codes: [],

  role_id: null,

  isFreeWithRegistrationEnabled: false,
  isAmountEnable: false,
  isLocationEnable: false,
  isVenueNameEnable: false,
  isTicketLimitationEnabled: false,

  tempLocationData: "",
};

const forms_seatingLayout = {
  floorOptions: "",
  rows: null,
  columns: null,
};

const forms_venueDetails = {
  venue_name: "",
  venue_description: "",
  address: "",
  country_id: null,
  state_id: null,
  city_id: null,
  pincode: null,
  location: "",
  venue_image: "",
  status: "",
  seating_layout: [],
  role_id: "",
  seating_details: "",
};

export {
  CREATE_EVENTS_SCHEMA,
  CREATE_SEASON_SCHEMA,
  forms_seatingLayout,
  forms_venueDetails,
};
