import React, { useState, useEffect, useContext } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from 'react-router-dom'; 
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import TextArea from "devextreme-react/text-area";
import { makeGetRequest, makePostRequest } from "../../Api/requestMethods";
import notify from "devextreme/ui/notify";
import IMAGE1 from "../../assets/images/genre/03.webp";
import { DURATION, ERROR, SUCCESS } from "../../global/ToastMsgConstants.tsx";
import { GET_STREAM_LOGIN_TOKEN, POST_STREAM_VOTE_DETAIL } from "../../Api/api.js";
import AuthContext from "../../context/AuthContext.js";
import StreamCountDownPage from "./StreamCountDownPage.js";
import { IoShareSocial } from "react-icons/io5";
import { CountrySelectorDropdown } from "react-international-phone";
function OffCanvasExample({ show, handleClose, ...props }) {
  return (
    <Offcanvas show={show} onHide={handleClose} {...props}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Comments</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ul className="list-inline m-0 p-0">
          <li className="pb-3 mb-3">
            <div className="d-flex flex-sm-row align-items-sm-center align-items-start gap-2">
              <div className="flex-shrink-0 videoCommentImg">
                <img src={IMAGE1} alt="user-image" className="img-fluid" />
              </div>
              <div className="about-user">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <h5 className="mb-0">Jack Stark</h5>
                  <span className="text-uppercase fst-italic fw-semibold published-date">
                    <i className="fas fa-minus fa-xs"></i> March 2, 2022
                  </span>
                </div>
                <p className="mt-2 mb-0">
                  There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                </p>
              </div>
            </div>
          </li>
          <li className="mb-3">
            <div className="d-flex flex-sm-row align-items-sm-center align-items-start gap-2">
              <div className="flex-shrink-0 videoCommentImg">
                <img src={IMAGE1} alt="user-image" className="img-fluid" />
              </div>
              <div className="about-user">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <h5 className="mb-0">Jhon Deo</h5>
                  <span className="text-uppercase fst-italic fw-semibold published-date">
                    <i className="fas fa-minus fa-xs"></i> March 2, 2022
                  </span>
                </div>
                <p className="mt-2 mb-0">
                  It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div className="d-flex align-items-center mt-2 mobileCommFixed">
          <div className="videoCommentImg">
            <img src={IMAGE1} alt="User" />
          </div>
          <div className="ms-2 w-100">
            <textarea className="w-100 textareaBorder" placeholder="Add a comment..." />
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

function Stream() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [base64Detail,setbase64Detail] = useState(null);
  const [urlData,setUrlData] = useState({});
  const [likeStatus, setLikeStatus] = useState(0);
  const [count, setCount] = useState(0);
  const [likeNumber, setlikeNumber] = useState(0);
  const [dislikeStatus, setDislikeStatus] = useState(0);
  const navigate = useNavigate();
  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = () => setShowOffcanvas(true);
  const extractBase64FromUrl = () => {
    const url = window.location.href;
    const urlobj = new URL(url);
    const pathSegments = urlobj.pathname.split('/');
    return pathSegments[pathSegments.length - 1];
  }

  const decodeBase64String = (base64String) => {
    try {
      // Decode base64 string
      const jsonString = atob(base64String);
  
      // Parse JSON string into an object
      const parsedData = JSON.parse(jsonString);
  
      return parsedData;
    } catch (error) {
      console.error('Error decoding base64 string:', error);
      return null;
    }
  };

  useEffect(() => {
    if (base64Detail) {
      const decodedData = decodeBase64String(base64Detail);
      console.log(decodedData,"DecodeD")
      setUrlData(decodedData)
    }
  }, [base64Detail]); 


console.log(urlData,"dindsbse64Data")

const {streamLoginDetail} = useContext(AuthContext);
 
//   const validateUrl = () => {
//     const base64String = extractBase64FromUrl();
//     if (!base64String || base64String.length < 10) return false;

//     const isValidBase64 = (str) => {
//       const base64Pattern = /^[a-zA-Z0-9+/=]+$/;
//       return base64Pattern.test(str) && (str.length % 4 === 0);
//     };

//     if (!isValidBase64(base64String)) return false;

//     try {
//       atob(base64String);
//       return true;
//     } catch (e) {
//       return false;
//     }
//   };

//   useEffect(() => {
//     if (!validateUrl()) {
//       navigate('/login');
//     }
//   }, [navigate]);
// -----------------------------------------------------------------------
const likeANDdislikeInStream = async(voteDetail)=>{
    try{
        const response = await makePostRequest(POST_STREAM_VOTE_DETAIL(),voteDetail);
        return response;
    }catch(error){
        throw error;
    }
}
const AllStreamVoteMutation = useMutation({
    mutationKey:["StreamVoteDetail"],
    mutationFn: (data)=>likeANDdislikeInStream(data),
    onSuccess:(data)=>{
        console.log("u liked Video")
    },
    onError:(error)=>{
        console.log(error);
    }
})
const handleVote =(action,data1)=>{
  const likedislikeValue = action === "like"? 1:0;
  const data={
    event_id : urlData.event_id,
    role_id : urlData.role_id,
    country_id : urlData.country_id,
    is_like : likedislikeValue
  }
    AllStreamVoteMutation.mutate(data,{
      onSuccess:()=>{
        if(action ==="like"){
          setLikeStatus(1);
          setCount(count+1);
          setDislikeStatus("inactive");
        }else{
          setDislikeStatus(1);
          if(!likeNumber === 0){
            setLikeStatus(1);
          }else{
           setlikeNumber(data1);
           setCount(count+1);
          }
          setLikeStatus(null)
        }
      },
        onError:(error)=>{
          console.log(error)
        }
      });
    }
  
  

// --------------------------------------------------------------------------------------------------------
const handleClick = (ticketDetail, from) => {
    navigate(`/events/ProductDetails?ci=${ticketDetail.country_id}&e=${ticketDetail.event_id}`);
};
  const fetchingVideo = async () => {
    const base64String = extractBase64FromUrl();
    setbase64Detail(base64String);
    const endpoint = `stream/event_details/${base64String}`;
    return await makeGetRequest(endpoint);
  };

  const { data:  VideoDetail,refetch} = useQuery({
    queryKey: ["VideoDetail"],
    queryFn: fetchingVideo,
    onSuccess: (data) => {
      notify(data.message === "success", SUCCESS, DURATION);
    },
    onError: (error) => {
      console.log(error, ERROR, DURATION);
    }
  });
useEffect(()=>{
  if(VideoDetail?.data){
    refetch();
  }
},[VideoDetail?.data,count]);

  function convertToBase64forSideThumbnail(data) {
    const jsonString = JSON.stringify(data);
    return btoa(jsonString);
  }
// --------------------------------------------------------------------------------------------------------
  const data = { "country_id": urlData?.country_id, "season_flag": VideoDetail?.season_flag }
  const base64String = convertToBase64forSideThumbnail(data);

  console.log(base64String,"Base64String")

  const fetchSideVideo = async () => {
    const endpoint = `stream/upcoming_events/${base64String}`;
    return await makeGetRequest(endpoint);
  }

  const { data: SideVideo } = useQuery({
    queryKey: ["SideVideo"],
    queryFn: fetchSideVideo,
    onError: () => {
      console.log("errorMessage");
    }
  });
// ---------------------IsEventLive-------------------------------
const isEventLive = () => {
    if (!VideoDetail?.data?.event_date || !VideoDetail?.data?.event_time) {
      return false;
    }
    const eventDate = new Date(VideoDetail.data.event_date);
    const [hours, minutes, seconds] = VideoDetail.data.event_time.split(':').map(Number);
    eventDate.setHours(hours, minutes, seconds);
    const currentDate = new Date();
    return currentDate >= eventDate;
  };
// ------------------------------------------------------

// -------------------------------------------------------------------------------------------//
  return (
    <div>
      <div className="videoOverflow">
        <div className="videoPageMargin section-padding pt-lg-3">
          <div className="container-fluid videoPageHeight">
            <div className="row gx-lg-4 gx-md-3 gx-0">
              <div className="col-lg-9">
                {  isEventLive() ? ( 
                <div className="">
                  <iframe
                    src={VideoDetail?.data?.stream_link || ""}
                    width="100%"
                    height="720"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    title="Vimeo Video"
                  />
                  <div className="mt-1">
                    <h4><b>{VideoDetail?.data?.event_title}</b></h4>
                    <div className="d-lg-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="videoCommentImg">
                          <img src={IMAGE1} alt="User" />
                        </div>
                        <div className="w-100 ms-2">
                          <h6 className="mb-0"><b>Channel Name</b></h6>
                          <p className="mb-0 videoMinuts">31 minutes ago</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end align-items-center gap-3 mt-2 mt-lg-0">
                        <div className="">
                          <div className="like_dislike" id="like_dislike">
                            <div
                              className={`like ${VideoDetail?.data?.likes === 1 ? "fa-solid" : "fa-regular"} touch_feedback`}
                              id="like"
                              onClick={() => handleVote("like")}
                              data-status ={likeStatus}
                            >
                              <i
                                id="like__icon"
                                className={`fa-thumbs-up ${VideoDetail?.data?.likes === 1 ? "like__animation" : ""}`}
                              ></i>
                              <span className="Likenumber" id="like-number">
                                {VideoDetail?.data?.likes}
                              </span>
                            </div>
                            <span className="divider"></span>
                            <div
                              className={`dislike ${VideoDetail?.data?.dislikes === 1 ? "fa-solid" : "fa-regular"} touch_feedback`}
                              id="dislike"
                              onClick={() => handleVote("dislike")}
                              data-status={dislikeStatus}
                            >
                              <i
                                id="dislike__icon"
                                className={`fa-thumbs-down ${VideoDetail?.data?.dislikes === 1 ? "dislike__animation" : ""}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                    <button
                      className="videoShareBtn d-flex align-items-center"
                      variant="primary"
                    //   onClick={handleShow}
                    >
                      <i aria-hidden="true"> <IoShareSocial /></i>
                      <span className="Likenumber">Share</span>
                    </button>
                       {/* <ShareModal
                       show={show}
                       handleClose={handleClose}
                       currentUrl={currentUrl}
                       handleCopy={handleCopy}
                       inputRef={inputRef}
                       mlogo={mlogo}
                       /> */}
                  </div>
                    </div>
                    <div className="shadow-sm p-2 mt-2">
                      <p><b>182K views 5 days ago</b></p>
                      <p className="mb-0" dangerouslySetInnerHTML={{ __html: VideoDetail?.data?.event_description }} />                      </div>
                    <div className="d-flex align-items-center mt-2">
                      <h5><b>105 Comments</b></h5>
                      <button className="ms-3 addCommBtn d-flex align-items-center" onClick={handleShowOffcanvas}>
                        <i className="fa-solid fa-plus me-2"></i> Add Comment
                      </button>
                    </div>
                  </div>
                </div>
                ):(<StreamCountDownPage targetDate={VideoDetail?.data?.event_date}/>)
              }
              </div>
              <div className="col-lg-3 mt-lg-0 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Upcoming Events</h5>
                </div>
                {SideVideo?.data?.map((event) => (
                  <div className="py-3" key={event.event_id} >
                    <div className="overflow-hidden sideThumbnailBox position-relative rounded" onClick={() => handleClick(event,"streaming")}>
                        <img src={`data:image/jpeg;base64,${event.thumbnail}`} className="w-100 img-fluid rounded" alt="Side Thumbnail" />
                      <p className="sideVideoDuration">{event.event_time}</p>
                    </div>
                    <div className="mt-2">
                      <p className="mb-0 text-start"><b>{event.event_title}</b></p>
                      <p className="mb-0 videoMinuts">Channel Name</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <OffCanvasExample show={showOffcanvas} handleClose={handleCloseOffcanvas} placement="end" />
        </div>
      </div>
    </div>
  );
}

export default Stream;
