import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import GlobalSearch from "./GlobalSearch/GlobalSearch.js";
import Artist from "./Subpages/MainPage/Artist.js";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import Landing from "./Dashboard/Landing";
import Organisers from "./Pages/Organisers/Organisers.js";
import ListingEvents from "./Pages/Filterpage/Filterpage.js";
import Event from "./Subpages/MainPage/Event.js";
import Artistall from "./Subpages/SubsubPage/Artistall.js";
import ProfileSingle from "./Subpages/profiles/ProfileSingle.js";
import Franchisees from "./Pages/Franchisees/Franchisees.js";
import Blogfourcolmn from "./Pages/Blogs/component/BlogCategory.js";
import Blog from "./Pages/Blogs/Blogs.js";
import LoginDashboard from "./LoginDashboard/LoginDashboard.js";

// newly added
import SpecificPost from "./LoginDashboard/components/Profile/SpecificPost.js";
import Success from "./Pages/invoice/SuccessPage.js";
import Failed from "./Pages/invoice/Failed.js";

//newly added
import MDndStore from "./MDndStore/MDndStore.js";
import AddtoCart from "./AddtoCart/AddtoCart.js";
import VideoStore from "./MDndStore/VideoStore.js";
import MDnDRecords from "./MDnDRecords/MDnDRecords.js";
import RecordsVideo from "./MDnDRecords/RecordsVideo.js";
import RagaBank from "./RagaBank/RagaBank.js";
import RagaBankVideo from "./RagaBank/RagaBankVideo.js";
import MDnDLive from "./Pages/MDnDLive/MDnDLive.js";

import SingleBlog from "./Pages/Blogs/component/SingleBlog.js";
import ProductDetails from "./Pages/productDetails/productDetails.js";
import ProductSummary from "./Pages/ProductSummary/ProductSummary.js";
import ProductFreeDetails from "./Pages/ProductFreeDetails/ProductFreeDetails.js";
import ProductFreeRegistration from "./Pages/ProductFreeRegistration/ProductFreeRegistration.js";
import SeasonTickets from "./SeasonTickets/SeasonTickets";
import SeasonEventDetails from "./SeasonTickets/components/SeasonEventDetails.js";
import ProductCountdown from "./Pages/ProductCountdown/ProductCountdown.js";
import ProductOnlineTicket from "./Pages/ProductOnlineTicket/ProductOnlineTicket.js";
import ArtistSingleProfile from "./Subpages/profiles/ArtistSingleProfile.js";
import Front from "./Auth/Front.js";
import Forgot from "./Auth/Forgot.js";
import Registration from "./Auth/Registration.js";
import { store } from "./store/index.js";
import { ThemeProvider } from "./Theme/themeProvider.js";

import Dashboard from "./LoginDashboard/components/Dashboard/Dashboard.js";
import Transactions from "./LoginDashboard/components/Transactions/Transactions.js";
import PageNotFound from "./Pages/PageNotFound/PageNotFound.js";

import EventsHeader from "./LoginDashboard/components/Events/EventsHeader.js";
import CreateEvents from "./LoginDashboard/components/Events/createEvents.js";
import ViewEvents from "./LoginDashboard/components/Events/ViewEvents.js";

//FORMS *******************
import FormsHeader from "./LoginDashboard/components/Forms/FormsHeader.js";
import CreateForms from "./LoginDashboard/components/Forms/CreateForms.js";
import ViewForms from "./LoginDashboard/components/Forms/ViewForms.js";
import RegisterForm from "./LoginDashboard/components/Forms/RegisterForm.js";
import ListForms from "./LoginDashboard/components/Forms/ListForms.js";

import WriteBlogs from "./LoginDashboard/components/Blogs/WriteBlogs.js";
import ViewBlogs from "./LoginDashboard/components/Blogs/ViewBlogs.js";
import BlogsHeader from "./LoginDashboard/components/Blogs/BlogsHeader.js";

import UploadVideosHeader from "./LoginDashboard/components/Uploadvideos/UploadVideosHeader.js";
import UploadVideos from "./LoginDashboard/components/Uploadvideos/UploadVideos.js";
import ViewVideos from "./LoginDashboard/components/Uploadvideos/ViewVideos.js";
import Feed from "./LoginDashboard/components/Feed/Feed.js";

import AddOnPromoCodeHeader from "./LoginDashboard/components/Add-onPromoCode/Add-onPromoCodeHeader.js";
import CreateAddOnCode from "./LoginDashboard/components/Add-onPromoCode/CreateAddOnCode.js";
import ViewAddOnCode from "./LoginDashboard/components/Add-onPromoCode/ViewAddOnCode.js";
import CreatePromoCode from "./LoginDashboard/components/Add-onPromoCode/CreatePromoCode.js";
import ViewPromoCode from "./LoginDashboard/components/Add-onPromoCode/ViewPromoCode.js";

import FormInBoxHeader from "./LoginDashboard/components/FormInBox/FormInBoxHeader.js";
import ViewFormInBox from "./LoginDashboard/components/FormInBox/ViewFormInBox.js";

import TransactionsHeader from "./LoginDashboard/components/Transactions/TransactionsHeader.js";
import Tickets from "./LoginDashboard/components/Transactions/Tickets.js";
import TransactionSeasonTickets from "./LoginDashboard/components/Transactions/SeasonTickets.js";
import VideoPurchased from "./LoginDashboard/components/Transactions/VideoPurchased.js";
import { AuthProvider } from "./context/AuthContext.js";
import PrivateRoute from "./utils/PrivateRoute.js";
import VerificationRegistration from "./Auth/VerificationRegistration.js";
import UpdatePassword from "./Auth/UpdatePassword.js";
import ArtistPerticularDetails from "./Pages/Artists/components/ArtistPerticularDetails.js";
import ArtistProfile from "./Pages/Artists/components/ArtistProfile.js";

import About from "./assets/FooterTermsPage/About.js";
import Cancellation from "./assets/FooterTermsPage/Cancellation.js";
import Contact from "./assets/FooterTermsPage/Contact.js";
import Faqs from "./assets/FooterTermsPage/Faqs.js";
import PrivacyPolicy from "./assets/FooterTermsPage/PrivacyPolicy.js";
import Terms from "./assets/FooterTermsPage/Terms.js";
import AllRecords from "./Pages/MDnDRecords/AllRecords.js";

// Season
import SeasonEvents from "./LoginDashboard/components/SeasonEvents/SeasonEvents.js";
import ViewSeasonEvents from "./LoginDashboard/components/SeasonEvents/viewSeasonEvents.js";
import SeasonHeader from "./LoginDashboard/components/SeasonEvents/SeasonHeader.js";

import MyProfile from "./LoginDashboard/components/Profile/MyProfile.js";

import Invoice from "./Pages/invoice/invoice.js";

// create Seating Layout
import CreateSeatingHeader from "./LoginDashboard/components/createSeatingLayouts/createSeatingLayoutHeader.js";
import CreateSeatingLayouts from "./LoginDashboard/components/createSeatingLayouts/createSeatingLayouts.js";

// Drafts
import SavedDrafts from "./LoginDashboard/components/Events/savedDrafts.js";

// Dashboard Components

import DashBoardNotifications from "./LoginDashboard/components/Dashboard/components/DashBoardNotifications.js";
import DashBoardMyFavourites from "./LoginDashboard/components/Dashboard/components/DashBoardMyFavourites.js";

// ADDS
import AdsHeader from "./LoginDashboard/components/Ads/AdsHeader.js";
import Ads from "./LoginDashboard/components/Ads/Ads.js";
import ViewAds from "./LoginDashboard/components/Ads/ViewAds.js";
import { useNavigate } from "react-router-dom";
import MailHeader from "./LoginDashboard/components/Mailers/MailHeader.js";
import MailMember from "./LoginDashboard/components/Mailers/Members/Members.js";
import Templates from "./LoginDashboard/components/Mailers/Templates/Templates.js";
import History from "./LoginDashboard/components/Mailers/History.js";
import Mailer from "./LoginDashboard/components/Mailers/Mailer/Mailer.js";
import Stream from "./Pages/StreamMedia/Stream.js";
import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import NumberedSeatingPurchase from "./Pages/numbered_seating_purchase/numberedSeatingPurchase.js";
import SavedDraftsForms from "./LoginDashboard/components/Forms/SavedDraftsForms.js";
import ViewTicketDetails from "./Pages/viewTicketDetails/viewTicketDetails.js";
import MDnDTestRecord from "./MDnDRecords/MDnDTestRecord.js";
import AllRecordsTest from "./Pages/MDnDRecords/AllRecordsTest.js";
function App() {
  function ExternalRedirect() {
    const navigate = useNavigate();
    const { slug } = useParams();
    useEffect(() => {
      if (slug) {
        try {
          // Decode the base64 slug
          const decodedString = atob(slug); // Decodes OTk4NiMzNSMxMDEjMg== to 9986#35#101#2
          const [someValue, anotherValue, ci, e] = decodedString.split("#");

          if (someValue === "9838") {
            navigate(`/events/ProductDetails?ci=101&e=2&editEvent=cXI=`);
          } else if (someValue === "9986") {
            navigate(`/events/ProductDetails?ci=101&e=1&editEvent=cXI=`);
          } else if (someValue === "9846") {
            navigate("/events/ProductDetails?ci=101&e=54&editEvent=cXI=");
          } else if (someValue === "9837") {
            navigate(`/events/ProductDetails?ci=101&e=41&editEvent=cXI=`);
          } else if (someValue === "9836") {
            navigate(`/events/ProductDetails?ci=101&e=44&editEvent=cXI=`);
          } else if (someValue === "10083") {
            navigate(`/events/ProductDetails?ci=101&e=4&editEvent=cXI=`);
          }
        } catch (error) {
          console.error("Error decoding the slug:", error);
        }
      }
    }, [slug]);
    return null; // No need to render anything
  }

  function ExternalFormRedirect() {
    const { formId, anotherId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      if (anotherId === "3659") {
        navigate(
          "/Form/Registration_form_-_Kala_Vidya_-_Music_competition_for_the_differently-abled?fid=eHk0QVQ1Mg=="
        );
      }
    }, [anotherId, navigate]);
    return null;
  }


  
  return (
    <>
    <HelmetProvider>
      <ThemeProvider>
        <Router>
          <AuthProvider>
            <Header />
            {/* <ErrorBoundary fallback={<div>Something went wrong!</div>}> */}
            <div className="overallMinHeight">
              <Routes>
                <Route path="*" element={<PageNotFound />} />
                <Route
                  path="/"
                  element={
                    <>
                      <Landing />
                    </>
                  }
                />
                <Route
                  path="/newevent/viewevent/:slug"
                  element={<ExternalRedirect />}
                />
                <Route
                  path="/registration/form/:formId/:anotherId"
                  element={<ExternalFormRedirect />}
                />
                <Route path="/Login" element={<Front />} />
                <Route path="/Register" element={<Front />} />
                <Route
                  path="/RegisterHere"
                  element={<VerificationRegistration />}
                />
                <Route path="/forgotpassword" element={<Forgot />} />
                <Route path="/updatePassword" element={<UpdatePassword />} />

                {/* newly changed here  */}
                <Route path="/GlobalSearch" element={<GlobalSearch />} />

                <Route path="/artist" element={<Artist />} />
                <Route path="/viewTicketDetails" element={<ViewTicketDetails/>}/>
                <Route path="/artists/:genreName" element={<ArtistProfile />} />
                <Route
                  path="/artist/:artistProfile"
                  element={<ArtistPerticularDetails />}
                />

                <Route path="/events" element={<Event />} />

                <Route path="Success" element={<Success />} />
                <Route path="Failed" element={<Failed />} />

                <Route
                  path="MyProfile"
                  element={
                    <PrivateRoute>
                      <MyProfile />
                    </PrivateRoute>
                  }
                />

                {/* newly added    */}
                <Route
                  path="MyProfile/:divid"
                  element={
                    <PrivateRoute>
                      <MyProfile />
                    </PrivateRoute>
                  }
                />

                {/* end of newly added */}

                {/* newly added on sep 4  */}
                <Route path="Form/:formName" element={<RegisterForm />} />

                <Route path="SpecificPost" element={<SpecificPost />} />

                <Route
                  path="ListForms"
                  element={
                    <PrivateRoute>
                      <ListForms />
                    </PrivateRoute>
                  }
                />

                {/* PRIVATE ROUTES */}
                <Route
                  path=""
                  element={
                    <PrivateRoute>
                      <LoginDashboard />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="dashboard"
                    element={
                      <PrivateRoute>
                        <Dashboard />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="dashboard/notifications"
                    element={
                      <PrivateRoute>
                        <DashBoardNotifications />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="dashboard/myFavourites"
                    element={
                      <PrivateRoute>
                        <DashBoardMyFavourites />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="feed"
                    element={
                      <PrivateRoute>
                        <Feed />
                      </PrivateRoute>
                    }
                  ></Route>

                  <Route
                    path=""
                    element={
                      <PrivateRoute>
                        <SeasonHeader />
                      </PrivateRoute>
                    }
                  >
                    <Route
                      path="SeasonEvents"
                      element={
                        <PrivateRoute>
                          <SeasonEvents />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="ViewSeasonEvents"
                      element={
                        <PrivateRoute>
                          <ViewSeasonEvents />
                        </PrivateRoute>
                      }
                    />
                  </Route>

                  <Route
                    path=""
                    element={
                      <PrivateRoute>
                        <EventsHeader />
                      </PrivateRoute>
                    }
                  >
                    <Route
                      path="createEvents"
                      element={
                        <PrivateRoute>
                          <CreateEvents />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="viewEvents"
                      element={
                        <PrivateRoute>
                          <ViewEvents />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="savedDrafts"
                      element={
                        <PrivateRoute>
                          <SavedDrafts />
                        </PrivateRoute>
                      }
                    />
                  </Route>

                  <Route
                    path=""
                    element={
                      <PrivateRoute>
                        <TransactionsHeader />
                      </PrivateRoute>
                    }
                  >
                    <Route
                      path="tickets"
                      element={
                        <PrivateRoute>
                          <Tickets />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="TransactionSeasonTickets"
                      element={
                        <PrivateRoute>
                          <TransactionSeasonTickets />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="videoPurchased"
                      element={
                        <PrivateRoute>
                          <VideoPurchased />
                        </PrivateRoute>
                      }
                    />
                  </Route>

                  <Route path="" element={<FormsHeader />}>
                    <Route
                      path="createForms"
                      element={
                        <PrivateRoute>
                          <CreateForms />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="viewForms"
                      element={
                        <PrivateRoute>
                          <ViewForms />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="draftForms"
                      element={
                        <PrivateRoute>
                          <SavedDraftsForms />
                        </PrivateRoute>
                      }
                    />
                  </Route>

                  <Route
                    path=""
                    element={
                      <PrivateRoute>
                        <BlogsHeader />
                      </PrivateRoute>
                    }
                  >
                    <Route
                      path="writeBlogs"
                      element={
                        <PrivateRoute>
                          <WriteBlogs />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="viewBlogs"
                      element={
                        <PrivateRoute>
                          <ViewBlogs />
                        </PrivateRoute>
                      }
                    />
                  </Route>

                  <Route
                    path=""
                    element={
                      <PrivateRoute>
                        <UploadVideosHeader />
                      </PrivateRoute>
                    }
                  >
                    <Route
                      path="uploadVideos"
                      element={
                        <PrivateRoute>
                          <UploadVideos />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="viewVideos"
                      element={
                        <PrivateRoute>
                          <ViewVideos />
                        </PrivateRoute>
                      }
                    />
                  </Route>
                  {/* -----------------------------------------------------Mailer---------------------------------------------- */}
                  <Route
                    path=""
                    element={
                      <PrivateRoute>
                        <MailHeader />
                      </PrivateRoute>
                    }
                  >
                    <Route
                      path="Mailer"
                      element={
                        <PrivateRoute>
                          <Mailer />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="Tamplates"
                      element={
                        <PrivateRoute>
                          <Templates />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="Members"
                      element={
                        <PrivateRoute>
                          <MailMember />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="History"
                      element={
                        <PrivateRoute>
                          <History />
                        </PrivateRoute>
                      }
                    />
                  </Route>
                  {/* ------------------------------------------------------------------------------------------------------------------ */}
                  <Route
                    path=""
                    element={
                      <PrivateRoute>
                        <UploadVideosHeader />
                      </PrivateRoute>
                    }
                  >
                    <Route
                      path="uploadVideos"
                      element={
                        <PrivateRoute>
                          <UploadVideos />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="viewVideos"
                      element={
                        <PrivateRoute>
                          <ViewVideos />
                        </PrivateRoute>
                      }
                    />
                  </Route>

                  <Route
                    path=""
                    element={
                      <PrivateRoute>
                        <AddOnPromoCodeHeader />
                      </PrivateRoute>
                    }
                  >
                    <Route
                      path="createAddOnCode"
                      element={
                        <PrivateRoute>
                          <CreateAddOnCode />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="viewAddOnCode"
                      element={
                        <PrivateRoute>
                          <ViewAddOnCode />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="createPromoCode"
                      element={
                        <PrivateRoute>
                          <CreatePromoCode />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="viewPromoCode"
                      element={
                        <PrivateRoute>
                          <ViewPromoCode />
                        </PrivateRoute>
                      }
                    />
                  </Route>

                  <Route
                    path=""
                    element={
                      <PrivateRoute>
                        <FormInBoxHeader />
                      </PrivateRoute>
                    }
                  >
                    <Route
                      path="viewFormInBox"
                      element={
                        <PrivateRoute>
                          <ViewFormInBox />
                        </PrivateRoute>
                      }
                    />
                  </Route>
                  {/* ------------------------------------------------Mailer----------------------------------------------------------- */}
                  <Route
                    path=""
                    element={
                      <PrivateRoute>
                        <MailHeader />
                      </PrivateRoute>
                    }
                  >
                    <Route
                      path="Mailer"
                      element={
                        <PrivateRoute>
                          <Mailer />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="Tamplates"
                      element={
                        <PrivateRoute>
                          <Templates />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="Members"
                      element={
                        <PrivateRoute>
                          <MailMember />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="History"
                      element={
                        <PrivateRoute>
                          <History />
                        </PrivateRoute>
                      }
                    />
                  </Route>
                  {/* ---------------------------------------------------------------------------------------------------- */}
                  <Route
                    path=""
                    element={
                      <PrivateRoute>
                        <AdsHeader />
                      </PrivateRoute>
                    }
                  >
                    <Route
                      path="Ads"
                      element={
                        <PrivateRoute>
                          <Ads />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="ViewAds/:base64"
                      element={
                        <PrivateRoute>
                          <ViewAds />
                        </PrivateRoute>
                      }
                    />
                    {/* <Route
                      path="UploadAds"
                      element={
                        <PrivateRoute>
                          <UploadAds />
                        </PrivateRoute>
                      }
                    /> */}
                  </Route>

                  {/* Create seating layout */}

                  <Route
                    path=""
                    element={
                      <PrivateRoute>
                        <CreateSeatingHeader />
                      </PrivateRoute>
                    }
                  >
                    <Route
                      path="createSeatingLayout"
                      element={
                        <PrivateRoute>
                          <CreateSeatingLayouts />
                        </PrivateRoute>
                      }
                    />
                  </Route>
                </Route>

                <Route path="/franchisees" element={<Franchisees />} />
                <Route
                  path="/franchisees/:artistProfile"
                  element={<ProfileSingle />}
                />

                <Route path="/organisers" element={<Organisers />} />
                <Route
                  path="/organiser/:artistProfile"
                  element={<ProfileSingle />}
                />
                <Route path="/stream/:slug" element={<Stream />} />

                <Route path="events" element={<ListingEvents />}>
                  <Route
                    path=":selectedEvent"
                    element={<ListingEvents />}
                  ></Route>
                </Route>
                <Route
                  path="events/ProductDetails"
                  element={<ProductDetails />}
                />
                <Route
                  path="events/numberedSeatings"
                  element={<NumberedSeatingPurchase />}
                />
                <Route path="/invoice" element={<Invoice />} />

                <Route path="/checkOut" element={<ProductSummary />} />

                <Route
                  path="/ProductCountdown"
                  element={<ProductCountdown />}
                />

                <Route path="SeasonTickets" element={<SeasonTickets />} />
                <Route
                  path="SeasonTickets/seasonEventDetails"
                  element={<SeasonEventDetails />}
                />

                <Route path="/MDndStore" element={<MDndStore />} />

                <Route path="/myCart" element={<AddtoCart />} />

                <Route path="/VideoStore" element={<VideoStore />} />

                <Route
                  path="/MDnDRecords/:category"
                  element={<MDnDRecords />}
                />
                {/* <Route
                  path="/MDnDRecordsTest/:category"
                  element={<MDnDTestRecord/>}
                /> */}
                <Route path="/MDnDRecords" element={<AllRecords />} />
                <Route path ="/MDndRecordsTest" element={<AllRecordsTest/>}/>
                <Route
                  path="/RecordsVideo/videos/:slug"
                  element={<RecordsVideo />}
                />
                <Route path="/RagaBank" element={<RagaBank />} />

                <Route
                  path="/RagaBankVideo/videos/:slug"
                  element={<RagaBankVideo />}
                />

                <Route path="/MDnDLive" element={<MDnDLive />} />

                <Route path="/blog" element={<Blog />} />
                <Route
                  path="/blogs/:artistProfile"
                  element={<Blogfourcolmn />}
                />
                <Route path="/blog/:title" element={<SingleBlog />} />

                <Route path="/confirmRegistration" element={<Registration />} />

                <Route path="/About" element={<About />} />
                <Route path="/Cancellation" element={<Cancellation />} />
                <Route path="/Contact" element={<Contact />} />
                <Route path="/Faqs" element={<Faqs />} />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                <Route path="/Terms" element={<Terms />} />
                {/* <Route path="/test" element={<ToggleButton/>}/> */}
              </Routes>
            </div>
            <Footer />
            {/* </ErrorBoundary> */}
          </AuthProvider>
        </Router>
      </ThemeProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
