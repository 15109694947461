import React from "react";
import team1 from "../images/pages/team1.webp";
import team2 from "../images/pages/team2.webp";
import team3 from "../images/pages/team3.webp";
import team4 from "../images/pages/team4.webp";

function About() {
  return (
    <>
      <div className="iq-breadcrumb organBanner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb" className="text-center">
                <h2 className="title text-white">About</h2>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className={`section-padding pt-lg-3`}>
        <div className="container px-3 aboutTextPara">
          <div className="row align-items-center">
            <div className="col-lg-3"></div>
            <div className="col-lg-6 col-md-12">
              <div className="text-center">
                <h2>About</h2>
                <p className="text-center">MDnD stands for ‘Music, Dance and Drama’.</p>
                {/* <p>
                  On <a href="https://www.mdnd.in">www.mdnd.in</a>, you can-
                </p> */}
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
          <div className="row about-us-detail">
            <div className="col-md-3 mb-4">
              <div className="image-box">
                <img src={team1} className="img-fluid w-100" alt="team" />
              </div>
              <div className="box-content">
                <h5 className="mt-1">Discover Events</h5>
                <p>
                  Fans can discover upcoming music, dance or drama events in
                  Indian Performing Arts, whether FREE or ticketed and whether
                  Offline or Online or Hybrid.
                </p>
                <p>
                  Artists and organisers / event managers can register at{" "}
                  <a href="https://www.mdnd.in/register">
                    www.mdnd.in/register
                  </a>{" "}
                  and upload details of their upcoming events at No Cost. The
                  events will then appear on{" "}
                  <a href="https://www.mdnd.in/event/newevent">
                    www.mdnd.in/event/newevent
                  </a>{" "}
                  . The event label can be promoted by sharing on WhatsApp,
                  Facebook, Instagram and Twitter or by email.
                </p>
                <p>
                  For support, please mail to{" "}
                  <a href="mailto:events@mdnd.in">events@mdnd.in</a>.
                </p>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="image-box">
                <img src={team2} className="img-fluid w-100" alt="team" />
              </div>
              <div className="box-content">
                <h5 className="mt-1">Discover Artists</h5>
                <p>
                  Fans, prospective clients and organisers can browse the ‘Wall
                  of Artists’{" "}
                  <a href="https://www.mdnd.in/artists">www.mdnd.in/artists</a>{" "}
                  or ‘Wall of Junior Artists’{" "}
                  <a href="https://www.mdnd.in/youngartists">
                    www.mdnd.in/youngartists
                  </a>{" "}
                  and identify artists, watch their videos, discover their
                  upcoming events, follow and engage with them.
                </p>
                <p>
                  Artists can register at{" "}
                  <a href="https://www.mdnd.in/register">
                    www.mdnd.in/register
                  </a>{" "}
                  and subscribe to Star Membership at a very nominal cost to
                  appear on the ‘Wall of Artists’ or ‘Wall of Junior Artists’,
                  as the case may be. Artist profiles that are complete in terms
                  of pictures, links to videos and having good number of
                  followers are likely to get greater attention and followers.
                  Profile links can be easily shared by WhatsApp or social
                  media.
                </p>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="image-box">
                <img src={team3} className="img-fluid w-100" alt="team" />
              </div>
              <div className="box-content">
                <h5 className="mt-1">Discover Live Streamed Events</h5>
                <p>
                  Fans can visit{" "}
                  <a href="https://www.mdnd.in/mdndlive">
                    www.mdnd.in/mdndlive
                  </a>{" "}
                  to watch ‘Live’ or ‘Premiered’ events by various artists. Past
                  recordings are also available for viewing.
                </p>
                <p>
                  Artists or organisers who want to ‘Live Stream’ their concerts
                  or even festivals at a nominal cost can email to{" "}
                  <a href="mailto:events@mdnd.in">events@mdnd.in</a>.
                </p>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="image-box">
                <img src={team4} className="img-fluid w-100" alt="team" />
              </div>
              <div className="box-content">
                <h5 className="mt-1">Discover Videos for Sale</h5>
                <p>
                  Fans can discover and buy videos put up for sale by different
                  artists and organisations at{" "}
                  <a href="https://www.mdnd.in/videostore">
                    www.mdnd.in/videostore
                  </a>{" "}
                  . These can be viewed online at any time and from anywhere,
                  but cannot be downloaded.
                </p>
                <p>
                  Artists or organisations who want to put up their videos for
                  sale on a nominal revenue-sharing basis may email to{" "}
                  <a href="mailto:events@mdnd.in">events@mdnd.in</a>.
                </p>
                <p>
                  Look out for more products, services and features as we
                  onboard more stakeholders in Indian Performing Arts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
