import React, { useState, useEffect, useRef } from "react";
import HtmlEditor, {
  Toolbar,
  MediaResizing,
  ImageUpload,
  Item,
} from "devextreme-react/html-editor";
import {
  DateBox,
  TextBox,
  SelectBox,
  TagBox,
  NumberBox,
  TextArea,
} from "devextreme-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import notify from "devextreme/ui/notify";
import { useFormik } from "formik";
import TicketDetailsGrid from "../Events/ticketDetailsGrid";
import Button from "devextreme-react/button";
import FileUploader from "devextreme-react/file-uploader";
import { markup, tabs } from "../../../components/others";
import { CREATE_SEASON_SCHEMA } from "../../../Schemas/Forms/LoginDashboard";
import {
  sizeValues,
  fontValues,
  headerValues,
  fontSizeOptions,
  fontFamilyOptions,
  headerOptions,
} from "../../../components/others";
import { seasonMode, seasonShowToPublic } from "../../../global/constants";
import { Tag, TagItem } from "../../../components/TagBox.js";
import { CheckBox } from "devextreme-react";
import { openEditor } from "react-profile";
import "react-profile/themes/default";
import { makeGetRequest } from "../../../Api/requestMethods.js";
import { COUNTRY } from "../../../Api/api.js";
import {
  ERROR,
  DURATION,
  SUCCESS,
} from "../../../global/ToastMsgConstants.tsx";
import {
  cityFetch,
  stateFetch,
  OrganiserDetailsFetch,
  getAllEventsTitle,
  organiserDetailsAllFetch,
} from "../Events/EventsFetch.js";
import {
  seasonStartDate,
  ClickToBuy,
  clickToBuyMultiple,
  seasonModePaid,
  seasonModeRegister,
  seasonModeTicketed,
  TicketingOptionMockData,
  EventModeOffline,
  EventModeOnline,
  EventMode,
  ticketedonmdnd,
  seatingPlanNonNumbered,
  PromoCode,
  AddOn,
} from "../../../global/constants";
import { SEASON_EVENTS_SCHEMA } from "../../../Schemas/Events.js";
import { yearMonthAndDay } from "../../../utils/dateFormatUtils.js";
import moment from "moment";
import { dateFormat } from "../../../global/constants";
import SearchLocationInput from "../../../components/Maps/googlePlaces.js";
import MapComponent from "../../../components/Maps/Map.js";
import { bookingWillOpenSoon } from "../../../global/constants";
import { makePostRequest } from "../../../Api/requestMethods.js";
import { CREATE_EVENTS } from "../../../Api/api.js";
import { useContext } from "react";
import AuthContext from "../../../context/AuthContext.js";
import { MapLocationEmbeded } from "../../../utils/commonUtils.js";
import AddPromoCodeGrid from "../Events/addPromoCodeGrid.js";
import AddOnCodeGrid from "../Events/addAddOnCodeGrid.js";
import { getAddOnCode, getPromoCode } from "../Events/EventsFetch.js";
import { isValidValue } from "../../../utils/commonUtils.js";

const SeasonEvents = () => {
  const [isMultiline, setIsMultiline] = useState(true);
  const [currentTab, setCurrentTab] = useState(tabs[2].value);
  const seasonEventOrganiserRef = useRef();
  const { user } = useContext(AuthContext);

  const [dataSource, setDataSource] = useState({
    mainCategoryDataSource: "",
    subCategoryDataSource: "",
    artistDetails: "",
    stateDataSource: "",
    cityDataSource: "",
    eventMode: "",
    accompanistDetails: "",
    organiserDetails: "",
    quickAddDataSource: [],
    seating_nonNumbered: [],
    tempOrganiserDetail: "",
    countryDataSource: "",
    getAllEventsDatasource: "",
    promoCodeDataSource: "",
    addOnCodeDataSource: "",
  });

  const [settings, setSettings] = useState({
    hasFocusedIn: false,
    timeZoneFocusIn: false,
    countryFocusIn: false,
    stateFocusIn: false,
    cityFocusIn: false,
    multipleCheckBox: false,
    timeZoneFocus: false,
    buttonActionEnable: false,
    quickAddEnable: false,
    imageName: "",
    isTicketLimitationEnabled: false,
    isSubCategoryValid: false,
  });

  const [selectedLocation, setSelectedLocation] = useState({
    lat: null,
    lng: null,
  });

  useEffect(() => {
    if (selectedLocation.lat && selectedLocation.lng) {
      const MapDetails = MapLocationEmbeded(
        selectedLocation.lat,
        selectedLocation.lng
      );
      setFieldValue("location", MapDetails);
    }
  }, [selectedLocation]);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: CREATE_SEASON_SCHEMA,
    validationSchema: SEASON_EVENTS_SCHEMA,
    onSubmit: async (values, { setSubmitting }) => {
      if (
        (values.ticketing_option === ticketedonmdnd &&
          dataSource.seating_nonNumbered &&
          dataSource.seating_nonNumbered.length === 0) ||
        (values.ticketing_option === seasonModeRegister &&
          dataSource.seating_nonNumbered &&
          dataSource.seating_nonNumbered.length === 0)
      )
      return notify("Ticket configuration is required", ERROR, DURATION);
      await createSeasonEvents(dataSource.quickAddDataSource);
      setSubmitting(false);
    },
  });

  const createSeasonEvents = async () => {
    try {
      values.role_id = user.role_id;
      values.ticket_details = dataSource.seating_nonNumbered;

      const response = await makePostRequest(CREATE_EVENTS(), values);
      if (response && response.status === SUCCESS) {
        notify(response?.message, SUCCESS, DURATION);
        resetForm();
        setFieldValue("season_start_date", seasonStartDate);
      }
    } catch (error) {
      notify(error);
      notify(error.response.data.message, ERROR, DURATION);
    }
  };

  useEffect(() => {
    // Season Mode - Ticked on MDnD
    if (values.ticketing_option === seasonModeTicketed) {
      !values.multiple_tickets
        ? setFieldValue("season_button_text", ClickToBuy)
        : setFieldValue("season_button_text", clickToBuyMultiple);

      setFieldValue("isFreeWithRegistrationEnabled", false);

      setSettings((prev) => ({
        ...prev,
        isTicketLimitationEnabled: true,
      }));

      setFieldValue("isAmountEnable", values.event_mode === EventModeOnline);
    }

    // Season Mode - Free with registration
    if (values.ticketing_option === seasonModeRegister) {
      setSettings((prev) => ({
        ...prev,
        isTicketLimitationEnabled: values.event_mode === EventModeOffline,
      }));
      setFieldValue(
        "isTicketLimitationEnabled",
        values.event_mode === EventModeOffline ? true : false
      );
    }
  }, [
    values.multiple_tickets,
    values.ticketing_option,
    values.season_start_date,
    values.event_mode,
  ]);

  useEffect(() => {
    if (values?.multiple_tickets) {
    } else {
      setFieldValue("season_event_ids", null);
    }
  }, [values?.multiple_tickets]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  }

  useEffect(() => {
    if (isValidValue(values.season_start_date)) {
      setFieldValue("season_end_date", formatDate(values.season_start_date));
    }
  }, [values.season_start_date]);

  console.log(errors, "errr");

  // Promo code mutation
  const promoCodeMutation = useMutation({
    mutationFn: (promoCodeData) => getPromoCode(promoCodeData),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, promoCodeDataSource: data?.data }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  // addOn code mutation
  const addOnCodeMutation = useMutation({
    mutationFn: (addOncodeData) => getAddOnCode(addOncodeData),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, addOnCodeDataSource: data?.data }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  useEffect(() => {
    const body = {
      role_id: user.role_id,
    };
    if (user.role_id && values.is_promo_code) {
      promoCodeMutation.mutate(body);
    }
    if (user.role_id && values.is_addon_code) {
      addOnCodeMutation.mutate(body);
    }
  }, [user.role_id, values.is_promo_code, values.is_addon_code]);

  // To Fetch Countries
  const countryEvent = async () =>
    settings.countryFocusIn && (await makeGetRequest(COUNTRY()));
  const { data: countryDataSource } = useQuery({
    queryKey: ["Seasoncountry"],
    queryFn: () => countryEvent(),
    enabled: settings.countryFocusIn,
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

    useEffect(() => {
      setFieldValue("country_id", "");
      if (countryDataSource && countryDataSource.data.length > 0) {
        if (values.ticketing_option === ticketedonmdnd) {
          const countryIds = [101, 231];
          const countryForTicketed =
            countryDataSource &&
            countryDataSource.data.filter((data) =>
              countryIds.includes(data.country_id)
            );

          setDataSource((prev) => ({
            ...prev,
            countryDataSource: countryForTicketed,
          }));
        } else {
          setDataSource((prev) => ({
            ...prev,
            countryDataSource: countryDataSource.data,
          }));
        }
      }
    }, [countryDataSource, values.ticketing_option]);

  const countryFocusIn = () => {
    setSettings((prev) => ({ ...prev, countryFocusIn: true }));
  };

  const Organisermutation = useMutation({
    mutationFn: (organiserDetails) =>
      organiserDetailsAllFetch(organiserDetails),
    onSuccess: (data) => {
      data &&
        setDataSource((prev) => ({ ...prev, organiserDetails: data.data }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  useEffect(() => {
    const body = {
      user_role: "organiser",
    };
    Organisermutation.mutate(body);
  }, []);

  const stateMutation = useMutation({
    mutationFn: (state_id) => stateFetch(state_id),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, stateDataSource: data?.data }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  const cityMutation = useMutation({
    mutationFn: (state_id) => cityFetch(state_id),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, cityDataSource: data?.data }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  const getAllEventsTitleData = useMutation({
    mutationFn: (data) => getAllEventsTitle(data),
    onSuccess: (data) => {
      setDataSource((prev) => ({
        ...prev,
        getAllEventsDatasource: data?.data,
      }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });
  useEffect(() => {
    const body = {
      role_id: user.role_id,
      country_id: values.country_id,
      event_mode: values.event_mode,
    };
    if (
      values.country_id &&
      values.country_id !== null &&
      values.country !== "" &&
      values.event_mode &&
      values.event_mode !== "" &&
      values.event_mode !== null &&
      user.role_id &&
      user.role_id !== null
    ) {
      getAllEventsTitleData.mutate(body);
    }
  }, [values.country_id, values.event_mode]);

  useEffect(() => {
    if (
      values.country_id &&
      values.country_id !== "" &&
      values.country_id !== null
    ) {
      const body = {
        country_id: values.country_id,
      };
      stateMutation.mutate(body);
    }
  }, [values.country_id]);

  useEffect(() => {
    if (values.state_id && values.state_id !== "" && values.state_id !== null) {
      const body = {
        state_id: values.state_id,
      };
      cityMutation.mutate(body);
    }
  }, [values.state_id]);

  const imageOnchange = async (e) => {
    setSettings((prev) => ({ ...prev, imageName: e?.value[0]?.name }));

    try {
      const imageFile = e?.value[0];
      if (!imageFile) {
        console.error("No image file provided");
      }
      const initialCrop = {
        x: 0,
        y: 0,
        width: 400,
        height: 400,
        unit: "px",
      };

      const editedImageBlob = await openEditor({
        src: URL.createObjectURL(imageFile),
        cropOptions: {
          aspectRatio: 1,
          maxWidth: 400,
          maxHeight: 400,
          minWidth: 400,
          minHeight: 400,
          center: true,
        },
        initCrop: initialCrop,
      });

      if (
        editedImageBlob &&
        typeof editedImageBlob.editedImage.getBlob === "function"
      ) {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result;
          setFieldValue("event_image", base64String);
        };
        reader.readAsDataURL(await editedImageBlob.editedImage.getBlob());
      } else {
        console.error(
          "Failed to obtain the edited image Blob or getBlob method is not available."
        );
      }
    } catch (error) {
      console.error("Error while processing image:", error);
    }
  };

  return (
    <div className="tab-content tabBodyBg">
      <div
        id="createevent"
        className="tab-pane animated fadeInUp active show"
        role="tabpanel"
      >
        <form onSubmit={handleSubmit}>
          <div className="description-content">
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-2">
                  <TextBox
                    type="text"
                    labelMode="floating"
                    className="dx-field-item-required"
                    required="required"
                    mode="floating"
                    label="Title"
                    value={values.season_event_title}
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "season_event_title", value: e.value },
                      })
                    }
                    onValueChanged={(e) =>
                      handleChange({
                        target: { name: "season_event_title", value: e.value },
                      })
                    }
                  />
                  {errors.season_event_title && touched.season_event_title && (
                    <p className="error-message">{errors.season_event_title}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <DateBox
                    className="dx-field-item-required"
                    label="Start Date"
                    labelMode="floating"
                    min={seasonStartDate}
                    acceptCustomValue={false}
                    value={values.season_start_date || null}
                    displayFormat={() =>
                      values.season_start_date !== ""
                        ? moment(values.season_start_date).format(
                            dateFormat || "DD-MM-YYYY"
                          )
                        : ""
                    }
                    onValueChanged={(e) =>
                      handleChange({
                        target: {
                          name: "season_start_date",
                          value: yearMonthAndDay(e.value),
                        },
                      })
                    }
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "season_start_date", value: e.value },
                      })
                    }
                  />
                  {errors.season_start_date && touched.season_start_date && (
                    <p className="error-message">{errors.season_start_date}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <DateBox
                    label="End Date"
                    labelMode="floating"
                    min={values.season_start_date}
                    acceptCustomValue={false}
                    disabled={
                      values.season_start_date === "" ||
                      values.season_start_date === undefined
                    }
                    className={"dx-field-item-required"}
                    value={values.season_end_date}
                    displayFormat={() =>
                      values.season_end_date !== ""
                        ? moment(values.season_end_date).format(
                            dateFormat || "DD-MM-YYYY"
                          )
                        : ""
                    }
                    onValueChanged={(e) =>
                      handleChange({
                        target: {
                          name: "season_end_date",
                          value: yearMonthAndDay(e.value),
                        },
                      })
                    }
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "season_end_date", value: e.value },
                      })
                    }
                  />
                  {errors.season_end_date && touched.season_end_date && (
                    <p className="error-message">{errors.season_end_date}</p>
                  )}
                </div>
              </div>
              {/* Description Box */}
              <div className="col-lg-12">
                <div className="form-group mb-2">
                  <div className="widget-container">
                    <HtmlEditor
                      height="auto"
                      defaultValue={markup}
                      label="Season Description"
                      className="dx-field-item-required"
                      labelMode="floating"
                      placeholder="Event Description"
                      value={values.season_description}
                      onFocusOut={(e) =>
                        handleBlur({
                          target: {
                            name: "season_description",
                            value: e.value,
                          },
                        })
                      }
                      onValueChanged={(e) =>
                        handleChange({
                          target: {
                            name: "season_description",
                            value: e.value,
                          },
                        })
                      }
                    >
                      <MediaResizing enabled={true} />
                      <ImageUpload tabs={currentTab} fileUploadMode="base64" />
                      <Toolbar multiline={isMultiline}>
                        <Item name="undo" />
                        <Item name="redo" />
                        <Item name="separator" />
                        <Item
                          name="size"
                          acceptedValues={sizeValues}
                          options={fontSizeOptions}
                        />
                        <Item
                          name="font"
                          acceptedValues={fontValues}
                          options={fontFamilyOptions}
                        />
                        <Item name="separator" />
                        <Item name="bold" />
                        <Item name="italic" />
                        <Item name="strike" />
                        <Item name="underline" />
                        <Item name="separator" />
                        <Item name="alignLeft" />
                        <Item name="alignCenter" />
                        <Item name="alignRight" />
                        <Item name="alignJustify" />
                        <Item name="separator" />
                        <Item name="orderedList" />
                        <Item name="bulletList" />
                        <Item name="separator" />
                        <Item
                          name="header"
                          acceptedValues={headerValues}
                          options={headerOptions}
                        />
                        <Item name="separator" />
                        <Item name="color" />
                        <Item name="background" />
                        <Item name="separator" />
                        <Item name="link" />
                        <Item name="image" />
                        <Item name="separator" />
                        <Item name="clear" />
                        <Item name="codeBlock" />
                        <Item name="blockquote" />
                        <Item name="separator" />
                        <Item name="insertTable" />
                        <Item name="deleteTable" />
                        <Item name="insertRowAbove" />
                        <Item name="insertRowBelow" />
                        <Item name="deleteRow" />
                        <Item name="insertColumnLeft" />
                        <Item name="insertColumnRight" />
                        <Item name="deleteColumn" />
                      </Toolbar>
                    </HtmlEditor>
                    {errors.season_description &&
                      touched.season_description && (
                        <p className="error-message">
                          {errors.season_description}
                        </p>
                      )}
                  </div>
                </div>
              </div>

              {/* Ticketing option */}
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <SelectBox
                    label="Ticketing option"
                    labelMode="floating"
                    className="dx-field-item-required"
                    dataSource={seasonMode || []}
                    displayExpr="name"
                    valueExpr="value"
                    value={values.ticketing_option}
                    onValueChanged={(e) =>
                      handleChange({
                        target: { name: "ticketing_option", value: e.value },
                      })
                    }
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "ticketing_option", value: e.value },
                      })
                    }
                    searchEnabled={true}
                  />
                  {errors.ticketing_option && touched.ticketing_option && (
                    <p className="error-message">{errors.ticketing_option}</p>
                  )}
                </div>
              </div>
              {/* Event Mode */}
              {values.ticketing_option &&
                values.ticketing_option !== "" &&
                values.ticketing_option !== null && (
                  <div className="col-lg-6">
                    <div className="form-group mb-2">
                      <SelectBox
                        label="Event Mode"
                        className="dx-field-item-required"
                        labelMode="floating"
                        dataSource={EventMode}
                        displayExpr="mode"
                        valueExpr="value"
                        onValueChanged={(e) =>
                          handleChange({
                            target: { name: "event_mode", value: e.value },
                          })
                        }
                        onFocusOut={(e) =>
                          handleBlur({
                            target: { name: "event_mode", value: e.value },
                          })
                        }
                        value={values.event_mode}
                      />
                      {errors.event_mode && touched.event_mode && (
                        <p className="error-message">{errors.event_mode}</p>
                      )}
                    </div>
                  </div>
                )}

              {values.ticketing_option &&
                values.ticketing_option !== "" &&
                values.ticketing_option !== null && (
                  <>
                    <div className="col-lg-4">
                      <div className="form-group mb-2">
                        <SelectBox
                          label="Country"
                          onFocusIn={countryFocusIn}
                          displayExpr="country_name"
                          className="dx-field-item-required"
                          labelMode="floating"
                          valueExpr="country_id"
                          value={values.country_id}
                          dataSource={dataSource.countryDataSource || []}
                          searchEnabled={true}
                          onValueChanged={(e) =>
                            handleChange({
                              target: { name: "country_id", value: e.value },
                            })
                          }
                          onFocusOut={(e) =>
                            handleBlur({
                              target: { name: "country_id", value: e.value },
                            })
                          }
                        />
                        {errors.country_id && touched.country_id && (
                          <p className="error-message">{errors.country_id}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group mb-2">
                        <SelectBox
                          label="State"
                          displayExpr="state_name"
                          className="dx-field-item-required"
                          labelMode="floating"
                          valueExpr="state_id"
                          value={values.state_id}
                          dataSource={dataSource.stateDataSource || []}
                          onValueChanged={(e) =>
                            handleChange({
                              target: { name: "state_id", value: e.value },
                            })
                          }
                          onFocusOut={(e) =>
                            handleBlur({
                              target: { name: "state_id", value: e.value },
                            })
                          }
                          searchEnabled={true}
                        />
                        {errors.state_id && touched.state_id && (
                          <p className="error-message">{errors.state_id}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group mb-2">
                        <SelectBox
                          label="City"
                          displayExpr="city_name"
                          className="dx-field-item-required"
                          valueExpr="city_id"
                          labelMode="floating"
                          value={values.city_id}
                          dataSource={dataSource.cityDataSource || null}
                          onValueChanged={(e) =>
                            handleChange({
                              target: { name: "city_id", value: e.value },
                            })
                          }
                          onFocusOut={(e) =>
                            handleBlur({
                              target: { name: "city_id", value: e.value },
                            })
                          }
                          searchEnabled={true}
                        />
                        {errors.city_id && touched.city_id && (
                          <p className="error-message">{errors.city_id}</p>
                        )}
                      </div>
                    </div>
                  </>
                )}

              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <TagBox
                    type="text"
                    dataSource={dataSource.organiserDetails || []}
                    labelMode="floating"
                    label="Organisers"
                    displayExpr="organiser_name"
                    valueExpr="organiser_id"
                    searchEnabled={true}
                    onValueChanged={(e) =>
                      handleChange({
                        target: { name: "season_organiser_id", value: e.value },
                      })
                    }
                    itemRender={(item) => (
                      <TagItem name={item.organiser_name} image={item.photo} />
                    )}
                    tagRender={(data) => (
                      <Tag name={data.organiser_name} image={data.photo} />
                    )}
                  />
                  {errors.season_organiser_id &&
                    touched.season_organiser_id && (
                      <p className="error-message">
                        {errors.season_organiser_id}
                      </p>
                    )}
                </div>
              </div>
              {/* Event List */}
              {/* Seating plan */}
              {values.ticketing_option === seasonModeTicketed &&
                values.event_mode === EventModeOffline && (
                  <div className="col-lg-6 align-items-center">
                    <div className="form-group d-flex align-items-center mb-0">
                      <TextBox
                        label="Seating Plan"
                        className="dx-field-item-required w-100"
                        labelMode="floating"
                        value={"Non-numbered"}
                        readOnly={true}
                      />
                    </div>
                  </div>
                )}
              {/* 
              {settings.isTicketLimitationEnabled === true && (
                <div className="col-lg-6">
                  <div className="form-group mb-2">
                    <NumberBox
                      label="Ticket limit per transaction"
                      className="dx-field-item-required"
                      labelMode="floating"
                      displayExpr="mode"
                      valueExpr="value"
                      onValueChanged={(e) =>
                        handleChange({
                          target: { name: "ticket_limitation", value: e.value },
                        })
                      }
                      onFocusOut={(e) =>
                        handleBlur({
                          target: { name: "ticket_limitation", value: e.value },
                        })
                      }
                      value={values.ticket_limitation}
                    />
                    {errors.ticket_limitation && touched.ticket_limitation && (
                      <p className="error-message">
                        {errors.ticket_limitation}
                      </p>
                    )}
                  </div>
                </div>
              )} */}
              </div>
              {values?.ticketing_option === seasonModeTicketed && (
                <TicketDetailsGrid
                  dataSource={dataSource.seating_nonNumbered}
                  values={values}
                  setDataSource={setDataSource}
                />
              )}
              {/* {values.event_mode === EventModeOnline &&
                values.ticketing_option === seasonModeTicketed &&
                values.country_id === 101 && (
                  <>
                    <div className="col-lg-6">
                      <div className="form-group mb-2">
                        <NumberBox
                          label="Enter INR"
                          className="dx-field-item-required"
                          labelMode="floating"
                          onValueChanged={(e) =>
                            handleChange({
                              target: { name: "amt_inr", value: e.value },
                            })
                          }
                          onFocusOut={(e) =>
                            handleBlur({
                              target: {
                                name: "amt_inr",
                                value: e.value,
                              },
                            })
                          }
                          value={values.amt_inr}
                        />
                        {errors.amt_inr && touched.amt_inr && (
                          <p className="error-message">{errors.amt_inr}</p>
                        )}
                      </div>
                    </div>
                  </>
                )}
              {values.event_mode === EventModeOnline &&
                values.ticketing_option === seasonModeTicketed &&
                values.country_id !== 101 && (
                  <div className="col-lg-6">
                    <div className="form-group mb-2">
                      <NumberBox
                        label="Enter USD"
                        className="dx-field-item-required"
                        labelMode="floating"
                        onValueChanged={(e) =>
                          handleChange({
                            target: { name: "amt_usd", value: e.value },
                          })
                        }
                        onFocusOut={(e) =>
                          handleBlur({
                            target: {
                              name: "amt_usd",
                              value: e.value,
                            },
                          })
                        }
                        value={values.amt_usd}
                      />
                      {errors.amt_usd && touched.amt_usd && (
                        <p className="error-message">{errors.amt_usd}</p>
                      )}
                    </div>
                  </div>
                )} */}
              {/* {!Season Mode paid} */}
              <div className="row">
              {values?.ticketing_option === seasonModeRegister &&
                values.event_mode === EventModeOffline && (
                  <>
                    <div className="col-lg-6 mb-2">
                      <div className="form-group mb-2">
                        <NumberBox
                          label="Row / Class"
                          className="dx-field-item-required"
                          labelMode="floating"
                          value={values.row_name}
                          onValueChanged={(e) =>
                            handleChange({
                              target: { name: "row_name", value: e.value },
                            })
                          }
                          onFocusOut={(e) =>
                            handleBlur({
                              target: {
                                name: "row_name",
                                value: e.value,
                              },
                            })
                          }
                        />
                        {errors.row_name && touched.row_name && (
                          <p className="error-message">{errors.row_name}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 mb-2">
                      <div className="form-group mb-2">
                        <NumberBox
                          label="Ticket Qty"
                          className="dx-field-item-required"
                          labelMode="floating"
                          min={0}
                          value={values.totalqty}
                          onValueChanged={(e) =>
                            handleChange({
                              target: { name: "totalqty", value: e.value },
                            })
                          }
                          onFocusOut={(e) =>
                            handleBlur({
                              target: {
                                name: "totalqty",
                                value: e.value,
                              },
                            })
                          }
                        />
                        {errors.totalqty && touched.totalqty && (
                          <p className="error-message">{errors.totalqty}</p>
                        )}
                      </div>
                    </div>
                  </>
                )}
              {/* 


              {/* Booking will open soon check box */}
              {values?.ticketing_option === seasonModeTicketed && (
                <div className="col-lg-6 d-flex align-items-center">
                  <div className="form-group d-flex align-items-center mb-2 mt-3 gap-2">
                    <CheckBox
                      value={values.is_booking_open}
                      onValueChanged={(e) =>
                        handleChange({
                          target: {
                            name: "is_booking_open",
                            value: e.value,
                          },
                        })
                      }
                    />

                    <span className="" style={{ fontSize: "14px" }}>
                      {bookingWillOpenSoon}
                    </span>
                  </div>
                </div>
              )}
              {/* 
              Button Text */}
              {/* <div className="col-lg-6">
                <div className="form-group mb-2">
                  <TextBox
                    label="Enter text"
                    className="dx-field-item-required"
                    labelMode="floating"
                    onValueChanged={(e) =>
                      handleChange({
                        target: { name: "season_button_text", value: e.value },
                      })
                    }
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "season_button_text", value: e.value },
                      })
                    }
                    value={values.season_button_text}
                  />
                  {errors.season_button_text && touched.season_button_text && (
                    <p className="error-message">{errors.season_button_text}</p>
                  )}
                </div>
              </div> */}
              {/* Seating plan */}
              {/* {values.ticketing_option === seasonModeTicketed &&
                values.event_mode === EventModeOffline && (
                  <div className="col-lg-6 align-items-center">
                    <div className="form-group d-flex align-items-center mb-0">
                      <TextBox
                        label="Seating Plan"
                        className="dx-field-item-required w-100"
                        labelMode="floating"
                        value={"Non-numbered"}
                        readOnly={true}
                      />
                    </div>
                  </div>
                )} */}
              {values.ticketing_option === ticketedonmdnd &&
                values.event_mode === EventModeOffline &&
                values.seating_option === seatingPlanNonNumbered && (
                  <div className="col-lg-6 mt-2 d-flex align-items-center">
                    <div className="form-group d-flex align-items-center mb-2 gap-2">
                      <CheckBox
                        onValueChanged={(e) =>
                          handleChange({
                            target: {
                              name: "is_promo_code",
                              value: e.value,
                            },
                          })
                        }
                        defaultValue={values.is_promo_code}
                      />

                      <span className="" style={{ fontSize: "14px" }}>
                        {PromoCode}
                      </span>
                    </div>
                  </div>
                )}
              {values.is_promo_code && (
                <AddPromoCodeGrid
                  promoCodeDataSource={dataSource.promoCodeDataSource}
                  setFieldValue={setFieldValue}
                />
              )}
              {values.ticketing_option === ticketedonmdnd &&
                values.event_mode === EventModeOffline &&
                values.seating_option === seatingPlanNonNumbered && (
                  <div className="col-lg-6 mt-2 d-flex align-items-center">
                    <div className="form-group d-flex align-items-center mb-2 gap-2">
                      <CheckBox
                        onValueChanged={(e) =>
                          handleChange({
                            target: {
                              name: "is_addon_code",
                              value: e.value,
                            },
                          })
                        }
                        defaultValue={values.is_addon_code}
                      />

                      <span className="" style={{ fontSize: "14px" }}>
                        {AddOn}
                      </span>
                    </div>
                  </div>
                )}

              {values?.ticketing_option === seasonModeTicketed && (
                <div className="col-lg-6 d-flex align-items-center">
                  <div className="form-group d-flex align-items-center mb-2 mt-3 gap-2">
                    <CheckBox
                      value={values.multiple_tickets}
                      onValueChanged={(e) =>
                        handleChange({
                          target: {
                            name: "multiple_tickets",
                            value: e.value,
                          },
                        })
                      }
                    />
                    <label style={{ fontSize: "14px" }}>Multiple Events </label>
                  </div>
                </div>
              )}

              {values && values?.multiple_tickets && (
                <div className="col-lg-6">
                  <div className="form-group mb-2">
                    <TagBox
                      labelMode="floating"
                      label="Event List"
                      className="dx-field-item-required"
                      dataSource={dataSource.getAllEventsDatasource || []}
                      displayExpr="event_title"
                      valueExpr="event_id"
                      id="appendData"
                      searchEnabled={true}
                      value={values.season_event_ids}
                      onValueChanged={(e) =>
                        handleChange({
                          target: { name: "season_event_ids", value: e.value },
                        })
                      }
                      itemRender={(item) => (
                        <TagItem name={item.event_title} image={item.photo} />
                      )}
                      tagRender={(data) => (
                        <Tag name={data.event_title} image={data.photo} />
                      )}
                    />
                    {errors.season_event_ids && touched.season_event_ids && (
                      <p className="error-message">{errors.season_event_ids}</p>
                    )}
                  </div>
                </div>
              )}

              {values.is_addon_code && (
                <AddOnCodeGrid
                  addOnCodeDataSource={dataSource.addOnCodeDataSource}
                  setFieldValue={setFieldValue}
                />
              )}
              {/* 
              Button Text */}
              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <TextBox
                    label="Enter text"
                    className="dx-field-item-required"
                    labelMode="floating"
                    onValueChanged={(e) =>
                      handleChange({
                        target: { name: "season_button_text", value: e.value },
                      })
                    }
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "season_button_text", value: e.value },
                      })
                    }
                    value={values.season_button_text}
                  />
                  {errors.season_button_text && touched.season_button_text && (
                    <p className="error-message">{errors.season_button_text}</p>
                  )}
                </div>
              </div>
              {/* 
              Show to Public */}
              {/* <div className="col-lg-6">
                <div className="form-group mb-0">
                  <SelectBox
                    label="Show to public"
                    displayExpr="name"
                    className="dx-field-item-required"
                    valueExpr="value"
                    labelMode="floating"
                    value={values.show_to_public}
                    dataSource={seasonShowToPublic || null}
                    onValueChanged={(e) =>
                      handleChange({
                        target: { name: "show_to_public", value: e.value },
                      })
                    }
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "show_to_public", value: e.value },
                      })
                    }
                    searchEnabled={true}
                  />
                  {errors.show_to_public && touched.show_to_public && (
                    <p className="error-message">{errors.show_to_public}</p>
                  )}
                </div>
              </div> */}
              <div className="col-lg-6 ">
                <div className="form-group mb-0">
                  <FileUploader
                    label="event_image"
                    className="dx-field-item-required mb-0"
                    labelMode="floating"
                    labelText="or Drop image here"
                    onValueChanged={(e) => imageOnchange(e)}
                    accept="image/*"
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "event_image", value: e.value },
                      })
                    }
                    selectButtonText="Upload Event Poster / Image"
                  />
                  {settings?.imageName && <p>{settings.imageName}</p>}

                  {errors.event_image && touched.event_image && (
                    <p className="error-message">{errors.event_image}</p>
                  )}
                </div>
                {values?.event_image &&
                values?.event_image !== null &&
                values?.event_image !== "" && (
                  <div className="col-lg-2">
                    <div className="form-group mb-2">
                      <p>Preview</p>
                      <img src={values?.event_image} />
                    </div>
                  </div>
                )}
              </div>
              
              {/* Location */}
              {/* Venue Name */}
              {values.event_mode === EventModeOffline && (
                <div className="col-lg-6">
                  <div className="form-group ">
                    <TextBox
                      label="Venue Name"
                      className="dx-field-item-required"
                      labelMode="floating"
                      onValueChanged={(e) =>
                        handleChange({
                          target: { name: "venue_name", value: e.value },
                        })
                      }
                      onFocusOut={(e) =>
                        handleBlur({
                          target: { name: "venue_name", value: e.value },
                        })
                      }
                      value={values.venue_name}
                    />
                    {errors.venue_name && touched.venue_name && (
                      <p className="error-message">{errors.venue_name}</p>
                    )}
                  </div>
                </div>
              )}
              {values.event_mode === EventModeOffline && (
                <div className="col-lg-6">
                  <div className="form-group mt-2">
                    <SearchLocationInput
                      setSelectedLocation={setSelectedLocation}
                      setFieldValue={setFieldValue}
                      tempLocationData={values.tempLocationData}
                    />
                    {errors.location && touched.location && (
                      <p className="error-message">{errors.location}</p>
                    )}
                  </div>
                </div>
              )}
              {/* 
              MAP */}
              {/* 
              Show to Public */}
              {/* <div className="col-lg-6">
                <div className="form-group mb-0">
                  <SelectBox
                    label="Show to Public"
                    displayExpr="name"
                    className="dx-field-item-required"
                    valueExpr="value"
                    labelMode="floating"
                    value={values.show_to_public}
                    dataSource={seasonShowToPublic || null}
                    onValueChanged={(e) =>
                      handleChange({
                        target: { name: "show_to_public", value: e.value },
                      })
                    }
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "show_to_public", value: e.value },
                      })
                    }
                    searchEnabled={true}
                  />
                  {errors.show_to_public && touched.show_to_public && (
                    <p className="error-message">{errors.show_to_public}</p>
                  )}
                </div>
              </div> */}
            </div>

            {values.event_mode === EventModeOffline &&
              selectedLocation &&
              selectedLocation.lat !== null &&
              selectedLocation.lng !== null && (
                <div class="col-lg-6 mapPosAlign">
                  <div class="mapPosAlign">
                    <MapComponent selectedLocation={selectedLocation} />
                  </div>
                </div>
              )}

            <Button
              text="Submit"
              className="jointEventAddBtn mt-4"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default SeasonEvents;
