import React, { useState, useEffect } from 'react';
import './streampage.css';

function StreamCountDownPage({ targetDate }) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    return () => clearInterval(timer); // Cleanup timer on component unmount
  }, [targetDate]);

  function calculateTimeLeft(targetDate) {
    const eventDate = new Date(targetDate);
    const now = new Date();
    const difference = eventDate - now;

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const seconds = Math.floor((difference / 1000) % 60);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));

    return { days, hours, minutes, seconds };
  }

  return (
    <div>
      <div className="section-padding height-auto-100 image-flip-rtl countDownImg">
        <div className="container h-100">
          <div className="d-flex flex-column justify-content-center h-100">
            <h4 className="fw-500 mb-0 text-center text-md-start text-white">40th year Ramanavami Utsavam - Discourses by Trichy Sri Kalyanaraman</h4>
            <h2 className="fw-500 mb-0 text-center text-md-start text-white">Countdown to event</h2>
            <ul className="countdown d-flex align-items-center list-inline count-down my-5 py-3">
              <li className="position-relative d-inline-lock">
                <span className="numberDisplay">{timeLeft.days}</span>
                <span className="text-center text-capitalize fw-semibold d-block pt-1 letter-spacing-1 text-white">Days</span>
              </li>
              <li className="position-relative d-inline-block">
                <span className="numberDisplay">{timeLeft.hours}</span>
                <span className="text-center text-capitalize fw-semibold d-block pt-1 letter-spacing-1 text-white">Hours</span>
              </li>
              <li className="position-relative d-inline-block">
                <span className="numberDisplay">{timeLeft.minutes}</span>
                <span className="text-center text-capitalize fw-semibold d-block pt-1 letter-spacing-1 text-white">Minutes</span>
              </li>
              <li className="position-relative d-inline-block">
                <span className="numberDisplay">{timeLeft.seconds}</span>
                <span className="text-center text-capitalize fw-semibold d-block pt-1 letter-spacing-1 text-white">Seconds</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StreamCountDownPage;
