import * as Yup from "yup";
import { COMMONFIELDREQUIRED } from "../global/ToastMsgConstants.tsx";

export const signupSchema = Yup.object({
  name: Yup.string()
    .required(COMMONFIELDREQUIRED),
  email: Yup.string()
    .email("Please enter valid email.")
    .required(COMMONFIELDREQUIRED),
  password: Yup.string().min(2).required(COMMONFIELDREQUIRED),
  mobile: Yup.string().required(COMMONFIELDREQUIRED),
});
