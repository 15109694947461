import React, { useState, useEffect, useContext, useRef } from "react";
import { viewEventsFetch, editEventsFetch } from "./EventsFetch";
import notify from "devextreme/ui/notify";
import {
  DURATION,
  ERROR,
  SUCCESS,
} from "../../../global/ToastMsgConstants.tsx";
import { useMutation } from "@tanstack/react-query";
import { GlobalContext } from "../../../global/provider";
import {
  generateReverseSerialNumber,
  ImageRender,
  isValidValue,
} from "../../../utils/commonUtils.js";
import { useSearchParams } from "react-router-dom";
import { ViewEventImagePath } from "../../../config/config.js";
import { convertDateFormatDDMMYYYY } from "../../../utils/dateFormatUtils.js";
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import DataGrid, {
  Column,
  SearchPanel,
  MasterDetail,
} from "devextreme-react/data-grid";
import { APPLICABLE, NOT_APPLICABLE } from "../../../global/constants";
import Switch from "devextreme-react/switch";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import CreateEvents from "./createEvents.js";
import { GET_COUNTRIES } from "../../../Api/api.js";
import { makeGetRequest } from "../../../Api/requestMethods.js";
import { SelectBox } from "devextreme-react";
import AuthContext from "../../../context/AuthContext.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FaTicketAlt } from "react-icons/fa";
import { showToPublic } from "../../../Api/api.js";
import { useNavigate } from "react-router-dom";

const ViewEvents = () => {
  const navigate = useNavigate();
  const viewEventRef = useRef(null);
  const [searchParams] = useSearchParams();
  const event_id = searchParams.get("event_id");
  const { userDetails } = useContext(GlobalContext);
  const { user } = useContext(AuthContext);
  const [dataSouce, setDataSource] = useState({
    viewEvent: "",
    viewEventTrigger: 0,
    selectedCountry: 101,
  });
  const viewDataMutation = useMutation({
    mutationFn: (state_id) => viewEventsFetch(state_id),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, viewEvent: data?.data }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  const editDataMutation = useMutation({
    mutationFn: (data) => editEventsFetch(data),
    onSuccess: (data) => {
      setDataSource((prev) => ({
        ...prev,
        viewEventTrigger: prev.viewEventTrigger + 1,
      }));
      notify(data.message, SUCCESS, DURATION);
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  useEffect(() => {
    if (user && user?.role_id) {
      const body = {
        role_id: user?.role_id,
        country_id: 101,
        is_season_event: "N",
      };
      viewDataMutation.mutate(body);
    }
  }, [userDetails, dataSouce.viewEventTrigger]);

  const onEditButtonClick = () => {};

  const showToPublicEvent = (e, row) => {
    const event_id = row && row?.data?.event_id;
    const body = {
      show_to_public: e.target.checked ? APPLICABLE : NOT_APPLICABLE,
      country_id: 101,
      event_id: event_id,
      role_id: user?.role_id,
      is_season_event: "N",
    };
    editDataMutation.mutate(body);
  };

  const switchElement = (row) => {
    const isApplicable = row.value === APPLICABLE ? true : false;
    return (
      <div className="form-check form-switch d-flex">
        <input
          className="form-check-input m-auto"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckChecked"
          checked={isApplicable}
          onChange={(e) => showToPublicEvent(e, row)}
        />
      </div>
    );
  };

  const countryFetch = async () => await makeGetRequest(GET_COUNTRIES);
  const {
    data: countryData,
    isLoading: isCountryDataLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["__Edit_countryFetch___"],
    queryFn: () => countryFetch(),
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  const EditEventsWrapper = (props) => {
    const isEditEvent = {
      isEditEvent: true,
      country_id: dataSouce.selectedCountry,
    };
    return <CreateEvents {...props} {...isEditEvent} />;
  };

  const handleCountryEvent = (e) => {
    setDataSource((prev) => ({ ...prev, selectedCountry: e.value }));
  };

  useEffect(() => {
    try {
      if (
        event_id &&
        isValidValue(event_id) &&
        Array.isArray(dataSouce?.viewEvent)
      ) {
        const dataGridInstance = viewEventRef.current?.instance;
        if (!dataGridInstance) {
          console.error("DataGrid instance is not available.");
          return;
        }
        const rowIndex = dataSouce.viewEvent.findIndex(
          (row) => Number(row.event_id) === Number(event_id)
        );
        if (rowIndex !== -1) {
          const matchingRowKey = dataSouce.viewEvent[rowIndex].event_id;
          dataGridInstance.selectRowsByIndexes([matchingRowKey]);

          dataGridInstance.navigateToRow(matchingRowKey);
        } else {
          console.warn("No matching row found for event_id:", event_id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [event_id, dataSouce.viewEvent]);

  const onViewTicketDetails = (data) => {
    console.log(data, "data");
    // Country id hardcoded as per sudhakars suggestion
    navigate(`/viewTicketDetails?event_id=${data.event_id}&country_id=${101}`);
  };

  return (
    <>
      <div className="col-lg-3 Viewevents">
        <SelectBox
          dataSource={countryData?.data || []}
          displayExpr="country_name"
          valueExpr="country_id"
          label="country"
          labelMode="floating"
          defaultValue={101}
          searchEnabled={true}
          onValueChanged={handleCountryEvent}
          value={dataSouce?.selectedCountry || null}
        />
      </div>

      <DataGrid
        dataSource={dataSouce.viewEvent || []}
        showBorders={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        selectionFilter={true}
        rowAlternationEnabled={true}
      >
        <MasterDetail enabled={true} component={EditEventsWrapper} />
        <SearchPanel visible={true} width={240} placeholder="Search..." />
        <Column
          width={80}
          dataField="ID"
          caption="S.no"
          calculateCellValue={(rowData) =>
            generateReverseSerialNumber(rowData, dataSouce.viewEvent)
          }
        />
        <Column
          dataField="event_date"
          caption="Event date"
          calculateCellValue={(rowData) =>
            convertDateFormatDDMMYYYY(rowData.event_date)?.fromDate
          }
        />
        <Column dataField="event_title" caption="Event title" />
        <Column dataField="event_mode" caption="Event mode" />
        <Column
          dataField="event_image"
          caption="Image"
          cellRender={(data) =>
            ImageRender(ViewEventImagePath, data, "event_image")
          }
        />
        <Column
          dataField="show_to_public"
          cellRender={switchElement}
          caption="Show to public"
        />
        <Column
          dataField="created_date"
          caption="Created on"
          calculateCellValue={(rowData) =>
            convertDateFormatDDMMYYYY(rowData.created_date)?.fromDate
          }
        />

        <Column
          caption="View Ticket Details"
          cellRender={({ data }) => (
            <div style={{cursor:"pointer"}}>
              <FaTicketAlt
                className="EditIcon"
                onClick={() => onViewTicketDetails(data)}
              />
            </div>
          )}
          width={180}
        />
        <Column
          caption="Edit"
          style={{ cursor: "pointer" }}
          cellRender={({ data }) => (
            <div>
              <MdModeEditOutline
                className="EditIcon"
                style={{ cursor: "pointer" }}
                onClick={() => onEditButtonClick(data)}
              />
            </div>
          )}
          width={80}
        />
        <Column
          caption="Delete"
          cellRender={({ data }) => (
            <div>
              <RiDeleteBinLine
                className="DeleteIcon"
                onClick={() => onEditButtonClick(data)}
              />
            </div>
          )}
          width={80}
        />
      </DataGrid>
    </>
  );
};

export default ViewEvents;
