import React, { useState, useEffect } from "react";
import DataGrid, {
  Column,
  Selection,
  FilterRow,
  Paging,
} from "devextreme-react/data-grid";

const AddPromoCodeGrid = ({ promoCodeDataSource, setFieldValue }) => {
  const handleSelectionChanged = (e) => {
    const selectedPromoCode = [];
    if (e && e.selectedRowsData && e.selectedRowsData.length > 0) {
      e.selectedRowsData.map((data, index) => selectedPromoCode.push(data.id));
    }
    setFieldValue("promo_codes", selectedPromoCode);
  };
  return (
    <>
      <DataGrid
        dataSource={promoCodeDataSource || []}
        showBorders={true}
        columnAutoWidth={true}
        rowAlternationEnabled={true}
        onSelectionChanged={(e) => handleSelectionChanged(e)}
      >
        <Selection mode="multiple" />
        <Paging enabled={true} />
        <Column dataField="promo_code" caption="Promo code" />
        <Column dataField="discount_type" caption="Discount type" />
        <Column dataField="discount_value" caption="Discount Amount " />
      </DataGrid>
    </>
  );
};

export default AddPromoCodeGrid;