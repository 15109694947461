import appAxios from "./axiosInterceptors";

const makeGetRequest = async (endpoint) => {
    const { data } = await appAxios.get(endpoint);
    return data;
};

const makePostRequest = async (endpoint, body) => {
    const { data } = await appAxios.post(endpoint, body);
    return data;
};

const makePatchRequest = async (endpoint, body) => {
    const { data } = await appAxios.patch(endpoint, body);
    return data;
};

const makeDeleteRequest = async (endpoint) => {
    const { data } = await appAxios.delete(endpoint);
    return data;
};

const makePutRequest = async(endpoint,body)=>{
    const {data} = await appAxios.put(endpoint,body);
    return data;
}
export { makeGetRequest, makePostRequest, makePatchRequest, makeDeleteRequest, makePutRequest };
 