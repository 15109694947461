import React, { useCallback } from "react";
import IMAGE1 from "../assets/images/genre/02.webp";
import { RAGABANK_IMAGEPATH } from "../config/config";
import "./RagaBank.css";
import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { GET_RAGABANK_RECORD, GET_RAGABANK_THUMBNAIL_RECORD, GET_THUMNAIL_FOR_RAGA } from "../Api/api";
import { makeGetRequest } from "../Api/requestMethods";
import { useQuery } from "@tanstack/react-query";
import notify from "devextreme/ui/notify";
import { parseISO, differenceInSeconds, differenceInMinutes, differenceInHours, differenceInDays, differenceInWeeks, differenceInMonths, differenceInYears } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import mdndimage from "./RagaImage/mdnd.png";
import ragaimage from "./RagaImage/ragaicon.png";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Modal, Button } from "react-bootstrap";
function RagaBank() {
  // -------------------------------------StatesMaintain----------------------------------------
  const [currentOffset, setCurrentOffset] = useState(0); // Start with offset 3 as required
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const limit = 20;
  const [mdndData, setMdndData] = useState([]);
  const [showModal, setShowModal] = useState(true);

  const navigate = useNavigate();
  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );

  // ------------------------------------------FetchingRagaDetail-------------------------------
  const fetchingRagaVideos = async (limit, offset) => {
    const data = { limit, offset };
    const jsonString = JSON.stringify(data);
    const base64String = btoa(jsonString);
    const GET_MDND_RAGABANK_RECORD = `videos/raga_bank/filter/${base64String}`;

    try {
      const response = await makeGetRequest(GET_MDND_RAGABANK_RECORD);
      const records = response.data;
      const ragaWithThumbnails = await Promise.all(records.map(async (record) => {
        const thumbnailResponse = await makeGetRequest(`${GET_THUMNAIL_FOR_RAGA()}/${record?.id}`);
        return {
          ...record,
          thumbnail: thumbnailResponse?.data?.thumbnail
        }
      }));
      return ragaWithThumbnails;
    } catch (error) {
      throw new Error(`Error fetching videos: ${error.message}`)
    }
  };

  const loadMoreVideos = useCallback(async () => {
    if (isFetching) return;

    setIsFetching(true);
    try {
      const newRecords = await fetchingRagaVideos(limit, currentOffset);
      setMdndData(prevData => [...prevData, ...newRecords]);
      setCurrentOffset(prevOffset => prevOffset + limit);
    } catch (error) {
      notify(error.message);
    } finally {
      setIsFetching(false);
      setIsLoading(false); // Data fetching is complete
    }
  }, [currentOffset, isFetching]);

  useEffect(() => {
    loadMoreVideos();
  }, []);

  useEffect(() => {
    setShowModal(true); // Automatically open the modal on page load
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop + 100 >= document.documentElement.scrollHeight && !isFetching) {
        loadMoreVideos();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loadMoreVideos, isFetching]);

  // -------------------------------------------DateFormat-------------------------------------------
  const formatTime = (dateString) => {
    if (!dateString) return "Invalid date";

    const date = parseISO(dateString);
    const now = new Date();

    const seconds = differenceInSeconds(now, date);
    const minutes = differenceInMinutes(now, date);
    const hours = differenceInHours(now, date);
    const days = differenceInDays(now, date);
    const weeks = differenceInWeeks(now, date);
    const months = differenceInMonths(now, date);
    const years = differenceInYears(now, date);

    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (weeks < 4) {
      return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
    } else if (months < 12) {
      return `${months} month${months !== 1 ? 's' : ''} ago`;
    } else {
      return `${years} year${years !== 1 ? 's' : ''} ago`;
    }
  };
  // ---------------------------------------------ForRouting----------------------------------------------------
  const handleRagaClick = (data) => {
    console.log(data, "thajosul");
    const json_string = JSON.stringify(
      {
        video_id: data?.id,
        role_id: user?.role_id ?? null
      }
    );
    const slug = btoa(json_string);
    navigate(`/RagaBankVideo/videos/${slug}`);
  }

  console.log(mdndData, "thisisraga")
  //----------------------------------------HandleGoBack-------------------------------------------------------
  const handleGoBack = () => {
    navigate(-1); // Navigates back to the previous page
  };
  // ----------------------------------------------------------------------------------------------------------
  return (
    <div>
      {/* <section className="continue-watching-block section-padding-top pt-lg-3">
        <div className="container-fluid">
          <div className="overflow-hidden">
            <div className="d-flex align-items-center justify-content-between px-1">
              <h6 className="main-title text-capitalize">
              </h6>
            </div>

            <div className="row row-cols-1 row-cols-sm-3 row-cols-md-3 row-cols-lg-5">
              {isLoading
                ? Array.from({ length: 10 }).map((_, index) => (
                  <div className="iq-watching-block mb-lg-3" key={index}>
                    <Skeleton height={180} />
                    <div className="d-flex justify-content-between p-2">
                      <Skeleton circle={true} height={50} width={50} />
                      <div className="w-100 ms-2">
                        <Skeleton count={2} />
                      </div>
                    </div>
                  </div>
                ))
                : mdndData &&
                mdndData?.length > 0 &&
                mdndData?.map((raga, index) => (
                  <div className="iq-watching-block mb-lg-3" key={index}>
                    <div
                      className="block-images position-relative"
                      onClick={() => handleRagaClick(raga)}
                    >
                      <div className="iq-image-box overly-images" style={{ borderRadius: '8px', overflow: 'hidden' }}>
                        <img
                          src={`${RAGABANK_IMAGEPATH}/${raga?.thumbnail_url}`}
                          alt={raga?.raaga_name}
                          className="img-fluid object-cover w-100 d-block border-0"
                        />
                      </div>
                      <div className="iq-preogress">
                        <div className="d-flex justify-content-between px-2 mb-1">
                          <p className="mb-0 videoViewBg">
                            views {raga?.views}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between p-2">
                      <div className="videoProfileImg">
                        <img src={mdndimage} />
                      </div>
                      <div className="w-100 ms-2">
                        <h6 className="mb-0">
                          <b>
                            {raga?.raaga_name} by {raga?.thala_name}
                          </b>
                        </h6>
                        <p className="mb-0 videoMinuts">
                          {formatTime(raga?.created_date)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </section> */}
      <Modal show={showModal} centered>
        <Modal.Header className="modalheader">
          <Modal.Title className="d-flex">
            <div className="d-flex align-items-center gap-2">
              <div className="iconSize">
                <img src={ragaimage} />
              </div>
              <h4 className="m-0 p-0"> Raga Bank  Update!</h4>
            </div>
            <button className=" update-closebutton" onClick={handleGoBack}>
              <p className="m-0 p-0" style={{ color: '#3f00ff' }}>Go Back</p>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5> We'll have all the Raga Bank videos up in a few days. Please bear with us.</h5>
        </Modal.Body>
        <Modal.Footer className="modalfooter">
          {/* <div className="scrolling-text">🎉Coming Soon! 🎉Coming Soon! 🎉Coming Soon!</div> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RagaBank;
