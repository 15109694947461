import React from "react";
import { useState, useEffect, useContext, useMemo, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Button from "devextreme-react/button";
import { BASEPATH } from "../../../config/config";
import HandleErrorToast from "../../../Toaster/Failure/Failure";
import SuccessNewToaster from "../../../Toaster/Success/SuccessNewToaster";
import { useMutation } from "@tanstack/react-query";
import { MdModeEditOutline } from "react-icons/md";
import { EDIT_PARTICULAR_FORM, LIST_FORM_FIELDS, GET_DETAILED_FORM_FROM_BACKEND } from "../../../Api/api";
import axios from "axios";
import {
  faTrashAlt,

  faTimes,
  faEdit,
  faUndo,

} from "@fortawesome/free-solid-svg-icons";
import { tabs } from "../../../components/others";
import HtmlEditor, {
  Toolbar,
  MediaResizing,
  ImageUpload,
  Item,
} from "devextreme-react/html-editor";
import AuthContext from "../../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateBox, TextBox, SelectBox } from "devextreme-react";
import {
  sizeValues,
  fontValues,
  headerValues,
  fontSizeOptions,
  fontFamilyOptions,
  headerOptions,
} from "../../../components/others";

import {
  faCalendarAlt,
  faListAlt,
  faIndianRupeeSign,
  faPhone,
  faPercent,
  faClock,
  faFont,
  faMusic,
  faVideo,
  faImage,
  faEnvelope,
  faLink,
  faHashtag,
  faGlobe,
  faMapMarkerAlt,
  faCity,
  faIdCard,
  faCheckSquare,
  faCheckCircle,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';
import Validator, { RequiredRule, CustomRule } from 'devextreme-react/validator';

const MANDATORY_DB = [
  {
    id: 1,
    text: "Mandatory",
  },
  {
    id: 2,
    text: "Not Mandatory",
  },
];

const dataSource_regform = [
  {
    id: 1,
    text: "Free",
  },
  {
    id: 2,
    text: "Paid",
  },
];

const fieldIcons = {
  "Date": faCalendarAlt,
  "Single Selection Drop Down List": faListAlt,
  "Amount": faIndianRupeeSign,
  "Multiple Selection Drop Down List": faListAlt,
  "Mobile": faPhone,
  "Percentage": faPercent,
  "Time": faClock,
  "Text": faFont,
  "Audio": faMusic,
  "Video": faVideo,
  "Image": faImage,
  "Email": faEnvelope,
  "Link": faLink,
  "Number": faHashtag,
  "Country": faGlobe,
  "State": faMapMarkerAlt,
  "City": faCity,
  "Pan": faIdCard,
  "Select Declaration": faCheckSquare,
  "Declare": faCheckCircle,
  "default": faQuestionCircle
};


export default function EditForms({ data, onCounterUpdate }) {

  //console.log(data, "data inside edit forms");

  // during Edit ***********************************************

  //const location = useLocation();
  //const queryParams = new URLSearchParams(location.search);
  //const form_name = queryParams.get("form_name");

  const { user, setUser, authTokens, setAuthTokens, logoutUser } =
    useContext(AuthContext);
  const token = localStorage.getItem("MDnD_auth");

  //const form_id = localStorage.getItem("form_id");

  const [form_name, setformname] = useState("");
  const [form_id, setformid] = useState("");
  const fileInputRef_userpic = useRef();
  const cropperRef = useRef(null);
  const [isMultiline, setIsMultiline] = useState(true);
  const [currentTab, setCurrentTab] = useState(tabs[2].value);

  useEffect(() => {
    if (data) {
      setformid(data?.data?.form_id);

      const formName = data?.data?.form_name.replace(/ /g, "_");

      if (formName) { setformname(formName) }
      else {
        setformname('')
      }

    }
    else {
      return;
    }

  }, [data])

  //console.log(form_name, "form_name for Edit");
  //console.log(form_id, "form_id for Edit")

  const [isReadOnly, setIsReadOnly] = useState({});
  const [isDelete, setIsDelete] = useState({});

  const [maintableupdate, setMainTableUpdate] = useState([]);
  const [counterUpdate, setCounterUpdate] = useState(0);
  const [dynamicBoxesUpdate, setDynamicBoxesUpdate] = useState({});
  const [updateCollection, setUpdateCollection] = useState({
    datafrombackend: "",
    old_data_type_final_submit_edit: "",
    formtitle: "",
    validation_formtitle: "",
    formFields: "",

    user_profile_pic: "",
    image: null,
    showCropper: false,
    imagechecker: true,
    dummyimg: "",
    showSaveandCancelbuttonPic: false,

    selectedformMode: "",
    feeinrAmount: "",
    validation_feeinrAmount: "",
    address: "",
    organiserName: "",
    extramailContent: "",
    TowardsOnlineCourseorWorkshop: "",
    validation_towards: "",

    editorContent: "",
    validation_editorContent: "",

    //last div
    column_name: "",
    field_name_desc: "",
    data_type: "",
    select_list_value: "",
    column_mandatory: "",
    filtereddata_type: {},

    old_column_name: "",
    old_field_name_desc: "",
    old_data_type: "",
    old_select_list_value: "",
    old_column_mandatory: "",
    validation_maintable: "",
  });
  const [copycatUpdate, setCopyCatUpdate] = useState({});
  const [editdeleteKeysUpdate, setEditDeleteKeysUpdate] = useState({});

  //newly added on aug 29...
  const [commavalidation, setcommavalidation] = useState({});
  const [correctcomma, setcorrectcomma] = useState({});

  const renderItemWithIcon = (item) => {
    const icon = fieldIcons[item.field_name] || fieldIcons.default;
    return (
      <div>
        <FontAwesomeIcon icon={icon} />
        <span style={{ marginLeft: '8px' }}>{item.field_name}</span>
      </div>
    );
  };

  // end of Edit ***************************************************

  //***** FUNCTIONALITY FOR EDIT **************************************************** */

  useEffect(() => {
    if (form_id && form_id !== "" && form_name) {
      mutationfetchFormForNewUpdate.mutate(form_id);
    } else {
      return;
    }
  }, [form_id, form_name, counterUpdate]);

  const GetSingleDetailedForm = (fid) =>
    GET_Detailed_form(GET_DETAILED_FORM_FROM_BACKEND, fid);

  const GET_Detailed_form = async (GET_DETAILED_FORM_FROM_BACKEND, fid) => {
    //console.log("im inside");

    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_DETAILED_FORM_FROM_BACKEND}`,
        data: {
          form_id: Number(fid),
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });
      //console.log(response,"response");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationfetchFormForNewUpdate = useMutation({
    mutationKey: ["get_form_detailed"],
    mutationFn: (fid) => GetSingleDetailedForm(fid),

    onSuccess: (data) => {
      //   queryClient.invalidateQueries({
      //     queryKey: ["fetchUser"],
      //   });

      //console.log(data, "DATA from backend");

      if (data.status === "success") {
        //setFormData(data.data[0]);
        setUpdateCollection((prevState) => ({
          ...prevState,
          datafrombackend: data.data[0],
        }));
      } else {
        setUpdateCollection((prevState) => ({
          ...prevState,
          datafrombackend: "",
        }));
      }
    },
    onError: (error) => {
      //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
      //HandleErrorToast("error", "Form Fields Could not be Fetched. Something went Wrong!", 3000);
      setUpdateCollection((prevState) => ({
        ...prevState,
        datafrombackend: "",
      }));
    },
  });


  //fetching formfields.........
  useEffect(() => {
    if (user) {
      mutationgetListFormFields.mutate();
    }
  }, []);

  const GetFormFields = () => GET_LIST_FORMFIELDS(LIST_FORM_FIELDS);

  const GET_LIST_FORMFIELDS = async (LIST_FORM_FIELDS) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASEPATH}${LIST_FORM_FIELDS}`,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationgetListFormFields = useMutation({
    mutationKey: ["get_all_formFields"],
    mutationFn: () => GetFormFields(),

    onSuccess: (data) => {
      //   queryClient.invalidateQueries({
      //     queryKey: ["fetchUser"],
      //   });

      //console.log(data, "DATA");

      if (data.status === "success") {
        setUpdateCollection((prevState) => ({
          ...prevState,
          formFields: data.data,
        }));
      } else {
        setUpdateCollection((prevState) => ({ ...prevState, formFields: "" }));
      }
    },
    onError: (error) => {
      //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
      HandleErrorToast(
        "error",
        "Form Fields Could not be Fetched. Something went Wrong!",
        3000
      );
      setUpdateCollection((prevState) => ({ ...prevState, formFields: "" }));
    },
  });

  //console.log(updateCollection, "updateCollection");

  const convertValuesToNumbers = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      acc[key] = Number(obj[key]);
      return acc;
    }, {});
  };

  useEffect(() => {
    if (updateCollection.datafrombackend) {
      setUpdateCollection((prevState) => ({
        ...prevState,

        formtitle: updateCollection.datafrombackend.form_name || "",
        user_profile_pic: updateCollection.datafrombackend.form_image || "",
        selectedformMode: updateCollection.datafrombackend.form_mode || "",
        editorContent: updateCollection.datafrombackend.form_desc || "",
        column_name: updateCollection.datafrombackend.column_name
          ? JSON.parse(updateCollection.datafrombackend.column_name)
          : "",
        field_name_desc: updateCollection.datafrombackend.field_name_desc
          ? JSON.parse(updateCollection.datafrombackend.field_name_desc)
          : "",
        select_list_value: updateCollection.datafrombackend.select_list_value
          ? JSON.parse(updateCollection.datafrombackend.select_list_value)
          : "",
        column_mandatory: updateCollection.datafrombackend.column_mandatory
          ? JSON.parse(updateCollection.datafrombackend.column_mandatory)
          : "",
        no_column: updateCollection.datafrombackend.no_column || "",
        inr: updateCollection.datafrombackend.inr
          ? JSON.parse(updateCollection.datafrombackend.inr)
          : "",
        old_column_name: updateCollection.datafrombackend.column_name
          ? JSON.parse(updateCollection.datafrombackend.column_name)
          : "",

        old_field_name_desc: updateCollection.datafrombackend.field_name_desc
          ? JSON.parse(updateCollection.datafrombackend.field_name_desc)
          : "",

        old_data_type: updateCollection.datafrombackend.data_type
          ? JSON.parse(updateCollection.datafrombackend.data_type)
          : "",
        old_data_type_update_submit: updateCollection.datafrombackend.data_type
          ? updateCollection.datafrombackend.data_type
          : "",

        old_select_list_value: updateCollection.datafrombackend
          .select_list_value
          ? JSON.parse(updateCollection.datafrombackend.select_list_value)
          : "",
        old_column_mandatory: updateCollection.datafrombackend.column_mandatory
          ? JSON.parse(updateCollection.datafrombackend.column_mandatory)
          : "",
        extramailContent:
          updateCollection.datafrombackend.success_mail_content || "",
        TowardsOnlineCourseorWorkshop:
          updateCollection.datafrombackend.toward || "",
        organiserName: updateCollection.datafrombackend.name || "",
        address: updateCollection.datafrombackend.address || "",
      }));
    } else {
      setUpdateCollection((prevState) => ({ ...prevState, formtitle: "" }));
      setUpdateCollection((prevState) => ({
        ...prevState,
        user_profile_pic: "",
        selectedformMode: "",
        editorContent: "",
        column_name: "",
        field_name_desc: "",
        data_type: "",
        select_list_value: "",
        column_mandatory: "",
        old_column_name: "",
        old_field_name_desc: "",
        old_data_type: "",
        old_select_list_value: "",
        old_column_mandatory: "",
        no_column: "",
        address: "",
        organiserName: "",
        TowardsOnlineCourseorWorkshop: "",
        extramailContent: "",
        old_data_type_final_submit_edit: "",
        old_data_type_update_submit: "",
        inr: "",
      }));
    }
  }, [updateCollection.datafrombackend]);

  // xxxxx- this is used for SUBMIT during edit
  useEffect(() => {
    if (updateCollection.old_data_type) {
      setUpdateCollection((prevState) => ({
        ...prevState,
        old_data_type_final_submit_edit: convertValuesToNumbers(
          updateCollection.old_data_type
        ),
      }));
    }
  }, [updateCollection.old_data_type]);

  // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

  //useEffect for Edit and delete button which excludes Name, Email and Mobile
  useEffect(() => {
    if (updateCollection.column_mandatory) {
      const newObject = {};
      for (const key in updateCollection.column_mandatory) {
        const numKey = Number(key);
        if (numKey >= 3) {
          newObject[numKey] = updateCollection.column_mandatory[key];
        }
      }
      if (newObject) {
        setEditDeleteKeysUpdate(newObject);
      }
    }
  }, [updateCollection.column_mandatory]);

  // console.log(editdeleteKeysUpdate, "editdeletekeysupdate");
  // console.log(
  //   updateCollection.column_mandatory,
  //   "updateCollection.column_mandatory"
  // );

  //end of for Edit and delete button which excludes Name, Email and Mobile

  useEffect(() => {
    if (updateCollection.old_data_type && obj) {
      const mapped = Object.keys(updateCollection.old_data_type).reduce(
        (acc, key) => {
          const value = updateCollection.old_data_type[key];
          acc[key] = obj[value];
          return acc;
        },
        {}
      );
      setUpdateCollection((prevState) => ({
        ...prevState,
        filtereddata_type: mapped,
      }));
    }
  }, [updateCollection.old_data_type]);

  useMemo(() => {
    const numberValues = {};
    if (updateCollection.old_data_type) {

      Object.keys(updateCollection.old_data_type).map((key) => {
        numberValues[key] = parseInt(updateCollection.old_data_type[key]);
      });
    }

    if (numberValues) {
      setUpdateCollection((prevState) => ({
        ...prevState,
        data_type: numberValues,
      }));
    }

  }, [updateCollection.old_data_type]);

  //1st div

  const handleFormTitle_update = (e) => {
    if (e) {
      setUpdateCollection((prevState) => ({
        ...prevState,
        formtitle: e.value,
      }));

    }

    if (e?.value?.length > 0) {
      setUpdateCollection((prevState) => ({
        ...prevState,
        validation_formtitle: "",
      }));
    }
  };

  //2nd div
  const handleFileChange_userpic_update = (event) => {
    const file_pic = event.target.files[0];

    if (file_pic) {

      const allowedTypes = ['image/jpeg', 'image/png'];
      if (!allowedTypes.includes(file_pic.type)) {
        HandleErrorToast("error", 'Please select a valid image file (JPEG, PNG).', 3000);
        event.target.value = null;
        return;
      }
    }


    if (file_pic) {
      setUpdateCollection((prevState) => ({
        ...prevState,
        image: URL.createObjectURL(file_pic),
        showCropper: true,
        imagechecker: true,
      }));
    }
  };

  const handleCrop_update = async () => {
    const cropper = cropperRef.current.cropper;
    const croppedDataUrl = cropper.getCroppedCanvas().toDataURL("image/png");
    //setCroppedImage(croppedDataUrl);

    const response = await fetch(croppedDataUrl);
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);

    setUpdateCollection((prevState) => ({
      ...prevState,
      user_profile_pic: croppedDataUrl,
      showCropper: false,
      dummyimg: objectUrl,
      imagechecker: false,
      showSaveandCancelbuttonPic: true,
    }));
  };

  const handleCancelforuserpic_update = () => {
    if (updateCollection.datafrombackend) {
      setUpdateCollection((prevState) => ({
        ...prevState,
        user_profile_pic: updateCollection.datafrombackend.form_image,
        showCropper: false,
        showSaveandCancelbuttonPic: false,
        imagechecker: true,
        dummyimg: "",
      }));
    }
  };

  //3rd div
  const handleFormModeSelectBox_update = (e) => {
    setUpdateCollection((prevState) => ({
      ...prevState,
      selectedformMode: e?.value,
    }));

    handleExtraTextboxes_update();
  };
  const handleExtraTextboxes_update = () => {
    if (updateCollection.selectedformMode === "Free") {
      setUpdateCollection((prevState) => ({
        ...prevState,
        address: "",
        organiserName: "",
        //receiptCode:"",
        TowardsOnlineCourseorWorkshop: "",
        extramailContent: "",
        //feeUSDamount:"",
        feeinrAmount: "",
        //inr:""
      }));
    }
  };

  const handleFeeInrAmount_update = (e) => {
    setUpdateCollection((prevState) => ({
      ...prevState,
      feeinrAmount: e?.value,
    }));

    if (e?.value?.length > 0) {
      setUpdateCollection((prevState) => ({
        ...prevState,
        validation_feeinrAmount: "",
      }));
    }
  };

  const handleaddress_update = (e) => {
    setUpdateCollection((prevState) => ({ ...prevState, address: e?.value }));
  };

  const handleorganiserName_update = (e) => {
    setUpdateCollection((prevState) => ({
      ...prevState,
      organiserName: e?.value,
    }));
  };

  const handleextramailContent_update = (e) => {
    setUpdateCollection((prevState) => ({
      ...prevState,
      extramailContent: e?.value,
    }));
  };

  const handleOnlineCourseorWorkshop_update = (e) => {
    setUpdateCollection((prevState) => ({
      ...prevState,
      TowardsOnlineCourseorWorkshop: e?.value,
    }));

    if (e?.value?.length > 0) {
      setUpdateCollection((prevState) => ({
        ...prevState,
        validation_towards: "",
      }));
    }
  };

  //4th div

  const handleValueChanged_htmlEditor_update = (e) => {
    setUpdateCollection((prevState) => ({
      ...prevState,
      editorContent: e?.value,
    }));

    if (e?.value?.length > 0) {
      setUpdateCollection((prevState) => ({
        ...prevState,
        validation_editorContent: "",
      }));
    }
  };

  //5th div..

  let obj = {
    1: "Date",
    2: "Single Selection Drop Down",
    3: "Amount",
    4: "Multiple Selection Drop Down",
    5: "Mobile",
    6: "Percentage",
    7: "Time",
    8: "Text",
    9: "Audio",
    10: "Video",
    11: "Image",
    12: "Email",
    13: "Link",
    14: "Number",
    15: "Country",
    16: "State",
    17: "City",
    18: "Pan",
    19: "Select Declaration",
    20: "Declare",
  };

  const handlecolumnname_update = (key, value) => {
    //console.log(key, "key inside");
    //console.log(value, "value at that key");

    if (key && value) {
      const newColumnName = { ...updateCollection.column_name };
      newColumnName[key] = value;

      const newUpdateCollection = {
        ...updateCollection,
        column_name: newColumnName,
      };
      setUpdateCollection(newUpdateCollection);
    }
  };

  const handleINR_update = (key, value) => {
    if (key && value) {
      const newColumnName = { ...updateCollection.inr };
      newColumnName[key] = value;

      const newUpdateCollection = { ...updateCollection, inr: newColumnName };
      setUpdateCollection(newUpdateCollection);
    }
  };
  const handlefieldnamedesc_update = (key, value) => {
    if (key && value) {
      const newFieldNameDesc = { ...updateCollection.field_name_desc };
      newFieldNameDesc[key] = value;

      const newUpdateCollection = {
        ...updateCollection,
        field_name_desc: newFieldNameDesc,
      };
      setUpdateCollection(newUpdateCollection);
    }
  };

  const handlefieldtype_update = (key, value) => {
    if (key && value) {
      const newdata_type = { ...updateCollection.data_type };
      newdata_type[key] = value;

      const newUpdateCollection = {
        ...updateCollection,
        data_type: newdata_type,
      };
      setUpdateCollection(newUpdateCollection);
    }
  };

  const handlelistvalue_update = (key, value) => {
    if (key && value) {
      const newselectlist = { ...updateCollection.select_list_value };
      newselectlist[key] = value;

      const newUpdateCollection = {
        ...updateCollection,
        select_list_value: newselectlist,
      };
      setUpdateCollection(newUpdateCollection);
    }
  };

  const handleMandatorySelection_update = (key, value) => {
    if (key && value) {
      const newmandatory = { ...updateCollection.column_mandatory };
      newmandatory[key] = value;

      const newUpdateCollection = {
        ...updateCollection,
        column_mandatory: newmandatory,
      };
      setUpdateCollection(newUpdateCollection);
    }
  };

  //edit and delete button during update....

  // useEffect(() => {
  //   const initialReadOnlyState = {};
  //   if(updateCollection.column_mandatory)
  //   {Object.keys(updateCollection.column_mandatory).forEach(key => {
  //     initialReadOnlyState[key] = true;
  //   });
  //   setIsReadOnly(initialReadOnlyState);}
  // }, [updateCollection.column_mandatory]);

  useEffect(() => {
    const initialReadOnlyState = {};
    const initialDeleteState = {};

    if (updateCollection.no_column) {
      for (let i = 0; i < updateCollection.no_column; i++) {
        initialReadOnlyState[`${i}`] = true;
        initialDeleteState[`${i}`] = false;
      }
    }
    setIsReadOnly(initialReadOnlyState);
    setIsDelete(initialDeleteState);
  }, [updateCollection.no_column]);

  //console.log(isReadOnly,"isReadOnly");

  const handleEditClick = (key) => {
    //console.log(key,"key inside FAedit");
    setIsReadOnly((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleUndoEdit = (key) => {
    //console.log(key,"key inside undo");
    setIsReadOnly((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));

    updateCollection.column_name[key] = updateCollection.old_column_name[key];
    updateCollection.column_mandatory[key] =
      updateCollection.old_column_mandatory[key];
    updateCollection.field_name_desc[key] =
      updateCollection.old_field_name_desc[key];
    updateCollection.select_list_value[key] =
      updateCollection.old_select_list_value[key];
    updateCollection.data_type[key] = updateCollection.old_data_type[key];
  };

  const handleDeleteClick = (key) => {
    //console.log(key,"key to delete");

    setIsDelete((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleAddFields_update = () => {
    if (updateCollection.no_column) {
      setMainTableUpdate((prevState) => [
        ...prevState,
        {
          number: prevState.length + Number(updateCollection.no_column),
          field_name: "",
          field_name_description: "",
          fieldType: "",
          listDetails: "",
          selectedMandatoryField: "Mandatory",
        },
      ]);
    }
  };

  //add fields to inr during update............

  const handleAddDynamicTextBoxes_update = () => {
    setDynamicBoxesUpdate((prevState) => {
      //const newKey = ((Object.keys(prevState).length) + (updateCollection.inr && Object.keys(updateCollection.inr).length) + 1);

      const newKey =
        Number(Object.keys(prevState).length) +
        (updateCollection.inr
          ? Number(Object.keys(updateCollection.inr).length)
          : 0) +
        1;
      return {
        ...prevState,
        [newKey]: "",
      };
    });

    if (dynamicBoxesUpdate && Object.keys(dynamicBoxesUpdate).length > 1) {
      setUpdateCollection((prevState) => ({
        ...prevState,
        validation_feeinrAmount: "",
      }));
    }
  };

  const handleSubtractDynamicTextBoxes_update = (key) => {
    setDynamicBoxesUpdate((prevState) => {
      const updatedBoxes = { ...prevState };
      delete updatedBoxes[key];
      return updatedBoxes;
    });
  };

  //console.log(isReadOnly, "isReadonly");
  //console.log(isDelete, "isDelete");

  //console.log(maintableupdate, "maintableupdate");

  //console.log(copycatUpdate, "copyCatUpdate")

  const handleSubmit_update = () => {
    //console.log("handleSubmitUpdate");

    let data_to_backend = {};
    data_to_backend.form_id = Number(form_id);

    if (updateCollection.formtitle !== "") { data_to_backend.formtitle = updateCollection.formtitle; }
    else {
      //console.log("here");
      setUpdateCollection((prevState) => ({
        ...prevState,
        validation_formtitle: "This Field is Required!",
      }));
      return;
    }

    data_to_backend.role_id = user ? user.role_id : "";

    data_to_backend.formimage = updateCollection.dummyimg
      ? updateCollection.user_profile_pic
      : "";

    data_to_backend.formmode = updateCollection.selectedformMode;

    if (updateCollection.selectedformMode === "Paid") {
      // const mergedObject = dynamicBoxesUpdate && updateCollection.inr
      //   ? { ...dynamicBoxesUpdate, ...updateCollection.inr }
      //   : dynamicBoxesUpdate || updateCollection.inr || {};
      const convertValuesToNumbers = (obj) => {
        return Object.keys(obj).reduce((acc, key) => {
          acc[key] = Number(obj[key]);
          return acc;
        }, {});
      };

      const dynamicBoxesUpdateNumeric = dynamicBoxesUpdate
        ? convertValuesToNumbers(dynamicBoxesUpdate)
        : {};

      const updateCollectionInrNumeric = updateCollection.inr
        ? convertValuesToNumbers(updateCollection.inr)
        : {};

      const mergedObject =
        dynamicBoxesUpdateNumeric && updateCollectionInrNumeric
          ? { ...dynamicBoxesUpdateNumeric, ...updateCollectionInrNumeric }
          : dynamicBoxesUpdateNumeric || updateCollectionInrNumeric || {};

      //data_to_backend.inr = updateCollection.mergedObject;
      data_to_backend.inr = JSON.stringify(mergedObject);
      data_to_backend.mailcontent = updateCollection.extramailContent;


      if (updateCollection.TowardsOnlineCourseorWorkshop !== "") {
        data_to_backend.toward = updateCollection.TowardsOnlineCourseorWorkshop;
      }
      else {

        setUpdateCollection((prevState) => ({
          ...prevState,
          validation_towards: "This Field is Required!",
        }));
        return;

      }



      data_to_backend.name = updateCollection.organiserName;

      data_to_backend.address = updateCollection.address;
    }

    if (updateCollection.selectedformMode === "Free") {
      data_to_backend.inr = "";
      data_to_backend.mailcontent = "";

      data_to_backend.toward = "";

      data_to_backend.name = "";

      data_to_backend.address = "";
    }

    data_to_backend.formdesc = updateCollection.editorContent;

    data_to_backend.nocolumn =
      maintableupdate && maintableupdate.length > 0
        ? updateCollection.no_column + maintableupdate.length
        : updateCollection.no_column;

    // new code
    let allValuesTrue = true;
    let validation_table_update = {};
    //newly added..
    let specialvalidation = {};
    //....

    if (data_to_backend.nocolumn && updateCollection.no_column) {
      for (let i = 0; i < updateCollection.no_column; i++) {
        if (i < updateCollection.no_column) {
          data_to_backend[`field_name_${i + 1}`] =
            updateCollection.column_name[i]?.toString();

          data_to_backend[`field_name_desc_${i + 1}`] =
            updateCollection.field_name_desc[i]?.toString();

          data_to_backend[`data_type_${i + 1}`] =
            updateCollection.data_type[i]?.toString();

          data_to_backend[`list_${i + 1}`] =
            updateCollection.select_list_value[i]?.toString();

          data_to_backend[`mandatory_${i + 1}`] =
            updateCollection.column_mandatory[i]?.toString();

          data_to_backend[`field_oldname_${i + 1}`] =
            updateCollection.old_column_name[i]?.toString();

          //action code

          if (isReadOnly[i] === true) {
            if (isDelete[i] === true) {
              data_to_backend[`action_${i + 1}`] = "DROP";
            } else {
              data_to_backend[`action_${i + 1}`] = "-";
            }
          } else {
            if (
              updateCollection.column_name[i] !==
              updateCollection.old_column_name[i] &&
              updateCollection.data_type[i] ===
              updateCollection.old_data_type_final_submit_edit[i]
            ) {
              data_to_backend[`action_${i + 1}`] = "RENAME";
            } else if (
              updateCollection.data_type[i] !==
              updateCollection.old_data_type_final_submit_edit[i] &&
              updateCollection.column_name[i] ===
              updateCollection.old_column_name[i]
            ) {
              data_to_backend[`action_${i + 1}`] = "MODIFY";
            } else {
              data_to_backend[`action_${i + 1}`] = "RENAME";
            }
          }
        }
      }

      if (maintableupdate && maintableupdate.length > 0) {

        //console.log("special validation");
        //loop for validation
        for (let i = updateCollection.no_column; i < data_to_backend.nocolumn; i++) {
          let obj = maintableupdate[i - updateCollection.no_column];



          //validation for comma separated...
          if (obj.fieldType === 2 || obj.fieldType === 4) {
            if (obj["listDetails"] === "") {
              specialvalidation[i] = "This field is Required";
              //return ;
            }
            else if (obj["listDetails"] !== "") {
              specialvalidation[i] = "";

              if (correctcomma) {
                if (Object.values(correctcomma).every(value => value !== true)) {
                  return;
                }
              }
            }
          }



          let result = obj && Object.keys(obj)
            .filter(
              (key) => key !== "field_name_description" && key !== "listDetails"
            )
            .every((key) => obj[key] !== undefined && obj[key] !== "");

          validation_table_update[i] = result;
          // setCopyCat(prevCopycat => ({
          //   ...prevCopycat,
          //   [i]: result
          // }));
        }
        if (validation_table_update) {
          setCopyCatUpdate(validation_table_update);
          //return;
        } else {
          setCopyCatUpdate("");
        }
        //newly added..
        if (specialvalidation) {
          setcommavalidation(specialvalidation);
        }



        allValuesTrue = Object.values(validation_table_update).every(
          (value) => value === true
        );

        //console.log(allValuesTrue, "allvaluestrue");

        // end of loop for validation

        // actual setting of maaintableupdate to backend
        for (
          let i = updateCollection.no_column;
          i < data_to_backend.nocolumn;
          i++
        ) {
          data_to_backend[`field_name_${i + 1}`] =
            maintableupdate[
              i - updateCollection.no_column
            ].field_name.toString();

          data_to_backend[`field_name_desc_${i + 1}`] =
            maintableupdate[
              i - updateCollection.no_column
            ].field_name_description.toString();

          data_to_backend[`data_type_${i + 1}`] =
            maintableupdate[
              i - updateCollection.no_column
            ].fieldType.toString();

          data_to_backend[`list_${i + 1}`] =
            maintableupdate[
              i - updateCollection.no_column
            ].listDetails.toString();

          data_to_backend[`mandatory_${i + 1}`] =
            maintableupdate[
              i - updateCollection.no_column
            ].selectedMandatoryField.toString();

          data_to_backend[`action_${i + 1}`] = "ADD";
          data_to_backend[`field_oldname_${i + 1}`] =
            maintableupdate[
              i - updateCollection.no_column
            ].field_name.toString();
        }
      }
    }


    if (data_to_backend && allValuesTrue && specialvalidation && Object.values(specialvalidation).every(value => value === "")) {
      mutationsendformtoBK.mutate(data_to_backend);
    } else {
      return;
    }
  };

  const Dynamic_Edit_form = (finaldata) =>
    EDIT_FORMS(EDIT_PARTICULAR_FORM, finaldata);

  const EDIT_FORMS = async (EDIT_PARTICULAR_FORM, finaldata) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${EDIT_PARTICULAR_FORM}`,
        data: finaldata,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  //console.log(maintableupdate, "maintableupdate");

  const mutationsendformtoBK = useMutation({
    mutationKey: ["edit_dynamic_form"],
    mutationFn: (finaldata) => Dynamic_Edit_form(finaldata),

    onSuccess: (data) => {
      //   queryClient.invalidateQueries({
      //     queryKey: ["fetchUser"],
      //   });

      //console.log(data, "DATA");

      if (data.status === "success") {
        //let filteredArray = data.data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
        //setfinalList((prevState) => ({ ...prevState, data: filteredArray }));

        //setfinalList((prevState) => ({ ...prevState, data: data.data }));
        SuccessNewToaster("success", "Form Updated Successfully!", 3000);
        setMainTableUpdate([]);

        setIsDelete({});
        setIsReadOnly({});
        setCounterUpdate(counterUpdate + 1);
        setDynamicBoxesUpdate({});
        //newly added
        setCopyCatUpdate({});
        if (onCounterUpdate) {
          onCounterUpdate();
        }
      } else {
        //setfinalList((prevState) => ({ ...prevState, data: "" }));
      }
    },
    onError: (error) => {
      HandleErrorToast(
        "error",
        "Form Could not be Updated. Something went Wrong!",
        3000
      );
      //setfinalList((prevState) => ({ ...prevState, data: "" }));
    },
  });

  //console.log(maintableupdate,"maintableupdate");
  //console.log(isDelete,"isDelete");

  //console.log(updateCollection, "updateCollection");
  //console.log(updateCollection.data_type, "data_type");

  const handleSubtractFields_update = (number) => {
    const updatedState = [...maintableupdate];
    const indextobedeleted = updatedState.findIndex(
      (item) => item.number === number
    );

    if (indextobedeleted !== -1) {
      updatedState.splice(indextobedeleted, 1);
      //console.log(updatedState, "UDS");
      setMainTableUpdate(updatedState);
    } else {
      //console.log(`Item with number ${number} not found.`);
    }
  };

  //console.log(dynamicBoxesUpdate, "dynamicBoxesUpdate");

  //console.log(dynamicBoxes, "dynamicBoxes");

  const handleValueChangeMainTable = (index, fieldName, value) => {


    setMainTableUpdate((prevState) => {
      const updatedMainTable = [...prevState];
      //updatedMainTable[index][fieldName] = value;

      const index_final = updatedMainTable.findIndex(
        (item) => item.number === Number(index)
      );

      if (index_final !== -1) {
        updatedMainTable[index_final] = {
          ...updatedMainTable[index_final],
          [fieldName]: value,
        };
      }
      if (fieldName === "fieldType" && value !== 2 && value !== 4) {
        updatedMainTable[index_final].listDetails = "";
      }

      return updatedMainTable;
    });

    const copyTable = [...maintableupdate];

    const isValid = Object.keys(copyTable[0])
      .filter((key) => key !== "field_name_description")
      .every(
        (key) => copyTable[0][key] !== undefined && copyTable[0][key] !== ""
      );

    if (isValid) {
      setUpdateCollection((prevState) => ({
        ...prevState,
        validation_maintable: "",
      }));
    }

  };
  const handleEditButtonClick_userpic = () => {
    //fileInputRef_userpic.current.click();

    if (fileInputRef_userpic.current) {
      fileInputRef_userpic.current.value = null;
    }


    fileInputRef_userpic.current.click();
  };

  const validateCommaSeparatedNames = (index) => (e) => {
    //console.log(index, "index");

    let correctcommaobj = { ...correctcomma };

    const names = e.value.split(',').map(name => name.trim());

    for (let name of names) {
      if (!name || name.length < 1) {
        correctcommaobj[index] = false;
        break;
      } else {
        correctcommaobj[index] = true;
      }
    }

    setcorrectcomma(prevState => {
      const hasChanged = prevState[index] !== correctcommaobj[index];
      if (hasChanged) {
        return { ...prevState, [index]: correctcommaobj[index] };
      }
      return prevState;
    });

    return correctcommaobj[index];
  };

  const markup = ` `;
  //console.log(commavalidation, "commavalidation");
  //console.log(correctcomma,"correctcomma");

  // **************** end of UPDATE FUNCTIONALITY************

 

  return (

    <>
      {/* Edit form */}
      <div className="description-content">
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group mb-2">
              <TextBox
                type="text"
                name="first-name_update"
                className="dx-field-item-required"
                required="required"
                labelMode="floating"
                label="Enter Form Title"
                //placeholder="Enter Form Title"
                value={updateCollection.formtitle || ""}
                onValueChanged={handleFormTitle_update}
              />
              {updateCollection.validation_formtitle && (
                <span style={{ color: "red" }}>
                  {updateCollection.validation_formtitle}
                </span>
              )}
            </div>
          </div>

          <div className="col-lg-6 form-group mb-2">
            <SelectBox
              className="dx-field-item-required"
              label="Select Form Mode"
              labelMode="floating"
              dataSource={dataSource_regform}
              valueExpr="text"
              displayExpr="text"
              value={updateCollection.selectedformMode}
              onValueChanged={handleFormModeSelectBox_update}
            />
          </div>

          {updateCollection.selectedformMode === "Paid" ? (
            <>
              {/* <TextBox
                          type="text"
                          //name="first-name"
                          //className="dx-field-item-required"
                          //required="required"

                          labelMode="floating"
                          label="Fee INR Amount (Eg: For multiple amount 10,20,30)"
                          value={updateCollection.feeinrAmount}
                          onValueChanged={handleFeeInrAmount_update}
                        /> */}

              {updateCollection.inr &&
                Object.keys(updateCollection.inr).map((key) => (
                  <div className="field-pair col-lg-6" key={key}>
                    <div className="field-column">
                      <TextBox
                        type="text"
                        labelMode="floating"
                        label={`Fee INR Amount ${key}`}
                        //readOnly={isReadOnly[key]}
                        value={updateCollection.inr[key]}
                        onValueChanged={(e) =>
                          handleINR_update(key, e.value)
                        }
                      />
                    </div>
                  </div>
                ))}
              <div className="col-lg-12">
                <button
                  className="py-2 px-5 rounded"
                  style={{ backgroundColor: "#e6ddff" }}
                  onClick={handleAddDynamicTextBoxes_update}
                >
                  {" "}
                  + Add Fee (Rs)
                </button>
              </div>
              {dynamicBoxesUpdate &&
                Object.keys(dynamicBoxesUpdate).map((key) => (
                  <div key={key} className="col-lg-6 d-flex">
                    <div className="w-100">
                      <TextBox
                        key={key}
                        type="text"
                        labelMode="floating"
                        required="required"
                        label={`Fee INR Amount ${key}`}
                        value={dynamicBoxesUpdate[key]}
                        onValueChanged={(e) => {
                          const newValue = e.value;
                          setDynamicBoxesUpdate((prevState) => ({
                            ...prevState,
                            [key]: newValue,
                          }));
                        }}
                      />
                    </div>

                    <button
                      className="px-4 mt-2 rounded ms-1"
                      style={{ backgroundColor: "#e6ddff" }}
                      onClick={() =>
                        handleSubtractDynamicTextBoxes_update(key)
                      }
                    >
                      {" "}
                      -
                    </button>
                  </div>
                ))}

              {updateCollection.validation_feeinrAmount && (
                <span style={{ color: "red" }}>
                  {updateCollection.validation_feeinrAmount}
                </span>
              )}
              {/* <TextBox
                  type="text"
                  //name="first-name"
                  //className="dx-field-item-required"
                  //required="required"
                  mode="floating"
                  label="Fee USD Amount* (Eg: For multiple amount 5,8,10)"
                  value={formCollection.feeUSDamount}
                  onValueChanged={handleFeeUSDAmount}
                /> */}
              <div className="col-lg-6">
                <TextBox
                  type="text"
                  //name="first-name"
                  //className="dx-field-item-required"
                  //required="required"
                  labelMode="floating"
                  label="Extra Mail Content"
                  value={updateCollection.extramailContent}
                  onValueChanged={handleextramailContent_update}
                />
              </div>
              <div className="col-lg-6">
                <TextBox
                  type="text"
                  //name="first-name"
                  //className="dx-field-item-required"
                  //required="required"
                  labelMode="floating"
                  label="Towards* (Eg: Fees for Online Course / Workshop)"
                  value={updateCollection.TowardsOnlineCourseorWorkshop}
                  onValueChanged={handleOnlineCourseorWorkshop_update}
                />
              </div>

              {updateCollection.validation_towards && (
                <span style={{ color: "red" }}>
                  {updateCollection.validation_towards}
                </span>
              )}

              {/* <TextBox
                  type="text"
                  //name="first-name"
                  //className="dx-field-item-required"
                  //required="required"
                  mode="floating"
                  label="Receipt Code* (Eg: For Music of Madras, the code can be MOM)"
                  value={formCollection.receiptCode}
                  onValueChanged={handlereceiptCode}
                /> */}
              {/* {formCollection.validation_receiptCode && <span style={{color:'red'}}>{formCollection.validation_receiptCode}</span>} */}
              <div className="col-lg-6">
                <TextBox
                  type="text"
                  //name="first-name"
                  //className="dx-field-item-required"
                  //required="required"
                  labelMode="floating"
                  label="Organiser Name"
                  value={updateCollection.organiserName}
                  onValueChanged={handleorganiserName_update}
                />
              </div>
              <div className="col-lg-6">
                <TextBox
                  type="text"
                  //name="first-name"
                  //className="dx-field-item-required"
                  //required="required"
                  labelMode="floating"
                  label="Address"
                  value={updateCollection.address}
                  onValueChanged={handleaddress_update}
                />
              </div>
            </>
          ) : null}
        </div>

        <div className="col-lg-12 mt-2 ">
          <div className="form-group mb-2">
            <div id="" className="">
              <label className="mb-2 me-5">
                Upload Image*
              </label>
              <MdModeEditOutline
          className="EditIcon me-4"
          onClick={handleEditButtonClick_userpic}
        />
              {updateCollection.showCropper ? (
                <div>
                  <Cropper
                    src={updateCollection.image}
                    style={{ height: "200px", width: "50%" }}
                    // Cropper.js options
                    aspectRatio={3.5} // Adjust the aspect ratio for a small but lengthy rectangle
                    guides={false}
                    ref={cropperRef}
                    viewMode={3} // Restrict cropping within the canvas
                    dragMode="move" // Allow moving the image within the crop box
                    cropBoxMovable={false} // Prevent the crop box from being moved
                    cropBoxResizable={false} // Prevent the crop box from being resized
                    autoCropArea={1} // Automatically fit the crop box within the canvas
                    cropend={handleCrop_update}
                  />
                  {/* <button
                          className="btn btn-info py-1 px-2 mt-2 me-4 text-white"
                          onClick={handleCrop_update}
                        >
                          {" "}
                          <FontAwesomeIcon icon={faSave} />
                        </button> */}
                  <button
                    className="btn btn-danger py-1 px-2 mt-2 me-4"
                    onClick={handleCancelforuserpic_update}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              ) : (
                <div className="mt-2">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange_userpic_update}
                    style={{ display: "none" }}
                    ref={fileInputRef_userpic}
                  />

                  {updateCollection.imagechecker ? (
                    <img
                      src={`${BASEPATH}public/images/form_images/${updateCollection.user_profile_pic}`}
                      className="img-fluid object-cover"
                      alt="cropped"
                      loading="lazy"

                      style={{ width: '400px', height: 'auto', cursor: 'pointer', }}
                    />
                  ) : (
                    <>
                      {updateCollection.showSaveandCancelbuttonPic && (
                        <div>
                          <i
                            class="fas fa-times cancelIcon"
                            onClick={handleCancelforuserpic_update}
                          ></i>
                        </div>
                      )}
                      <img
                        src={updateCollection.dummyimg}
                        className="img-fluid object-cover"
                        alt="cropped"
                        loading="lazy"
                        style={{ width: '400px', height: 'auto', cursor: 'pointer', }}

                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* 4th div   */}

        

        <div className="col-lg-12">
          <div className="form-group mb-2">
            <label className="mb-2">
              Description, Video Links & Social Media Links&nbsp;{" "}
              <span className="text-danger">*</span>
            </label>
            <div className="widget-container">
              <HtmlEditor
                height="auto"
                defaultValue={markup}
                value={updateCollection.editorContent}
                placeholder="Write Something!"
                labelMode="floating"
                label="Description"
                onValueChanged={handleValueChanged_htmlEditor_update}
              >
                <MediaResizing enabled={true} />
                <ImageUpload tabs={currentTab} fileUploadMode="base64" />
                <Toolbar multiline={isMultiline}>
                  <Item name="undo" />
                  <Item name="redo" />
                  <Item name="separator" />
                  <Item
                    name="size"
                    acceptedValues={sizeValues}
                    options={fontSizeOptions}
                  />
                  <Item
                    name="font"
                    acceptedValues={fontValues}
                    options={fontFamilyOptions}
                  />
                  <Item name="separator" />
                  <Item name="bold" />
                  <Item name="italic" />
                  <Item name="strike" />
                  <Item name="underline" />
                  <Item name="separator" />
                  <Item name="alignLeft" />
                  <Item name="alignCenter" />
                  <Item name="alignRight" />
                  <Item name="alignJustify" />
                  <Item name="separator" />
                  <Item name="orderedList" />
                  <Item name="bulletList" />
                  <Item name="separator" />
                  <Item
                    name="header"
                    acceptedValues={headerValues}
                    options={headerOptions}
                  />
                  <Item name="separator" />
                  <Item name="color" />
                  <Item name="background" />
                  <Item name="separator" />
                  <Item name="link" />
                  <Item name="image" />
                  <Item name="separator" />
                  <Item name="clear" />
                  <Item name="codeBlock" />
                  <Item name="blockquote" />
                  <Item name="separator" />
                  <Item name="insertTable" />
                  <Item name="deleteTable" />
                  <Item name="insertRowAbove" />
                  <Item name="insertRowBelow" />
                  <Item name="deleteRow" />
                  <Item name="insertColumnLeft" />
                  <Item name="insertColumnRight" />
                  <Item name="deleteColumn" />
                </Toolbar>
              </HtmlEditor>
              {updateCollection.validation_editorContent && (
                <span style={{ color: "red" }}>
                  {updateCollection.validation_editorContent}
                </span>
              )}
            </div>
          </div>
        </div>

        {/* 5th div  */}

        <div className="border-gray py-2 rounded ">
          <div>
            <div>
              {updateCollection.column_name &&
                Object.keys(updateCollection.column_name).map((key) => (
                  <div
                    className="row pb-2 pb-2 borderLineForm"
                    key={key}
                    style={{
                      backgroundColor: isDelete[key]
                        ? "rgb(230, 221, 255)"
                        : "transparent",
                    }}
                  >
                    <div className="col-lg-2">
                      <TextBox
                        type="text"
                        labelMode="floating"
                        label="Edit Field Name"
                        readOnly={isReadOnly[key]}
                        value={updateCollection.column_name[key]}
                        onValueChanged={(e) =>
                          handlecolumnname_update(key, e.value)
                        }
                      />
                    </div>
                    <div className="col-lg-2">
                      <TextBox
                        type="text"
                        labelMode="floating"
                        label="Edit Field Name Description"
                        readOnly={isReadOnly[key]}
                        value={updateCollection.field_name_desc[key]}
                        onValueChanged={(e) =>
                          handlefieldnamedesc_update(key, e.value)
                        }
                      />
                    </div>
                    <div className="col-lg-2">
                      <SelectBox
                        //className="dx-field-item-required"
                        className="item-field"
                        label="Select Field Type"
                        labelMode="floating"
                        dataSource={updateCollection.formFields}
                        valueExpr="field_id"
                        readOnly={isReadOnly[key]}
                        displayExpr="field_name"
                        itemRender={renderItemWithIcon}
                        value={updateCollection.data_type[key]}
                        onValueChanged={(e) =>
                          handlefieldtype_update(key, e?.value)
                        }
                      />
                    </div>

                    <div className="col-lg-2">
                      <TextBox
                        className="item-field"
                        labelMode="floating"
                        value={updateCollection.select_list_value[key]}
                        readOnly={isReadOnly[key]}
                        onValueChanged={(e) =>
                          handlelistvalue_update(key, e?.value)
                        }
                        label="List the drop-down options comma seperated without space before or after comma"
                      />
                    </div>

                    <div className="col-lg-2">
                      <SelectBox
                        className="item-field"
                        //className="dx-field-item-required"
                        label="Select Mandantory Field"
                        labelMode="floating"
                        defaultValue={MANDATORY_DB[0].text}
                        dataSource={MANDATORY_DB}
                        readOnly={isReadOnly[key]}
                        valueExpr="text"
                        displayExpr="text"
                        value={updateCollection.column_mandatory[key]}
                        onValueChanged={(e) =>
                          handleMandatorySelection_update(key, e?.value)
                        }
                      />
                    </div>

                    <div className="col-lg-2 d-flex py-3">
                      {key > 2 ? (
                        <div>
                          <button>
                            {isReadOnly[key] ? (
                              <FontAwesomeIcon
                                icon={faEdit}
                                onClick={() => handleEditClick(key)}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faUndo}
                                onClick={() => handleUndoEdit(key)}
                              />
                            )}
                          </button>
                        </div>
                      ) : (
                        <div>
                          <button></button>
                        </div>
                      )}

                      {key > 2 ? (
                        <button onClick={() => handleDeleteClick(key)}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      ) : (
                        <div>
                          <button></button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {maintableupdate &&
            maintableupdate.map((item) => (
              <div key={item.number} className="item-container row pb-2 borderLineForm">
                {copycatUpdate && copycatUpdate[item.number] === false && (
                  <div className="item-field" style={{ color: "red" }}>
                    All fields except Field Name Description & List Details
                    are mandatory!
                  </div>
                )}
                <div className="col-lg-2">
                  <TextBox
                    className="item-field"
                    labelMode="floating"
                    value={item.field_name}
                    onValueChanged={(e) =>
                      handleValueChangeMainTable(
                        item.number,
                        "field_name",
                        e.value
                      )
                    }
                    label="Enter Field Name"
                  />
                </div>
                <div className="col-lg-2">
                  <TextBox
                    className="item-field"
                    labelMode="floating"
                    value={item.field_name_description}
                    onValueChanged={(e) =>
                      handleValueChangeMainTable(
                        item.number,
                        "field_name_description",
                        e.value
                      )
                    }
                    label="Enter Field Name Description"
                  />
                </div>
                <div className="col-lg-2">
                  <SelectBox
                    //className="dx-field-item-required"
                    className="item-field"
                    label="Select Field Type"
                    labelMode="floating"
                    dataSource={updateCollection.formFields}
                    valueExpr="field_id"
                    displayExpr="field_name"
                    value={item.fieldType}
                    onValueChanged={(e) =>
                      handleValueChangeMainTable(
                        item.number,
                        "fieldType",
                        e.value
                      )
                    }
                  />
                </div>

                {(item.fieldType === 2 || item.fieldType === 4) && (
                  <>
                    <div className="col-lg-2">
                      <TextBox
                        className="item-field"
                        labelMode="floating"
                        value={item.listDetails}
                        onValueChanged={(e) =>
                          handleValueChangeMainTable(
                            item.number,
                            "listDetails",
                            e.value
                          )
                        }
                        label="List the drop-down options comma seperated without space before or after comma"
                      >

                        <Validator>
                          <RequiredRule message="Please enter at least one name." />
                          <CustomRule
                            message="Each name should be at least 1 character long."
                            validationCallback={validateCommaSeparatedNames(item.number)}
                          />
                        </Validator>

                      </TextBox>
                      <div
                        className="col-12 item-field mb-2"
                        style={{ color: "red" }}
                      >   {commavalidation[item.number]} </div>


                    </div>
                  </>
                )}
                <div className="col-lg-2">
                  <SelectBox
                    className="item-field"
                    //className="dx-field-item-required"
                    label="Select Mandantory Field"
                    labelMode="floating"
                    defaultValue={MANDATORY_DB[0].text}
                    dataSource={MANDATORY_DB}
                    valueExpr="text"
                    displayExpr="text"
                    value={item.selectedMandatoryField}
                    onValueChanged={(e) =>
                      handleValueChangeMainTable(
                        item.number,
                        "selectedMandatoryField",
                        e?.value
                      )
                    }
                  />
                </div>
                {/* <button className="btn py-2" onClick={() => handleSubtractFields(index)}> - </button> */}
                <div className="col-12 col-md-6 col-lg-2 mb-2  ">
                  <button className="btn py-1 mt-2">
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      onClick={() =>
                        handleSubtractFields_update(item.number)
                      }
                    />
                  </button>
                </div>
              </div>
            ))}
        </div>
        <div className="form-group mb-2 col-lg-12 d-flex align-baseline justify-content-between mt-4">
          <button
            style={{
              backgroundColor: "#e6ddff",
              padding: "5px 10px",
              fontSize: "14px",
              width: "100px",
              borderRadius: "4px",
              boxSizing: "content-box",
            }}
            onClick={handleAddFields_update}
          >
            Add Fields
          </button>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-info py-2 px-4 text-end text-white submitBtn text-center"
              onClick={handleSubmit_update}
            >
              Submit
            </button>
          </div>
        </div>

      </div>
    </>




  )
}