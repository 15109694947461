import React from 'react'
import { FaPhone } from "react-icons/fa6";

const Contact = () => {
  return (
    
    <div>
          <div className="iq-breadcrumb organBanner">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-sm-12">
          <nav aria-label="breadcrumb" className="text-center">
            <h2 className="title text-white">Contact</h2>
          </nav>
        </div>
      </div>
    </div>
  </div>

      <div className="section-padding py-lg-3">
  <div className="container">
    <div className="row">
      <div className="col-lg-8">
        <div className="">
          <h2>Create With Us</h2>
        </div>
        <form className="mb-5 mb-lg-0">
          <div className="row">
            <div className="col-md-6 mb-4 mb-lg-5">
              <input type="text" className="form-control font-size-14" placeholder="Your Name" />
            </div>
            <div className="col-md-6 mb-4 mb-lg-5">
              <input type="tel" className="form-control font-size-14" maxlength="140" minlength="10" placeholder="Phone Number" />
            </div>
            <div className="col-md-6 mb-4 mb-lg-5">
              <input type="email" className="form-control font-size-14" placeholder="Your Email" />
            </div>
            <div className="col-md-6 mb-4 mb-lg-5">
              <input type="text" className="form-control font-size-14" placeholder="Subject" />
            </div>
            <div className="col-md-12 mb-4 mb-lg-5">
              <textarea className="form-control font-size-14" cols="40" rows="10" placeholder="Your Message"></textarea>
            </div>
            <div className="col-md-12 d-flex justify-content-end">
              <div className="iq-button">
                <button type="submit" className="btn">Send Message</button>
              </div>
            </div>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>

<div className="section-padding pt-2">
  <div className="container">
    <div className="row">
      <div className="col-lg-10">
        <div className="title-box">
          <h4 className="fw-500">To Learn More About How MDnD Can Help You, Contact Us. We'd Be Happy To Take On The Challenge!</h4>
        </div>
      </div>
      <div className="col-lg-2 d-none d-lg-block"></div>
    </div>
    <div className="row">
      <div className="col-lg-4 col-md-6">
        <div className="contact-box d-flex gap-3 rounded mb-3 mb-lg-0">
          <div className="icon-wrapper rounded-circle text-black">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
  <path d="M12 2C8.13 2 5 5.13 5 9c0 6.25 7 13 7 13s7-6.75 7-13c0-3.87-3.13-7-7-7z"></path>
  <circle cx="12" cy="9" r="2.5"></circle>
</svg>

          </div>
          <div>
            <h6 className="font-size-18 fw-500 mb-2">Address</h6>
            <p className="mb-1 font-size-14">MDnD Entertainment Private Limited
149/1, Luz Church Road, Mylapore, Chennai - 600004 , Tamil nadu, India, India
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="contact-box d-flex gap-3 rounded mb-3 mb-lg-0">
          <div className="icon-wrapper rounded-circle text-black">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
  <path d="M12 2C8.13 2 5 5.13 5 9c0 6.25 7 13 7 13s7-6.75 7-13c0-3.87-3.13-7-7-7z"></path>
  <circle cx="12" cy="9" r="2.5"></circle>
</svg>

          </div>
          <div>
            <h6 className="font-size-18 fw-500 mb-2">Phone Number</h6>
            <p className="mb-1 font-size-14">WhatsApp "HI" to: +91 8072 336688
or call: +91 99401 52520
            </p>
            <p></p>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="contact-box d-flex gap-3 rounded">
          <div className="icon-wrapper rounded-circle text-black">
          <FaPhone />

          </div>
          <div>
            <h6 className="font-size-18 fw-500 mb-2">Email Address</h6>
            <p className="mb-0">kalyan@mdnd.in
            </p>
            <p className="mb-0">events@mdnd.in
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    </div>

  )
}

export default Contact