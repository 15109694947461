import React from "react";
import { FacebookShareButton,TwitterShareButton,WhatsappShareButton } from "react-share";
import { Helmet } from "react-helmet";


export default function ShareToSocialMedia_myform({shareUrl, postdescr}){
    
    return(
    
    <>
 <Helmet>
        <meta property="og:url" content={shareUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={postdescr} />
        {/* <meta property="og:description" content={postdescr || "Check out this content!"} /> */}
        {/* <meta property="og:image" content={postimage || ogUrl} /> */}
      </Helmet>


     <FacebookShareButton style={{color: "blue"}} url={shareUrl}>
            <i className="fa-brands fa-facebook" style={{
                  fontSize: "40px",
                  cursor: "pointer",
                }}></i>
            </FacebookShareButton>
            <TwitterShareButton style={{color: "black"}} url={shareUrl} >
            
              <i className="fa-brands fa-x-twitter" style={{
                  fontSize: "40px",
                  cursor: "pointer",
                }}></i>
              </TwitterShareButton>
            <WhatsappShareButton style={{color: "green"}} url={shareUrl}>
            <i
                                          className="fa-brands fa-whatsapp"
                                          style={{
                                            fontSize: "40px",
                                            cursor: "pointer",
                                          }}
                                        ></i>
            </WhatsappShareButton>
    
    </>)
}