import React from "react";
import { useState, useEffect, useContext } from "react";

import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { BASEPATH } from "../../config/config";
import { GET_FOLLOWERS } from "../../Api/api";
import { IMAGE_URL, ArtistallImg, Fan_images, ORGANISER_URL } from "../MainPage/constant";
import { useNavigate } from "react-router-dom";
import Noimage_circle from "../../global/NoImage_circle";
import NoDataFound from "../../global/NoDataFound";
import { isValidImage, imageExists } from "../../global/ImageCheck";


export default function Followers_organiser(props) {
  const navigate = useNavigate();
  let { roleid } = props;
  const token = localStorage.getItem("MDnD_auth");


  const [followCollection, setFollowCollection] = useState({
    followers: "",

  })

  useEffect(() => {
    if (roleid) { mutationfetchFollowersList.mutate(); }
    else {
      return;
    }
  }, [roleid])

  const Getting_Followers = () =>
    GET_FOLLOWERS_ALL(GET_FOLLOWERS);

  const GET_FOLLOWERS_ALL = async (
    GET_FOLLOWERS) => {

    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_FOLLOWERS}`,
        data: {
          role_id: roleid ? roleid : ""

        },

        headers: {
          'MDnD_auth': `${token}`,

        },

        timeout: 60000,
        responseType: 'json',

      });

      return response.data;
    }
    catch (error) {
      console.error(error);
    }
  };

  const mutationfetchFollowersList = useMutation({
    mutationKey: ["get_All_followers"],
    mutationFn: (fid) => Getting_Followers(),

    onSuccess: (data) => {
      //   queryClient.invalidateQueries({
      //     queryKey: ["fetchUser"],
      //   });

      let filteredData = data?.data && data?.data.filter((item) => item.role_name !== null && item.role_name !== "");

      const uniqueData = filteredData && filteredData?.reduce((acc, current) => {
        const duplicate = acc.find(item => item.follower_id === current.follower_id);

        if (!duplicate) {
          acc.push(current);
        }

        return acc;
      }, []);


      if (data.Status === "success") {

        if (uniqueData) { setFollowCollection((prevState) => ({ ...prevState, followers: uniqueData })); }
      }
      else {
        setFollowCollection((prevState) => ({ ...prevState, followers: uniqueData ? uniqueData : "" }));
      }
    },
    onError: (error) => {


      setFollowCollection((prevState) => ({ ...prevState, followers: "" }));
    },
  });

  const handleFollowerNavigate = (item) => {
    console.log(item, "item");
    if (item.role_name === "artist") {
      navigate(`/artist/${item.profilename}?rid=${item.follower_id}`, {
        state: { follower_id: item.follower_id },
      });
    }
    else if (item.role_name === "organiser") {
      navigate(`/organiser/${item.profilename}?rid=${item.follower_id}`, { state: { follower_id: item.follower_id } });
    }
    else {
      return;
    }
  }

  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <>
      <div>
       
        <div className="row">
          {followCollection.followers && followCollection.followers.length > 0 ? (

            followCollection.followers.map((item, index) => (
              <>
                <div
                  className="col-lg-6 col-xl-4 col-xxl-3 d-flex align-items-center gap-1 gap-lg-0 my-1 mb-3 pointer"
                  key={index}
                  onClick={() => handleFollowerNavigate(item)}
                >

                  {
                    item?.role_name === "artist" ? (
                      isValidImage(item.photo) && imageExists(`${ArtistallImg}/${item.photo}`) ? (
                        <img
                          src={`${ArtistallImg}/${item.photo}`}
                          onClick={() => handleFollowerNavigate(item)}
                          //onError={handleImageError}
                          className="commentImg rounded-circle me-2"
                          alt="Artist"
                        />
                      ) : (
                        <Noimage_circle  />
                      )
                    ) : item?.role_name === "organiser" ? (
                      isValidImage(item.photo) && imageExists(`${ORGANISER_URL}/${item.photo}`) ? (
                        <>
                          <img
                            src={`${ORGANISER_URL}/${item.photo}`}
                            onClick={() => handleFollowerNavigate(item)}
                            //onError={handleImageError}
                            className="commentImg rounded-circle me-2"
                            alt="Organiser"
                          />
                        </>) : (
                        <Noimage_circle  />
                      )
                    ) : item?.role_name === "fan" ? (
                      isValidImage(item.photo) && imageExists(`${Fan_images}/${item.photo}`) ? (
                        <>
                          <img
                            src={`${Fan_images}/${item.photo}`}
                            onClick={() => handleFollowerNavigate(item)}
                            //onError={handleImageError}
                            className="commentImg rounded-circle me-2"
                            alt="Fan"
                          />
                        </>) : (
                        <Noimage_circle   />
                      )
                    ) : (
                      <Noimage_circle />
                    )
                  }



                  <span
                    style={{ fontSize: '16px' }}
                    onClick={() => handleFollowerNavigate(item)}

                  >{item.name} ({item.role_name})</span>
                </div></>
            ))) : (<>
            <div>No followers yet!</div>
            <NoDataFound /></>)}
        </div>
      </div>
    </>
  )
}