import React, { useEffect } from "react";

const LanguageSwitcher = () => {
  useEffect(() => {
    const script1 = document.createElement("script");
    script1.innerHTML = `
      window.gtranslateSettings = {
        "default_language": "en",
        "languages": ["en", "ta", "hi", "te", "kn", "ml"],
        "wrapper_selector": ".gtranslate_wrapper"
      };
    `;
    document.body.appendChild(script1);

    const script2 = document.createElement("script");
    script2.src = "https://cdn.gtranslate.net/widgets/latest/dropdown.js";
    script2.defer = true;
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  return <div className="gtranslate_wrapper"></div>;
};

export default LanguageSwitcher;
