import CryptoJS from "crypto-js";
import { ENCRYPT_DECRYPT_KEY } from "../global/constants";

export const encryptedQuery = async (queryString) => {
  const encryptedQueryData = CryptoJS.AES.encrypt(
    queryString,
    ENCRYPT_DECRYPT_KEY
  ).toString();
  return encryptedQueryData;
};

export const decryptQuery = async (encryptedQuery) => {
  const bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(encryptedQuery),
    ENCRYPT_DECRYPT_KEY
  );
  const decryptedQuery = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedQuery;
};


export const customEncrypt = (value) => {
  const key = 3; 
  let encrypted = value
    .split("")
    .map((char) => String.fromCharCode(char.charCodeAt(0) + key))
    .join("");
  encrypted = btoa(encrypted).substring(0, 5);
  return encrypted;
};

export const customDecrypt = (encryptedValue) => {
  const key = 3;
  let decoded = atob(encryptedValue);
  const decrypted = decoded
    .split("")
    .map((char) => String.fromCharCode(char.charCodeAt(0) - key))
    .join("");
  return decrypted;
};


// const valueToEncrypt = "yes"; // Change this to "no", "true", or "false"
// const encryptedValue = customEncrypt(valueToEncrypt); // Encrypts the value
// console.log(`Encrypted Value: ${encryptedValue}`);

// const decryptedValue = customDecrypt(encryptedValue); // Decrypts the value
// console.log(`Decrypted Value: ${decryptedValue}`);
