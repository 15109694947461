import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import HtmlEditor, {
  Toolbar,
  MediaResizing,
  ImageUpload,
  Item,
} from "devextreme-react/html-editor";
import { markup, tabs } from "../../../components/others";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Button from "devextreme-react/button";
import { DateBox, TextBox, SelectBox } from "devextreme-react";
import Tooltip from "devextreme-react";
import {
  sizeValues,
  fontValues,
  headerValues,
  fontSizeOptions,
  fontFamilyOptions,
  headerOptions,
  timeLabel,
} from "../../../components/others";
import HandleErrorToast from "../../../Toaster/Failure/Failure";
import AuthContext from "../../../context/AuthContext";
import {
  LIST_FORM_FIELDS,
  SEND_CREATEFORM_SUBMIT,
  GET_DETAILED_FORM_FROM_BACKEND,
  EDIT_PARTICULAR_FORM,
} from "../../../Api/api";
import { BASEPATH } from "../../../config/config";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import "./CreateForms.css";
import SuccessNewToaster from "../../../Toaster/Success/SuccessNewToaster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faSave,
  faTimes,
  faEdit,
  faUndo,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import Validator, { NumericRule, RangeRule } from "devextreme-react/validator";

//newly added
import { MdModeEdit } from "react-icons/md";
import { FiUpload } from "react-icons/fi";


import {
  faCalendarAlt,
  faListAlt,
  faIndianRupeeSign,
  faPhone,
  faPercent,
  faClock,
  faFont,
  faMusic,
  faVideo,
  faImage,
  faEnvelope,
  faLink,
  faHashtag,
  faGlobe,
  faMapMarkerAlt,
  faCity,
  faIdCard,
  faCheckSquare,
  faCheckCircle,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';

import { RequiredRule, CustomRule } from 'devextreme-react/validator';
import { CREATE_SAVEDRAFT } from "../../../Api/api";

import notify from "devextreme/ui/notify";


const initialFields = [
  {
    number: 1,
    field_name: "Name",
    field_name_description: "",
    fieldType: 8,
    listDetails: "",
    selectedMandatoryField: "Mandatory",
  },
  {
    number: 2,
    field_name: "Email",
    field_name_description: "",
    fieldType: 12,
    listDetails: "",
    selectedMandatoryField: "Mandatory",
  },
  {
    number: 3,
    field_name: "Mobile",
    field_name_description: "",
    fieldType: 5,
    listDetails: "",
    selectedMandatoryField: "Mandatory",
  },
]

export default function CreateForms() {
  // during Edit ***********************************************

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const form_name = queryParams.get("form_name");
  const token = localStorage.getItem("MDnD_auth");

  const form_id = localStorage.getItem("form_id");
  //console.log(form_name, "form_name for Edit");
  //console.log(form_id, "form_id for Edit")

  const [isReadOnly, setIsReadOnly] = useState({});
  const [isDelete, setIsDelete] = useState({});

  const [maintableupdate, setMainTableUpdate] = useState([]);
  const [counterUpdate, setCounterUpdate] = useState(0);
  const [dynamicBoxesUpdate, setDynamicBoxesUpdate] = useState({});
  const [updateCollection, setUpdateCollection] = useState({
    datafrombackend: "",
    old_data_type_final_submit_edit: "",
    formtitle: "",
    validation_formtitle: "",

    user_profile_pic: "",
    image: null,
    showCropper: false,
    imagechecker: true,
    dummyimg: "",
    showSaveandCancelbuttonPic: false,

    selectedformMode: "",
    feeinrAmount: "",
    validation_feeinrAmount: "",
    address: "",
    organiserName: "",
    extramailContent: "",
    TowardsOnlineCourseorWorkshop: "",
    validation_towards: "",

    editorContent: "",
    validation_editorContent: "",

    //last div
    column_name: "",
    field_name_desc: "",
    data_type: "",
    select_list_value: "",
    column_mandatory: "",
    filtereddata_type: {},

    old_column_name: "",
    old_field_name_desc: "",
    old_data_type: "",
    old_select_list_value: "",
    old_column_mandatory: "",
    validation_maintable: "",
  });
  const [copycatUpdate, setCopyCatUpdate] = useState({});
  const [editdeleteKeysUpdate, setEditDeleteKeysUpdate] = useState({});

  // end of Edit ***************************************************

  const now = new Date();
  const [isMultiline, setIsMultiline] = useState(true);

  //new states...............................



  //newly added on aug 29...
  const [commavalidation, setcommavalidation] = useState({});
  const [correctcomma, setcorrectcomma] = useState({});
 

  //.............................



  const { user, setUser, authTokens, setAuthTokens, logoutUser } =
    useContext(AuthContext);
  const fileInputRef_userpic = useRef();

  const [dynamicBoxes, setDynamicBoxes] = useState({});
  const [formCollection, setformCollection] = useState({
    user_profile_pic: "",
    base64user_profile_pic: "",
    dummyimg: "",
    imagechecker: true,
    validationmsg_profile_pic: "",
    formtitle: "",
    selectedformMode: "",
    formFields: "",
    editorContent: "",
    feeinrAmount: "",
    //feeUSDamount:"",
    extramailContent: "",
    TowardsOnlineCourseorWorkshop: "",
    //receiptCode:"",
    organiserName: "",
    address: "",
    validation_formtitle: "",
    validation_picture: "",
    validation_editorContent: "",
    validation_feeinrAmount: "",
    validation_towards: "",
    //validation_receiptCode:"",

    validation_maintable: "",
    total_inr: "",
    validation_totalinr: "",
    showinr: false,
    dynamicTextBoxes: "",
    sixrulevalidator_inr: "",
    matchinginrvalidation: "",
  });
  //const [dynamicTextBoxes, setDynamicTextBoxes] = useState({});
  const [showCropper, setShowCropper] = useState(false);
  const [image, setImage] = useState(null);
  const cropperRef = useRef(null);

  const [croppedImage, setCroppedImage] = useState(null);
  const [showSaveandCancelbuttonPic, setShowSaveandCancelbuttonPic] =
    useState(false);

  const [maintable, setMainTable] = useState([]);
  const [copycat, setCopyCat] = useState({});
  const [counter, setCounter] = useState(0);
  const [status, setStatus] = useState({});



  //.............................

  const [currentTab, setCurrentTab] = useState(tabs[2].value);
  const [formData, setFormData] = useState({
    rows: [
      { eventArtists: null, eventAccompanists: null, eventOrganisers: null },
    ],
  });

  const handleRemoveRow = (index) => {
    setFormData((prevState) => ({
      rows: prevState.rows.filter((_, i) => i !== index),
    }));
  };

  const handleAddAnotherRow = () => {
    setFormData((prevState) => ({
      rows: [
        ...prevState.rows,
        {
          eventArtists: null,
          eventAccompanists: null,
          eventOrganisers: null,
        },
      ],
    }));
  };

  //console.log(user, "JWT");
  //code for CREATE FORM...................

  //setting initial maintable..........
  // useMemo(()=>{
  //   setMainTable(prevState => [
  //     ...prevState,
  //     { number: 1, field_name: 'Name', field_name_description: "", fieldType: 8, listDetails: "", selectedMandatoryField: "Mandatory" },
  //     {number: 2, field_name: 'Email', field_name_description: "", fieldType: 12, listDetails: "", selectedMandatoryField: "Mandatory"},
  //     {
  //      number: 3, field_name: 'Mobile', field_name_description: "", fieldType: 5, listDetails: "", selectedMandatoryField: "Mandatory"}
  //   ]);

  // },[])

  useEffect(() => {
    setMainTable(initialFields);
  }, []);

  //end of setting main table




  const handleEditButtonClick_userpic = () => {
    //fileInputRef_userpic.current.click();

    if (fileInputRef_userpic.current) {
      fileInputRef_userpic.current.value = null;
    }


    fileInputRef_userpic.current.click();
  };

  const handleFileChange_userpic = (event) => {
    const file_pic = event.target.files[0];


    if (file_pic) {
      const allowedTypes = ['image/jpeg', 'image/png'];
      if (!allowedTypes.includes(file_pic.type)) {
        HandleErrorToast("error", 'Please select a valid image file (JPEG, PNG)', 3000);
        event.target.value = null;
        return;
      }
    }

    if (file_pic) {
      setImage(URL.createObjectURL(file_pic));
      setShowCropper(true);
      setformCollection((prevState) => ({
        ...prevState,
        //dummyimg: URL.createObjectURL(file_pic),
        imagechecker: true,
      }));
    }
  };

  const handleCrop = async () => {
    const cropper = cropperRef.current.cropper;
    const croppedDataUrl = cropper.getCroppedCanvas().toDataURL("image/jpeg");
    setCroppedImage(croppedDataUrl);
    setShowCropper(false);

    const response = await fetch(croppedDataUrl);
    const blob = await response.blob();
    const objectUrl = blob && URL.createObjectURL(blob);

    setformCollection((prevState) => ({
      ...prevState,
      user_profile_pic: croppedDataUrl,
      base64user_profile_pic: croppedDataUrl,
      dummyimg: objectUrl,
      imagechecker: false,
    }));

    setShowSaveandCancelbuttonPic(true);
    setformCollection((prevState) => ({
      ...prevState,
      validation_picture: "",
    }));
  };

  const handleCancelforuserpic = () => {
    setformCollection((prevState) => ({
      ...prevState,
      user_profile_pic: "",
      base64user_profile_pic: "",
      dummyimg: "",
      imagechecker: true,
    }));

    setShowCropper(false);
    setImage(null);
    setCroppedImage(null);
    fileInputRef_userpic.current.value = "";
    setShowSaveandCancelbuttonPic(false);
  };

  const handleFormTitle = (e) => {

    if (e?.value?.includes('/')) {
      setformCollection((prevState) => ({ ...prevState, formtitle: "" }));
      HandleErrorToast("error","/ Character is not Allowed",3000);
      return;
    }

    setformCollection((prevState) => ({ ...prevState, formtitle: e?.value }));

    if (e?.value?.length > 0) {
      setformCollection((prevState) => ({
        ...prevState,
        validation_formtitle: "",
      }));
    }
  };

  //console.log(formCollection,"formCollection");

  const dataSource_regform = [
    {
      id: 1,
      text: "Free",
    },
    {
      id: 2,
      text: "Paid",
    },
  ];

  useEffect(() => {
    if (dataSource_regform) {
      setformCollection((prevState) => ({
        ...prevState,
        selectedformMode: dataSource_regform[0].text,
      }));
    } else {
      setformCollection((prevState) => ({
        ...prevState,
        selectedformMode: "",
      }));
    }
  }, []);

  const handleFormModeSelectBox = (e) => {
    setformCollection((prevState) => ({
      ...prevState,
      selectedformMode: e?.value,
    }));

    //if (formCollection.selectedformMode === "Free") {
    handleExtraTextboxes();
  };

  // const handleFeeInrAmount = (e) => {
  //   setformCollection((prevState) => ({ ...prevState, feeinrAmount: e?.value }));

  //   if (e.value.length > 0) {
  //     setformCollection((prevState) => ({ ...prevState, validation_feeinrAmount: "" }));
  //   }
  // }

  // const handletotalinr =(e)=>{
  //   //setDynamicTextBoxes({});
  //   //console.log(e,"event e");
  //   const value = parseInt(e.value, 10);
  //  if(value < 6)
  //   {
  //     setformCollection((prevState) => ({ ...prevState, total_inr: value, showinr:true,
  //     sixrulevalidator_inr:""
  //    }));

  //   }
  //   else{
  //     setformCollection((prevState) => ({ ...prevState, sixrulevalidator_inr:"Enter a number less than 6"}));

  //     setformCollection((prevState) => ({ ...prevState, total_inr: "", showinr:false,}));

  //   }

  //   if (e.value.length > 0) {
  //     setformCollection((prevState) => ({ ...prevState, validation_totalinr: "" }));
  //   }
  //   handledynamicinrtextboxes(e.value);

  // }

  const handleAddDynamicTextBoxes = () => {
    setDynamicBoxes((prevState) => {
      const newKey = Object.keys(prevState).length + 1;
      return {
        ...prevState,
        [newKey]: "",
      };
    });

    if (dynamicBoxes && Object.keys(dynamicBoxes).length > 1) {
      setformCollection((prevState) => ({
        ...prevState,
        validation_feeinrAmount: "",
      }));
    }
  };

  const handleSubtractDynamicTextBoxes = (key) => {
    setDynamicBoxes((prevState) => {
      const updatedBoxes = { ...prevState };
      delete updatedBoxes[key];
      return updatedBoxes;
    });
  };

  //console.log(dynamicBoxes, "dynamicBoxes");

  // const handledynamicinrtextboxes=(number)=>{
  //   //setDynamicTextBoxes({});
  //   const newDynamicTextBoxes = {};
  //   for (let i = 1; i <= number; i++) {
  //     newDynamicTextBoxes[i] = '';
  //   }
  //   //setDynamicTextBoxes(newDynamicTextBoxes);
  //   setformCollection((prevState) => ({ ...prevState, dynamicTextBoxes: newDynamicTextBoxes }));

  // }

  // const handleDynamicTextBoxChange = (e, key) => {
  //setDynamicTextBoxes((prevState) => ({ ...prevState, [key]: e.value }));
  // const handleDynamicTextBoxChange = (e, key) => {
  //   setformCollection((prevState) => ({
  //     ...prevState,
  //     dynamicTextBoxes: {
  //       ...prevState.dynamicTextBoxes,
  //       [key]: e.value
  //     }
  //   }));

  // };

  //console.log(formCollection, "formcollection");
  //console.log(formCollection.dynamicTextBoxes,"dynamictextboxes");

  // const handleFeeUSDAmount=(e)=>{
  //   setformCollection((prevState) => ({ ...prevState, feeUSDamount: e?.value }));
  // }

  const handleextramailContent = (e) => {
    setformCollection((prevState) => ({
      ...prevState,
      extramailContent: e?.value,
    }));
  };

  const handleOnlineCourseorWorkshop = (e) => {
    setformCollection((prevState) => ({
      ...prevState,
      TowardsOnlineCourseorWorkshop: e?.value,
    }));

    if (e?.value?.length > 0) {
      setformCollection((prevState) => ({
        ...prevState,
        validation_towards: "",
      }));
    }
  };

  // const handlereceiptCode=(e)=>{
  //   setformCollection((prevState) => ({ ...prevState, receiptCode: e?.value }));

  //  if(e.value.length > 0){
  //   setformCollection((prevState) => ({ ...prevState, validation_receiptCode: "" }));
  //  }
  // }

  const handleorganiserName = (e) => {
    setformCollection((prevState) => ({
      ...prevState,
      organiserName: e?.value,
    }));
  };

  const handleaddress = (e) => {
    setformCollection((prevState) => ({ ...prevState, address: e?.value }));
  };

  const handleExtraTextboxes = () => {
    if (formCollection.selectedformMode === "Free") {
      setformCollection((prevState) => ({
        ...prevState,
        address: "",
        organiserName: "",
        //receiptCode:"",
        TowardsOnlineCourseorWorkshop: "",
        extramailContent: "",
        //feeUSDamount:"",
        feeinrAmount: "",

        total_inr: "",
        showinr: false,
        dynamicTextBoxes: "",
      }));

      //setDynamicTextBoxes({});
    }
  };

  //fetch for Assigned To *.....................
  useEffect(() => {
    if (user) {
      mutationgetListFormFields.mutate();
    }
  }, []);

  const GetFormFields = () => GET_LIST_FORMFIELDS(LIST_FORM_FIELDS);

  const GET_LIST_FORMFIELDS = async (LIST_FORM_FIELDS) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASEPATH}${LIST_FORM_FIELDS}`,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationgetListFormFields = useMutation({
    mutationKey: ["get_all_formFields"],
    mutationFn: () => GetFormFields(),

    onSuccess: (data) => {
      //   queryClient.invalidateQueries({
      //     queryKey: ["fetchUser"],
      //   });

      //console.log(data, "DATA");

      if (data.status === "success") {
        setformCollection((prevState) => ({
          ...prevState,
          formFields: data.data,
        }));
      } else {
        setformCollection((prevState) => ({ ...prevState, formFields: "" }));
      }
    },
    onError: (error) => {
      //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
      HandleErrorToast(
        "error",
        "Form Fields Could not be Fetched. Something went Wrong!",
        3000
      );
      setformCollection((prevState) => ({ ...prevState, formFields: "" }));
    },
  });

  //console.log(formCollection.formFields, "formFields");

  const handleValueChanged_htmlEditor = (e) => {
    
    
    setformCollection((prevState) => ({
      ...prevState,
      editorContent: e?.value,
    }));

    if (e?.value?.length > 0) {
      setformCollection((prevState) => ({
        ...prevState,
        validation_editorContent: "",
      }));
    }
  };

  //specific table


  

  const handleAddFields = () => {
    setMainTable((prevState) => [
      ...prevState,
      {
        number: prevState.length + 1,
        field_name: "",
        field_name_description: "",
        fieldType: "",
        listDetails: "",
        selectedMandatoryField: "Mandatory",
      },
    ]);
  };

  const handleSubtractFields = (index) => {
    setMainTable((prevState) => prevState.filter((_, idx) => idx !== index));
  };

  const handleResetFields = () => {
    setMainTable([]);
  };

  //console.log(maintable, "maintable during create");

  const handleValueChangeMainTable = (index, fieldName, value) => {
    // setMainTable(prevState => {
    //   const updatedMainTable = [...prevState];
    //   updatedMainTable[index][fieldName] = value;
    //   return updatedMainTable;
    // });

    // if(fieldName=="fieldType" && (value!==2 || value!==4) ){
    //   maintable[index].listdetails = "";

    //   setMainTable(prevState => {
    //     const updatedMainTable = [...prevState];
    //     updatedMainTable[index].listdetails = "";
    //     return updatedMainTable;
    //   });

    // }
    //console.log(index,"index inside func");
    //console.log(fieldName,"fieldname inside func");
    //console.log(value,"value insdie func");


    setMainTable((prevState) => {
      const updatedMainTable = [...prevState];
      updatedMainTable[index][fieldName] = value;

      if (fieldName === "fieldType" && value !== 2 && value !== 4) {
        updatedMainTable[index].listDetails = "";
      }

      return updatedMainTable;
    });

    const copyTable = [...maintable];

    const isValid = Object.keys(copyTable[0])
      .filter((key) => key !== "field_name_description")
      .every(
        (key) => copyTable[0][key] !== undefined && copyTable[0][key] !== ""
      );

    if (isValid) {
      setformCollection((prevState) => ({
        ...prevState,
        validation_maintable: "",
      }));
    }



  };

  const MANDATORY_DB = [
    {
      id: 1,
      text: "Mandatory",
    },
    {
      id: 2,
      text: "Not Mandatory",
    },
  ];

  //console.log(maintable, "MAINTABLE");


  const validateCommaSeparatedNames = (index) => (e) => {
    //console.log(index, "index");

    let correctcommaobj = { ...correctcomma };

    const names = e.value.split(',').map(name => name.trim());

    for (let name of names) {
      if (!name || name.length < 1) {
        correctcommaobj[index] = false;
        break;
      } else {
        correctcommaobj[index] = true;
      }
    }

    setcorrectcomma(prevState => {
      const hasChanged = prevState[index] !== correctcommaobj[index];
      if (hasChanged) {
        return { ...prevState, [index]: correctcommaobj[index] };
      }
      return prevState;
    });

    return correctcommaobj[index];
  };

  //console.log(correctcomma, "correctcomma");


  const handleSubmit = () => {

    


    if (!formCollection.formtitle) {
      setformCollection((prevState) => ({
        ...prevState,
        validation_formtitle: "This Field is required!",
      }));
      return;
    } else if (!formCollection.base64user_profile_pic) {
      setformCollection((prevState) => ({
        ...prevState,
        validation_picture: "Please Upload a Picture!",
      }));
      return;
    } else if (!formCollection.editorContent) {
      setformCollection((prevState) => ({
        ...prevState,
        validation_editorContent: "Enter something!",
      }));
      return;
    } else {
      setformCollection((prevState) => ({
        ...prevState,
        validation_formtitle: "",
        validation_picture: "",
        validation_editorContent: "",
      }));

      if (formCollection.selectedformMode === "Paid") {
        const numberOfKeysWithoutValue = Object.keys(dynamicBoxes).reduce(
          (count, key) => {
            if (dynamicBoxes[key] === "") {
              count++;
            }
            return count;
          },
          0
        );

        if (Object.keys(dynamicBoxes).length === 0) {
          setformCollection((prevState) => ({
            ...prevState,
            validation_feeinrAmount: "Please Enter atleast one Fee! (Rs)",
          }));
          return;
        } else if (numberOfKeysWithoutValue > 0) {
          //console.log("inside");
          setformCollection((prevState) => ({
            ...prevState,
            matchinginrvalidation: "Please fill all the fields in Fee(Rs) !",
          }));
          return;
        } else if (!formCollection.TowardsOnlineCourseorWorkshop) {
          setformCollection((prevState) => ({
            ...prevState,
            validation_towards: "This field is required!",
          }));
          return;
        }

        // else if(!formCollection.receiptCode){
        //   setformCollection((prevState) => ({ ...prevState, validation_receiptCode: "This field is required!" }));
        // }
        else {
          setformCollection((prevState) => ({
            ...prevState,
            validation_feeinrAmount: "",
            validation_towards: "",
            matchinginrvalidation: "",
            //validation_receiptCode:""
          }));
        }
      }

      let validation_table = {};
      //newly added..
      let specialvalidation = {};

      if (maintable.length < 1) {
        setformCollection((prevState) => ({
          ...prevState,
          validation_maintable:
            "Atleast one Entry in this Table should completely filled!",
        }));
        return;
      } else {
        setformCollection((prevState) => ({
          ...prevState,
          validation_maintable: "",
        }));

        for (let i = 0; maintable && i < maintable.length; i++) {
          let obj = maintable[i];

          //validation for comma separated...
          if (obj.fieldType === 2 || obj.fieldType === 4) {
            if (obj["listDetails"] === "") {
              specialvalidation[i] = "This field is Required";
              //return ;
            }
            else if (obj["listDetails"] !== "") {
              specialvalidation[i] = "";

              if (correctcomma) {
                if (Object.values(correctcomma).every(value => value !== true)) {
                  return;
                }
              }
            }
          }

          // special validation for country,state,city..
          if(obj.fieldType === 16){
            //console.log("spl validation");
             for(let j=0; maintable && j < maintable.length; j++){
                  let spl_obj= maintable[j];
                  if(spl_obj.fieldType !== 15)  {

                       HandleErrorToast("error","State cannot be created without a Country",5000);
                       return;
                      
                      
                      }                
             }
          }

          if(obj.fieldType === 17){
            for(let k=0; maintable && k < maintable.length; k++ ){
              let spl_obj= maintable[k];
              if(spl_obj.fieldType !== 15)  {

                HandleErrorToast("error","City cannot be created without a Country",5000);
                   return;
                  
                   
                  }
                  else if(spl_obj.fieldType !== 16){
                    HandleErrorToast("error","City cannot be created without a State",5000);
                    return;
                  }                
         }
          }

          let result = Object.keys(obj)
            .filter(
              (key) => key !== "field_name_description" && key !== "listDetails"
            )
            .every((key) => obj[key] !== undefined && obj[key] !== "");

          validation_table[i] = result;
          // setCopyCat(prevCopycat => ({
          //   ...prevCopycat,
          //   [i]: result
          // }));
        }

        if (validation_table) {
          setCopyCat(validation_table);
          //setCounter(counter+1);
        } else {
          setCopyCat("");
        }

        //newly added..
        if (specialvalidation) {
          setcommavalidation(specialvalidation);
        }




      }

      let allValuesTrue = Object.values(validation_table).every(
        (value) => value === true
      );

      //console.log(allValuesTrue,"allValuesTrue");
      if (
        formCollection.validation_formtitle == "" &&
        formCollection.validation_picture == "" &&
        formCollection.validation_editorContent == "" &&
        formCollection.matchinginrvalidation == "" &&
        formCollection.validation_feeinrAmount == "" &&
        formCollection.validation_towards == "" &&
        formCollection.validation_maintable == ""
      ) {
        if (allValuesTrue === true &&
          specialvalidation && Object.values(specialvalidation).every(value => value === "")) {
           
            mutatationCreateFormSubmit.mutate();
        } else {
          return;
        }
      } else {
        return;
      }
    }
  };

  //console.log(status,"status");

  // useEffect(()=>{
  //   if(copycat)
  //     {
  //       setStatus(copycat);
  //     }
  //     else{
  //       setStatus("");
  //     }
  // },[counter])

  // final submit tanstack..............
  const SendcreateForm = () =>
    SEND_CREATEFORM_TO_BACKEND(SEND_CREATEFORM_SUBMIT);

  const SEND_CREATEFORM_TO_BACKEND = async (SEND_CREATEFORM_SUBMIT) => {
    let finaldata = {};
    let finalBoxesObj = {};

    finaldata.formtitle = formCollection.formtitle
      ? formCollection.formtitle
      : "";
    finaldata.formimage = formCollection.base64user_profile_pic
      ? formCollection.base64user_profile_pic
      : "";
    finaldata.formmode = formCollection.selectedformMode
      ? formCollection.selectedformMode
      : "";

    const convertValuesToNumbers = (obj) => {
      const newObj = {};
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          newObj[key] = Number(obj[key]);
        }
      }
      return newObj;
    };

    const reindexBoxes = (boxes) => {
      const values = Object.values(boxes);
      const newBoxesObj = {};

      values &&
        values.forEach((value, index) => {
          newBoxesObj[index + 1] = value;
        });

      return newBoxesObj;
    };

    if (formCollection.selectedformMode === "Paid") {
      //finaldata.inr = formCollection.feeinrAmount ? formCollection.feeinrAmount : "";
      //finaldata.usd = formCollection.feeUSDamount ? formCollection.feeUSDamount : "";
      //finaldata.inr = formCollection.dynamicTextBoxes ? formCollection.dynamicTextBoxes  : "";

      finalBoxesObj = reindexBoxes(dynamicBoxes);

      finaldata.inr = JSON.stringify(
        finalBoxesObj ? convertValuesToNumbers(finalBoxesObj) : ""
      );

      finaldata.mailcontent = formCollection.extramailContent
        ? formCollection.extramailContent
        : "";
      finaldata.toward = formCollection.TowardsOnlineCourseorWorkshop
        ? formCollection.TowardsOnlineCourseorWorkshop
        : "";
      //finaldata.receiptcode = formCollection.receiptCode ? formCollection.receiptCode : "";
      finaldata.name = formCollection.organiserName
        ? formCollection.organiserName
        : "";
      finaldata.address = formCollection.address ? formCollection.address : "";
    }
    finaldata.formdesc = formCollection.editorContent
      ? formCollection.editorContent
      : "";
    finaldata.nocolumn = maintable && maintable.length;
    finaldata.role_id = user ? user.role_id : "";

    for (let i = 0; maintable && i < maintable.length; i++) {
      finaldata[`field_name_${i + 1}`] = maintable[i].field_name.toString();
      finaldata[`field_name_desc_${i + 1}`] =
        maintable[i].field_name_description.toString();
      finaldata[`data_type_${i + 1}`] = maintable[i].fieldType.toString();
      finaldata[`list_${i + 1}`] = maintable[i].listDetails.toString();
      finaldata[`mandatory_${i + 1}`] =
        maintable[i].selectedMandatoryField.toString();
    }

    //console.log(finaldata,"finaldata");

    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${SEND_CREATEFORM_SUBMIT}`,

        data: finaldata,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });
      //console.log(response, "RESPONSE");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutatationCreateFormSubmit = useMutation({
    mutationKey: ["send_createform_submit_backend"],
    mutationFn: () => SendcreateForm(),

    onSuccess: (data) => {
      //   queryClient.invalidateQueries({
      //     queryKey: ["fetchUser"],
      //   });

      //console.log(data, "DATA");

      if (data.status === "success") {
        //console.log(data,"dt");
      
        SuccessNewToaster("success", "Form Created Successfully!", 3000);
        setformCollection((prevState) => ({
          ...prevState,
          user_profile_pic: "",
          base64user_profile_pic: "",
          dummyimg: "",
          imagechecker: true,
          validationmsg_profile_pic: "",
          formtitle: "",
          selectedformMode: "",

          editorContent: "",
          feeinrAmount: "",
          //feeUSDamount:"",
          extramailContent: "",
          TowardsOnlineCourseorWorkshop: "",
          //receiptCode:"",
          organiserName: "",
          address: "",
          validation_formtitle: "",
          validation_picture: "",
          validation_editorContent: "",
          validation_feeinrAmount: "",
          validation_towards: "",
          //validation_receiptCode:"",

          validation_maintable: "",
        }));
        setMainTable(initialFields);
        setCopyCat({});
      }
    },
    onError: (error) => {
   
      HandleErrorToast(
        "error",
        "Form Could not be Submitted. Something went Wrong!",
        3000
      );
    },
  });

  // newly added by vignesh- aug 6th..........................................................

  const fieldIcons = {
    "Date": faCalendarAlt,
    "Single Selection Drop Down List": faListAlt,
    "Amount": faIndianRupeeSign,
    "Multiple Selection Drop Down List": faListAlt,
    "Mobile": faPhone,
    "Percentage": faPercent,
    "Time": faClock,
    "Text": faFont,
    "Audio": faMusic,
    "Video": faVideo,
    "Image": faImage,
    "Email": faEnvelope,
    "Link": faLink,
    "Number": faHashtag,
    "Country": faGlobe,
    "State": faMapMarkerAlt,
    "City": faCity,
    "Pan": faIdCard,
    "Select Declaration": faCheckSquare,
    "Declare": faCheckCircle,
    "default": faQuestionCircle
  };

  const renderItemWithIcon = (item) => {
    const icon = fieldIcons[item.field_name] || fieldIcons.default;
    return (
      <div>
        <FontAwesomeIcon icon={icon} />
        <span style={{ marginLeft: '8px' }}>{item.field_name}</span>
      </div>
    );
  };

  //console.log(maintable,"maintable");

  //console.log(formCollection,"formCOllection");

  //console.log(MANDATORY_DB[0].text,"mandatory first value");

  //new code..

  const handleSaveAsDraft=()=>{

    if(formCollection.formtitle === ""){
      HandleErrorToast("error","Form Title is required",5000);
      return;
    }

     
     mutationSaveAsDraft_Form.mutate();

  }

  const SaveAsDraftForm = () =>
    SEND_SAVEASDRAFT_TO_BACKEND(CREATE_SAVEDRAFT);

  const SEND_SAVEASDRAFT_TO_BACKEND = async (CREATE_SAVEDRAFT) => {

    let finaldata = {};
    let finalBoxesObj = {};

    finaldata.formtitle = formCollection.formtitle
    ? formCollection.formtitle
    : "";
  finaldata.formimage = formCollection.base64user_profile_pic
    ? formCollection.base64user_profile_pic
    : "";
  finaldata.formmode = formCollection.selectedformMode
    ? formCollection.selectedformMode
    : "";

  const convertValuesToNumbers = (obj) => {
    const newObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[key] = Number(obj[key]);
      }
    }
    return newObj;
  };

  const reindexBoxes = (boxes) => {
    const values = Object.values(boxes);
    const newBoxesObj = {};

    values &&
      values.forEach((value, index) => {
        newBoxesObj[index + 1] = value;
      });

    return newBoxesObj;
  };

  if (formCollection.selectedformMode === "Paid") {
    

    finalBoxesObj = reindexBoxes(dynamicBoxes);

    finaldata.inr = JSON.stringify(
      finalBoxesObj ? convertValuesToNumbers(finalBoxesObj) : ""
    );

    finaldata.mailcontent = formCollection.extramailContent
      ? formCollection.extramailContent
      : "";
    finaldata.toward = formCollection.TowardsOnlineCourseorWorkshop
      ? formCollection.TowardsOnlineCourseorWorkshop
      : "";
    //finaldata.receiptcode = formCollection.receiptCode ? formCollection.receiptCode : "";
    finaldata.name = formCollection.organiserName
      ? formCollection.organiserName
      : "";
    finaldata.address = formCollection.address ? formCollection.address : "";
  }
  finaldata.formdesc = formCollection.editorContent
    ? formCollection.editorContent
    : "";
  finaldata.nocolumn = maintable ? maintable.length : 0;
  finaldata.role_id = user ? user.role_id : "";


  if(maintable && maintable.length > 0){
  for (let i = 0; maintable && i < maintable.length; i++) {
    finaldata[`field_name_${i + 1}`] = maintable[i]?.field_name?.toString();
    finaldata[`field_name_desc_${i + 1}`] =
      maintable[i]?.field_name_description?.toString();
    finaldata[`data_type_${i + 1}`] = maintable[i]?.fieldType?.toString();
    finaldata[`list_${i + 1}`] = maintable[i]?.listDetails?.toString();
    finaldata[`mandatory_${i + 1}`] =
      maintable[i]?.selectedMandatoryField?.toString();
  }
  }

  //console.log(finaldata,"finaldata draft");

    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${CREATE_SAVEDRAFT}`,

        data: {
          role_id: user ? user.role_id : "",
          draft_data: finaldata,
          draft_from : "form",
          draft_id: "",
  
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });
      //console.log(response, "RESPONSE");
      return response.data;
    } catch (error) {
      console.error(error);
    }

  }

  const mutationSaveAsDraft_Form = useMutation({
    mutationKey: ["saveasDraft_form"],
    mutationFn: () => SaveAsDraftForm(),

    onSuccess: (data) => {
      //   queryClient.invalidateQueries({
      //     queryKey: ["fetchUser"],
      //   });

      //console.log(data, "DATA");

      if (data.status === "success") {
        //console.log(data,"dt");
   
        SuccessNewToaster("success", "Saved As Draft!", 3000);
        setformCollection((prevState) => ({
          ...prevState,
          user_profile_pic: "",
          base64user_profile_pic: "",
          dummyimg: "",
          imagechecker: true,
          validationmsg_profile_pic: "",
          formtitle: "",
          selectedformMode: "",

          editorContent: "",
          feeinrAmount: "",
          //feeUSDamount:"",
          extramailContent: "",
          TowardsOnlineCourseorWorkshop: "",
          //receiptCode:"",
          organiserName: "",
          address: "",
          validation_formtitle: "",
          validation_picture: "",
          validation_editorContent: "",
          validation_feeinrAmount: "",
          validation_towards: "",
          //validation_receiptCode:"",

          validation_maintable: "",
        }));
        setMainTable(initialFields);
        setCopyCat({});
      }
    },
    onError: (error) => {
    
      HandleErrorToast(
        "error",
        "Draft Could not be Submitted. Something went Wrong!",
        3000
      );
    },
  });

  

  return (
    <div className="tab-content tabBodyBg">
      <div
        id="createevent"
        className="tab-pane animated fadeInUp active show"
        role="tabpanel"
      >

        <div className="description-content">
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group mb-2">
                <TextBox
                  type="text"
                  name="first-name"
                  className="dx-field-item-required"
                  required="required"
                  labelMode="floating"
                  label="Enter Form Title"
                  //placeholder="Enter Form Title"
                  value={formCollection.formtitle}
                  onValueChanged={handleFormTitle}
                />
                {formCollection.validation_formtitle && (
                  <span style={{ color: "red" }}>
                    {formCollection.validation_formtitle}
                  </span>
                )}
              </div>
            </div>

            <div className=" col-lg-6 form-group mb-2">
              <SelectBox
                className="dx-field-item-required"
                label="Select Form Mode"
                labelMode="floating"
                dataSource={dataSource_regform}
                valueExpr="text"
                displayExpr="text"
                value={formCollection.selectedformMode}
                onValueChanged={handleFormModeSelectBox}
              />
            </div>

            {formCollection.selectedformMode === "Paid" ? (
              <>
                <div className="col-lg-12">
                  <button
                    className="py-2 px-5 rounded"
                    style={{ backgroundColor: "#e6ddff" }}
                    onClick={handleAddDynamicTextBoxes}
                  >
                    {" "}
                    + Add Fee (Rs)
                  </button>
                </div>
                {formCollection.validation_feeinrAmount && (
                  <span style={{ color: "red" }}>
                    {formCollection.validation_feeinrAmount}
                  </span>
                )}
                {formCollection.matchinginrvalidation && (
                  <span style={{ color: "red" }}>
                    {formCollection.matchinginrvalidation}
                  </span>
                )}

                {dynamicBoxes &&
                  Object.keys(dynamicBoxes).map((key) => (
                    <div key={key} className="col-lg-6 d-flex">
                      <div className="w-100">
                        <TextBox
                          key={key}
                          //type="text"
                          labelMode="floating"
                          required="required"
                          label={`Fee INR Amount`}
                          value={dynamicBoxes[key]}
                          onValueChanged={(e) => {
                            if (/^\d*\.?\d*$/.test(e.value))
                              {



                            const newValue = e.value;
                            setDynamicBoxes((prevState) => ({
                              ...prevState,
                              [key]: newValue,
                            }));
                          }
                          else{
                            HandleErrorToast("error","Only Numbers are allowed",4000);
                            setDynamicBoxes((prevState) => ({
                              ...prevState,
                              [key]: '',
                            }));
                          }
                        }
                        }
                        // onKeyDown={(e) => {
                        //   if (!/^[0-9]*$/.test(e.key) && e.key !== 'Backspace') {
                        //     e.preventDefault();
                        //   }
                        // }}
                        />
                      </div>
                      <button
                        className="px-4 mt-2 rounded ms-1"
                        style={{ backgroundColor: "#e6ddff" }}
                        onClick={() => handleSubtractDynamicTextBoxes(key)}
                      >
                        {" "}
                        -
                      </button>
                    </div>
                  ))}

                <div className="col-lg-6">
                  <TextBox
                    type="text"
                    //name="first-name"
                    //className="dx-field-item-required"
                    //required="required"
                    labelMode="floating"
                    label="Extra Mail Content"
                    value={formCollection.extramailContent}
                    onValueChanged={handleextramailContent}
                  />
                </div>

                <div className="col-lg-6">
                  <TextBox
                    type="text"
                    //name="first-name"
                    //className="dx-field-item-required"
                    required="required"
                    labelMode="floating"
                    label="Towards* (Eg: Fees for Online Course / Workshop)"
                    value={formCollection.TowardsOnlineCourseorWorkshop}
                    onValueChanged={handleOnlineCourseorWorkshop}
                  />
                  {formCollection.validation_towards && (
                  <span style={{ color: "red" }}>
                    {formCollection.validation_towards}
                  </span>
                )}
                </div>
                

                <div className="col-lg-6">
                  <TextBox
                    type="text"
                    //name="first-name"
                    //className="dx-field-item-required"
                    //required="required"
                    labelMode="floating"
                    label="Organiser Name"
                    value={formCollection.organiserName}
                    onValueChanged={handleorganiserName}
                  />
                </div>

                <div className="col-lg-6">
                  <TextBox
                    type="text"
                    //name="first-name"
                    //className="dx-field-item-required"
                    //required="required"
                    labelMode="floating"
                    label="Address"
                    value={formCollection.address}
                    onValueChanged={handleaddress}
                  />
                </div>
              </>
            ) : null}

            <div className="col-lg-12 mt-2">
              <div className="form-group mb-2 ">
                <div id="" className="">
                  {/* <DateBox
                    type="date"
                    className="dx-field-item-required"
                    label="Event Date"
                  // className="form-control w-lg-25"

                  /> */}
                  {/* <label className="mb-2 me-5">
                      Upload Mast Head(image)*
                    </label> */}
                  <div
                    id=""
                    className="col-lg-6 d-flex justify-content-between"
                  >
                    <label className="mb-2 me-5">
                      Upload Image*
                    </label>

                  </div>

                  {/* <Button
                      className="btn-primary text-white py-2 px-3 text-center mb-2"
                      //className='mainCatEditIcon'
                      onClick={handleEditButtonClick_userpic}
                    >
                      {" "}
                      <FiUpload className="me-1" />
                      Upload Image
                    </Button>

                    <Button
                        className="btn-white text-info py-2 px-1 text-center mb-2 me-3 shadow-none"
                        //className='mainCatEditIcon'
                        onClick={handleEditButtonClick_userpic}
                      >
                        {" "}
                        <MdModeEdit />
                      </Button> */}

                  {showCropper ? (
                    <>

                      <Cropper
                        src={image}
                        style={{ height: "200px", width: "50%" }}
                        aspectRatio={2.6}
                        guides={true}
                        ref={cropperRef}
                        viewMode={0} 
                        dragMode="move" 
                        cropBoxMovable={true} 
                        cropBoxResizable={true} 
                        autoCropArea={1} 
                      />

                      <button
                        className="btn btn-info py-1 px-2 mt-2 me-4 text-white"
                        onClick={handleCrop}
                      >
                        {" "}
                        <FontAwesomeIcon icon={faSave} />
                      </button>
                      <button
                        className="btn btn-danger py-1 px-2 mt-2 me-4"
                        onClick={handleCancelforuserpic}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </>
                  ) : (
                    null
                  )}

                  {formCollection.validation_picture && (
                    <span style={{ color: "red" }}>
                      {formCollection.validation_picture}
                    </span>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange_userpic}
                    style={{ display: "none" }}
                    ref={fileInputRef_userpic}
                  />


                  {/* <Button
                      className="btn-white py-1 px-1 mt-2 me-4 text-center"
                      icon="edit"
                      //className='mainCatEditIcon'
                      onClick={handleEditButtonClick_userpic}
                    /> */}
                  {/* {showSaveandCancelbuttonPic && (
                      <div>
                        <i
                          class="fas fa-times cancelIcon"
                          onClick={handleCancelforuserpic}
                        ></i>
                      </div>
                    )} */}

                  {formCollection.imagechecker ? (
                    <>
                      {showCropper === false &&
                        <Button
                          className="btn-primary text-white py-2 px-3 text-center mb-2"
                          //className='mainCatEditIcon'
                          onClick={handleEditButtonClick_userpic}
                        >
                          {" "}
                          <FiUpload className="me-1" />
                          Upload Image
                        </Button>}
                    </>
                  ) : (
                    <div className="d-flex align-baseline">
                      <img
                        src={formCollection.dummyimg}
                        className="img-fluid object-cover"
                        alt="cropped"
                        loading="lazy"
                        style={{ width: "600px", height: "auto" }}
                      //onMouseEnter={() => setIsHovered(true)}
                      //onMouseLeave={() => setIsHovered(false)}
                      />
                      <Button
                        className="btn-white text-info py-2 px-1 text-center mb-2 me-3 shadow-none"
                        //className='mainCatEditIcon'
                        onClick={handleEditButtonClick_userpic}
                      >
                        {" "}
                        <MdModeEdit />
                      </Button>

                    </div>)}
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group mb-2">
                {/* <SelectBox
                  label="Assigned To"
                  className="dx-field-item-required"
                /> */}
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group mb-2">
                <label className="mb-2">
                  Description, Video Links & Social Media Links&nbsp;{" "}
                  <span className="text-danger">*</span>
                </label>
                <div className="widget-container">
                  <HtmlEditor
                    height="auto"
                    //defaultValue={markup}
                    value={formCollection.editorContent}
                    placeholder="Write Something!"
                    labelMode="floating"
                    label="Description"
                    onValueChanged={handleValueChanged_htmlEditor}
                  >
                    <MediaResizing enabled={true} />
                    <ImageUpload tabs={currentTab} fileUploadMode="base64" />
                    <Toolbar multiline={isMultiline}>
                      <Item name="undo"  />
                      <Item name="redo" />
                      <Item name="separator" />
                      <Item
                        name="size"
                        acceptedValues={sizeValues}
                        options={fontSizeOptions}
                      />
                      <Item
                        name="font"
                        acceptedValues={fontValues}
                        options={fontFamilyOptions}
                      />
                      <Item name="separator" />
                      <Item name="bold" />
                      <Item name="italic" />
                      <Item name="strike" />
                      <Item name="underline" />
                      <Item name="separator" />
                      <Item name="alignLeft" />
                      <Item name="alignCenter" />
                      <Item name="alignRight" />
                      <Item name="alignJustify" />
                      <Item name="separator" />
                      <Item name="orderedList" />
                      <Item name="bulletList" />
                      <Item name="separator" />
                      <Item
                        name="header"
                        acceptedValues={headerValues}
                        options={headerOptions}
                      />
                      <Item name="separator" />
                      <Item name="color" />
                      <Item name="background" />
                      <Item name="separator" />
                      <Item name="link" />
                      <Item name="image" />
                      <Item name="separator" />
                      <Item name="clear" />
                      <Item name="codeBlock" />
                      <Item name="blockquote" />
                      <Item name="separator" />
                      <Item name="insertTable" />
                      <Item name="deleteTable" />
                      <Item name="insertRowAbove" />
                      <Item name="insertRowBelow" />
                      <Item name="deleteRow" />
                      <Item name="insertColumnLeft" />
                      <Item name="insertColumnRight" />
                      <Item name="deleteColumn" />
                    </Toolbar>
                  </HtmlEditor>
                  {formCollection.validation_editorContent && (
                    <span style={{ color: "red" }}>
                      {formCollection.validation_editorContent}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="">
              <div className="form-group mb-2 border-gray p-1 rounded ">
                {/* <DateBox
      defaultValue={now}
      inputAttr={timeLabel}
      className="dx-field-item-required"
      type="time"
      label="Event Start Time"
    /> */}
                <div>
                  <br />
                  {formCollection.validation_maintable && (
                    <span style={{ color: "red" }}>
                      {formCollection.validation_maintable}
                    </span>
                  )}

                  {maintable &&
                    maintable.map((item, index) => (
                      <div
                        key={index}
                        className="row item-container mb-3 borderLineForm"
                      >
                        {copycat && copycat[index] === false && (
                          <div
                            className="col-12 item-field mb-2"
                            style={{ color: "red" }}
                          >
                            All fields except Field Name Description & List
                            Details are mandatory!
                          </div>
                        )}
                        <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mb-2">
                          <TextBox
                            className="item-field"
                            labelMode="floating"
                            value={item.field_name}
                            onValueChanged={(e) =>
                              handleValueChangeMainTable(
                                index,
                                "field_name",
                                e?.value
                              )
                            }
                            label="Enter Field Name"
                            readOnly={index < 3}
                          />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mb-2">
                          <TextBox
                            className="item-field"
                            labelMode="floating"
                            value={item.field_name_description}
                            onValueChanged={(e) =>
                              handleValueChangeMainTable(
                                index,
                                "field_name_description",
                                e?.value
                              )
                            }
                            label="Enter Field Name Description"
                            readOnly={index < 3}
                          />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mb-2">
                          <SelectBox
                            className="item-field"
                            label="Select Field Type"
                            labelMode="floating"
                            dataSource={formCollection.formFields}
                            valueExpr="field_id"
                            displayExpr="field_name"
                            itemRender={renderItemWithIcon}
                            value={item.fieldType}
                            onValueChanged={(e) =>
                              handleValueChangeMainTable(
                                index,
                                "fieldType",
                                e?.value
                              )
                            }
                            readOnly={index < 3}
                          />
                        </div>
                        {(item.fieldType === 2 || item.fieldType === 4) && (
                          <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mb-2">
                            <TextBox
                              className="item-field"
                              labelMode="floating"
                              value={item.listDetails}
                              readOnly={index < 3}
                              onValueChanged={(e) =>
                                handleValueChangeMainTable(
                                  index,
                                  "listDetails",
                                  e?.value
                                )
                              }
                              label="List the drop-down options comma separated without space before or after comma"
                            >
                              <Validator>
                                <RequiredRule message="Please enter at least one name." />
                                <CustomRule
                                  message="Each name should be at least 1 character long."
                                  validationCallback={validateCommaSeparatedNames(index)}
                                />
                              </Validator>

                            </TextBox>
                            <div
                              className="col-12 item-field mb-2"
                              style={{ color: "red" }}
                            >   {commavalidation[index]} </div>
                          </div>
                        )}
                        <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mb-2">
                          <SelectBox
                            className="item-field"
                            label="Select Mandatory Field"
                            labelMode="floating"
                            defaultValue={MANDATORY_DB[0].text}
                            dataSource={MANDATORY_DB}
                            valueExpr="text"
                            displayExpr="text"
                            value={item.selectedMandatoryField}
                            onValueChanged={(e) =>
                              handleValueChangeMainTable(
                                index,
                                "selectedMandatoryField",
                                e?.value
                              )
                            }
                            readOnly={index < 3}
                          />
                        </div>
                        {index > 2 && (
                          <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mb-2  ">
                            <button
                              className="btn py-1 mt-2"
                              onClick={() => handleSubtractFields(index)}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </div>
                        )}

                      </div>
                    ))}
                  <button
                    style={{
                      marginLeft: "30px",
                      backgroundColor: "#e6ddff",
                      padding: "5px 10px",
                      fontSize: "14px",
                      borderRadius: "4px",
                      boxSizing: "content-box",
                    }}
                    onClick={handleAddFields}
                  >
                    Add Fields
                  </button>
                </div>
              </div>
              <div className="d-flex justify-content-end">
              <button
                 className="btn py-1 px-3 filterBtn m-0 text-nowrap me-2 brandcolor"
                 onClick={handleSaveAsDraft}
                >
                Save As Draft
                </button>
                {" "} { " "}&nbsp; &nbsp;
                <button
                  className="button"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
}
