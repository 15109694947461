import React from 'react';
import DataGrid, { Column, MasterDetail } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react';
//import { dataSource } from '../../../global/constants';
import { useState, useEffect, useContext, useRef } from 'react';
import AuthContext from '../../../context/AuthContext';
import HandleErrorToast from '../../../Toaster/Failure/Failure';
import { GET_FORMS_FROM_BACKEND, GET_USERPROFILE, GET_DETAILED_FORM_FROM_BACKEND, SHOWTOPUBLIC_STATUS_BACKEND, DELETE_DYNAMIC_FORM_BACKEND } from '../../../Api/api';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { BASEPATH } from '../../../config/config';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ShareToSocialMedia_myform from './ShareToSocialMedia_myform';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBorderStyle, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import "./ViewForms.css"

//newly added
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import ListForms from './ListForms';
//import { FaShareAlt } from "react-icons/fa";
import { HiOutlineShare } from "react-icons/hi";
import EditForms from './EditForms';


export default function ViewForms() {

  const { user, setUser, authTokens, setAuthTokens, logoutUser } = useContext(AuthContext);
  const token = localStorage.getItem("MDnD_auth");
  const [counter, setCounter] = useState(0);
  const [popup, setPopup] = useState(false);
  const [popupRegistration, setpopupRegistration] = useState(false);
  const [Rowdata, setRowdata] = useState({});
  const [loading, setLoading] = useState(true);

  const [splcounter, setsplcounter] = useState(0);

  const queryClient = useQueryClient();
  const [formsFromBackend, setFormsFromBackend] = useState({
    formData: "",
    profileDetails: "",
    singleData: ""
  })
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(prevState => !prevState);
  };

  const [formidfordelete, setformidfordelete] = useState("");


  // useEffect(() => {
  //   if (user) {
  //     mutationgetListAllCreatedForms.mutate()
  //   }
  // }, [counter])

  useEffect(() => {
    const triggerMutation = () => {
      if (user) {
        mutationgetListAllCreatedForms.mutate();
      }
    };


    triggerMutation();

    // const checkFormSubmission = () => {

    //   console.log("inside event listener");
    //     if (localStorage.getItem('formSubmitted') === 'true') {
    //         triggerMutation();
    //         localStorage.removeItem('formSubmitted'); 
    //     }
    // };

    // window.addEventListener('storage', checkFormSubmission);

    // return () => {
    //     window.removeEventListener('storage', checkFormSubmission);
    // };
}, [counter, splcounter]); 

  const GetAllForms = () =>
    GET_FORMS(GET_FORMS_FROM_BACKEND);

  const GET_FORMS = async (
    GET_FORMS_FROM_BACKEND) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_FORMS_FROM_BACKEND}`,
        data: {
          role_id: user ? user.role_id : ""
        },
        headers: {
          'MDnD_auth': `${token}`,

        },

        timeout: 60000,
        responseType: 'json',

      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationgetListAllCreatedForms = useMutation({
    mutationKey: ["get_all_createdForms"],
    mutationFn: () => GetAllForms(),

    onSuccess: (data) => {
      setLoading(false);

      //   queryClient.invalidateQueries({
      //     queryKey: ["fetchUser"],
      //   });


      //console.log(data, "DATA");

      if (data.status === "success") {

        let filteredArray = data.data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
        setFormsFromBackend((prevState) => ({ ...prevState, formData: filteredArray }));


      }
      else {
        setFormsFromBackend((prevState) => ({ ...prevState, formData: "" }));
      }
    },
    onError: (error) => {
      setLoading(false);
      //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
      HandleErrorToast("error", "Form Fields Could not be Fetched. Something went Wrong!", 3000);
      setFormsFromBackend((prevState) => ({ ...prevState, formData: "" }));
    },
  });


  const handleCounterUpdate = () => {
    setsplcounter(splcounter + 1);
  };

  //console.log(formsFromBackend.formData, "FD");

  const renderimagepic = ({ data }) => {

    const imageUrl = `${BASEPATH}public/images/form_images/${data.form_image}`;
    return (
      <img
        src={imageUrl}
        alt="Form Photo"
        style={{ width: '40px', height: '40px', cursor: 'pointer', }}
        className='rounded-circle'
      //onClick={() => handleImageClick(data.blog_id)}
      />
    );
  };

  const handleRegistrationList = (cellInfo) => {

    const rowData = cellInfo.data;
    const buttonStyle = {
      backgroundColor: "#e6ddff",
      //color: "white",
      color: "black",
      //borderRadius: "50%", 
      padding: "5px"
    };
    return (
      <>
        <button style={buttonStyle} onClick={() => handleList(rowData, cellInfo)}>
          View Registrations
        </button>
      </>)





  }

  const handleList = (rowData, cellInfo) => {
    //console.log(rowData, "rd for list");

    //dont delete this...............
    // const formtableName = rowData.table_name;

    // const formid = rowData.form_id;


    // localStorage.setItem('formidmain', formid);
    // localStorage.setItem('tableName', formtableName);
    // const basePath = window.location.origin;


    // const url = `${basePath}/ListForms`;

    // window.open(url, '_blank');

    //const dataGrid = cellInfo.component;
    //dataGrid.expandRow(rowData);

    setpopupRegistration(true);
    setRowdata(rowData);
  }

  const handleRegFormLink = (cellInfo) => {

    const rowData = cellInfo.data;
    const buttonStyle = {
      backgroundColor: "#e6ddff",
      //color: "white",
      color: "black",
      //borderRadius: "50%", 
      padding: "5px"
    };
    return (
      <>
        <button style={buttonStyle} onClick={() => handleRegisterFormtoNextPage(rowData)}>View Form</button>
      </>)

  }

  const encodeBase64 = (str) => {
    const prefix = "xy4AT";
    const combinedStr = prefix + str.toString();
    return btoa(combinedStr); // Base64 encoding with prefix
  };
  const handleRegisterFormtoNextPage = async (rowData) => {
    if (rowData) {

      const formName = rowData.form_name.replace(/ /g, "_");

      const formid = rowData.form_id;

      //console.log(formid, "formid");


      localStorage.setItem('formidmain', formid);
      const basePath = window.location.origin;

      const encodedFormId = formid && encodeBase64(formid);


      //const paddedFormId = encodedFormId && encodedFormId.padEnd(30, 'A'); 

      const url = `${basePath}/Form/${formName}?fid=${encodedFormId}`;

      //const url = `${basePath}/RegisterFormNewTab?formName=${formName}&formId=${formid}`;
      window.open(url, '_blank');
    }
    else { return; }

  }


  const handleToggleChange = (rowData) => {
    //console.log("changed");
    //console.log(rowData, "rowData for toggle");

    if (rowData) {
      mutationToggle.mutate(rowData);
    }
  };

  const ChangeToggle = (rowData_x) =>
    HANDLE_TOGGLE(SHOWTOPUBLIC_STATUS_BACKEND, rowData_x);

  const HANDLE_TOGGLE = async (
    SHOWTOPUBLIC_STATUS_BACKEND, rowData_x) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${SHOWTOPUBLIC_STATUS_BACKEND}`,
        data: {
          role_id: user ? user.role_id : "",
          form_id: rowData_x.form_id ? rowData_x.form_id : "",
          show_to_public: rowData_x.show_to_public === "N" ? "Y" : "N"
        },
        headers: {
          'MDnD_auth': `${token}`,

        },

        timeout: 60000,
        responseType: 'json',

      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationToggle = useMutation({
    mutationKey: ["change_toggle"],
    mutationFn: (rowData_x) => ChangeToggle(rowData_x),

    onSuccess: (data) => {
      // queryClient.invalidateQueries({
      //   queryKey: ["get_all_createdForms"],
      // });


      //console.log(data, "DATA");

      if (data.status === "success") {

        //let filteredArray = data.data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
        //setFormsFromBackend((prevState) => ({ ...prevState, formData: filteredArray }));
        setCounter(counter + 1);


      }
      else {
        //setFormsFromBackend((prevState) => ({ ...prevState, formData: "" }));
      }
    },
    onError: (error) => {
      //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
      HandleErrorToast("error", "Toggle could not be changed. Something went Wrong!", 3000);
      //setFormsFromBackend((prevState) => ({ ...prevState, formData: "" }));
    },
  });



  const handleStatus = (cellInfo) => {
    let rowData = cellInfo.data;
    //console.log(rowData,"RD");

    return (
      <>

        <div className="viewformToggle m-auto d-flex">
          <input
            type="checkbox"
            id={rowData.form_id}
            checked={rowData.show_to_public === 'Y'}
            onChange={() => handleToggleChange(cellInfo.data)}
          />
          <label for={rowData.form_id}>Toggle</label>
        </div>


      </>
    )

  }

  const handleDate = ({ data }) => {

    const date = new Date(data.created_date);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  };

  // const handleCreatedBy=()=>{
  //   return(<>
  //   {formsFromBackend.profileDetails.name}
  //   </>)
  // }

  const handleAction = (cellInfo) => {
    const buttonStyle_edit = {
      backgroundColor: "#e6ddff",
      //color: "white",
      color: "black",
      //borderRadius: "50%", 
      padding: "5px"
    };

    const buttonStyle_delete = {
      backgroundColor: "red",
      color: "white",
      //borderRadius: "50%", 
      padding: "5px"
    };

    //const rowData= cellInfo.data;

    return (
      <>
        <MdModeEditOutline
          className="EditIcon me-4"
          onClick={() => handleEdit(cellInfo)}
        />&nbsp;
        <RiDeleteBinLine
          className="DeleteIcon"
          onClick={() => handleDelete(cellInfo)}
        />

      </>
    )
  }

  //EDIT and DELETE FUNCTIONALITY...........

  const handleEdit = (cellInfo) => {
    const rowData = cellInfo.data;


    // const formName = rowData.form_name.replace(/ /g, "_");
    // localStorage.setItem('form_id', rowData.form_id);

    // navigate(`/createForms?form_name=${formName}`);


    const dataGrid = cellInfo.component;
    dataGrid.expandRow(rowData);

  }

  const handleDelete = (cellInfo) => {

    const rowData = cellInfo.data;

    if (rowData) {
      setPopup(true);

      setformidfordelete(rowData.form_id);
    }

    //console.log(rowData,"rowData inside delete");

    // if(rowData){
    //   mutationDeleteForm.mutate(rowData.form_id);
    // }

  }

  const handleDeleteSubmit = () => {
    mutationDeleteForm.mutate(formidfordelete);
  }

  const handleClosePopup = () => {
    setPopup(false);
    setformidfordelete("");
  }

  const DeleteDynamicForm = (fid) =>
    HANDLE_DELETE(DELETE_DYNAMIC_FORM_BACKEND, fid);

  const HANDLE_DELETE = async (
    DELETE_DYNAMIC_FORM_BACKEND, fid) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${DELETE_DYNAMIC_FORM_BACKEND}`,
        data: {
          role_id: user ? user.role_id : "",
          form_id: fid ? fid : ""

        },
        headers: {
          'MDnD_auth': `${token}`,

        },

        timeout: 60000,
        responseType: 'json',

      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationDeleteForm = useMutation({
    mutationKey: ["delete_dynamic_form"],
    mutationFn: (fid) => DeleteDynamicForm(fid),

    onSuccess: (data) => {
      // queryClient.invalidateQueries({
      //   queryKey: ["get_all_createdForms"],
      // });

      if (data.status === "success") {

        setformidfordelete("");
        setPopup(false);
        setCounter(counter + 1);

      }
      else {

      }
    },
    onError: (error) => {

      HandleErrorToast("error", "Toggle could not be changed. Something went Wrong!", 3000);

    },
  });

  //new code..

  const [expandedRowData, setExpandedRowData] = useState(null);
  const [urlforformid, seturlforformid] = useState("");
  const [urlforformName, seturlforformName] = useState("");
  const inputRef = useRef(null);

  const [popupforShare, setpopupforShare] = useState(false);

  const handleExpand = (e) => {
    setExpandedRowData(e.data);  // Store the expanded row data directly
  };

  const handleShare = (cellInfo) => {


    return (<>
      <button>
        <HiOutlineShare
          onClick={() => handleShareSecond(cellInfo)}
        />

      </button>
    </>)
  }

  const handleShareSecond = (cellInfo) => {

    const rowData = cellInfo.data;

    if (rowData) {

      const formName = rowData.form_name.replace(/ /g, "_");

      const formid = rowData.form_id;

      localStorage.setItem('formidmain', formid);
      const basePath = window.location.origin;

      const encodedFormId = formid && encodeBase64(formid);

      const url = `${basePath}/Form/${formName}?fid=${encodedFormId}`;

      if (url) {
        seturlforformid(url);


      }
      if (formName) { seturlforformName(formName); }

      setpopupforShare(true);
    }

  }

  const handleCloseShare = () => {
    setpopupforShare(false);
    seturlforformid("");
  }

  const handleOpenWhatsApp = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const whatsappUrl = isMobile
      ? "whatsapp://send"
      : "https://web.whatsapp.com/";
    window.open(whatsappUrl, "_blank");
  };

  const handleCopy = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(inputRef.current.value).catch((err) => {
      console.error("Failed to copy: ", err);
    });
  };

  const handleClose_registrations = () => {
    setRowdata({});
    setpopupRegistration(false);
  }

  return (
    <div className='mt-2'>
       {loading ? (
        <Skeleton count={10} height={60} />
      ) : (
      <DataGrid
        dataSource={formsFromBackend.formData}
        showBorders={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        rowAlternationEnabled={true}
      //onRowExpanding={handleExpand}
      >
        <Column
          caption="Sl.No"
          cellRender={(cellInfo) => {
            const { rowIndex, component } = cellInfo;
            const pageSize = component.pageSize();
            const pageIndex = component.pageIndex();
            const totalCount = component.totalCount();


            const descendingIndex = totalCount - (pageIndex * pageSize + rowIndex);

            return descendingIndex;
          }}
          width={80}
          allowEditing={false}
        />

        <Column
          dataField="form_id"
          visible={false}

        />
        <Column
          dataField="table_name"
          visible={false}
        />
        <Column
          dataField="form_name"
          caption='Title'
        />
        <Column
          dataField="form_image"
          caption="Image"
          cellRender={renderimagepic}
        />
        <Column
          dataField="created_date"
          caption="Created on"
          cellRender={handleDate}
        />
        {/* <Column
         caption="Created By"
         cellRender={handleCreatedBy}
         /> */}
        <Column
          //dataField="Registration List"
          caption="Registrations"
          cellRender={handleRegistrationList}

        />
        <Column
          //dataField="Registration Form List"
          caption="Form Link"
          cellRender={handleRegFormLink}

        />
        <Column
          //dataField="Status"
          caption="Status"
          cellRender={(cellInfo) => handleStatus(cellInfo)}
        />
        <Column
          caption="Share"
          cellRender={(cellInfo) => handleShare(cellInfo)}
        />
        <Column
          caption="Action"
          cellRender={handleAction}
        />
        {/* <MasterDetail
          enabled={true}
          component={EditForms}
        /> */}
        <MasterDetail
          enabled={true}
          component={(props) => <EditForms {...props} onCounterUpdate={handleCounterUpdate} />}
        />

      </DataGrid>
      )}
      {popup &&
        <Modal show={popup} onHide={handleClosePopup}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to delete?</Modal.Title>
          </Modal.Header>

          <Modal.Footer>

            <Button variant="primary"
              onClick={handleDeleteSubmit}
            >
              Yes
            </Button>
            <Button variant="primary" onClick={handleClosePopup}>
              No
            </Button>


          </Modal.Footer>
        </Modal>}

      {popupforShare &&
        <Modal show={popupforShare} onHide={handleCloseShare}>
          <Modal.Header closeButton>
            <h5 className='text-center m-auto border-light'>Share</h5>
          </Modal.Header>
          <Modal.Body>


            <ShareToSocialMedia_myform
              postdescr={urlforformName}
              shareUrl={urlforformid}
            />



            <div className="copy-link-container">
              <div className="copy-header"></div>
              <div className="copy-link">
                <div className="copy-link-inner">
                  <form onSubmit={handleCopy} className="d-flex gap-2">
                    <input
                      type="text"
                      value={urlforformid || ""}
                      ref={inputRef}
                      className="w-100 overflow-hidden"
                      onClick={() => inputRef.current.select()}
                      readOnly
                    />
                    <input
                      type="submit"
                      value="Copy"
                      style={{ fontSize: "14px" }}
                    />
                  </form>
                </div>
              </div>
            </div>

          </Modal.Body>


        </Modal>


      }
      {popupRegistration &&
        (
          <>
            <Modal
              show={popupRegistration}
              onHide={handleClose_registrations}
              centered
              dialogClassName="custom-modal-width" 
            >
              <Modal.Header
                closeButton
                className="m-0 d-flex align-items-center text-center border-light"
                style={{ padding: "10px 10px" }}
              >
                <h5 className="text-nowrap text-center m-auto">List of Registrations</h5>
              </Modal.Header>
              <Modal.Body
                className="d-flex flex-row gap-3 justify-content-center"
                style={{ padding: "20px 12px" }}
              >
                <ListForms data={Rowdata} />
              </Modal.Body>
            </Modal>

          </>
        )}
    </div>
  );
};

