import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useLayoutEffect,
} from "react";
import "./createSeatingLayout.css";
import { useFormik } from "formik";
import { forms_venueDetails } from "../../../Schemas/Forms/LoginDashboard";
import {
  generateRowLabels,
  capitalizeFirstLetter,
} from "../../../utils/commonUtils";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactDOMServer from "react-dom/server";
import SeatingLayoutForms from "./SeatingLayoutForms";
import { FaCouch } from "react-icons/fa6";
import { validation_venueDetails } from "../../../Schemas/Events";
import { createVenue } from "../../../Api/api";
import { makePostRequest } from "../../../Api/requestMethods";
import { GlobalContext } from "../../../global/provider";
import {
  SUCCESS,
  ERROR,
  DURATION,
} from "../../../global/ToastMsgConstants.tsx";
import notify from "devextreme/ui/notify";
import { SOMETHING_WENT_WRONG } from "../../../Schemas/errorMessageConstants";
import { NumberBox, SelectBox, TextBox, TagBox } from "devextreme-react";
import {
  seatingType,
  labelType,
  choiceGST,
  seatNumberingFormatAlphabet,
  screenSvg,
  screenSvgMini,
  statusBlocked,
  statusBooked,
  statusSelected,
  numberedSeatingExpire,
  HcType,
} from "../../../global/constants.js";
import DropDownBox from "devextreme-react/drop-down-box";
import DataGrid, { Selection, Column } from "devextreme-react/data-grid";
import ColorBox, { ColorBoxTypes } from "devextreme-react/color-box";
import { v4 as uuidv4 } from "uuid";
import { makeGetRequest } from "../../../Api/requestMethods";
import { getGstConfig } from "../../../Api/api";
import { useQuery } from "@tanstack/react-query";
import LayoutMiniMap from "./LayoutMiniMap.js";
import DynamicSeatSelection from "./DynamicTagBoxLabel.js";

const CreateSeatingLayouts = ({
  propsDataSource,
  setDataSourceEvents,
  isticketpurchase,
}) => {
  const seatingLayoutRef = useRef(null);
  const scrollTimeout = useRef(null);
  const [activeKey, setActiveKey] = useState(null);

  const [scrollPosition, setScrollPosition] = useState({
    top: 0,
    left: 0,
    clientWidth: 0,
    clientHeight: 0,
    scrollWidth: 0,
    scrollHeight: 0,
  });

  const { userDetails, eventsDatasource, setEventDataSource } =
    useContext(GlobalContext);
  const [formData, setFormData] = useState({
    seatingType: "",
    labelType: "",
    amount: "",
    columnDetails: [],
    currentSelectedType: "",
    colorCode: "",
  });
  const [dataSource, setDataSource] = useState({
    rowData: "",
    columnData: "",
    floorDetails: [],
    seatColorings: [],
    rowLables: "",
    aisleColumns: "",
    position: "",
    layout: {},
    stateDataSource: "",
    cityDataSource: "",
    seatingTypeDataSource: "",
    seatingLableDataSource: "",
    seatingColumnDetailsDs: "",
    excludedRowLables: [],
  });

  const [settings, setSettings] = useState({
    trigger: 0,
    countryFocusIn: false,
    choosedSeatingValue: "",
    isScrolling: false,
    isVenuDetailsOpened: "1",
  });

  const scrollEvent = () => {
    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current);
    }
    setSettings((prev) => ({ ...prev, isScrolling: true }));
    scrollTimeout.current = setTimeout(() => {
      setSettings((prev) => ({ ...prev, isScrolling: false }));
    }, 550);
  };

  useEffect(() => {
    if (propsDataSource && !isticketpurchase) {
      const newSeatingType = Object.keys(propsDataSource).map(
        (data, index) => ({
          id: index + 1,
          seatingType: data,
        })
      );
      setDataSource((prev) => ({
        ...prev,
        layout: propsDataSource,
        seatingTypeDataSource: newSeatingType,
      }));
    }

    if (propsDataSource && isticketpurchase) {
      setDataSource((prev) => ({
        ...prev,
        layout: propsDataSource,
      }));
    }
  }, [propsDataSource]);

  const handleInputChange = (name, value) => {
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        currentSelectedType: name,
        [name]: value,
      };
      return updatedFormData;
    });
  };
  useEffect(() => {
    if (
      formData.seatingType !== "" &&
      formData.currentSelectedType === seatingType
    ) {
      const selectedSeatingType =
        dataSource && dataSource.layout[formData.seatingType];

      const newLabelType = Object.entries(selectedSeatingType)
        .filter(([_, seats]) =>
          seats.some((seat) => seat.seat !== null && seat.seat !== "")
        )
        .map(([label], index) => ({
          id: index + 1,
          seatingLabel: label,
        }));

      setDataSource((prev) => ({
        ...prev,
        seatingLableDataSource: newLabelType,
        seatingColumnDetailsDs: "",
      }));
    }

    if (
      formData.labelType !== "" &&
      formData.currentSelectedType === labelType
    ) {
      const selectedLabelType =
        dataSource &&
        dataSource.layout[formData.seatingType][formData.labelType];
      const newColumnDetails =
        selectedLabelType &&
        selectedLabelType.map((data, index) => ({
          id: index + 1,
          columnDetails: `${formData.labelType}-${data.seat}`,
          columnDetailsValue: `${data.seat}`,
          amount: data.amount,
          colorCode: data.colorCode,
        }));
      setDataSource((prev) => ({
        ...prev,
        seatingColumnDetailsDs: newColumnDetails,
      }));
    }
  }, [
    formData.seatingType,
    formData.labelType,
    formData.currentSelectedType,
    formData.colorCode,
  ]);

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    isValid,
    setSubmitting,
    isSubmitting,
    resetForm,
  } = useFormik({
    initialValues: forms_venueDetails,
    validationSchema: validation_venueDetails,
    onSubmit: async (values, { setSubmitting }) => {
      if (isValid) {
        await submitLayout();
      } else {
        handleErrorAction();
      }
      setSubmitting(false);
    },
  });
  const initializeLayoutNumbers = (floorDetails) => {
    try {
      const layout = {};
      floorDetails.forEach((floor) => {
        const {
          floorName,
          rowName,
          aisleRows,
          numberOfColumns,
          SeatNumberingFormat,
        } = floor;

        if (SeatNumberingFormat === "alphabetic") return;
        const rowLabels = rowName.split(",").map((label) => label.trim());
        const aisleRowsArray = aisleRows
          ? aisleRows.split(",").map((aisleRow) => parseInt(aisleRow.trim()))
          : [];
        layout[floorName] = {};
        let rowCount = 1;
        rowLabels.forEach((label, index) => {
          const rowArray = Array.from(
            { length: numberOfColumns },
            (_, colIndex) => ({
              seat: `${colIndex + 1}`,
              row_label: `${label}`,
              isAisle: false,
              status: "",
              amount: null,
              isFullyAisle: false,
              isAlphabetic: false,
              colorCode: "",
              gst_tax: 0,
              hc_charges: 0,
              hc_gst_tax: 0,
              hc_type: "amount",
              ticket_quantity: 1,
              seat_label: "",
              floorName: floorName,
              unique_id: uuidv4(),
            })
          );

          layout[floorName][rowCount++] = rowArray;

          aisleRowsArray.forEach((aisleRow) => {
            if (rowCount - 1 === aisleRow) {
              const aisleRowArray = Array.from(
                { length: numberOfColumns },
                () => ({
                  seat: null,
                  label: null, // No label for aisles
                  isAisle: true,
                  status: "",
                  amount: null,
                  isFullyAisle: true,
                  isAlphabetic: false,
                  colorCode: "",
                  gst_tax: 0,
                  hc_charges: 0,
                  hc_gst_tax: 0,
                  hc_type: "amount",
                  ticket_quantity: 1,
                  seat_label: "",
                  unique_id: uuidv4(),
                })
              );
              layout[floorName][rowCount++] = aisleRowArray;
            }
          });
        });
      });

      return layout;
    } catch (error) {
      console.error("Error in initializeLayout:", error);
      return null;
    }
  };

  const initializeLayoutAlphabets = (floorDetails) => {
    try {
      const layout = {};
      floorDetails.forEach((floor) => {
        const {
          floorName,
          rowName,
          aisleRows,
          numberOfColumns,
          SeatNumberingFormat,
        } = floor;

        if (SeatNumberingFormat !== "alphabetic") return;

        layout[floorName] = {};
        const rowLabels = [...rowName.split(",").map((name) => name.trim())];
        const aisleRowsArray = aisleRows
          ? aisleRows.split(",").map((row) => row.trim().toUpperCase())
          : [];

        rowLabels.forEach((label, rowIndex) => {
          const rowArray = Array.from(
            { length: numberOfColumns },
            (_, colIndex) => ({
              seat: `${colIndex + 1}`,
              label: `${label}${colIndex + 1}`, // Adding the label field similar to the numeric function
              row_label: `${label}`,
              isAisle: false,
              status: "",
              amount: null,
              isFullyAisle: false,
              isAlphabetic: true,
              colorCode: "",
              gst_tax: 0,
              hc_charges: 0,
              hc_gst_tax: 0,
              seat_label: "",
              hc_type: "amount",
              ticket_quantity: 1,
              unique_id: uuidv4(),
            })
          );

          layout[floorName][label] = rowArray;

          if (aisleRowsArray.includes(label.toUpperCase())) {
            const aisleRow = Array.from({ length: numberOfColumns }, () => ({
              seat: null,
              label: null, // No label for aisle rows
              isAisle: true,
              status: "",
              amount: null,
              isFullyAisle: true,
              isAlphabetic: true,
              colorCode: "",
              gst_tax: 0,
              hc_charges: 0,
              hc_gst_tax: 0,
              seat_label: "",
              hc_type: "",
              ticket_quantity: 1,
              unique_id: uuidv4(),
            }));

            layout[floorName][`Aisle ${label}`] = aisleRow;
          }
        });

        setDataSource((prev) => ({
          ...prev,
          excludedRowLables: aisleRowsArray.map((row) => `Aisle ${row}`),
        }));
      });

      return layout;
    } catch (error) {
      console.error("Error in initializeLayout:", error);
      return null;
    }
  };

  const initializeCombinedLayout = (floorDetails) => {
    try {
      const numericLayout = initializeLayoutNumbers(floorDetails);
      const alphabeticLayout = initializeLayoutAlphabets(floorDetails);
      const combinedLayout = { ...numericLayout, ...alphabeticLayout };

      return combinedLayout;
    } catch (error) {
      console.error("Error in initializeCombinedLayout:", error);
      return null;
    }
  };

  useEffect(() => {
    if (dataSource.floorDetails.length > 0) {
      const layout = initializeCombinedLayout(dataSource.floorDetails);
      setDataSource((prev) => ({ ...prev, layout }));
    }
  }, [dataSource.floorDetails, settings.trigger]);

  // FOR ODD EVEN MANUAL LABLE AND STATUS UPDATE
  // const onLableChange = (
  //   floorName,
  //   rowLabel,
  //   colIndex,
  //   seatDetails,
  //   seatLabel,
  //   isInputField
  // ) => {
  //   if (isInputField) {
  //     setDataSource((prevDataSource) => {
  //       const newLayout = { ...prevDataSource.layout };
  //       newLayout[floorName][rowLabel][colIndex].seat_label = seatLabel;
  //       return { ...prevDataSource, layout: newLayout };
  //     });
  //   } else {
  //     setDataSource((prevDataSource) => {
  //       const newLayout = { ...prevDataSource.layout };
  //       newLayout[floorName][rowLabel][colIndex].status = seatLabel;
  //       return { ...prevDataSource, layout: newLayout };
  //     });
  //   }
  // };

  const handleCheckboxChange = (floorName, rowLabel, colIndex, seatDetails) => {
    if (!propsDataSource && !isticketpurchase) {
      setDataSource((prevDataSource) => {
        const newLayout = { ...prevDataSource.layout };
        newLayout[floorName][rowLabel][colIndex].isAisle =
          !newLayout[floorName][rowLabel][colIndex].isAisle;

        return { ...prevDataSource, layout: newLayout };
      });
    }
    if (propsDataSource && isticketpurchase) {
      setDataSource((prevDataSource) => {
        const newLayout = { ...prevDataSource.layout };
        const currentStatus = newLayout[floorName][rowLabel][colIndex].status;
        if (currentStatus === "") {
          newLayout[floorName][rowLabel][colIndex].status = statusSelected;
        } else if (currentStatus === statusSelected) {
          newLayout[floorName][rowLabel][colIndex].status = "";
        }
        return { ...prevDataSource, layout: newLayout };
      });
      if (seatDetails?.amount !== null) {
        setEventDataSource((prev) => {
          const seatIndex = prev.numberedTicketAmountDetails.findIndex(
            (item) => item.unique_id === seatDetails.unique_id
          );
          let updatedTicketDetails;
          let updatedAmount;
          if (seatIndex === -1) {
            updatedTicketDetails = [
              ...prev.numberedTicketAmountDetails,
              {
                floorName: floorName,
                rowLabel: rowLabel,
                ...seatDetails,
              },
            ];
            updatedAmount =
              (prev.numberedChoosedTicketAmount || 0) + seatDetails.amount;
          } else {
            updatedTicketDetails = prev.numberedTicketAmountDetails.filter(
              (item, index) => index !== seatIndex
            );
            updatedAmount =
              (prev.numberedChoosedTicketAmount || 0) - seatDetails.amount;
          }

          return {
            ...prev,
            numberedTicketAmountDetails: updatedTicketDetails,
            numberedChoosedTicketAmount: updatedAmount,
          };
        });
      }
      setDataSourceEvents(floorName, rowLabel, colIndex, seatDetails?.seat);
    }
  };

  const columnAisleChangeEvent = (floorName, rowLabel, selectedIndices, e) => {
    setDataSource((prevDataSource) => {
      const newLayout = { ...prevDataSource.layout };
      newLayout[floorName][rowLabel] = newLayout[floorName][rowLabel].map(
        (seat, index) => ({
          ...seat,
          isAisle: selectedIndices.includes(index),
        })
      );
      return { ...prevDataSource, layout: newLayout };
    });
  };

  const addDenominations = (e) => {
    e.preventDefault();
    try {
      const { seatingType, labelType, columnDetails, amount, colorCode } =
        formData;
      if (
        seatingType &&
        labelType &&
        columnDetails &&
        columnDetails?.length > 0 &&
        amount !== null
      ) {
        setDataSource((prev) => {
          const newLayout = { ...prev.layout };
          const updateArray = newLayout[seatingType][labelType];
          if (updateArray) {
            newLayout[seatingType][labelType] = updateArray.map((data) =>
              columnDetails.includes(data.seat.toString())
                ? { ...data, amount, colorCode }
                : data
            );
          }
          const newSeatingColumnDetailsDs = (
            prev.seatingColumnDetailsDs || []
          ).map((data) =>
            columnDetails.includes(data?.columnDetailsValue.toString())
              ? { ...data, amount, colorCode }
              : data
          );
          return {
            ...prev,
            layout: newLayout,
            seatingColumnDetailsDs: newSeatingColumnDetailsDs,
          };
        });

        setFormData((prev) => ({
          ...prev,
          columnDetails: "",
          amount: "",
          colorCode: "",
        }));
        setDataSourceEvents((prev) => ({
          ...prev,
          overAllSeatingLayoutDs: dataSource.layout,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitLayout = async () => {
    values.seating_layout = dataSource?.layout;
    values.role_id = userDetails?.profileDetails?.role_id;
    values.seating_details = dataSource?.floorDetails;
    try {
      setSubmitting(true);
      const response = await makePostRequest(createVenue, values);
      if (response && response.status === SUCCESS) {
        notify(response.message, SUCCESS, DURATION);
        resetForm();
        setDataSource((prev) => ({
          ...prev,
          rowData: "",
          columnData: "",
          floorDetails: [],
          seatColorings: [],
          rowLables: "",
          aisleColumns: "",
          position: "",
          layout: {},
          stateDataSource: "",
          cityDataSource: "",
          seatingTypeDataSource: "",
          seatingLableDataSource: "",
          seatingColumnDetailsDs: "",
          excludedRowLables: [],
        }));
      } else {
        notify(response.message || SOMETHING_WENT_WRONG, ERROR, DURATION);
      }
    } catch (error) {
      setSubmitting(false);
      throw error;
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit(e);
  };
  const handleErrorAction = () => {
    setActiveKey("0");
  };

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      setActiveKey("0");
    }
  }, [errors]);
  const onDataSaved = () => {
    setSettings((prev) => ({
      ...prev,
      trigger: prev.trigger + 1,
    }));
  };

  const handleDataGridSelection = ({ selectedRowKeys }) => {
    handleInputChange("columnDetails", selectedRowKeys);
  };

  // GST config
  const gstConfigEvent = async () => await makeGetRequest(getGstConfig);
  const { data: gstConfig } = useQuery({
    queryKey: ["__gstConfig__"],
    queryFn: () => gstConfigEvent(),
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  useEffect(() => {
    try {
      if (
        eventsDatasource &&
        eventsDatasource?.numberedEventTempBookedDetails &&
        eventsDatasource?.numberedEventTempBookedDetails.length > 0
      ) {
        eventsDatasource?.numberedEventTempBookedDetails.map((data) => {
          const currentTime = new Date();
          const seatTimer = new Date(data.timer);
          const timeDifference = (currentTime - seatTimer) / 1000 / 60;
          if (timeDifference <= numberedSeatingExpire) {
            setDataSource((prevDataSource) => {
              const newLayout = { ...prevDataSource.layout };
              if (
                newLayout[data.floor_name] &&
                newLayout[data.floor_name][data.row_name]
              ) {
                const seatToUpdate = newLayout[data.floor_name][
                  data.row_name
                ].find((filterData) => filterData.seat === data.seat_number);

                if (seatToUpdate) {
                  seatToUpdate.status = statusBooked;
                }
              } else {
                console.error(
                  `Floor name ${data.floor_name} or row name ${data.row_name} not found in layout.`
                );
              }
              return { ...prevDataSource, layout: newLayout };
            });
          }
        });
      }
    } catch (error) {
      console.log(error, "Error occurred while updating seat status.");
    }
  }, [eventsDatasource.numberedEventTempBookedDetails]);

  useEffect(() => {
    setEventDataSource((prev) => ({
      ...prev,
      numberedTicketAmountDetails: [],
      numberedChoosedTicketAmount: null,
    }));
  }, []);

  const colorCodeCellRender = (cellData) => {
    const colorCode = cellData.data.colorCode || "";
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: 20,
            height: 20,
            backgroundColor: colorCode || "",
            border: `${colorCode ? "1px solid #ddd" : ""}`,
            marginRight: 8,
            borderRadius: "50%",
          }}
        ></div>
        <span>{colorCode || ""}</span>
      </div>
    );
  };
  const dataGridRender = () => {
    return (
      <DataGrid
        dataSource={dataSource.seatingColumnDetailsDs || []}
        keyExpr="columnDetailsValue"
        selectedRowKeys={formData.columnDetails}
        onSelectionChanged={handleDataGridSelection}
        width={"100%"}
      >
        <Selection mode="multiple" />
        <Column dataField="columnDetails" caption="Column Details" />
        <Column dataField="amount" caption="Amount" />
        <Column
          dataField="colorCode"
          caption="Color"
          cellRender={colorCodeCellRender}
        />
      </DataGrid>
    );
  };

  const gstData = (gstConfig && gstConfig.data && gstConfig?.data[0]) || null;

  const handleScroll = () => {
    if (seatingLayoutRef.current) {
      requestAnimationFrame(() => {
        const {
          scrollTop,
          scrollLeft,
          clientWidth,
          clientHeight,
          scrollWidth,
          scrollHeight,
        } = seatingLayoutRef.current;
        setScrollPosition({
          top: scrollTop,
          left: scrollLeft,
          clientWidth,
          clientHeight,
          scrollWidth,
          scrollHeight,
        });
      });
    }
  };

  const handleResize = () => {
    if (seatingLayoutRef.current) {
      handleScroll();
    }
  };

  useLayoutEffect(() => {
    const layoutElement = seatingLayoutRef.current;
    if (layoutElement) {
      layoutElement.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);
      handleScroll();
    }
    return () => {
      if (layoutElement) {
        layoutElement.removeEventListener("scroll", handleScroll);
      }
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const minimapBox = {
    x:
      (scrollPosition.left / scrollPosition.scrollWidth) *
      eventsDatasource.svgWidth,
    y:
      (scrollPosition.top / scrollPosition.scrollHeight) *
      eventsDatasource.svgHeight,
    width:
      (scrollPosition.clientWidth / scrollPosition.scrollWidth) *
      eventsDatasource.svgWidth,
    height:
      (scrollPosition.clientHeight / scrollPosition.scrollHeight) *
      eventsDatasource.svgHeight,
  };

  const seatingLayout = () => {
    const hasAisle = (row) => {
      const data = row.some((seat) => seat.isAisle === false);
      return data;
    };
    return (
      <div>
        {propsDataSource && !isticketpurchase && (
          <div className="row">
            <div className="col-lg-3">
              <SelectBox
                label="Seating Type"
                labelMode="floating"
                dataSource={dataSource.seatingTypeDataSource || []}
                displayExpr="seatingType"
                valueExpr="seatingType"
                onValueChanged={(e) =>
                  handleInputChange("seatingType", e.value)
                }
                value={formData.seatingType}
              />
            </div>

            <div className="col-lg-3">
              <SelectBox
                label="Label"
                labelMode="floating"
                displayExpr="seatingLabel"
                valueExpr="seatingLabel"
                dataSource={dataSource.seatingLableDataSource || []}
                onValueChanged={(e) => handleInputChange("labelType", e.value)}
                value={formData.labelType}
              />
            </div>

            <div className="col-lg-6 mt-2">
              <DropDownBox
                value={formData.columnDetails}
                deferRendering={false}
                displayExpr="columnDetails"
                valueExpr="columnDetailsValue"
                showClearButton={true}
                placeholder="select column Details"
                dataSource={dataSource.seatingColumnDetailsDs || []}
                onValueChanged={(e) =>
                  handleInputChange("columnDetails", e.value)
                }
                contentRender={dataGridRender}
                width={"auto"}
              />
            </div>

            <div className="col-lg-4">
              <NumberBox
                label="Amount"
                labelMode="floating"
                value={formData.amount}
                onValueChanged={(e) => handleInputChange("amount", e.value)}
              />
            </div>

            <div className="col-lg-4">
              <SelectBox
                label="GST tax"
                labelMode="floating"
                displayExpr="text"
                valueExpr="id"
                dataSource={
                  [
                    { id: 0, text: "0%" },
                    {
                      id: gstData?.gst_max || choiceGST,
                      text: `${gstData?.gst_max || choiceGST}%`,
                    },
                  ] || []
                }
                onValueChanged={(e) => handleInputChange("gst_tax", e.value)}
                value={formData.gst_tax}
              />
            </div>

            <div className="col-lg-4">
              <SelectBox
                label="HC Type"
                labelMode="floating"
                displayExpr="type"
                valueExpr="value"
                dataSource={HcType || []}
                onValueChanged={(e) => handleInputChange("hc_type", e.value)}
                value={formData.hc_type}
              />
            </div>

            <div className="col-lg-4">
              <NumberBox
                label="HC Value (Enter % / amount)"
                labelMode="floating"
                value={formData.hc_charges}
                min={0}
                max={formData.hc_type === "percentage" ? 100 : null}
                onValueChanged={(e) => handleInputChange("hc_charges", e.value)}
              />
            </div>

            <div className="col-lg-4">
              <NumberBox
                label="Handling charge Gst (Enter % )"
                labelMode="floating"
                value={formData.hc_gst_tax}
                min={0}
                max={100}
                onValueChanged={(e) => handleInputChange("hc_gst_tax", e.value)}
              />
            </div>

            <div className="col-lg-4  mt-2">
              <ColorBox
                value={formData.colorCode || "#3f00ff"}
                defaultValue="#3f00ff"
                onValueChanged={(e) => handleInputChange("colorCode", e.value)}
              />
            </div>
            <div>
              <button
                className="button mt-2"
                type="button"
                onClick={(e) => addDenominations(e)}
              >
                Add
              </button>
            </div>
          </div>
        )}

        <div>
          {<div className="screenSvg">{screenSvg}</div>}
          <div
            id="seating-layout"
            className="seatingLayoutScroll w-100"
            ref={seatingLayoutRef}
            onScroll={scrollEvent}
          >
            {settings && settings?.isScrolling && isticketpurchase && (
              <div
                style={{
                  position: "fixed",
                  top: "160px",
                  right: "20px",
                  zIndex: 999,
                  width: "80%",
                }}
              >
                <LayoutMiniMap
                  dataSource={dataSource}
                  minimapBox={minimapBox}
                />
              </div>
            )}

            <div className="seating-layout-scrollable m-auto">
              {Object.keys(dataSource.layout).map((floorName, index) => (
                <div className="overflowScrollBar" key={index}>
                  <p className="SeatingFloorName">
                    {capitalizeFirstLetter(floorName)}

                    {index === 0 ? (
                      <>
                        <span className="text-dark ms-3 amountDetailsStyle">
                          A to K - ₹ 2000
                        </span>
                        {""}
                        <span className="text-dark ms-3 amountDetailsStyle">
                          L to Q - ₹ 1000
                        </span>
                      </>
                    ) : (
                      <span className="text-dark ms-3 amountDetailsStyle">
                        {" "}
                        R to ZZ - ₹450
                      </span>
                    )}
                  </p>
                  <table className="mx-auto">
                    {Object.keys(dataSource.layout[floorName]).map(
                      (rowLabel, rowIndex) => {
                        const row = dataSource.layout[floorName][rowLabel];
                        const aislePresent = hasAisle(row);
                        let seatNumber = 1;
                        return (
                          <tr key={rowIndex}>
                            {!propsDataSource &&
                              (aislePresent ? (
                                <td className="pe-4 rowlabelsColor">
                                  <DynamicSeatSelection
                                    row={row}
                                    floorName={floorName}
                                    rowLabel={rowLabel}
                                    onSelectionChange={columnAisleChangeEvent}
                                  />
                                </td>
                              ) : null)}
                            {/* Left-side Row Label */}
                            <td>
                              {row.map((seat, columnIndex) => (
                                <React.Fragment key={columnIndex}>
                                  {columnIndex === 0 && (
                                    <span
                                      style={{ color: "#3f00ff" }}
                                      className="row-label"
                                    >
                                      {seat.row_label}
                                    </span>
                                  )}
                                </React.Fragment>
                              ))}
                            </td>
                            {/* Seating Columns */}

                            {row
                              .slice() // Create a shallow copy of the row array to avoid mutating the original array
                              .reverse() // Reverse the order of seats in the row
                              .map((seat, colIndex) => {
                                const originalColIndex =
                                  row.length - 1 - colIndex; // Calculate the original index

                                return (
                                  <td key={colIndex} className="seatingColumn ">
                                    <div
                                      className={`seatingBoxOutside d-flex justify-content-start ${
                                        seat.isAisle
                                          ? propsDataSource
                                            ? "addSeatColoringWithCondition"
                                            : "addSeatColoring"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        if (
                                          seat.status !== statusBooked &&
                                          seat.status !== statusBlocked &&
                                          propsDataSource !== undefined
                                        ) {
                                          // Pass the original index to handleCheckboxChange
                                          handleCheckboxChange(
                                            floorName,
                                            rowLabel,
                                            originalColIndex,
                                            seat
                                          );
                                        }
                                      }}
                                      style={{
                                        visibility: seat.isFullyAisle
                                          ? "hidden"
                                          : "",
                                        marginBottom:
                                          rowIndex === 4 || rowIndex === 12
                                            ? "20px"
                                            : "0px",
                                      }}
                                    >
                                      <div
                                        className={`${
                                          seat.colorCode
                                            ? `1px solid ${seat.colorCode}`
                                            : "seatingBoxInside"
                                        }`}
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                          border: `${
                                            seat.status === statusBooked
                                              ? ""
                                              : seat.colorCode
                                              ? `1px solid ${seat.colorCode}`
                                              : seat.status === statusSelected
                                              ? "1px solid #0000FF" // Border for selected seats
                                              : seat.status === ""
                                              ? "1px solid #3f00ff"
                                              : ""
                                          }`,
                                          cursor:
                                            seat.status === statusBooked ||
                                            seat.status === statusBlocked
                                              ? "not-allowed"
                                              : "pointer",
                                          fontSize: "10px",
                                          verticalAlign: "top",
                                          display: "inline-block",
                                          textAlign: "center",
                                          lineHeight: "25px",
                                          borderRadius: "2px",
                                          backgroundColor:
                                            seat.status === statusBlocked
                                              ? "rgb(0 0 0)"
                                              : seat.status === statusSelected
                                              ? "rgb(63, 0, 255)" // Blue background for selected seats
                                              : seat.status === ""
                                              ? ""
                                              : "#dddddd",
                                          color:
                                            seat.status === statusBlocked
                                              ? "#ffffff"
                                              : seat.status === statusSelected
                                              ? "#ffffff" // White text color for selected seats
                                              : seat.status === ""
                                              ? ""
                                              : "#262626",
                                        }}
                                      >
                                        {seat?.seat_label}
                                      </div>
                                    </div>
                                  </td>
                                );
                              })}
                          </tr>
                        );
                      }
                    )}
                  </table>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const downloadPDF = () => {
    const input = document.getElementById("seating-layout");
    html2canvas(input, {
      onclone: (clonedDoc) => {
        const checkboxes = clonedDoc.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
          const parentTd = checkbox.parentNode;
          if (!checkbox.checked) {
            const icon = document.createElement("div");
            icon.innerHTML = ReactDOMServer.renderToString(
              <FaCouch style={{ fontSize: "16px", color: "black" }} />
            );
            parentTd.replaceChild(icon, checkbox);
          } else {
            parentTd.removeChild(checkbox);
          }
        });
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();

      // Get the current date and time
      const currentDate = new Date();
      const formattedDate = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;

      // Add header
      pdf.setFontSize(20);
      pdf.text(
        "MDnD Seating Layout",
        pdf.internal.pageSize.getWidth() / 2,
        20,
        {
          align: "center",
        }
      );

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 30, pdfWidth, pdfHeight);

      pdf.setFontSize(10);
      pdf.text(
        `Generated on: ${formattedDate}`,
        pdf.internal.pageSize.getWidth() / 2,
        pdf.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );

      pdf.save("MDnD_seating_layout.pdf");
    });
  };

  const shouldRenderScreen =
    (propsDataSource && !isticketpurchase) ||
    (dataSource.floorDetails && dataSource.floorDetails.length > 0);

  return (
    <React.Fragment>
      <>
        <div
          className={`content-block ${
            !propsDataSource && !isticketpurchase ? "" : "condtionScreenSeating"
          }`}
        >
          {!propsDataSource && !isticketpurchase && (
            <SeatingLayoutForms
              settings={settings}
              dataSource={dataSource}
              setSettings={setSettings}
              setFieldValue={setFieldValue}
              values={values}
              setDataSource={setDataSource}
              submitLayout={submitLayout}
              handleBlur={handleBlur}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              onDataSaved={onDataSaved}
              activeKey={activeKey}
              setActiveKey={setActiveKey}
            />
          )}
        </div>
        {seatingLayout()}

        {!propsDataSource &&
          !isticketpurchase &&
          dataSource.floorDetails &&
          dataSource.floorDetails.length > 0 && (
            <form onSubmit={handleFormSubmit}>
              <div className="d-flex justify-content-end">
                {/* <button
                className="btn btn-secondary rounded py-2 px-4 mt-4 me-4"
                onClick={downloadPDF}
              >
                Download
              </button> */}
                <button
                  className="button"
                  onClick={handleFormSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          )}
      </>
    </React.Fragment>
  );
};

export default CreateSeatingLayouts;
