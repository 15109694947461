import "./PlayerCostom.css";
import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { GET_ADS_BEFORE_VIDEO, GET_WATCHED_ID } from "../Api/api";
import { makeGetRequest, makePutRequest } from "../Api/requestMethods";
import { useMutation, useQuery } from "@tanstack/react-query";

const HLSVideoPlayer = ({ videoUrl, categoryId, videoId }) => {
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(true);
  const [volume, setVolume] = useState(1.0);
  const [playbackRate, setPlaybackRate] = useState(1.0);
  const [currentUrl, setCurrentUrl] = useState(videoUrl);
  const [isAdPlaying, setIsAdPlaying] = useState(false);
  const [adsId, setAdsId] = useState(null);
  const [controlsVisible, setControlsVisible] = useState(false);
  const [qualityVisible, setQualityVisible] = useState(false);
  const [iconVisible,setIconVisible] = useState(false);
  const [qualityOptions, setQualityOptions] = useState([]);

  // Function to rewind video by 10 seconds
  const rewind = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(Math.max(currentTime - 10, 0)); // Rewind 10 seconds
    }
  };

  // Function to forward video by 10 seconds
  const forward = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(currentTime + 10); // Forward 10 seconds
    }
  };

  // Handle ad end event
  const handleAdEnd = () => {
    setCurrentUrl(videoUrl);
    setIsAdPlaying(false);
    if (playerRef.current) {
      playerRef.current.seekTo(0); // Start main video from the beginning
    }
    setPlaying(true); // Start playing the main video
  };

  // Fetch ad video data
  const fetchAdsVideo = async (categoryId) => {
    const endPoint = `${GET_ADS_BEFORE_VIDEO()}/${categoryId}`;
    const response = await makeGetRequest(endPoint);
    console.log(response,"RESPONSEOFADS")
    return response?.data;
  };

  // Use React Query to fetch ads data
  const { data: adsVideo, refetch } = useQuery({
    queryKey: ["Ads", categoryId, videoId],
    queryFn: () => fetchAdsVideo(categoryId),
    onError: (error) => {
      console.error(`Error fetching ads for categoryId: ${categoryId}`, error);
    },
  });

  // Update watched video ID
  const TakingVideoAdsId = async (id) => {
    try {
      const endPoint = `${GET_WATCHED_ID()}/${id}`;
      const response = await makePutRequest(endPoint);
      return response;
    } catch (error) {
      throw error;
    }
  };

  // Mutation for updating watched video ID
  const takingVideoIdMutation = useMutation({
    mutationKey: ["getIDvideo"],
    mutationFn: (id) => TakingVideoAdsId(id),
  });

  useEffect(() => {
    refetch(); // Refetch ads when categoryId or videoId changes
  }, [categoryId, videoId, refetch]);

  useEffect(() => {
    if (adsVideo) {
      if (adsVideo.add_url) {
        setCurrentUrl(adsVideo.add_url);
        setAdsId(adsVideo.id);
        setIsAdPlaying(true);
        setPlaying(true); // Start playing the ad video
      } else {
        setCurrentUrl(videoUrl);
        setIsAdPlaying(false);
        setPlaying(true); // No ad, start playing the main video
      }
    }
  }, [adsVideo, videoUrl]);

  useEffect(() => {
    if (!isAdPlaying && playerRef.current) {
      playerRef.current.seekTo(0); // Ensure the main video starts from the beginning
    }
  }, [isAdPlaying, currentUrl]);

  useEffect(() => {
    if (adsId) {
      takingVideoIdMutation.mutateAsync(adsId);
    }
  }, [adsId]);

  // Fetch and parse quality options from manifest file
  const fetchQualityOptions = async (manifestUrl) => {
    try {
      const response = await fetch(manifestUrl);
      const manifestText = await response.text();
      const lines = manifestText.split("\n");
      const options = [];

      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        if (line.startsWith("#EXT-X-STREAM-INF")) {
          const resolutionMatch = line.match(/RESOLUTION=\d+x(\d+)/);
          const resolution = resolutionMatch ? resolutionMatch[1] : "Unknown";
          const streamUrl = lines[i + 1];
          options.push({
            resolution: resolution,
            url: new URL(streamUrl, manifestUrl).href,
          });
        }
      }

      setQualityOptions(options);
    } catch (error) {
      console.error("Error fetching or parsing the manifest file:", error);
    }
  };

  useEffect(() => {
    if (videoUrl) {
      fetchQualityOptions(videoUrl);
    }
  }, [videoUrl]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.seekTo(0); // Start from the beginning
    }
  }, [currentUrl]);

  // Handle quality change
  const changeQuality = (url) => {
    setCurrentUrl(url);
    setPlaying(true); // Resume playing the video after changing quality
  };

  return (
    <div
    className="player-wrapper"
    onMouseEnter={() => {
      setControlsVisible(true);
      // setQualityVisible(true);
      setIconVisible(true)
    }}
    onMouseLeave={() => {
      setControlsVisible(false);
      setQualityVisible(false);
      setIconVisible(false);
    }}
  >
      <ReactPlayer
        ref={playerRef}
        className="react-player"
        url={currentUrl}
        playing={playing}
        controls={true}
        volume={volume}
        playbackRate={playbackRate}
        width="100%"
        height="100%"
        onEnded={isAdPlaying ? handleAdEnd : null}
        config={{
          file: {
            attributes: {
              controlsList: isAdPlaying ? "nodownload noremoteplayback" : "",
              disablePictureInPicture: true,
            },
          },
        }}
      />
      <div
        className={`play-pause ${controlsVisible ? "visible" : ""}`}
        onClick={() => setPlaying(!playing)}
      >
        <i
          className="fa fa-step-backward"
          aria-hidden="true"
          onClick={rewind}
        />
        <i
          className={`fa ${playing ? "fa-pause-circle" : "fa-play-circle"}`}
          aria-hidden="true"
        />
        <i
          className="fa fa-step-forward"
          aria-hidden="true"
          onClick={forward}
        />
      </div>

      <div
        className="quality-settings"
        onClick={() => setQualityVisible(!qualityVisible)}
      >
        {iconVisible &&(
          <i className={`fa fa-cog ${qualityVisible ? "visible" : ""}`} onClick={() => setQualityVisible(!qualityVisible)}  aria-hidden="true" />
        )}
         {qualityVisible &&(
          <div className="quality-options">
            {qualityOptions.map((option) => (
              <div key={option.url} onClick={() => changeQuality(option.url)}>
                {option.resolution}p
              </div>
            ))}
          </div> 
         )}       
      </div>

    </div>
  );
};

export default HLSVideoPlayer;
