import React from "react";
import { BASEPATH } from "../../config/config";
import { GET_FOLLOWING } from "../../Api/api";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import {  ArtistallImg, Fan_images, ORGANISER_URL } from "../MainPage/constant";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../global/NoDataFound";
import Noimage_circle from "../../global/NoImage_circle";
import { isValidImage, imageExists  } from "../../global/ImageCheck";

export default function Following_organiser(props) {

  let { roleid } = props;
  const navigate = useNavigate();
  const token = localStorage.getItem("MDnD_auth");

  const [followCollection, setFollowCollection] = useState({
    following: "",

  })
  useEffect(() => {
    if (roleid) { mutationfetchFollowingList.mutate(); }
    else {
      return;
    }
  }, [])

  const Getting_Following = () =>
    GET_FOLLOWING_ALL(GET_FOLLOWING);

  const GET_FOLLOWING_ALL = async (
    GET_FOLLOWING) => {

    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_FOLLOWING}`,
        data: {
          role_id: roleid ? roleid : ""

        },

        headers: {
          'MDnD_auth': `${token}`,

        },

        timeout: 60000,
        responseType: 'json',

      });

      return response.data;
    }
    catch (error) {
      console.error(error);
    }
  };

  const mutationfetchFollowingList = useMutation({
    mutationKey: ["get_All_following"],
    mutationFn: (fid) => Getting_Following(),

    onSuccess: (data) => {
      //   queryClient.invalidateQueries({
      //     queryKey: ["fetchUser"],
      //   });

      let filteredData = data?.data && data?.data.filter((item) => item.role_name !== null && item.role_name !== "");

      console.log(filteredData,"filteredData");

      const uniqueData = filteredData && filteredData?.reduce((acc, current) => {
        const duplicate = acc.find(item => item.following_id === current.following_id);
      
        if (!duplicate) {
          acc.push(current);
        }
      
        return acc;
      }, []);

    console.log(uniqueData,"uniqueData");


      if (data.Status === "success") {

        if(uniqueData)
        {setFollowCollection((prevState) => ({ ...prevState, following: uniqueData })); }
      }
      else {
        setFollowCollection((prevState) => ({ ...prevState, following: uniqueData ? uniqueData : "" }));
      }
    },
    onError: (error) => {


      setFollowCollection((prevState) => ({ ...prevState, following: "" }));
    },
  });

  const handleFollowingNavigate = (item) => {
    console.log(item, "item");
    if (item.role_name === "artist") {
      navigate(`/artist/${item.profilename}`, {
        state: { follower_id: item.following_id },
      });
    }
    else if (item.role_name === "organiser") {
      navigate(`/organiser/${item.profilename}`, { state: { follower_id: item.following_id } });
    }
    else {
      return;
    }
  }

  const [imageError, setImageError] = useState(false);

       const handleImageError = () => {
    setImageError(true);
  };


  return (
    <>
      <div>
      
<div className="row">
        {followCollection.following && followCollection.following.length > 0 ? (
        
        followCollection.following.map((item, index) => (
          <>
          <div 
          className="col-lg-6 col-xl-4 col-xxl-3 d-flex align-items-center gap-1 gap-lg-0 my-1 mb-3 pointer"
          key={index}
          onClick={()=>handleFollowingNavigate(item)}
          >
             {
  item?.role_name === "artist" ? (
    isValidImage(item?.photo) && imageExists(`${ArtistallImg}/${item?.photo}`) ? (
      <img
        src={`${ArtistallImg}/${item?.photo}`}
        onClick={()=>handleFollowingNavigate(item)}
        //onError={handleImageError}
        className="commentImg rounded-circle me-2"
        alt="Artist"
      />
    ) : (
      <Noimage_circle />
    )
  ) : item?.role_name === "organiser" ? (
    isValidImage(item?.photo) && imageExists(`${ORGANISER_URL}/${item?.photo}`) ? (
      <>
      <img
        src={`${ORGANISER_URL}/${item?.photo}`}
        onClick={()=>handleFollowingNavigate(item)}
        //onError={handleImageError}
        className="commentImg rounded-circle me-2"
        alt="Organiser"
      />
    </>) : (
      <Noimage_circle />
    )
  ) : item?.role_name === "fan" ? (
    isValidImage(item?.photo) && imageExists(`${Fan_images}/${item?.photo}`) ? (
      <>
      <img
        src={`${Fan_images}/${item?.photo}`}  
        onClick={()=>handleFollowingNavigate(item)}
        //onError={handleImageError}
        className="commentImg rounded-circle me-2"
        alt="Fan"
      />
    </>) : (
      <Noimage_circle />
    )
  ) : (
    <Noimage_circle />
  )
}
<span 
       onClick={()=>handleFollowingNavigate(item)}> 
       {item?.name} ({item?.role_name})</span>
          </div>
          </>
        ))):(<>
         <div>You are not following anybody yet!</div>
         <NoDataFound/>
        </>)}
        </div>
      </div>
    </>
  )
}