import React from "react";

import staticImg from "../assets/images/genre/g1.webp";
import IMAGE1 from "../assets/images/genre/02.webp";
import "./GlobalSearch.css"
import { FaFilter } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import { useState, useEffect, useCallback, useContext, useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import HandleErrorToast from "../Toaster/Failure/Failure";
import { BASEPATH } from "../config/config";
import { useNavigate } from "react-router-dom";
import { SelectBox } from "devextreme-react";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import { ArtistallImg, IMAGE_URL, Fan_images, EventImg } from "../Subpages/MainPage/constant";
import { ARTIST_GLOBAL_SEARCH, ORGANISER_GLOBAL_SEARCH, RAGABANK_GLOBAL_SEARCH, RECORDS_GLOBAL_SEARCH, EVENTS_GLOBAL_SEARCH, SEASON_EVENTS_GLOBAL_SEARCH, COUNTRIES_FROM_BACKEND, STATES_FROM_BACKEND, CITIES_FROM_BACKEND, GET_THUMBNAIL_VIDEOS_IN_UPLOAD, } from "../Api/api";
//import NoDataFound from "./NoDataFound.jpg";
import { Modal } from "react-bootstrap";
import NoDataFound from "../global/NoDataFound";
import Noimage from "../global/NoImage";
import { isValidImage } from "../global/ImageCheck";
import { imageExists } from "../global/ImageCheck";
import { generateAlphabetCharacters } from "../utils/commonUtils";

import Noimage_circle from "../global/NoImage_circle";
import { ORGANISER_URL } from "../Subpages/MainPage/constant";

function GlobalSearch() {
  const location = useLocation();
  const { user, setUser, authTokens, setAuthTokens, logoutUser } = useContext(AuthContext);
  let { searchtext } = location?.state || {};

  console.log(searchtext, "search text");
  const token = localStorage.getItem("MDnD_auth");

  const [searchtextstate, setSearchTextState] = useState("");



  const [activeTab, setActiveTab] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [iteration, setIteration] = useState(0);
  const [iteration_ragabank, setIterationRagaBank] = useState(0);
  const limit = 5;

  const [imageError, setImageError] = useState(false);
  const handleImageError = () => setImageError(true);

  const handleTabClick = (tab) => {
    if (tab) {
      setActiveTab(tab);
      setCurrentOffset(0);
      setIteration(0);
      setIterationRagaBank(0);
      setsearchCollection((prevState) => ({ ...prevState, searchData: [] }));
      setHasMoreData(true);
      //setAddress((prevState) => ({ ...prevState, selectedCity: "", selectedState:"", selectedCountry:"" }));
      setThumbnail({});

      setIsFetching(false);

      setProfilephoto({});
      setThumbnailRagaBank({});
    }
  };

  console.log(activeTab, "activeTab");
  const [searchCollection, setsearchCollection] = useState({
    searchData: [],


  })
  const [thumbnail, setThumbnail] = useState({});
  const [thumbnailRagaBank, setThumbnailRagaBank] = useState({});

  const [profilephoto, setProfilephoto] = useState({});

  useEffect(() => {
    if (searchtext) {
      setSearchTextState(searchtext);
      setsearchCollection((prevState) => ({ ...prevState, searchData: [] }));

      setCurrentOffset(0);

      setIteration(0);
      setIterationRagaBank(0);
      setThumbnail({});
      setProfilephoto({});
      setThumbnailRagaBank({});
      setHasMoreData(true);
      setActiveTab("events");
    }
    else {
      setSearchTextState("");
    }

  }, [searchtext])

  const [address, setAddress] = useState({
    country: "",
    city: "",
    state: "",
    selectedCountry: "",
    selectedState: "",
    selectedCity: "",


    country_season: "",
    city_season: "",
    state_season: "",
    selectedCountry_season: "",
    selectedState_season: "",
    selectedCity_season: ""
  })

  const navigate = useNavigate();

  const encodeToBase64 = (obj) => {
    return btoa(JSON.stringify(obj));
  };

  const loadMore = useCallback(async () => {

    //console.log("loadmore enter");
    //console.log(hasMoreData, "hasMoreData inisde loadmore");
    let url;
    let params = {};
    let base64params;

    if (isFetching || !hasMoreData) { return; }

    setIsFetching(true);
    try {
      //const newRecords = await fetchVideos(limit, currentOffset);
      if (activeTab === "events") {

        //console.log("inside here");






        params.search_string = searchtextstate ? searchtextstate : "";
        params.limit = limit;
        params.offset = currentOffset;
        params.country_id = address.selectedCountry ? address.selectedCountry : "";
        params.state_id = address.selectedState ? address.selectedState : "";
        params.city_id = address.selectedCity ? address.selectedCity : "";


        //console.log(params,"params");

        if (params) {
          base64params = encodeToBase64(params);
          //console.log(params, "params");

        }

        if (base64params) {
          url = `${BASEPATH}${EVENTS_GLOBAL_SEARCH}/${base64params}`;

        }




      }
      else if (activeTab === "artists") {


        params.search_string = searchtextstate ? searchtextstate : "";
        params.limit = limit;
        params.offset = currentOffset;

        if (params != undefined) {
          base64params = encodeToBase64(params);

        }

        if (base64params != undefined) { url = `${BASEPATH}${ARTIST_GLOBAL_SEARCH}/${base64params}`; }

        //console.log(params,"params in artist");
      }
      else if (activeTab === 'organiser') {


        params.search_string = searchtextstate ? searchtextstate : "";
        params.limit = limit;
        params.offset = currentOffset;
        if (params) { base64params = encodeToBase64(params); }

        if (base64params) { url = `${BASEPATH}${ORGANISER_GLOBAL_SEARCH}/${base64params}`; }

      }

      else if (activeTab === 'mdndRecords') {


        params.search_string = searchtextstate ? searchtextstate : "";
        params.limit = limit;
        params.offset = currentOffset;

        if (params) { base64params = encodeToBase64(params); }

        if (base64params) { url = `${BASEPATH}${RECORDS_GLOBAL_SEARCH}/${base64params}`; }

      }
      else if (activeTab === 'ragaBank') {


        params.search_string = searchtextstate ? searchtextstate : "";
        params.limit = limit;
        params.offset = currentOffset;

        if (params) { base64params = encodeToBase64(params); }

        if (base64params) { url = `${BASEPATH}${RAGABANK_GLOBAL_SEARCH}/${base64params}`; }

      }

      else if (activeTab === 'seasonEvents') {


        params.search_string = searchtextstate ? searchtextstate : "";
        params.limit = limit;
        params.offset = currentOffset;
        //params.country_id = 101;
        params.country_id = address.selectedCountry_season ? address.selectedCountry_season : "";
        params.state_id = address.selectedState_season ? address.selectedState_season : "";
        params.city_id = address.selectedCity_season ? address.selectedCity_season : "";

        if (params && params != undefined) { base64params = encodeToBase64(params); }

        if (base64params) { url = `${BASEPATH}${SEASON_EVENTS_GLOBAL_SEARCH}/${base64params}`; }
      }

      else {
        return;
      }

      //let paramsBase64 = Buffer.from(JSON.stringify(params)).toString('base64');

      //console.log(paramsBase64,"paramsBase64");

      const response = await axios({
        method: "GET",
        url: url,
        headers: {
          'MDnD_auth': `${token}`,

        },

        timeout: 60000,
        responseType: 'json',


      })

      //console.log(response.data,"RD");
      if (response.data.status == "success") {
        //setsearchCollection((prevState) => ({ ...prevState, searchData: response.data.data }));


        

        
       

        // setsearchCollection((prevState) => ({
        //   ...prevState,
        //   searchData: currentOffset > 0 ? [...prevState.searchData, ...response.data.data] : response.data.data,
        // }));

        setsearchCollection((prevState) => {
          
          const searchDataArray = Array.isArray(prevState.searchData) ? prevState.searchData : [];
        
          
          const isSameData = JSON.stringify(searchDataArray) === JSON.stringify(response?.data.data);
        
          return {
            ...prevState,
            searchData:
              currentOffset === 0
                ? response.data.data
                : currentOffset > 0 && !isSameData
                ? [...searchDataArray, ...response.data.data]
                : searchDataArray,
          };
        });

     
        setHasMoreData(response?.data.data.length === limit);

        setIsFetching(false);

        if (activeTab === "mdndRecords") {
          setIteration(currentOffset);

        }

        else if (activeTab === "ragaBank") {
          setIterationRagaBank(currentOffset);
        }
        else {
          setIteration(0);
          setIterationRagaBank(0);
        }
      }

      else {
        //setsearchCollection((prevState) => ({ ...prevState, searchData: "" }));
        setsearchCollection((prevState) => ({ ...prevState, searchData: [] }));
        setHasMoreData(false);
        setIsFetching(true);
      }

      if (response?.data?.data?.length > 0) {
        setCurrentOffset((prevOffset) => prevOffset + limit);
        //setHasMoreData(false);

      }

      //setCurrentOffset((prevOffset) => prevOffset + limit);
    } catch (error) {
      setsearchCollection((prevState) => ({ ...prevState, searchData: [] }));

    }
    // finally {
    //   setIsFetching(false);
    // }
  }, [currentOffset, activeTab, isFetching, searchtextstate, address.selectedCity, address.selectedCity_season]);

  useEffect(() => {


    if (activeTab === "events" && address.selectedCity && address.selectedState && address.selectedCountry) {
      //console.log("check here");
      loadMore();

    }
    else if (activeTab === "artists") {
      loadMore();
    }
    else if (activeTab === 'organiser') {
      loadMore();
    }
    else if (activeTab === 'mdndRecords') {
      loadMore();
    }

    else if (activeTab === 'ragaBank') {
      loadMore();
    }
    else if (activeTab === 'seasonEvents' && address.selectedCity_season && address.selectedState_season && address.selectedCountry_season) {
      loadMore();
    }

    else {
      return;
    }



  }, [activeTab, address.selectedCity, searchtextstate, address.selectedCity_season]);

  //console.log(hasMoreData, "HasMoreData");

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop + 100 >= document.documentElement.scrollHeight && !isFetching &&
        hasMoreData) {
        loadMore();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isFetching, hasMoreData]);


  //console.log(searchCollection, "searchCollection");

  const handleArtist = (profileData) => {
    navigate(`/artist/${profileData.artist_name}?rid=${profileData.role_id}`, {
      state: { artistId: profileData.role_id }
    });

    
  }

  const handleOrganiser = (profileData) => {

    navigate(`/organiser/${profileData.organiser_name}?rid=${profileData.role_id}`, { state: { organiserId: profileData.role_id } });

  }

  useEffect(() => {
    mutationCountry.mutate();
  }, [])

  const GetCountries = () =>
    GET_COUNTRY(COUNTRIES_FROM_BACKEND);

  const GET_COUNTRY = async (
    COUNTRIES_FROM_BACKEND) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASEPATH}${COUNTRIES_FROM_BACKEND}`,
        headers: {
          'MDnD_auth': `${token}`,

        },

        timeout: 60000,
        responseType: 'json',

      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationCountry = useMutation({
    mutationKey: ["get_Country"],
    mutationFn: () => GetCountries(),

    onSuccess: (data) => {
      //   queryClient.invalidateQueries({
      //     queryKey: ["fetchUser"],
      //   });


      //console.log(data, "DATA");

      if (data.status === "success") {

        setAddress((prevState) => ({ ...prevState, country: data.data, country_season: data.data }));

      }
      else {
        setAddress((prevState) => ({ ...prevState, country: "", country_season: "" }));
      }
    },
    onError: (error) => {
      //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
      HandleErrorToast("error", "Countries Could not be Fetched. Something went Wrong!", 3000);
      //setAddress((prevState) => ({ ...prevState, country: ""}));
    },
  });



  useEffect(() => {
    const GETSTATESDATA = async () => {
      try {
        const response = await axios.post(
          `${BASEPATH}${STATES_FROM_BACKEND}`,
          {
            country_id: address.selectedCountry,
          },
          {
            headers: {
              'MDnD_auth': `${token}`,

            },

            timeout: 60000,
            responseType: 'json',
          },
        );


        if (response.data.status == "success") {

          setAddress((prevState) => ({ ...prevState, state: response.data.data, }));
        }
        else {
          setAddress((prevState) => ({ ...prevState, state: "", }));
        }



      }

      catch (error) {
        console.error("error fetching States for this country", error);
      }
    }


    //mutationGetStates.mutate();
    if (address.selectedCountry) { GETSTATESDATA(); }
  }, [address.selectedCountry])


  // useEffect states for season Events...
  useEffect(() => {
    const GETSTATESDATA_season = async () => {
      try {
        const response = await axios.post(
          `${BASEPATH}${STATES_FROM_BACKEND}`,
          {
            country_id: address.selectedCountry_season,
          }, {
          headers: {
            'MDnD_auth': `${token}`,

          },

          timeout: 60000,
          responseType: 'json',
        },
        );


        if (response.data.status == "success") {

          setAddress((prevState) => ({ ...prevState, state_season: response.data.data, }));
        }
        else {
          setAddress((prevState) => ({ ...prevState, state_season: "", }));
        }



      }

      catch (error) {
        console.error("error fetching States for this country", error);
      }
    }


    //mutationGetStates.mutate();
    if (address.selectedCountry_season) { GETSTATESDATA_season(); }
  }, [address.selectedCountry_season])





  useMemo(() => {
    {
      setAddress((prevState) => ({
        ...prevState, selectedCountry: 101, selectedState: 35, selectedCity: 3659,
        selectedCountry_season: 101, selectedState_season: 35, selectedCity_season: 3659,
      }));
    }
  }, [])

  useEffect(() => {
    const GETCITIESDATA = async () => {
      try {
        const response = await axios.post(
          `${BASEPATH}${CITIES_FROM_BACKEND}`,
          {
            state_id: address.selectedState,
          }, {
          headers: {
            'MDnD_auth': `${token}`,

          },

          timeout: 60000,
          responseType: 'json',
        },
        );


        if (response.data.status == "success") {

          setAddress((prevState) => ({ ...prevState, city: response.data.data, }));
        }
        else {
          setAddress((prevState) => ({ ...prevState, city: "", }));
        }



      }

      catch (error) {
        console.error("error fetching States for this country", error);
      }
    }
    if (address.selectedState) { GETCITIESDATA(); }
  }, [address.selectedState])

  //useeffect for cities - season events
  useEffect(() => {
    const GETCITIESDATA_season = async () => {
      try {
        const response = await axios.post(
          `${BASEPATH}${CITIES_FROM_BACKEND}`,
          {
            state_id: address.selectedState_season,
          },
          {
            headers: {
              'MDnD_auth': `${token}`,

            },

            timeout: 60000,
            responseType: 'json',
          }
        );


        if (response.data.status == "success") {

          setAddress((prevState) => ({ ...prevState, city_season: response.data.data, }));
        }
        else {
          setAddress((prevState) => ({ ...prevState, city_season: "", }));
        }



      }

      catch (error) {
        console.error("error fetching States for this country", error);
      }
    }
    if (address.selectedState_season) { GETCITIESDATA_season(); }
  }, [address.selectedState_season])



  const handleEventz = (ci, ei) => {
    //console.log("here");
    //console.log(ci, "ci");
    //console.log(ei, "ei");
    navigate(`/events/ProductDetails?ci=${ci}&e=${ei}`);


  }

  const handleEventz_season = () => {

  }

  const handlethis = () => {
    //console.log("here");
  }


  const handleMDNDRecords = (item) => {

    const json_string = JSON.stringify({
      video_id: item?.id,
      role_id: item?.role_id ?? null,
      maincatid: item?.main_category_id,
    });
    const slug = btoa(json_string);
    navigate(`/RecordsVideo/videos/${slug}`);

  }

  const handleRagaBank = (item) => {
    //window.location.href = item.video_link;

    const json_string = JSON.stringify(
      {
        video_id: item?.id,
        role_id: user ? user.role_id : ""
      }
    )
    const slug = btoa(json_string);
    navigate(`/RagaBankVideo/videos/${slug}`);

  }

  const handleCountry = (e) => {

    //console.log("im inside");
    if (e.value) {
      setAddress((prevState) => ({ ...prevState, selectedCountry: e.value }));
      setAddress((prevState) => ({ ...prevState, city: [] }));
      setAddress((prevState) => ({ ...prevState, selectedState: "", selectedCity: "", }));


    }
  }

  const handleCountry_season = (e) => {
    if (e.value) {
      setAddress((prevState) => ({ ...prevState, selectedCountry_season: e.value }));
      setAddress((prevState) => ({ ...prevState, city_season: [] }));
      setAddress((prevState) => ({ ...prevState, selectedState_season: "", selectedCity_season: "", }));


    }

  }

  const handleState = (value) => {

    if (value) {
      setAddress((prevState) => ({ ...prevState, selectedState: value }));
      setAddress((prevState) => ({ ...prevState, city: [] }));
      setAddress((prevState) => ({ ...prevState, selectedCity: "", }));

    }
  }

  const handleState_season = (value) => {
    if (value) {
      setAddress((prevState) => ({ ...prevState, selectedState_season: value }));

      setAddress((prevState) => ({ ...prevState, city_season: [] }));
      setAddress((prevState) => ({ ...prevState, selectedCity_season: "", }));



    }
  }



  const handleCity = (value) => {

    console.log("inside handleCity globalSearch");
    if (value) {
      setAddress((prevState) => ({ ...prevState, selectedCity: value }));

      //setHasMoreData((prevHasMoreData) => !prevHasMoreData);
      setHasMoreData(true);
      setIsFetching(false);
      setCurrentOffset(0);
      setsearchCollection((prevState) => ({ ...prevState, searchData: [] }));
    }


  }

  const handleCity_season = (value) => {
    if (value) {
      setAddress((prevState) => ({ ...prevState, selectedCity_season: value }));

      //setHasMoreData((prevHasMoreData) => !prevHasMoreData);
      setHasMoreData(true);
      setIsFetching(false);
      setCurrentOffset(0);
      setsearchCollection((prevState) => ({ ...prevState, searchData: [] }));
    }
  }


  //console.log(address, "address");

  console.log(searchCollection, "SearchCollection");



  // THUMBNAIL FOR MDNDRECORDS...


  useEffect(() => {
    const fetchThumbnail = async (item, index) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${BASEPATH}${GET_THUMBNAIL_VIDEOS_IN_UPLOAD}/${item.id}`,
          headers: {
            'MDnD_auth': `${token}`,

          },

          timeout: 60000,
          responseType: 'json',
        });

        if (response.data.status === "success") {
          //console.log(response.data, "response.data");

          const base64Data = response.data.data.thumbnail;
          const dataURL = `data:image/jpeg;base64,${base64Data}`;

          const blob = await (await fetch(dataURL)).blob();

          const url = URL.createObjectURL(blob);

          if (url) {
            setThumbnail((prevThumbnail) => ({
              ...prevThumbnail,
              [index]: url,
            }));
          }




          // setThumbnail((prevThumbnail) => ({
          //   ...prevThumbnail,
          //   [index]: response.data.data.thumbnail,
          // }));
        } else {

          setThumbnail((prevThumbnail) => ({
            ...prevThumbnail,
            [index]: "",
          }));
        }
      } catch (error) {
        console.error("Thumbnail pictures cannot be fetched", error);

        setThumbnail((prevThumbnail) => ({
          ...prevThumbnail,
          [index]: "",
        }));
      }
    };


    if (searchCollection.searchData && activeTab === 'mdndRecords') {

      for (let i = iteration; i < searchCollection.searchData.length; i++) {
        //console.log(i,"i inside loop");
        fetchThumbnail(searchCollection.searchData[i], i);
      }

    }
  }, [searchCollection.searchData]);


  // thumbnail for RAGABANK.............................
  useEffect(() => {
    const fetchThumbnailRAGABANK = async (item, index) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${BASEPATH}videos/raga_bank/get_thumbnail/${Number(item.id)}`,
          headers: {
            'MDnD_auth': `${token}`,

          },

          timeout: 60000,
          responseType: 'json',
        });

        if (response.data.status === "success") {

          const base64Data = response.data.data.thumbnail;
          const dataURL = `data:image/jpeg;base64,${base64Data}`;

          const blob = await (await fetch(dataURL)).blob();

          const url = URL.createObjectURL(blob);

          if (url) {
            setThumbnailRagaBank((prevThumbnail) => ({
              ...prevThumbnail,
              [index]: url,
            }));
          }


        } else {

          setThumbnailRagaBank((prevThumbnail) => ({
            ...prevThumbnail,
            [index]: "",
          }));
        }
      } catch (error) {
        console.error("Thumbnail pictures cannot be fetched", error);

        setThumbnailRagaBank((prevThumbnail) => ({
          ...prevThumbnail,
          [index]: "",
        }));
      }
    };


    if (searchCollection.searchData && activeTab === 'ragaBank') {
      for (let i = iteration_ragabank; i < searchCollection.searchData.length; i++) {
        fetchThumbnailRAGABANK(searchCollection.searchData[i], i);
      }
    }
  }, [searchCollection.searchData]);



  //to fetch profile picture..

  useEffect(() => {
    const fetchProfilePic = async () => {
      try {
        const response = await axios({
          method: "GET",
          url: `${BASEPATH}profiles/get_user_profile/${Number(searchCollection?.searchData[0]?.role_id)}`,
          headers: {
            'MDnD_auth': `${token}`,

          },

          timeout: 60000,
          responseType: 'json',
        });

        if (response.data.status === "success") {

          const base64Data = response.data.data.profile_image;
          const dataURL = `data:image/jpeg;base64,${base64Data}`;

          const blob = await (await fetch(dataURL)).blob();

          const url = URL.createObjectURL(blob);

          if (url) {

            searchCollection.searchData.forEach((item, index) => {
              setProfilephoto((prev) => ({
                ...prev,
                [index]: url,
              }));
            });



          }


        } else {


          setProfilephoto({});
        }
      } catch (error) {
        console.error("Profile pictures cannot be fetched", error);

        setProfilephoto({});
      }
    };


    if (activeTab === 'mdndRecords' && searchCollection.searchData) {

      fetchProfilePic();

    }
    else {
      return;
    }
  }, [searchCollection.searchData]);

  console.log(searchCollection, "searchCollection");
  //console.log(profilephoto, "profile photo");
  //console.log(thumbnail, "THUMBNAIL IMAGES")
  //console.log(searchCollection, "searchCollection");

  //console.log(thumbnailRagaBank, "thumbnail Ragabank");

  function timeDifference(createdAt) {
    const currentDate = new Date();
    const createdDate = new Date(createdAt);

    const diffMs = currentDate - createdDate; // Difference in milliseconds
    const diffSeconds = Math.floor(diffMs / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);
    const diffWeeks = Math.floor(diffDays / 7);
    const diffMonths = Math.floor(diffDays / 30);
    const diffYears = Math.floor(diffDays / 365);

    if (diffSeconds < 60) {
      return `${diffSeconds} seconds ago`;
    } else if (diffMinutes < 60) {
      return `${diffMinutes} minutes ago`;
    } else if (diffHours < 24) {
      return `${diffHours} hours ago`;
    } else if (diffDays < 7) {
      return `${diffDays} days ago`;
    } else if (diffWeeks < 4) {
      return `${diffWeeks} weeks ago`;
    } else if (diffMonths < 12) {
      return `${diffMonths} months ago`;
    } else {
      return `${diffYears} years ago`;
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);


    const options = { weekday: 'long' };
    const dayOfWeek = new Intl.DateTimeFormat('en-US', options).format(date);


    const day = date.getDate();


    const monthOptions = { month: 'long' };
    const month = new Intl.DateTimeFormat('en-US', monthOptions).format(date);

    return `${dayOfWeek}, ${day} ${month}`;
  };

  const convertTime = (timeString) => {

    const [hours, minutes] = timeString?.split(':');


    const time = new Date();
    time.setHours(parseInt(hours, 10));
    time.setMinutes(parseInt(minutes, 10));


    const formattedTime = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }).format(time);

    return formattedTime;
  };

  console.log(hasMoreData, "hasMoreData");

  const [filter, setFilter] = useState(false);
  const doFilter = () => {
    setFilter(!filter);
  };

  const handleSeasonEventz=(countryid,eventid)=>{
    navigate(
      `/SeasonTickets/seasonEventDetails?ci=${countryid}&se=${eventid}`
    );
  }
  

  return (
    <div>
      <div className={`section-padding service-details} pt-lg-5`}>
        <div className="container-fluid pageMargin">
          <div className="row">
            <div className="content-details iq-custom-tab-style-two">
              <ul
                className="navBarBg w-100 nav nav-underline overflow-auto d-flex  nav nav-pills align-items-center text-center mb-lg-0 gap-lg-4 gap-2 py-lg-2 mt-lg-0 mt-sm-3"
                role="tablist"
              >
                <li className="nav-item">

                  <button
                    className={`nav-link ${activeTab === 'events' ? 'active show' : ''}`}
                    onClick={() => handleTabClick('events')}
                    role="tab"
                    data-bs-toggle="pill"
                    aria-selected={activeTab === 'events'}

                  >
                    Events
                  </button>
                </li>

                <li className="nav-item">

                  <button
                    className={`nav-link ${activeTab === 'artists' ? 'active show' : ''}`}
                    onClick={() => handleTabClick('artists')}
                    role="tab"
                    data-bs-toggle="pill"
                    aria-selected={activeTab === 'artists'}
                  //aria-selected="false"
                  >
                    Artists
                  </button>

                </li>

                <li className="nav-item">
                  {/* <a
                        className="nav-link"
                        data-bs-toggle="pill"
                        href="#organiser"
                        role="tab"
                        aria-selected="false"
                      >
                        Organisers
                      </a> */}
                  <button
                    className={`nav-link ${activeTab === 'organiser' ? 'active show' : ''}`}
                    onClick={() => handleTabClick('organiser')}
                    role="tab"
                    data-bs-toggle="pill"
                    aria-selected={activeTab === 'organiser'}
                  //aria-selected="false"
                  >
                    Organisers
                  </button>
                </li>

                <li className="nav-item">

                  <button
                    className={`nav-link ${activeTab === 'mdndRecords' ? 'active show' : ''}`}
                    onClick={() => handleTabClick('mdndRecords')}
                    role="tab"
                    data-bs-toggle="pill"
                    aria-selected={activeTab === 'mdndRecords'}

                  >
                    MDnD Records
                  </button>

                </li>
                <li className="nav-item">

                  <button
                    className={`nav-link ${activeTab === 'ragaBank' ? 'active show' : ''}`}
                    onClick={() => handleTabClick('ragaBank')}
                    role="tab"
                    data-bs-toggle="pill"
                    aria-selected={activeTab === 'ragaBank'}
                  //aria-selected="false"
                  >
                    Raga Bank
                  </button>

                </li>
                <li className="nav-item">

                  <button
                    className={`nav-link ${activeTab === 'seasonEvents' ? 'active show' : ''}`}
                    onClick={() => handleTabClick('seasonEvents')}
                    role="tab"
                    data-bs-toggle="pill"
                    aria-selected={activeTab === 'seasonEvents'}
                  //aria-selected="false"
                  >
                    Season Events
                  </button>

                </li>



              </ul>

              <div className="tab-content globalBorder px-2 px-lg-3">
                {activeTab === 'events' && (
                  <div
                    id="events"
                  
                  >
                   
                     <div className="row">

                     <div className="col-lg-2 sideBarFilter">
                          <div className="filterBg pb-2">
                            <div className="">
                              <h5><FaFilter /> Filters</h5>
                            </div>

                          <div className="mt-4">
                            <h6 className="mb-0">Location</h6>


                            <SelectBox
                              dataSource={address.country}
                              displayExpr="country_name"
                              valueExpr="country_id"
                              label="Select Country"
                              searchEnabled={true}
                              onValueChanged={handleCountry}
                              labelMode="floating"
                              value={address.selectedCountry}
                            //ref={element => inputRefs.current[key] = element}
                            />


                            <SelectBox
                              dataSource={address.state || []}
                              label="Select State"
                              displayExpr="state_name"
                              valueExpr="state_id"
                              labelMode="floating"
                              searchEnabled={true}
                              onValueChanged={(e) => handleState(e.value)}
                              value={address.selectedState}
                            //ref={element => inputRefs.current[key] = element}
                            />

                              <SelectBox
                                dataSource={address.city || []}
                                label="Select City"
                                labelMode="floating"
                                displayExpr="city_name"
                                valueExpr="city_id"
                                searchEnabled={true}
                                onValueChanged={(e) => handleCity(e.value)}
                                value={address.selectedCity}
                              //ref={element => inputRefs.current[key] = element}
                              />
                            </div>
                          </div>
                        </div>

                       
                        

                        <div className="col-lg-10 mt-lg-3 mt-2">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 ">

                          {searchCollection.searchData && searchCollection.searchData.length > 0 ?

                            (
                              searchCollection.searchData.map((eitem, index) => (
                                <div className="col" key={index}>
                                  <div className="product-block mb-3 mb-lg-4 px-lg-0">
                                    <div className="image-wrap">
                                      {/* <div className="product-image" >  */}
                                      <div 
                                      className="product-image-container"
                                      onClick={() => handleEventz(eitem.country_id, eitem.event_id)}
                                      >


                                        {isValidImage(eitem.event_image) && imageExists(`${EventImg}/${eitem.event_image}`) ?
                                          (<img
                                            src={
                                              `${EventImg}/${eitem.event_image}`
                                            }
                                            className="img-fluid w-100 product-image"
                                            //onError={handleImageError}
                                            //className="img-fluid w-100"
                                            //alt="productImg-".

                                            //loading="lazy"
                                            //onClick={handlethis}
                                            onClick={() => handleEventz(eitem.country_id, eitem.event_id)}
                                          />) : (
                                            <Noimage />
                                          )}
                                        <div class="align-items-center blog-description bg-white p-2">
                                          <h5
                                            className="mb-0 text-ellipsis"
                                            title={eitem.event_title}
                                            style={{
                                              maxHeight: "48px",
                                              fontSize: "16px",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {eitem.event_title}
                                          </h5>
                                          <p
                                            className="mb-0 text-ellipsis"
                                            style={{
                                              maxHeight: "24px",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {eitem.venue_name}
                                          </p>
                                          <div className="justify-content-between d-flex align-items-end gap-2">
                                            <div className="pe-0">
                                              <p class="mb-0 iq-title text-black">
                                                {formatDate(eitem.event_date)}
                                                <br />
                                                {convertTime(eitem.event_time)}
                                              </p>
                                            </div>
                                            <p
                                              className="button m-0 text-center"
                                              style={{
                                                padding: "6px 24px",
                                                height: "auto",
                                                fontSize: "12px",
                                                width: "40%",
                                                backgroundColor: eitem.ticketing_option === 'free' || eitem?.ticketing_option === "register" ? "green" : "#3f00ff"
                                              }}
                                            >
                                              {eitem.ticketing_option}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))

                            ) : (
                              <NoDataFound />
                            )

                          }

                        </div>
                      </div>      

                   {/* mobile view  */}
                   <div>
                   <div className="d-lg-none filter-sticky d-flex flex-nowrap align-items-center gap-3 justify-content-end">
                          <div
                            className="filter-button align-items-center"
                            onClick={doFilter}
                          >
                            <FaFilter />
                            &nbsp; Filters
                          </div>
                        </div>
              <div className="col-lg-9 col-xl-10 filtermh">
              <div>
                <Modal className="filterPopUp" show={filter} onHide={doFilter}>
                  <Modal.Header closeButton
                    className="m-0 d-flex align-items-center text-center border-light"
                    style={{ padding: "10px 10px", }}
                  >
                    <h5 className="text-nowrap text-center m-auto">Filters</h5>
                  </Modal.Header>
                  <Modal.Body className="p-3">
                   
                  <SelectBox
                                dataSource={address.country}
                                displayExpr="country_name"
                                valueExpr="country_id"
                                label="Select Country"
                                searchEnabled={true}
                                onValueChanged={handleCountry}
                                labelMode="floating"
                                value={address.selectedCountry}
                              //ref={element => inputRefs.current[key] = element}
                              />


                                <SelectBox
                                  dataSource={address.state || []}
                                  label="Select State"
                                  displayExpr="state_name"
                                  valueExpr="state_id"
                                  labelMode="floating"
                                  searchEnabled={true}
                                  onValueChanged={(e) => handleState(e.value)}
                                  value={address.selectedState}
                                //ref={element => inputRefs.current[key] = element}
                                />

                                <SelectBox
                                  dataSource={address.city || []}
                                  label="Select City"
                                  labelMode="floating"
                                  displayExpr="city_name"
                                  valueExpr="city_id"
                                  searchEnabled={true}
                                  onValueChanged={(e) => handleCity(e.value)}
                                  value={address.selectedCity}
                                //ref={element => inputRefs.current[key] = element}
                                />
                              </Modal.Body>
                              <Modal.Footer className="py-1 px-2 border-0">
                                {/* <button
                      className="button"
                      onClick={doFilter}
                    >
                      Apply
                    </button> */}
                              </Modal.Footer>
                            </Modal>
                            {/* ---------------------------------------------------------- */}
                          </div>
                        </div>


                      </div>


                    </div>



                  </div>)}

                {/* second div  */}
                {activeTab === 'artists' && (
                  <>
                    <div
                      id="artists"

                      role="tabpanel"
                    >
                      <h5 className="main-title text-capitalize my-3">
                        Artists
                      </h5>
                      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 filtermh" >
                      {searchCollection.searchData &&
                          searchCollection.searchData.length > 0 ?

                          (    searchCollection.searchData.map((item, index) => (
                            <div className="col">
                              <div className="rounded overflow-hidden " >
                                <div
                                  className="img-container"
                                  style={{
                                    backgroundColor: item.profile_photo ? 'transparent' : '#3f00ff',
                                    width: '100%',
                                    height: '250px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  }}
                                  onClick={() => handleArtist(item)}
                                >
                                {isValidImage(item.profile_photo) && imageExists(`${ArtistallImg}/${item.profile_photo}`) ?( 
                                  <img
                                    //src={staticImg}
                                    src={`${ArtistallImg}/${item.profile_photo}`}
                                    className="img-fluid object-cover"
                                    style={{ width: '100%', height: 'full', borderRadius: '4px' }}
                                    alt="castImg"
                                    onClick={() => handleArtist(item)}
                                  />):(
                                  <Noimage/>
                                  )}
                                </div>
                                <div className="card-img-overlay artistTextBg position-relative">
                                  <p className="cast-title fw-500 text-black mb-0">
                                    {item.artist_name}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))

                          ) : (
                            <NoDataFound />
                          )

                        }
                      </div>
                    </div></>
                )}


                {/* third div  */}
                {activeTab === 'organiser' && (
                  <>
                    <div
                      id="organiser"
                      //className="tab-pane animated fadeInUp"
                      role="tabpanel"
                    >
                      <h5 className="main-title text-capitalize my-3">
                        Organisers
                      </h5>
                      

<div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 filtermh">
                        {searchCollection.searchData && searchCollection.searchData.length > 0 ? (
                          searchCollection.searchData.map((item, index) => (
                            <div className="col" key={index}>
                              <div className="rounded overflow-hidden" onClick={() => handleOrganiser(item)}>
                                {/* Container with static background color and size matching the image */}
                                <div
                                  className="img-container"
                                  style={{
                                    backgroundColor: item.profile_photo ? '#3f00ff' : 'transparent',
                                    width: '100%',
                                    height: '250px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  }}
                                >
                                    
                                  {isValidImage(item.profile_photo) && imageExists(`${ORGANISER_URL}/${item.profile_photo}`) ? (
                                    <img
                                      src={`${ORGANISER_URL}/${item.profile_photo}`}
                                     // onError={handleImageError}
                                      className="img-fluid object-cover"
                                      alt={item.organiser_name}
                                      style={{ objectFit: 'cover', height: '100%' }}
                                    />
                                  ) : (
                                    // <h1 style={{ color: '#ffffff', fontSize: '4rem', margin: 0 }}>
                                    //   {generateAlphabetCharacters(item.organiser_name)}
                                    // </h1>
                                    <Noimage/>
                                  )}
                                </div>

                                <div className="card-img-overlay artistTextBg position-relative">
                                  <p className="cast-title fw-500 text-black mb-0">
                                    {item.organiser_name}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="w-auto justify-content-center d-flex"><NoDataFound /></div>
                        )}

                      </div>


                    </div>




                  </>)}


                {activeTab === 'franchisees' && (
                  <>
                    <div
                      id="franchisees"
                    //className="tab-pane animated fadeInUp"
                    //role="tabpanel"
                    >
                      <div className="overflow-hidden">
                        <div className="d-flex align-items-center justify-content-between my-2">
                          <h5 className="main-title text-capitalize mb-0">
                            Franchisees
                          </h5>
                        </div>
                        <div className="row row-cols-2 row-cols-md-2 row-cols-lg-5">
                          <div className="col">
                            <div className="iq-cast">
                              <img
                                src={staticImg}
                                className="img-fluid w-100"
                                alt="castImg"
                              />
                              <div className="card-img-overlay artistTextBg position-relative">
                                <h6 className="cast-title fw-500">Sanjeev</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div></>

                )}
                {activeTab === 'blogs' && (
                  <div
                    id="blogs"
                  //className="tab-pane animated fadeInUp"
                  //role="tabpanel"
                  >
                    <div className="overflow-hidden">
                      <div className="d-flex align-items-center justify-content-between my-2">
                        <h5 className="main-title text-capitalize mb-0">
                          Blogs
                        </h5>
                      </div>
                      <div className="row row-cols-2 row-cols-md-2 row-cols-lg-5">
                        <div className="col">
                          <div
                            className="iq-blog-box pb-2"
                          >
                            <div className="iq-blog-image clearfix mb-2">
                              <a>
                                <img
                                  src={staticImg}
                                  className="img-fluid w-100"
                                />
                              </a>
                            </div>
                            <div className="iq-blog-detail">
                              <a>
                                <h6 className="line-count-1 mb-0">Name</h6>
                              </a>
                              <p className="line-count-2 mb-0">Name2</p>
                              <div className="iq-button link-button">
                                <a
                                  className="btn text-capitalize position-relative"
                                >
                                  <span className="button-text">
                                    Read More
                                    <i
                                      className="fa fa-angle-right ml-2"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                )}

                {activeTab === 'mdndRecords' && (
                  <div
                    id="mdndRecords"
                  //className="tab-pane animated fadeInUp"
                  //role="tabpanel"
                  >
                    {/* <div className="overflow-hidden">
                      <div className="d-flex align-items-center justify-content-between my-2">
                        <h5 className="main-title text-capitalize mb-0">MDnD Records</h5>
                      </div>

                      {searchCollection.searchData && (
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                          {searchCollection.searchData.map((item, index) => (
                            <div className="col" key={index}>
                              <div className="iq-watching-block mb-lg-3">
                                <div className="block-images position-relative">
                                  <div className="iq-image-box overly-images">
                                    {thumbnail[index] ? (
                                      <img
                                        src={thumbnail[index]}
                                        onClick={() => handleMDNDRecords(item)}
                                        className="img-fluid object-cover w-100 d-block border-0"
                                      />
                                    ) : (
                                      <d    iv>
                                        <img
                                          src={`${IMAGE_URL}/noimage.png`}
                                          onClick={() => handleMDNDRecords(item)}
                                          className="img-fluid object-cover w-100 d-block border-0"
                                        />
                                      </d>
                                    )}
                                  </div>
                                  <div className="iq-preogress">
                                    <div className="d-flex py-1 px-2" style={{backgroundColor: 'rgba(0, 0, 0, 0.2);', }}> 
                                      <p style={{color: 'white'}}>350 views</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex p-2 gap-2">
                                  <div className="videoProfileImg">
                                    {profilephoto[index] && (
                                      <img src={profilephoto[index]} className="videoImg" />
                                    )}
                                  </div>
                                  <div className="w-100">
                                    <p className="mb-0" style={{maxHeight: '48px', overflow: 'hidden', fontSize: '16px'}}><b>{item?.video_title}</b></p>
                                    <p className="mb-0">{item?.publisher?.profile_name}</p>
                                    <p className="mb-0 videoMinuts">{timeDifference(item?.created_at)}</p>
                                  </div>
                                  <div>
                                    <i className="fas fa-ellipsis-v fs-5"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            
                          ))}
                        </div>
                        
                      )}
                    </div> */}



                    <div className="w-100 d-flex flex-column">
                      <h5 className="main-title text-capitalize mb-4 mt-2">MDnD Records</h5>
                      {searchCollection.searchData && 
                      searchCollection.searchData.length > 0 ?
                      (
                      <div className="mdnd-records-grid">
                      {searchCollection.searchData.map((item, index) => (
                        <div className="mdnd-record"  key={index}>
                          <div className="position-relative rounded overflow-hidden w-100" >
                            <div className="w-100" 
                            style={{height: '100%'}}
                            onClick={() => handleMDNDRecords(item)}
                            
                            > 
                            {thumbnail[index] ? (
                              <img
                                src={thumbnail[index]}
                                onClick={() => handleMDNDRecords(item)}
                                className="img-fluid object-cover w-100 d-block border-0"
                                style={{height: '100%'}}
                              />
                            ) : (
                              <div  onClick={() => handleMDNDRecords(item)}>
                                <Noimage/>
                              </div>
                            )}
                            </div>
                            <div className="d-flex w-fit px-2 py-1 bg-dark text-white bg-opacity-25 rounded position-absolute" style={{bottom: '8px', left: '8px'}}>
                              <p className="p-0 m-0">350 Views</p>
                            </div>
                          </div>
                          <div className="w-100 d-flex gap-2 align-items-center">
                            <div className="w-100 d-flex gap-2 align-items-center">
                              <div className="" style={{width: '48px', height: '48px'}}>
                                {profilephoto[index] ? (
                                  <img src={profilephoto[index]} className="videoImg rounded-circle" />
                                ):(
                                  <Noimage_circle/>
                                )}
                              </div>
                              <div className="d-flex flex-column">
                                <p className="mb-0" style={{maxHeight: '48px', overflow: 'hidden', fontSize: '16px'}}><b>{item?.video_title}</b></p>
                                <p className="mb-0">{item?.publisher?.profile_name}</p>
                                <p className="mb-0 videoMinuts">{timeDifference(item?.created_at)}</p>
                              </div>
                            </div>
                            <i className="fas fa-ellipsis-v fs-5" style={{marginRight: '12px'}}></i>
                          </div>
                        </div>
                        ))}
                      </div>
                      ) :(
                        <div className="w-auto justify-content-center d-flex"> <NoDataFound/>
                      </div>)
                      
                      
                      
                      }
                    </div>



                  </div>
                )}

                {activeTab === 'ragaBank' && (
                  <div
                    id="ragaBank"
                    //className="tab-pane animated fadeInUp"
                    role="tabpanel"
                  >

                    <div className="w-100 d-flex flex-column">
                      <h5 className="main-title text-capitalize mb-4">Raga Bank</h5>
                      {searchCollection.searchData && 
                      searchCollection.searchData.length > 0 ?
                      
                      (
                      <div className="mdnd-records-grid">
                      {searchCollection.searchData.map((item, index) => (
                        <div className="mdnd-record"  key={index}>
                          <div className="position-relative rounded overflow-hidden w-100" >
                            <div 
                            className="w-100" 
                            style={{height: '100%'}}
                            onClick={() => handleRagaBank(item)}
                            > 
                            {thumbnailRagaBank[index] ? (
                              <img
                                src={thumbnailRagaBank[index]}
                                onClick={() => handleRagaBank(item)}
                                className="img-fluid object-cover w-100 d-block border-0"
                                style={{height: '100%'}}
                              />
                            ) : (
                              <div>
                               <Noimage/>
                              </div>
                            )}
                            </div>
                            <div className="d-flex w-fit px-2 py-1 bg-dark text-white bg-opacity-25 rounded position-absolute" style={{bottom: '8px', left: '8px'}}>
                              <p className="p-0 m-0">350 Views</p>
                            </div>
                          </div>
                          <div className="w-100 d-flex gap-2 align-items-center">
                            <div className="w-100 d-flex gap-2 align-items-center">
                              <div className="" style={{width: '48px', height: '48px'}}>
                                <img src={IMAGE1} className="videoImg rounded-circle" />
                              </div>
                              <div className="d-flex flex-column">
                                <p className="mb-0" style={{maxHeight: '48px', overflow: 'hidden', fontSize: '16px'}}><b>{item.song_title}</b></p>
                                <p className="mb-0">MDND</p>
                                <p className="mb-0 videoMinuts">{timeDifference(item.created_date)}</p>
                              </div>
                            </div>
                            <i className="fas fa-ellipsis-v fs-5" style={{marginRight: '12px'}}></i>
                          </div>
                        </div>
                        ))}
                      </div>
                      ) :(
                        <div className="w-auto justify-content-center d-flex"> <NoDataFound/>
                     </div> )
                      
                      
                      
                      }
                    </div>



                  </div>

                )}

                {activeTab === 'seasonEvents' && (
                  <>
                    <div
                      id="seasonEvents"

                      role="tabpanel"
                    >

                      <div className="overflow-hidden">
                        <div className="d-flex align-items-center justify-content-between my-2">
                          {/* <h5 className="main-title text-capitalize mb-0">
                        Events
                      </h5> */}
                        </div>
                        <div className="row">
                          <div className="col-lg-2 sideBarFilter">
                            <div className="filterBg pb-2">
                              <div className="">
                                <h5><FaFilter /> Filters</h5>
                              </div>

                              <div className="mt-4">
                                <h6 className="mb-0">Location</h6>


                                <SelectBox
                                  dataSource={address.country_season}
                                  displayExpr="country_name"
                                  valueExpr="country_id"
                                  label="Select Country"
                                  searchEnabled={true}
                                  onValueChanged={handleCountry_season}
                                  labelMode="floating"
                                  value={address.selectedCountry_season}
                                //ref={element => inputRefs.current[key] = element}
                                />


                                <SelectBox
                                  dataSource={address.state_season || []}
                                  label="Select State"
                                  displayExpr="state_name"
                                  valueExpr="state_id"
                                  labelMode="floating"
                                  searchEnabled={true}
                                  onValueChanged={(e) => handleState_season(e.value)}
                                  value={address.selectedState_season}
                                //ref={element => inputRefs.current[key] = element}
                                />

                                <SelectBox
                                  dataSource={address.city_season || []}
                                  label="Select City"
                                  labelMode="floating"
                                  displayExpr="city_name"
                                  valueExpr="city_id"
                                  searchEnabled={true}
                                  onValueChanged={(e) => handleCity_season(e.value)}
                                  value={address.selectedCity_season}
                                //ref={element => inputRefs.current[key] = element}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-10">
                          {searchCollection.searchData && searchCollection?.searchData?.length > 0 ?
                          
                        (  
          searchCollection?.searchData?.map((eitem, index) => (
                            <div key={index}>
                              <div className="row row-cols-2 row-cols-md-2 row-cols-lg-5">
                                <div className="col mb-4">
                                  <div className="product-block mb-3 mb-lg-4 px-lg-0">
                                    <div className="image-wrap">
                                      {/* <div className="product-image" >  */}
                                      <div
                                       onClick={() => handleSeasonEventz(eitem.country_id, eitem.event_id)}
                                      >

                                      {isValidImage(eitem.event_image) && imageExists(`${EventImg}/${eitem.event_image}`) ?(     
                                        <img
                                      
                                          src={`${EventImg}/${eitem.event_image}` }
                                          //className="img-fluid w-100"
                                          //alt="productImg-"
                                          //loading="lazy"
                                          //onClick={handlethis}
                                          onClick={() => handleSeasonEventz(eitem.country_id, eitem.event_id)}
                                        />):(
                                          <Noimage/>
                                        )}
                                        <div class="d-flex justify-content-between align-items-center blog-description bg-white p-2 filterDateText">
                                         
                                          <p class="mb-0 iq-title text-black">Start Date -{formatDate(eitem?.event_start_date)}</p>
                                          <p class="mb-0 iq-title text-black">End Date -{formatDate(eitem?.event_end_date)}</p>
                                          {/* <i className="fa-solid fa-heart"></i> */}
                                          <p className="mb-0 ticketBgText">{eitem.ticketing_option}</p>
                                        </div>

                                            </div>
                                            {/* </div> */}
                                            <div className="eventsDetails mt-1">
                                              <h5 className="mb-0">{eitem.event_title}</h5>
                                              <span className="mb-0 fs-12">{eitem.venue_name}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div> </div>

                                ))

                              ) : (
                                <div className="w-auto justify-content-center d-flex"><NoDataFound /></div>
                              )

                            }

                          </div>



{/* mobile view  */}
<div>
                            <div className="d-lg-none filter-sticky d-flex flex-nowrap align-items-center gap-3 justify-content-end">
                              <div
                                className="filter-button align-items-center"
                                onClick={doFilter}
                              >
                                <FaFilter />
                                &nbsp; Filters
                              </div>
                            </div>
                              <div>
                                <Modal className="filterPopUp" show={filter} onHide={doFilter}>
                                  <Modal.Header closeButton
                                    className="m-0 d-flex align-items-center text-center border-light"
                                    style={{ padding: "10px 10px", }}
                                  >
                                    <h5 className="text-nowrap text-center m-auto">Filters</h5>
                                  </Modal.Header>
                                  <Modal.Body className="p-3">
                                    <SelectBox
                                      dataSource={address.country_season}
                                      displayExpr="country_name"
                                      valueExpr="country_id"
                                      label="Select Country"
                                      searchEnabled={true}
                                      onValueChanged={handleCountry_season}
                                      labelMode="floating"
                                      value={address.selectedCountry_season}
                                    //ref={element => inputRefs.current[key] = element}
                                    />


                                    <SelectBox
                                      dataSource={address.state_season || []}
                                      label="Select State"
                                      displayExpr="state_name"
                                      valueExpr="state_id"
                                      labelMode="floating"
                                      searchEnabled={true}
                                      onValueChanged={(e) => handleState_season(e.value)}
                                      value={address.selectedState_season}
                                    //ref={element => inputRefs.current[key] = element}
                                    />

                                    <SelectBox
                                      dataSource={address.city_season || []}
                                      label="Select City"
                                      labelMode="floating"
                                      displayExpr="city_name"
                                      valueExpr="city_id"
                                      searchEnabled={true}
                                      onValueChanged={(e) => handleCity_season(e.value)}
                                      value={address.selectedCity_season}
                                    //ref={element => inputRefs.current[key] = element}
                                    />

                                  </Modal.Body>
                                  <Modal.Footer className="py-1 px-2 border-0">
                                    {/* <button
                      className="button"
                      onClick={doFilter}
                    >
                      Apply
                    </button> */}
                                  </Modal.Footer>
                                </Modal>
                                {/* ---------------------------------------------------------- */}
                              </div>





                          </div>





                        </div>

                      </div>




                    </div>

                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GlobalSearch;
