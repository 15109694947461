import React from "react";
import { GET_TICKET_DETAILS, numbered_addToCart } from "../../Api/api";
import { makeDeleteRequest, makePostRequest } from "../../Api/requestMethods";
import { useEffect, useState, useContext } from "react";
import { GlobalContext } from "../../global/provider";
import { useLocation, useNavigate, Link } from "react-router-dom";
import CreateSeatingLayouts from "../../LoginDashboard/components/createSeatingLayouts/createSeatingLayouts";
import { SUCCESS } from "../../global/ToastMsgConstants.tsx";
import { IoChevronBack } from "react-icons/io5";
import AuthContext from "../../context/AuthContext.js";
import { ALLEVENT_DETAILS_IMAGES } from "../../global/constants.js";
import {
  capitalizeFirstLetter,
} from "../../utils/commonUtils.js";
import NumberedNotification from "../../components/alertModel.js";
import noTicketFoundAvailable from "../.././assets/images/ticketsNotAvailbale.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { v4 as uuidv4 } from "uuid";
import "./numberedSeatingPurchase.css";

const NumberedSeatingPurchase = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const searchParams = new URLSearchParams(location.search);
  const { eventsDatasource, setEventDataSource, setCartDetails } =
    useContext(GlobalContext);
  const [popup, setPop] = useState({
    show: false,
    message: "",
  });
  const sessionId = localStorage.getItem("mdnd_cId");
  const countryId = searchParams.get("ci") || "";
  const eventId = searchParams.get("e") || "";
  const eventMode = searchParams.get("e_mode") || "";
  const ticketingOption = searchParams.get("t_option") || "";
  const seatingOption = searchParams.get("s_option") || "";
  const seatingFlag = searchParams.get("s_flag") || "";
  const [dataSource, setDataSource] = useState();
  const [eventDetails, setEventDetails] = useState();
  const [seatingDetails, setSeatingDetails] = useState([]);


  const onClose = () => {
    setPop((prev) => ({ ...prev, show: false }));
  };

  const chooseTicketEventCB = (floor_name, row_name, row_index, seat) => {
    const newEntry = {
      floor_name,
      row_name,
      row_index,
      seat,
    };
    setSeatingDetails((prev) => {
      const entryExists = prev?.some(
        (entry) =>
          entry.floor_name === newEntry.floor_name &&
          entry.row_name === newEntry.row_name &&
          entry.row_index === newEntry.row_index
      );
      if (!entryExists) {
        return [...prev, newEntry];
      }
      console.log("aldredy exist");
      return prev;
    });
  };



  const fetchNumberedDetails = async () => {
    var uniqueId;
    try {
      if (
        countryId &&
        eventId &&
        eventMode &&
        ticketingOption &&
        seatingOption &&
        seatingFlag
      ) {
        uniqueId = localStorage.getItem("mdnd_cId");
        if (!uniqueId) {
          uniqueId = uuidv4();
          localStorage.setItem("mdnd_cId", uniqueId);
        }
        console.log(uniqueId, "uniqueId");
        const body = {
          country_id: countryId,
          event_id: eventId,
          event_mode: eventMode,
          ticketing_option: ticketingOption,
          seating_option: seatingOption,
          season_flag: seatingFlag,
          role_id: Number(user?.role_id) || "",
          session_id: user?.role_id ? null : uniqueId,
        };

        const response = await makePostRequest(GET_TICKET_DETAILS, body);
        if (response && response.status === SUCCESS) {
          const modifiedData = response.data?.map((data) => {
            let parsedLayout;
            try {
              parsedLayout = JSON.parse(data.balance_tickets);
            } catch (error) {
              console.error("Error parsing JSON:", error);
              parsedLayout = {};
            }
            return {
              ...data,
              balance_tickets: parsedLayout,
            };
          });
          const eventDetails = modifiedData && modifiedData[0];

          setEventDetails(eventDetails);
          const rowLayoutObject = modifiedData?.reduce((acc, data) => {
            acc[data.row_name] = data.balance_tickets;
            return acc;
          }, {});

          const newModifiedData = Object.keys(rowLayoutObject).reduce(
            (acc, floorName) => {
              acc[floorName] = Object.keys(rowLayoutObject[floorName]).reduce(
                (floorAcc, rowLabel) => {
                  floorAcc[rowLabel] = rowLayoutObject[floorName][rowLabel].map(
                    (data) => ({
                      ...data,
                      numbered_id: modifiedData.find(
                        (item) => item.row_name === floorName
                      ).id,
                    })
                  );
                  return floorAcc;
                },
                {}
              );
              return acc;
            },
            {}
          );
          setDataSource(newModifiedData);
          setEventDataSource((prev) => ({
            ...prev,
            numberedEventTempBookedDetails:
              response?.data &&
                response.data[0] &&
                response.data[0].temp_booked_details
                ? response.data[0].temp_booked_details
                : [],
          }));
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching numbered details:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNumberedDetails();
  }, [
    countryId,
    eventId,
    eventMode,
    ticketingOption,
    seatingOption,
    seatingFlag,
  ]);

  const queryParams = location.search;

  const handleBackClick = async () => {
    try {
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  const total_quantity = eventsDatasource.numberedTicketAmountDetails
    ? eventsDatasource.numberedTicketAmountDetails.map((data) => {
      data.seat = data.seat_label;
      return {
        ...data,
        rowname: data.seat_label,
        seat_unique: data.unique_id,
      };
    })
    : [];

  const numberedPayNow = async () => {
    const body = {
      role_id: Number(user?.role_id) || "",
      login_id: Number(user?.login_id) || "",
      session_id: !user?.role_id && !user?.login_id ? sessionId : null,
      country_id: Number(countryId),
      item_id: Number(eventId),
      cart_purpose: "ticket",
      seating_option: "numbered",
      is_multiple: "",
      season_flag: "N",
      event_id: Number(eventId),
      total_quantity: total_quantity || [],
    };
    try {
      if (user?.role_id || sessionId) {
        const response = await makePostRequest(numbered_addToCart, body);
        if (response && response?.status === SUCCESS) {
          const cartCount = response?.data && response?.data?.cart_count;
          setCartDetails((prev) => ({
            ...prev,
            cartValue: cartCount,
          }));
          navigate("/checkOut");
        } else {
          setPop((prev) => ({
            ...prev,
            message: response.message,
            show: true,
          }));
          return;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setEventDataSource((prev) => ({ ...prev, numberedChoosedTicketAmount: 0 }));
  }, []);

  console.log(
    eventsDatasource?.numberedChoosedTicketAmount,
    "eventsDatasource?.numberedChoosedTicketAmount"
  );

  return (
    <div>
      <div className="">
        <div className="stageHeader row py-2 px-lg-5 px-1">
          <div className="d-flex justify-content-baseline col-md-8">
            {" "}
            <Link to={location.pathname + queryParams} onClick={handleBackClick}>
              <IoChevronBack className="backBTNnumbered me-2" />
            </Link>
            {loading ? (
              <Skeleton width={50} height={50} className="rounded-circle" />
            ) : (
              <img
                className="ticketImg"
                src={`${ALLEVENT_DETAILS_IMAGES}${eventDetails?.event_image}`}
              />
            )}
            <div className="ms-2">
              {loading ? (
                <Skeleton height={50} />
              ) : (
                <p className="mb-0 h5 h4 h-md-4 pt-lg-2">
                  {capitalizeFirstLetter(eventDetails?.event_title)}
                </p>
              )}
              {loading ? (
                <Skeleton height={30} />
              ) : (
                <p className="mt-0 mb-0 ms-1">
                  <>
                    {capitalizeFirstLetter(eventDetails?.venue_name)}
                    {"   |   "}
                    {eventDetails?.event_date}
                    {","}
                    {eventDetails?.event_time}
                  </>
                </p>
              )}

            </div>
          </div>
          <div className="d-flex col-md-4 justify-content-end mt-md-0 mt-2">
            <div className=" d-flex align-items-center me-2">
              <span
                class="Legend-colorBox  me-1"
                style={{ backgroundColor: "#3f00ff" }}
              ></span>
              <span class="Legend-label">Selected</span>
            </div>
            <div className=" d-flex align-items-center me-2">
              <span
                class="Legend-colorBox  me-1"
                style={{ backgroundColor: "hsla(0,0%,6%,.22)" }}
              ></span>
              <span class="Legend-label">Booked</span>
            </div>
            <div className=" d-flex align-items-center me-2">
              <span
                class="Legend-colorBox  me-1"
                style={{ backgroundColor: "#2f2f2f" }}
              ></span>
              <span class="Legend-label">Blocked</span>
            </div>
            <div className=" d-flex align-items-center me-2">
              <span
                class="Legend-colorBox  me-1"
                style={{ border: "1px solid #4300ff" }}
              ></span>
              <span class="Legend-label">Available</span>
            </div>
          </div>
        </div>

      </div>
      {loading ? (
        <Skeleton height={1000} className="skeletonMargin" />
      ) : (
        <div className="SeatingMargin ">
          <CreateSeatingLayouts
            propsDataSource={dataSource}
            setDataSourceEvents={chooseTicketEventCB}
            isticketpurchase={true}
          />
        </div>
      )}

      {eventsDatasource?.numberedChoosedTicketAmount !== null &&
        eventsDatasource?.numberedChoosedTicketAmount !== 0 && (
          <div className="stageFooter">
            <button className="footerBtn" onClick={numberedPayNow}>
              Pay ₹ {eventsDatasource?.numberedChoosedTicketAmount || 0}
            </button>
          </div>
        )}

      <NumberedNotification
        show={popup.show}
        onClose={onClose}
        message={popup.message}
        imageSource={noTicketFoundAvailable}
      />
    </div>
  );
};

export default NumberedSeatingPurchase;
