import React from "react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useState, useEffect,useContext } from "react";
import { BASEPATH } from "../../config/config";
import { GET_CALENDAR } from "../../Api/api";
import Scheduler, { Resource, View } from 'devextreme-react/scheduler';
import NoDataFound from "../../global/NoDataFound";


export default function MyCalendar_organiser(props){
    let { roleid } = props;

    console.log(roleid,"roleid calendar");
    const token= localStorage.getItem("MDnD_auth");

    const views = ['month', 'week'];

    const[calendarCollection, setCalendarCollection] = useState({
        calendarData:[],
        firstdate: null,
    })


    useEffect(() => {
       if(roleid)
         {   mutationGetCalendar.mutate(); }
       
    }, [roleid])

    const GetCalendar = () =>
        GET_MYCALENDAR_FROM_BACKEND(GET_CALENDAR);

    const GET_MYCALENDAR_FROM_BACKEND = async (
        GET_CALENDAR) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${BASEPATH}${GET_CALENDAR}`,

                data: {
                    country_id: 101,
                    role_id: roleid ? Number(roleid) : ""


                },
                headers: {
                    'MDnD_auth': `${token}`, 
                   
                },
    
                timeout: 60000,
                responseType: 'json',
            });
            //console.log(response, "RESPONSE");
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const mutationGetCalendar = useMutation({
        mutationKey: ["get_my_calendar"],
        mutationFn: () => GetCalendar(),

        onSuccess: (data) => {
            //   queryClient.invalidateQueries({
            //     queryKey: ["fetchUser"],
            //   });


            //console.log(data, "DATA");

            if (data.status === "success") {
                //console.log(data,"dt");
                //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
                //SuccessNewToaster("success", "Message Sent Successfully!", 3000);


                let filteredArray = data.data.sort((a, b) => new Date(a.event_date) - new Date(b.event_date));


                let formattedData = filteredArray && filteredArray.map(event => ({
                   
                    text: event.event_title,
                    startDate: new Date(event.event_date),
                    endDate: new Date(new Date(event.event_date).setHours(new Date(event.event_date).getHours() + 1)) // Assuming 1-hour events
                }));

                

                setCalendarCollection((prevState) => ({ ...prevState, calendarData: formattedData,
                    firstdate: formattedData.length > 0 ? formattedData[0].startDate : null

                }));
            }
            else {
                setCalendarCollection((prevState) => ({ ...prevState, calendarData: "" }));
            }
        },
        onError: (error) => {
            //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
            //HandleErrorToast("error", "My Calendar Could not be Fetched. Something went Wrong!", 3000);
            //setCalendarCollection((prevState) => ({ ...prevState, calendarData: "" }));
        },
    });

    return(
        <>
         <div>
        

        { calendarCollection.calendarData && calendarCollection.calendarData.length > 0 ?(
<>
<Scheduler
    timeZone="America/Los_Angeles"
    dataSource={calendarCollection.calendarData}
    views={['month']}
    defaultCurrentView="month"
    defaultCurrentDate={calendarCollection.firstdate || null}
    height={730}
    //showAllDayPanel={true}
    //firstDayOfWeek={1}
    //startDayHour={8}
    //endDayHour={18}
    //startDayHour={9}
  >
    
  
            </Scheduler> </>):(<NoDataFound/>) }
        



        </div>
        
        
        
        
        </>


    )


}