import React from "react";
import noimage from "./noimage.png"

export default function Noimage_circle(){

    return(
        <>
         <img src={noimage} alt="No image Found" className="commentImg rounded-circle me-2"/>
        
        </>
    )
}