import React, { useEffect, useState, useRef } from "react";
import DataGrid, { Column, Editing, Paging } from "devextreme-react/data-grid";

const QuickAddGrid = ({ dataSource, condition, setSettings }) => {
  const [gridData, setGridData] = useState(dataSource.quickAddDataSource || []);
  const gridRef = useRef(null);

  useEffect(() => {
    if (condition) {
      gridRef.current?.instance.addRow();
    }
  }, [condition]);

  const onRowSaving = (e) => {
    try {
      const changes = e.changes;
      changes.forEach((change) => {
        if (change.type === "insert" || change.type === "update") {
          const artists = change.data.quickadd_artists;
          const accompanists = change.data.quickadd_accompanists;
          const organisers = change.data.quickadd_organisers;
          if (!artists && !accompanists && !organisers) {
            e.cancel = true;
            e.promise = Promise.reject(
              "At least one field (Artists, Accompanists, Organisers) must be filled."
            );
          } else {
            setSettings((prev) => ({
              ...prev,
              quickAddTrigger: prev.quickAddTrigger + 1,
            }));
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onRowRemovedTrigger = () => {
    setSettings((prev) => ({
      ...prev,
      quickAddTrigger: prev.quickAddTrigger + 1,
    }));
  };

  return (
    <DataGrid
      ref={gridRef}
      dataSource={gridData}
      showBorders={true}
      columnAutoWidth={true}
      rowAlternationEnabled={true}
      onSaving={onRowSaving}
      onRowRemoved={onRowRemovedTrigger}
      id="gridContainer"
    >
      <Paging enabled={true} />
      <Editing
        mode="row"
        allowUpdating={true}
        allowDeleting={true}
        allowAdding={true}
      />
      <Column dataField="quickadd_artists" caption="Artists" />
      <Column dataField="quickadd_accompanists" caption="Accompanists" />
      <Column dataField="quickadd_organisers" caption="Organisers" />
    </DataGrid>
  );
};

export default QuickAddGrid;
