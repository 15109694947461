import React, { useState, useEffect, useRef } from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import { TextBox, SelectBox, TagBox } from "devextreme-react";
import { sizeValues, fontValues, headerValues, fontSizeOptions, fontFamilyOptions, headerOptions, markup, tabs} from "../../../components/others";
import { useFormik } from 'formik';
import videoValidationSchema from './Validation/Auth';
import { makeGetRequest, makePostRequest } from '../../../Api/requestMethods';
import { ARTIST_SUB_CATEGORIES, ARTIST_SUB_CATEGORY, EVENT_MAIN_CATEGORY, GET_VIDEO_CATEGORIES, UPDATE_VIDEOS_UPLOAD } from '../../../Api/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import notify from 'devextreme/ui/notify';
import { DURATION, ERROR, SUCCESS } from '../../../global/ToastMsgConstants.tsx';
import { openEditor } from 'react-profile';
import { Prev } from 'react-bootstrap/esm/PageItem';
import { TagArtistDetailFetch, TagedOrganiserDetailFetch } from '../Blogs/Validation/BlogsFetch.js';
import { TagItem, Tag } from '../../../components/TagBox.js';
import Button from "devextreme-react/button";
import { jwtDecode } from 'jwt-decode';
import { FaRegLaughWink, FaRegWindowClose } from "react-icons/fa";
import { MDnDUploadRecordsCDN } from '../../../config/config.js';
import VideoPlayer from './HlsPlayer.js';
import "./uploadVideo.css";
import HtmlEditor, {
  Toolbar,
  MediaResizing,
  ImageUpload,
  Item,
} from "devextreme-react/html-editor";

const initialValues = {
  video_title: "",
  main_category_id: "",
  sub_category_id: "",
  artist_ids: "",
  organiser_ids: "",
  description: "",
  thumbnail: "",
  video_url: "",
  status: true,
  show_to_public: true,
  artist_names: [],
  organiser_names: []
}
const UploadVideos = () => {

  const [settings, setSettings] = useState({
    hasFocusedIn: false,
  })
  const [videoData, setVideoData] = useState({
    tagArtistDetail: '',
    tagOrganiserDetail: ''
  })

  const [dataSources, setDataSources] = useState({
    mainCategoryVideoSource: "",
    subCategoryVideoSource: "",
    tagArtist: "",

  })
  const [isMultiline, setIsMultiline] = useState(true);
  const [currentTab, setCurrentTab] = useState(tabs[2].value); // Assuming "base64" is the initial tab
  const markup = ''; // Assuming initial value is an empty string
  const [imageName, setImageName] = useState('');
  const [imageBase64, setImageBase64] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedVideoURL, setUploadedVideoURL] = useState("");
  const [tags, setTags] = useState([]);
  const [organiserTag, setOrganiserTag] = useState([]);
  // ------------------------------------------jwt------------------------------------------------------------
  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );
  const { values, handleBlur, handleChange, handleSubmit, errors, touched, isSubmitting, setFieldValue, resetForm } = useFormik({
    initialValues,
    // validationSchema: videoValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log(values, "thisisformikvlaue");
  
      // Filter out empty strings and empty arrays
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(
          ([key, value]) => value !== "" && (!Array.isArray(value) || value.length > 0)
        )
      );
  
      // Add additional fields to the filtered values
      const updatedValues = {
        ...filteredValues,
        role_id: user.role_id,
        publisher_id: user.login_id,
      };
  
      try {
        await videoUploadMutationDetail.mutateAsync(updatedValues);
      } catch (error) {
        console.log('Error:', error);
      } finally {
        setSubmitting(false);
        resetForm();
      }
    },
  });
  // --------------------------------VideoUplodingThing------------------------------------------
  const videoPostfunction = async (data) => {
    try {
      const endpoint = await makePostRequest(UPDATE_VIDEOS_UPLOAD(), data);
      return endpoint;
    } catch (error) {
      throw error
    }
  }
  const videoUploadMutationDetail = useMutation({
    mutationKey: ["videoDetail"],
    mutationFn: (videodata) => videoPostfunction(videodata),
    onSuccess: (data) => {
      console.log(data,"allData")
      if (data.status !== "created") {
        notify(data.message,'error', DURATION);
        // setSuccessMessage(data.message);
      }
      if (data.status === "created") {
        notify("Details submitted succesfully",'SUCCESS');
      }
      resetForm();
      console.log(data, 'data');
    },
    onError: (error) => {
      console.log(error, "errorpost");
      notify(error.message,'error', DURATION);
    }
  });
  // -------------------------------------MainCategory-----------------------------------------------------------

  const mainCategoryVedio = async () => settings.hasFocusedIn && (await makeGetRequest(EVENT_MAIN_CATEGORY));

  const {
    data: mainCategoryVideoSource,
    isLoading,
  } = useQuery({
    queryKey: ["vieomaincategory"],
    queryFn: () => mainCategoryVedio(),
    enabled: settings.hasFocusedIn,
    onError: () => {
      notify(errors.message, ERROR, DURATION)
    }
  })
  // --------------------------------------SubCategory-----------------------------------------------------------
  const subCategoryVideo = async (mainCatId) => {
    try {
      if (mainCatId) {
        const response = await makePostRequest(ARTIST_SUB_CATEGORIES, mainCatId);
        return response;
      }
    } catch (error) {
      throw error;
    }
  }
  const subMutation = useMutation({
    mutationKey: ["fetchSubcategory"],
    mutationFn: subCategoryVideo,
    onSuccess: (data) => {
      setDataSources((prev) => ({ ...prev, subCategoryVideoSource: data?.data }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    }
  })
  useEffect(() => {
    if (
      values.main_category_id &&
      values.main_category_id !== "" &&
      values.main_main_category_idcatid !== null
    ) {
      const body = {
        maincatid: values.main_category_id,
      };
      subMutation.mutate(body);
    }
  }, [values.main_category_id]);
  //---------------------------------TagArtist---------------------------------------------------
  const TagArtist = useMutation({
    mutationKey: [],
    mutationFn: (tagArtistDetail) => TagArtistDetailFetch(tagArtistDetail),
    onSuccess: (data) => {
      console.log(data, "datafofjd")
      data && setVideoData((prev) => ({ ...prev, tagArtistDetail: data.data }))
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION)
    }
  })
  const tagBoxRef = useRef(null);
  const artistinput = (e) => {
    console.log(e, "event");
    const inputValue = e.component.option("text");
    console.log(inputValue, "inputvalue")

    if (inputValue.trim() !== "") {
      const body = {
        artistname: inputValue,
      };
      TagArtist.mutate(body);
    }
  };
  // ------------------------------------------TagOrganiser---------------------------------------------
  const TagOrganiser = useMutation({
    mutationKey: [],
    mutationFn: (tagOragniserDetail) => TagedOrganiserDetailFetch(tagOragniserDetail),
    onSuccess: (data) => {
      data && setVideoData((prev) => ({ ...prev, tagOrganiserDetail: data.data }))
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION)
    }
  })
  const organiserinput = (e) => {
    const inputValue = e.component.option("text");
    if (inputValue.trim() !== "") {
      const body = {
        organisername: inputValue,
      };
      TagOrganiser.mutate(body);
    }
  };
  console.log(videoData.tagOrganiserDetail, "thisidOURDA")
  // -------------------------------HanleFocousin------------------------------------------------
  const handleFocusIn = (e) => {
    setSettings((prev) => ({ ...prev, hasFocusedIn: true }));
    handleBlur({ target: { name: "event_title", value: e.value } });
  };
  // ----------------------------------handleImageChange------------------------------------------------------------------------------
  const handleFileChange = async (e) => {
    try {
      const file = e.value?.[0];
      if (!file) {
        console.log("No file selected");
        return;
      }
  
      const initialCrop = {
        x: 0,
        y: 0,
        width: 320,
        height: 180,
        unit: "px",
      };
  
      const VideoImage = await openEditor({
        src: URL.createObjectURL(file),
        cropOptions: {
          aspectRatio: 1,
          maxWidth: 320,
          maxHeight: 180,
          minWidth: 320,
          minHeight: 180,
          center: true,
        },
        initCrop: initialCrop,
      });
  
      if (!VideoImage || typeof VideoImage.editedImage.getBlob !== "function") {
        console.log("No new image");
        return;
      }
  
      const blob = await VideoImage.editedImage.getBlob();
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        setFieldValue("thumbnail", base64String);
        setImageBase64(base64String);
        setImageName(file.name);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error while processing image:", error);
    }
  };

  // ------------------------------------------------------DescriptionHandle--------------------------------------------------------------------
  const handleEditorValueChanged = (e) => {
    handleChange({ target: { name: "description", value: e.value } });
    handleBlur({ target: { name: "description", value: e.value } });
  }
  //--------------------------------------------------------HandleUplodeVideos------------------------------------------------------------------

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  const handleVideoUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('video', file);
  
      try {
        setIsUploading(true); // Disable submit button when upload starts
        // simulateUploadProgress(); ; // Simulate upload progress
  
        const response = await fetch(MDnDUploadRecordsCDN, {
          method: "POST",
          body: formData,
        });
  
        if (!response.ok) {
          throw new Error('Failed to upload video');
        }else{
          notify('Submitted succesfully - your video will be ready for viewing in 1 hour',SUCCESS,DURATION)
        }
  
        const data = await response.json(); // Assuming server returns JSON with CDN link
        const cdnLink = data.path; // Adjust based on actual response structure
  
        setUploadedVideo({ file, name: file.name });
        setFieldValue('video_url', cdnLink);
        setUploadedVideoURL(cdnLink);
  
      } catch (error) {
        console.error('Error uploading video:', error);
        notify('Failed to upload video', ERROR, DURATION);
      } finally {
        setIsUploading(false); // Enable submit button after upload
      }
    }
  };
 
  // --------------------------------------------------------------------------------------------------------------------------------------
  const handleCancelUpload = () => {
    setUploadedVideo(null);
    setUploadProgress(null);
    setUploadedVideoURL("");
    fileInputRef.current.value = null;  // Clear the file input value
  };
  // ------------------------------------------------ARTISTNAME-------------------------------------------------------------

  const handleCustomItemCreating = (args) => {
    console.log(args, "thisisargs");
  
    // Ensure the input is trimmed
    const inputValue = args.text.trim();
  
    // If the input contains a comma, split and add as tags
    if (inputValue.includes(',')) {
      // Split the input text by commas, filter out empty tags, and trim spaces
      const newTags = inputValue.split(',').map(tag => tag.trim()).filter(tag => tag !== '');
      const updatedTags = [...tags, ...newTags];
      console.log(updatedTags, "UpdatedTags");
      setTags(updatedTags);
      setFieldValue('artist_names', updatedTags);
    } else {
      // If no comma, just add the tag
      if (inputValue) {
        const updatedTags = [...tags, inputValue];
        console.log(updatedTags, "UpdatedTags");
        setTags(updatedTags);
        setFieldValue('artist_names', updatedTags);
      }
    }
  
    // Clear the input field
    args.customItem = '';
  };
  
  const handleTagRemove = (tagToRemove) => {
    // Filter out the tag to be removed
    const updatedTags = tags.filter(tag => tag !== tagToRemove);
    setTags(updatedTags);
    setFieldValue('artist_names', updatedTags);
  };
  //-----------------------------------------------------------------------------------------------------------------------------------------------
  
  const handleOrgItemCreating = (orgargs) => {
    // Trim the input text to remove any leading or trailing whitespace
    const inputValue = orgargs.text.trim();
    
    // Check if the input contains commas
    if (inputValue.includes(',')) {
      // Split the input by commas, trim each tag, and filter out empty tags
      const newTags = inputValue.split(',').map(tag => tag.trim()).filter(tag => tag !== '');
      setOrganiserTag(prevTags => {
        const updatedTags = [...prevTags, ...newTags];
        console.log(updatedTags, "Updated Organiser Tags");
        setFieldValue('organiser_names', updatedTags);
        return updatedTags;
      });
    } else {
      // Handle single input case
      if (inputValue) {
        setOrganiserTag(prevTags => {
          const updatedTags = [...prevTags, inputValue];
          console.log(updatedTags, "Updated Organiser Tags");
          setFieldValue('organiser_names', updatedTags);
          return updatedTags;
        });
      }
    }
    
    // Clear the input field
    orgargs.customItem = '';
  };
  
  const handleTagRemoveOrg = (tagToRemove) => {
    setOrganiserTag(prevTags => {
      const updatedTags = prevTags.filter(tag => tag !== tagToRemove);
      // Update the form field value to keep it in sync
      setFieldValue('organiser_names', updatedTags);
      return updatedTags;
    });
  };
  
  // Debugging output
  console.log(values.organiser_names, 'Updated organiser_names');
  
  // ----------------------------------------------------------------------------------------------------------------------------------------------
  return (
    <form onSubmit={handleSubmit}>
      <div id="createevent" className="tab-pane animated fadeInUp active show" role="tabpanel">
        <div className="description-content">
          <div className="row" >
            <div className=" -lg-6 mb-2">
              <TextBox
                type="text"
                label="Video Title"
                className="dx-field-item-required"
                labelMode="floating"
                name="video_title"
                value={values.video_title}
                onFocusOut={(e) =>
                  handleBlur({
                    target: { name: "video_title", value: e.value }
                  })
                }
                onValueChanged={(e) => handleChange({ target: { name: 'video_title', value: e.value } })}
              />
              {errors.video_title && touched.video_title && <div className="text-danger">{errors.video_title}</div>}
            </div>
            <div className="col-lg-6 mb-2">
              <SelectBox
                label="Main Category"
                labelMode="floating"
                onFocusIn={handleFocusIn}
                className="dx-field-item-required"
                dataSource={mainCategoryVideoSource?.data || null}
                displayExpr="main_cat_name"
                valueExpr="main_cat_id"
                value={values.main_category_id}
                onValueChanged={(e) => handleChange({ target: { name: 'main_category_id', value: e.value } })}
                onFocusOut={(e) => handleBlur({ target: { name: "main_category_id", value: e.value } })}
              />
              {errors.main_category_id && touched.main_category_id && <div className="text-danger">{errors.main_category_id}</div>}
            </div>
            <div className="col-lg-6 mb-2">
              <SelectBox label="Sub Category"
                labelMode="floating"
                dataSource={dataSources?.subCategoryVideoSource || null}
                displayExpr="sub_cat_name"
                valueExpr="sub_cat_id"
                value={values.sub_category_id}
                onValueChanged={(e) =>
                  handleChange({
                    target: { name: "sub_category_id", value: e.value },
                  })
                }
                onFocusOut={(e) =>
                  handleBlur({
                    target: { name: "sub_category_id", value: e.value },
                  })
                }
              />
              {errors.sub_category_id && touched.sub_category_id && <div className="text-danger">{errors.sub_category_id}</div>}
            </div>
            <div className="col-lg-6 mb-2">
              <TagBox
                onInput={(e) => artistinput(e)}
                ref={tagBoxRef}
                label="Tag Artists"
                type="text"
                labelMode="floating"
                dataSource={videoData.tagArtistDetail || null}
                displayExpr="artist_name"
                valueExpr="artistid"
                searchEnabled={true}
                value={values.artist_ids}
                onValueChanged={(e) => handleChange({ target: { name: 'artist_ids', value: e.value } })}
                onFocusOut={(e) => handleBlur({ target: { name: "artist_ids", value: e.value } })}
                itemRender={(item) => (
                  <TagItem name={item.artist_name} image={item.photo} />
                )}
                tagRender={(data) => (
                  <Tag name={data.artist_name} image={data.photo} />
                )}
              />
              {errors.artist_ids && touched.artist_ids && <div className="text-danger">{errors.artist_ids}</div>}
            </div>
            <div className="col-lg-6 mb-2">
              <TagBox
                onInput={organiserinput}
                labelMode='floating'
                label="Tag Organiser"
                type="text"
                dataSource={videoData.tagOrganiserDetail || null}
                displayExpr="organiser_name"
                valueExpr="organiserid"
                searchEnabled={true}
                value={values.organiser_ids}
                onValueChanged={(e) => handleChange({ target: { name: 'organiser_ids', value: e.value } })}
                onFocusOut={(e) => handleBlur({ target: { name: "organiser_ids", value: e.value } })}
                itemRender={(item) => (
                  <TagItem name={item.organiser_name} image={item.photo} />
                )}
                tagRender={(data) => (
                  <Tag name={data.organiser_name} image={data.photo} />
                )}
              />
              {errors.organiser_ids && touched.organiser_ids && <div className="text-danger">{errors.organiser_ids}</div>}
            </div>
            <div className="col-lg-6 mb-2">
              <TagBox
                items={tags}
                value={tags}
                label="Artist Name"
                labelMode='floating'
                onValueChanged={(e) => setTags(e.value)}
                onItemRemoved={(e) => handleTagRemove(e.item)}
                showClearButton={true}
                multiline={true}
                acceptCustomValue={true}
                onCustomItemCreating={handleCustomItemCreating}
                noDataText="Type and put/press comma to add tags... and then press ENTER"
              />
            </div>
            <div className="col-lg-6 mb-2">
              <TagBox
                items={organiserTag}
                value={organiserTag}
                label="Organiser Name"
                labelMode="floating"
                onValueChanged={(e)=>setOrganiserTag(e.value)}
                onItemRemoved={(e) => handleTagRemoveOrg(e.item)}
                showClearButton={true}
                multiline={true}
                acceptCustomValue={true}
                onCustomItemCreating={handleOrgItemCreating}
                noDataText="Type and put/press comma to add tags... and then press ENTER"
              />
            </div>
            {/* --------------------------------------------discription------------------------------- */}
            <div className="col-lg-12">
              <div className="form-group mb-2">
                <label className="required-label">Discription <span className="text-danger">*</span></label>
                <div className="widget-container">
                  <HtmlEditor
                    height="auto"
                    defaultValue={markup}
                    label="Video Description"
                    onValueChanged={handleEditorValueChanged}
                  >
                    <MediaResizing enabled={true} />
                    <ImageUpload
                      tabs={currentTab}
                      fileUploadMode="base64"
                    />
                    <Toolbar multiline={isMultiline}>
                      <Item name="undo" />
                      <Item name="redo" />
                      <Item name="separator" />
                      <Item
                        name="size"
                        acceptedValues={sizeValues}
                        options={fontSizeOptions}
                      />
                      <Item
                        name="font"
                        acceptedValues={fontValues}
                        options={fontFamilyOptions}
                      />
                      <Item name="separator" />
                      <Item name="bold" />
                      <Item name="italic" />
                      <Item name="strike" />
                      <Item name="underline" />
                      <Item name="separator" />
                      <Item name="alignLeft" />
                      <Item name="alignCenter" />
                      <Item name="alignRight" />
                      <Item name="alignJustify" />
                      <Item name="separator" />
                      <Item name="orderedList" />
                      <Item name="bulletList" />
                      <Item name="separator" />
                      <Item
                        name="header"
                        acceptedValues={headerValues}
                        options={headerOptions}
                      />
                      <Item name="separator" />
                      <Item name="color" />
                      <Item name="background" />
                      <Item name="separator" />
                      <Item name="link" />
                      <Item name="image" />
                      <Item name="separator" />
                      <Item name="clear" />
                      <Item name="codeBlock" />
                      <Item name="blockquote" />
                      <Item name="separator" />
                      <Item name="insertTable" />
                      <Item name="deleteTable" />
                      <Item name="insertRowAbove" />
                      <Item name="insertRowBelow" />
                      <Item name="deleteRow" />
                      <Item name="insertColumnLeft" />
                      <Item name="insertColumnRight" />
                      <Item name="deleteColumn" />
                    </Toolbar>
                  </HtmlEditor>
                  {errors.description && touched.description && <div className="text-danger">{errors.description}</div>}
                </div>
              </div>
            </div>
            {/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
            <div className="col-lg-5 mb-2">
              <label>Thumbnail Photo</label>
              <div className="fileuploader-container">
                <FileUploader
                  selectButtonText="Select photo"
                  labelText=""
                  accept="image/*"
                  uploadMode="useForm"
                  onValueChanged={handleFileChange}
                />
                {imageName && <p>Selected file: {imageName}</p>}
                {imageBase64 && (
                  <div>
                    <img src={imageBase64} alt="Uploaded" style={{ width: '50%', height: 'auto' }} />
                  </div>
                )}
              </div>
              {errors.thumbnail && touched.thumbnail && <div className="text-danger">{errors.thumbnail}</div>}
            </div>
            {/* ------------------------------------------------------------------VideoPart------------------------------------------------------------------------------------------------- */}
            <div className="col-lg-7 mx-auto">
              <div className="wrappers">
                <header className="d-flex justify-content-between align-items-center">
                  <span>Upload Video</span>

                  <FaRegWindowClose onClick={handleCancelUpload} />
                </header>
                  <div
                    className="upload-area"
                    onClick={handleUploadClick}
                    style={{ cursor: 'pointer', textAlign: 'center' }}
                  >
                    <form action="#">
                      <i className="fas fa-cloud-upload-alt"></i>
                      <p className="mx-auto text-center">Drag and drop or browse videos to upload</p>
                      <input
                        className="file-input"
                        type="file"
                        name="file"
                        accept="video/*"
                        ref={fileInputRef}
                        onChange={handleVideoUpload}
                        hidden
                      />
                    </form>

                  </div>
              <p>Video Name: {uploadedVideo?.name}</p>
                {errors.video_url && touched.video_url && <div className="text-danger">{errors.video_url}</div>}
              </div>
            </div>

            <div className="d-flex justify-content-end mt-4 iq-button">
              <Button
                type="submit"
                text={isUploading?"Uploading..." : "Submit"}
                disabled={isUploading} 
                className="btn position-relative h-auto"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default UploadVideos