import React, { useState, useEffect, useContext } from "react";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Lookup,
} from "devextreme-react/data-grid";
import { HcType, SeatingsContent } from "../../../global/constants";
import { ValidationRule } from "devextreme-react/data-grid";
import {
  freewithregistration,
  EventModeOffline,
  ticketedonmdnd,
  EventModeOnline,
  choiceGST,
} from "../../../global/constants";
import { getGstConfig } from "../../../Api/api";
import { makeGetRequest } from "../../../Api/requestMethods";
import { useQuery } from "@tanstack/react-query";
import notify from "devextreme/ui/notify";
import { ERROR, DURATION } from "../../../global/ToastMsgConstants.tsx";
import { GlobalContext } from "../../../global/provider.js";

const TicketDetailsGrid = ({ dataSource, values, setDataSource }) => {
  const { eventsDatasource } = useContext(GlobalContext);
  const [columnsConfig, setColumnsConfig] = useState({
    inrVisible: false,
    usdVisible: true,
    hcChargesVisible: false,
    gstTaxVisible: false,
    hcTypeVisible: false,
    rowClassVisible: false,
    ticketQtyVisible: false,
    handlingChargeVisible: false,
    inrValidation: [],
    usdValidation: [],
  });

  // GST config
  const gstConfigEvent = async () => await makeGetRequest(getGstConfig);
  const { data: gstConfig } = useQuery({
    queryKey: ["__gstConfig__"],
    queryFn: () => gstConfigEvent(),
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });
  const hcTypeValidation = (data) => {
    if (data.hc_type === "percentage") {
      if (data.hc_charges < 0 || data.hc_charges > 100) {
        return false;
      }
    }
    return true;
  };

  console.log(dataSource, "dataSource");

  useEffect(() => {
    // Ticketed on MDND - Online
    if (values.ticketing_option !== freewithregistration) {
      // Amount INR and Amount USD has been removed. hence i haven't remove unwanted code completely - Due to lack of time
      if (values?.country_id === 101) {
        setColumnsConfig({
          gstTaxVisible: true,
          handlingChargeVisible: true,
          hcChargesVisible: true,
          inrVisible: true,
          hcTypeVisible: true,
          usdVisible: false,
          inrValidation: [
            { type: "required", message: "This field is required" },
          ],
          usdValidation: [],
        });
      }
      if (values?.country_id !== 101) {
        setColumnsConfig({
          gstTaxVisible: true,
          handlingChargeVisible: true,
          hcChargesVisible: true,
          inrVisible: false,
          usdVisible: true,
          hcTypeVisible: true,
          inrValidation: [],
          usdValidation: [
            { type: "required", message: "This field is required" },
          ],
        });
      }

      if (
        values.ticketing_option === ticketedonmdnd &&
        values.event_mode === EventModeOffline
      ) {
        setColumnsConfig((prev) => ({
          ...prev,
          rowClassVisible: true,
          ticketQtyVisible: true,
        }));
      }

      if (
        values.ticketing_option === ticketedonmdnd &&
        values.event_mode === EventModeOnline
      ) {
        setColumnsConfig((prev) => ({
          ...prev,
          rowClassVisible: false,
          ticketQtyVisible: false,
        }));
      }
    }
    // free with registration
    if (values.ticketing_option === freewithregistration) {
      setColumnsConfig((prev) => ({
        ...prev,
        inrVisible: false,
        usdVisible: false,
        gstTaxVisible: false,
        handlingChargeVisible: false,
        hcChargesVisible: false,
        hcTypeVisible: false,
        rowClassVisible: true,
        ticketQtyVisible: true,
      }));
    }
    if (values?.country_id) {
      setDataSource((prev) => ({ ...prev, seating_nonNumbered: [] }));
    }
  }, [values?.country_id, values.ticketing_option, values.event_mode]);

  const handleRowInserting = (e) => {
    if (
      (values.ticketing_option === freewithregistration &&
        dataSource.length >= 1) ||
      (values.ticketing_option === ticketedonmdnd &&
        values.event_mode === EventModeOnline &&
        dataSource.length >= 1)
    ) {
      e.cancel = true;
      notify(
        "Only one row is allowed for free with registration and Ticketed on MDnD - online.",
        ERROR,
        DURATION
      );
    }
  };

  const handleRowRemoved = () => {
    if (
      values.ticketing_option === freewithregistration ||
      (values.ticketing_option === ticketedonmdnd &&
        values.event_mode === EventModeOnline &&
        dataSource.length < 1)
    ) {
      setColumnsConfig((prevConfig) => ({ ...prevConfig }));
    }
  };

  const onRowDataInserted = () => {
    setColumnsConfig((prevConfig) => ({ ...prevConfig }));
  };

  const gstData = (gstConfig && gstConfig.data && gstConfig?.data[0]) || null;

  const handleInitNewRow = (e) => {
    e.data = {
      total_quantity: 1,
      amount: 1,
      ticket_limitations: 1,
      gst_tax: 0,
      hc_gst_tax: 0,
      hc_type: "Nil",
      hc_charges: 0,
    };
  };

  useEffect(() => {
    if (values.event_mode) {
      // setDataSource((prev) => ({ ...prev, seating_nonNumbered: [] }));
      // setDataSource((prev) => ({ ...prev, seating_nonNumbered: [] }));
    }
  }, [values.event_mode]);

  return (
    <>
      <h5 className="col-8 Transactiontext">{"Ticket Configuration"}</h5>
      <DataGrid
        dataSource={dataSource || eventsDatasource.ticketingDetails || []}
        showBorders={true}
        columnAutoWidth={true}
        rowAlternationEnabled={true}
        onRowInserting={handleRowInserting}
        onRowRemoved={handleRowRemoved}
        onSaved={onRowDataInserted}
        onInitNewRow={handleInitNewRow}
        className="dataGridWidth"
      >
        <Paging enabled={true} />
        <Editing
          mode="row"
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={
            values.ticketing_option === freewithregistration &&
              dataSource.length >= 1
              ? false
              : true
          }
          newRowPosition={"last"}
        />
        <Column
          type="buttons"
          width={120}
          fixed={true}
          fixedPosition="right"
          buttons={["edit", "delete"]}
          caption={["Edit", "Delete"]}
        />

        {columnsConfig.rowClassVisible && (
          <Column
            width={110}
            dataField="row_name"
            caption="Row / Class"
            visible={columnsConfig.rowClassVisible}
            validationRules={[
              { type: "required", message: "This field is required" },
            ]}
          />
        )}

        {columnsConfig.ticketQtyVisible && (
          <Column
            dataField="total_quantity"
            caption="Ticket Qty"
            editorType="dxNumberBox"
            visible={columnsConfig.ticketQtyVisible}
            editorOptions={{ min: 1 }}
            validationRules={[
              { type: "required", message: "This field is required" },
            ]}
            setCellValue={(newData, value, currentRowData) => {
              newData.total_quantity = Math.max(1, value);
            }}
            width={120}
          />
        )}

        {values.ticketing_option !== freewithregistration && (
          <Column
            dataField="amount"
            width={130}
            caption={`Denomination ${values?.country_id && values?.country_id !== ""
              ? values?.country_id === 101
                ? "₹"
                : "$"
              : ""
              }`}
            editorType="dxNumberBox"
            editorOptions={{
              min: 1,
            }}
            setCellValue={(newData, value, currentRowData) => {
              if (value === null || value === "") {
                newData.amount = 1;
              } else {
                newData.amount = value;
              }
            }}
            validationRules={[
              { type: "required", message: "This field is required" },
            ]}
            visible={values.ticketing_option !== freewithregistration}
          />
        )}

        <Column
          dataField="ticket_limitations"
          caption="Ticket limitations"
          editorType="dxNumberBox"
          width={150}
          setCellValue={(newData, value, currentRowData) => {
            newData.ticket_limitations = Math.max(1, value);
          }}
          validationRules={[
            { type: "required", message: "This field is required" },
          ]}
        />

        {columnsConfig.hcTypeVisible && (
          <Column
            dataField="hc_type"
            caption="Handling charge(HC) Type"
            width={220}
            visible={columnsConfig.hcTypeVisible}
            setCellValue={(newData, value, currentRowData) => {
              newData.hc_type = value;
              newData.hc_charges = 0;
            }}
          >
            <Lookup dataSource={HcType} displayExpr="type" valueExpr="value" />
          </Column>
        )}

        {columnsConfig.hcChargesVisible && (
          <Column
            dataField="hc_charges"
            caption="HC Value(% / amount)"
            width={200}
            editorType="dxNumberBox"
            visible={columnsConfig.hcChargesVisible}
            setCellValue={(newData, value, currentRowData) => {
              newData.hc_charges = value;
              if (currentRowData.hc_type === "percentage") {
                newData.hc_charges = Math.max(1, Math.min(100, value));
              }
              if (currentRowData.hc_type === "Nil") {
                newData.hc_charges = 0;
              }
              if (value === null || value === "") {
                newData.hc_charges = Math.max(0, value);
              }
            }}
          >
            <ValidationRule
              type="custom"
              validationCallback={(e) => hcTypeValidation(e.data)}
              message="For '% Per Ticket', the value should be between 1 and 100."
            />
          </Column>
        )}

        {/* <Column
          dataField="ticket_limitations"
          caption="Ticket limitations"
          width={200}
          editorType="dxNumberBox"
          // editorOptions={{
          //   min: 1,
          //   onValueChanged: (e) => {
          //     if (e.value === null || e.value === "") {
          //       e.component.option("value", 1);
          //     }
          //   },
          // }}
          setCellValue={(newData, value, currentRowData) => {
            newData.ticket_limitations = Math.max(1, value);
          }}
          validationRules={[
            { type: "required", message: "This field is required" },
          ]}
        /> */}
        {columnsConfig.gstTaxVisible && (
          <Column
            dataField="gst_tax"
            caption="GST (%)"
            width={100}
            visible={columnsConfig.gstTaxVisible}
            editorType="dxSelectBox"
            defaultValue={0}
            editorOptions={{
              dataSource: [
                { id: 0, text: "0%" },
                {
                  id: gstData?.gst_max || choiceGST,
                  text: `${gstData?.gst_max || choiceGST}%`,
                },
              ],
              valueExpr: "id",
              displayExpr: "text",
              searchEnabled: false,
            }}
          />
        )}

        {columnsConfig.handlingChargeVisible && (
          <Column
            dataField="hc_gst_tax"
            caption="HC GST ( % )"
            width={140}
            visible={columnsConfig.handlingChargeVisible}
            editorType="dxNumberBox"
            editorOptions={{ min: 0, max: 100 }}
            setCellValue={(newData, value, currentRowData) => {
              newData.hc_gst_tax = value;
              if (currentRowData.hc_gst_tax) {
                newData.hc_gst_tax = Math.max(1, Math.min(100, value));
              }
              if (value === null || value === "") {
                newData.hc_gst_tax = Math.max(0, value);
              }
            }}
          ></Column>
        )}
      </DataGrid>
    </>
  );
};

export default TicketDetailsGrid;
