import "./RagaBank.css"; // Assuming you have some custom CSS for styling

import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";

const HLSVideoPlayer = ({ videoUrl }) => {
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(true);
  const [volume, setVolume] = useState(1.0);
  const [playbackRate, setPlaybackRate] = useState(1.0);
  const [currentUrl, setCurrentUrl] = useState(null); // Set initial state to null
  const [controlsVisible, setControlsVisible] = useState(false);
  const [qualityVisible, setQualityVisible] = useState(false);
  const [qualityOptions, setQualityOptions] = useState([]);

  // Rewind video by 10 seconds
  const rewind = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(Math.max(currentTime - 10, 0));
    }
  };

  // Forward video by 10 seconds
  const forward = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(currentTime + 10);
    }
  };

  // Fetch and parse quality options from manifest file
  const fetchQualityOptions = async (manifestUrl) => {
    try {
      const response = await fetch(manifestUrl);
      const manifestText = await response.text();
      const lines = manifestText.split("\n");
      const options = [];

      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        if (line.startsWith("#EXT-X-STREAM-INF")) {
          const resolutionMatch = line.match(/RESOLUTION=\d+x(\d+)/);
          const resolution = resolutionMatch ? resolutionMatch[1] : "Unknown";
          const streamUrl = lines[i + 1];
          options.push({
            resolution: resolution,
            url: new URL(streamUrl, manifestUrl).href,
          });
        }
      }

      setQualityOptions(options);
    } catch (error) {
      console.error("Error fetching or parsing the manifest file:", error);
    }
  };

  useEffect(() => {
    if (videoUrl) {
      setCurrentUrl(videoUrl); // Update currentUrl when videoUrl changes
      fetchQualityOptions(videoUrl);
    }
  }, [videoUrl]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.seekTo(0); // Start from the beginning
    }
  }, [currentUrl]);

  // Handle quality change
  const changeQuality = (url) => {
    setCurrentUrl(url);
    setPlaying(true); // Resume playing the video after changing quality
  };

  return (
    <div
      className="player-wrapper"
      onMouseEnter={() => setControlsVisible(true)}
      onMouseLeave={() => setControlsVisible(false)}
    >
      {currentUrl ? (
        <ReactPlayer
          ref={playerRef}
          className="react-player"
          url={currentUrl}
          playing={playing}
          controls={true}
          volume={volume}
          playbackRate={playbackRate}
          width="100%"
          height="100%"
          config={{
            file: {
              attributes: {
                controlsList: "nodownload noremoteplayback",
                disablePictureInPicture: true,
              },
            },
          }}
        />
      ) : (
        <div>Loading video...</div> // Display a loading message or spinner if videoUrl is not available
      )}
      <div
        className={`play-pause ${controlsVisible ? "visible" : ""}`}
        onClick={() => setPlaying(!playing)}
      >
        <i
          className="fa fa-step-backward m-0 p-0"
          aria-hidden="true"
          onClick={rewind}
        />

        <i
          className={`fa ${playing ? "fa-pause-circle" : "fa-play-circle"}`}
          aria-hidden="true"
        />
        <i
          className="fa fa-step-forward m-0 p-0"
          aria-hidden="true"
          onClick={forward}
        />
      </div>

      <div
        className={`quality-settings ${qualityVisible ? "visible" : ""}`}
        onClick={() => setQualityVisible(!qualityVisible)}
      >
        <i className="fa fa-cog" aria-hidden="true" />
        {qualityVisible && (
          <div className="quality-options">
            {qualityOptions.map((option) => (
              <div key={option.url} onClick={() => changeQuality(option.url)}>
                {option.resolution}p
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="next-song">
        <i className="fa fa-step-forward" aria-hidden="true" />
      </div>
    </div>
  );
};

export default HLSVideoPlayer;
