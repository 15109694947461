import React from "react";

const DisplayHtmlElement = ({ data }) => {
  try {
    if (!data) {
      return null;
    }

    const htmlString = data
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
    const parseHtmlStringToReactElement = (htmlString) => {
      const container = document.createElement("div");
      container.innerHTML = htmlString;
      const children = Array.from(container.childNodes);

      const convertNodeToElement = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          return node.textContent;
        }

        if (node.nodeType === Node.ELEMENT_NODE) {
          const children = Array.from(node.childNodes).map(
            convertNodeToElement
          );
          return React.createElement(
            node.tagName.toLowerCase(),
            {},
            ...children
          );
        }

        return null;
      };

      return children.map(convertNodeToElement);
    };

    const elements = parseHtmlStringToReactElement(htmlString);
    return <>{elements}</>;
  } catch (error) {
    console.error(error);
  }
};

export default DisplayHtmlElement;
