import React from "react";

const Terms = () => {
  return (
    <div>
      <div className="iq-breadcrumb organBanner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb" className="text-center">
                <h2 className="title text-white">Terms & Conditions</h2>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="section-padding pt-lg-4">
          <p className="fs-6">
            If you continue to browse and use this website you are agreeing to
            comply with and be bound by the following terms and conditions of
            use, which together with our privacy policy govern this website’s
            relationship with you in relation to this website.The term ’’Music
            of Madras”, "Music of Bangalore", "Music IN CA", "Music of Bombay",
            “www.musicofmadras.in” or “MDnD Entertainment Private Limited” or
            “MDnD” or ‘us’ or ‘we’ refers to the owner of the website MDnD
            Entertainment Private Limited having its administrative office at
            31/14, Krishnaswamy Avenue, Luz, Mylapore, Chennai 600004, India. The term
            ‘you’ refers to the user or viewer of our website.
          </p>
          <p className="fs-6">
            The use of this website is subject to the following terms of use:
          </p>
          <ol>
            <li className="mb-1">
              The content of the pages of this website is for your general
              information and use only. It is subject to change without notice.
            </li>
            <li className="mb-1">Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
            <li className="mb-1">Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</li>
            <li className="mb-1">This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, information, videos, audio and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
            <li className="mb-1">Trademarks, if any, reproduced in this website which are not our property are hereby acknowledged as belonging to the respective owners of the trademarks.</li>
            <li className="mb-1">Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence.</li>
            <li className="mb-1">From time to time this website may also include links to and from other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</li>
            <li className="mb-1">Your use of this website and any dispute arising out of such use of the website is subject to the laws of India and subject to Chennai jurisdiction.</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Terms;
