// ShareModal.js
import React from 'react';
import { Modal } from 'react-bootstrap';

const ShareModalRaga = ({ show, handleClose, currentUrl, handleCopy, inputRef, mlogo }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="p-2 border-bottom-0">
        <Modal.Title>
          <h5>Share</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-2 pb-4">
        <div className="d-flex gap-3">
          <div className="share-icon">
            <img src={mlogo} alt="mdnd" />
          </div>

          <div className="share-icon whatsapp">
            <i className="fa-brands fa-whatsapp" aria-hidden="true"></i>
          </div>

          <div className="share-icon facebook">
            <i className="fa-brands fa-facebook" aria-hidden="true"></i>
          </div>

          <div className="share-icon twitter">
            <i className="fa-brands fa-x-twitter"></i>
          </div>
        </div>

        <div className="copy-link-container">
          <div className="copy-header"></div>
          <div className="copy-link">
            <div className="copy-link-inner">
              <form onSubmit={handleCopy}>
                <input
                  type="text"
                  value={currentUrl}
                  ref={inputRef}
                  onClick={() => inputRef.current.select()}
                  readOnly
                />
                <input type="submit" value="Copy" />
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModalRaga;
