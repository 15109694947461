import React, { useEffect } from "react";
import { useState, useCallback, useRef } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import IMAGE1 from "../assets/images/genre/02.webp";
import { IoShareSocial } from "react-icons/io5";
import {
  ALL_ARTIST_DETAIL,
  COMPOSERS_ITEM,
  GET_PERTICULAR_RAGABANK,
  GET_PROFILE_FOR_RECORD,
  GET_RAGABANK_DELETE_COMMENT,
  GET_THUMNAIL_FOR_RAGA,
  INSERT_COMMENT_FOR_RAGA,
  LANGUAGE_ITEMS,
  POST_VOTE_RAGA_DETAIL,
  RAGA_ITEMS,
  UPDATE_VIDEOS_VIEW,
  V_TYPE_ITEMS,
} from "../Api/api";
import {
  makeDeleteRequest,
  makeGetRequest,
  makePostRequest,
} from "../Api/requestMethods";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import VideoPath from "./HLSVideoRagaPlayer.js";
import {
  parseISO,
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import { DURATION, ERROR } from "../global/ToastMsgConstants.tsx";
import notify from "devextreme/ui/notify";
import { BsFillSendFill } from "react-icons/bs";
import { jwtDecode } from "jwt-decode";
import ShareModalRaga from "./ShareModelRaga.js";
import mlogo from "../MDnDRecords/Static/mlogo.png";
import { FaFilter } from "react-icons/fa";
import SelectBox from "devextreme-react/select-box";
import mdndimage from "./RagaImage/mdnd.png";
import { RAGABANK_IMAGEPATH } from "../config/config.js";
import { FaRegComment } from "react-icons/fa6";

const dataSource = [
  { value: "value1", text: "Text 1" },
  { value: "value2", text: "Text 2" },
  { value: "value3", text: "Text 3" },
];

function OffCanvasExample({ name, show, handleClose, ...props }) {
  return (
    <>
      <Offcanvas
        className="commentMobileHeight"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Comments</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="list-inline m-0 p-0">
            <li className="pb-3 mb-3">
              <div className="d-flex flex-sm-row align-items-sm-center align-items-start gap-2">
                <div className="flex-shrink-0 videoCommentImg">
                  <img src={IMAGE1} alt="user-image" className="img-fluid" />
                </div>
                <div className="about-user">
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <h5 className="mb-0">Jack Stark</h5>
                    <span className="text-uppercase fst-italic fw-semibold published-date">
                      <i className="fas fa-minus fa-xs"></i> march 2, 2022
                    </span>
                  </div>
                  <p className="mt-2 mb-0">
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form.
                  </p>
                </div>
              </div>
            </li>
            <li className="mb-3">
              <div className="d-flex flex-sm-row align-items-sm-center align-items-start gap-2">
                <div className="flex-shrink-0 videoCommentImg">
                  <img src={IMAGE1} alt="user-image" className="img-fluid" />
                </div>
                <div className="about-user">
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <h5 className="mb-0">Jhon Deo</h5>
                    <span className="text-uppercase fst-italic fw-semibold published-date">
                      <i className="fas fa-minus fa-xs"></i> march 2, 2022
                    </span>
                  </div>
                  <p className="mt-2 mb-0">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                </div>
              </div>
            </li>
          </ul>
          <div className="d-flex align-items-center mt-2 mobileCommFixed">
            <div className="videoCommentImg">
              <img src={IMAGE1}></img>
            </div>
            <div className="ms-2 w-100">
              <textarea
                className="w-100 textareaBorder"
                placeholder="Add a comment..."
              />
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function VideoStore() {
  const textareaRef = useRef(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [videoDetail, setVideoDetail] = useState({});
  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = () => setShowOffcanvas(true);

  const [currentOffset, setCurrentOffset] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const limit = 20;
  const [mdndData, setMdndData] = useState([]);
  const [ragaBankComment, setRagaBankComments] = useState([]);
  const [profileImages, setProfileImages] = useState({});
  const [value, setValue] = useState("");
  const [likeStatus, setLikeStatus] = useState(0);
  const [likeNumber, setlikeNumber] = useState(0);
  const [dislikeStatus, setDislikeStatus] = useState(0);
  const [count, setCount] = useState(0);
  const ragaslug = useParams();
  const navigate = useNavigate();
  const ragaobject = JSON.parse(atob(ragaslug.slug));
  const currentUrl = window.location.href;

  console.log(ragaobject, "ragaObject");

  const videoid = ragaobject.video_id;
  const roleid = ragaobject.role_id;
  console.log(roleid, "this is");
  //------------------------------------------------FocousFilter------------------------------------------------------
  const [settings, setSetting] = useState({
    hasRagaFocousIn: true,
    hasThalaFocousIn: false,
    hasVtypeIdFocousIn: false,
    hasLanguageFocousIn: false,
    hasComposerFocousIn: false,
  });
  // -------------------------------------------FilterSideVideos------------------------------------------------------
  const [filters, setFilters] = useState({
    ragaId: null,
    thalaId: null,
    vtypeId: null,
    languageId: null,
    composerId: null,
  });
  // --------------------------------------------------JWT-------------------------------------------
  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );
  console.log(user, "USER from jwt");

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  // ------------------------GetPerticulrVideo---------------------------------------------------

  const fetchPerticulatRagaVideo = async (videoid, roleid) => {
    const endpoint = `${GET_PERTICULAR_RAGABANK()}/${videoid}/${roleid}`;
    return await makeGetRequest(endpoint);
  };

  const { data: PerticularDisplay, refetch } = useQuery({
    queryKey: ["RagaVideo", videoid, roleid],
    queryFn: () => fetchPerticulatRagaVideo(videoid, roleid),
    onError: (error) => {
      console.log(error);
    },
  });
  useEffect(() => {
    if (videoid) {
      refetch();
    }
  }, [videoid, count]);

  useEffect(() => {
    setVideoDetail(PerticularDisplay?.data);
  }, [PerticularDisplay]);

  console.log(videoDetail, "videoDetail");
  // ------------------------------------------------------------------------------------------------------
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const inputRef = useRef(null);

  const handleCopy = (e) => {
    e.preventDefault();
    inputRef.current.select();
    document.execCommand("copy");
  };

  // -------------------------------------------SideVideoUrl------------------------------------------------

  const fetchingRagaVideos = async (limit, offset, filters) => {
    const { ragaId, thalaId, vtypeId, languageId, composerId } = filters;
    const data = { limit, offset };

    if (ragaId !== null) data.raga_id = ragaId;
    if (thalaId !== null) data.thala_id = thalaId;
    if (vtypeId !== null) data.vtype_id = vtypeId;
    if (languageId !== null) data.language_id = languageId;
    if (composerId !== null) data.composer_id = composerId;

    const jsonString = JSON.stringify(data);
    const base64String = btoa(jsonString);
    const GET_MDND_RAGABANK_RECORD = `videos/raga_bank/filter/${base64String}`;

    try {
      const response = await makeGetRequest(GET_MDND_RAGABANK_RECORD);
      const records = response.data;
      const ragaWithThumbnails = await Promise.all(
        records.map(async (record) => {
          const thumbnailResponse = await makeGetRequest(
            `${GET_THUMNAIL_FOR_RAGA()}/${record?.id}`
          );
          return {
            ...record,
            thumbnail: thumbnailResponse?.data?.thumbnail,
          };
        })
      );
      return ragaWithThumbnails;
    } catch (error) {
      throw new Error(`Error fetching videos: ${error.message}`);
    }
  };

  const loadMoreVideos = useCallback(
    async (resetOffset = false) => {
      if (isFetching) return;

      setIsFetching(true);
      try {
        const offset = resetOffset ? 0 : currentOffset;
        const newRecords = await fetchingRagaVideos(limit, offset, filters);
        setMdndData((prevData) =>
          resetOffset ? newRecords : [...prevData, ...newRecords]
        );
        setCurrentOffset((prevOffset) => offset + limit);
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsFetching(false);
      }
    },
    [currentOffset, isFetching, filters]
  );

  useEffect(() => {
    loadMoreVideos(true);
  }, [filters]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 100 >=
          document.documentElement.scrollHeight &&
        !isFetching
      ) {
        loadMoreVideos();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loadMoreVideos, isFetching]);

  // -------------------------------------------HandleSideVideo------------------------------------------------------------
  const handleSideVideo = (e, sidedata) => {
    console.log(sidedata, "thissidedata");
    const json_string = JSON.stringify({
      video_id: sidedata.id,
      role_id: user.role_id,
    });
    const slug = btoa(json_string);
    navigate(`/RagaBankVideo/videos/${slug}`);
  };
  // -----------------------------------------------FormatTime-------------------------------------------------------------
  const formatTime = (dateString) => {
    if (!dateString) return "Invalid date";

    const date = parseISO(dateString);
    const now = new Date();

    const seconds = differenceInSeconds(now, date);
    const minutes = differenceInMinutes(now, date);
    const hours = differenceInHours(now, date);
    const days = differenceInDays(now, date);
    const weeks = differenceInWeeks(now, date);
    const months = differenceInMonths(now, date);
    const years = differenceInYears(now, date);

    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else if (weeks < 4) {
      return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
    } else if (months < 12) {
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    } else {
      return `${years} year${years !== 1 ? "s" : ""} ago`;
    }
  };
  // ---------------------------------------------CommentFetching-----------------------------------------------------------
  const GET_RAGA_COMMENT_DETAIL = `videos/raga_bank/${videoid}/comments/read_all`;

  const fetchComment = async () =>
    await makeGetRequest(GET_RAGA_COMMENT_DETAIL);
  const { data: CommentDataSource } = useQuery({
    queryKey: ["commentDetail"],
    quetyFn: () => fetchComment(),
    onError: () => {
      console.log("erroe Message");
    },
  });

  const fetchRagaComment = async () => {
    try {
      const response = await fetchComment();
      setRagaBankComments(response.data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };
  useEffect(() => {
    fetchRagaComment(CommentDataSource);
  }, [videoid]);

  console.log(ragaBankComment, "CommentDataSource");
  // ---------------------------------------------PerticularCommentIcon----------------------------------------------------------------------------------------------
  const fetchCommentProfile = async (roleid) => {
    const endPoint = `${GET_PROFILE_FOR_RECORD()}/${roleid}`;
    return await makeGetRequest(endPoint);
  };
  const { data: ProfileComment } = useQuery({
    queryKey: ["PerticularCommentProfile", roleid],
    queryFn: () => {
      return fetchCommentProfile(roleid);
    },
    onError: (error) => {
      console.log("Error occurred for roleid:", roleid);
      console.error(error);
    },
  });
  console.log(ProfileComment, "profileComment");

  // ---------------------------------------------GetloginBaseRagaCommentIcon-------------------------------------------------------------------
  const fetchingProfile = async (roleid) => {
    const endPoint = `${GET_PROFILE_FOR_RECORD()}/${roleid}`;
    return await makeGetRequest(endPoint);
  };

  const fetchAllProfileData = async (comments) => {
    const profileDataPromises = comments.map((comment) =>
      fetchingProfile(comment.role_id).then((data) => ({
        role_id: comment.role_id,
        profile_data: data.data,
      }))
    );
    const profileDataArray = await Promise.all(profileDataPromises);
    return profileDataArray.reduce((acc, { role_id, profile_data }) => {
      acc[role_id] = profile_data;
      return acc;
    }, {});
  };
  useEffect(() => {
    const fetchingProfile = async () => {
      if (ragaBankComment.length > 0) {
        try {
          const images = await fetchAllProfileData(ragaBankComment);
          setProfileImages(images);
        } catch (error) {
          console.error("Error fetching profile images:", error);
        }
      }
    };

    fetchingProfile();
  }, [ragaBankComment]);

  console.log(profileImages, "heloowProfile");
  // -------------------------------------------------DeleteRagaComment-------------------------------------------------------------------------
  const DeleteRagaComment = async (commentid) => {
    try {
      const response = await makeDeleteRequest(
        `${GET_RAGABANK_DELETE_COMMENT()}/${commentid}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  const deletCommetMutation = useMutation({
    mutationKey: ["deleteRagaCommet"],
    mutationFn: DeleteRagaComment,
    onSuccess: (data, commentid) => {
      const updatedComments = ragaBankComment.filter(
        (comment) => comment.id !== commentid
      );
      setRagaBankComments(updatedComments);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const handleDelete = async (commentid) => {
    console.log(commentid, "commentid");
    if (commentid.login_id === user.login_id) {
      const commentedid = commentid.id;
      try {
        await deletCommetMutation.mutateAsync(commentedid);
      } catch (error) {
        console.error("Error deleteting comment :", error);
      }
    }
  };
  // -----------------------------------------------INSERTING==COMMENT--------------------------------------------------------------------------------
  const InsertingCommentMessage = async (message) => {
    try {
      const endpoint = await makePostRequest(
        INSERT_COMMENT_FOR_RAGA(),
        message
      );
      return endpoint;
    } catch (error) {
      throw error;
    }
  };

  const InsertMessage = useMutation({
    mutationKey: ["insertRagaMessage"],
    mutationFn: (data) => InsertingCommentMessage(data),
    onSuccess: (data) => {
      console.log("succesfully got data", "data");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleRagaComment = () => {
    if (!user) {
      notify("Please register OR login to perform comment operation", Error);
    } else {
      const newComment = {
        video_id: videoid,
        login_id: user.login_id,
        role_id: user.role_id,
        message: value,
      };
      InsertMessage.mutate(newComment, {
        onSuccess: (data) => {
          setRagaBankComments([
            ...ragaBankComment,
            { ...newComment, id: data.id, created_at: data.created_at },
          ]);
          setValue("");
          fetchRagaComment();
        },
        onError: (error) => {
          console.log("Error posting comment:", error);
        },
      });
    }
  };
  //------------------------------------------------UpdatelikesAction--------------------------------------------------------------------------------
  const LikeorDislike = async (voteDetail) => {
    try {
      const response = await makePostRequest(
        POST_VOTE_RAGA_DETAIL(),
        voteDetail
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const AllRagaVoteMutation = useMutation({
    mutationKey: ["ragaVote"],
    mutationFn: (data) => LikeorDislike(data),
    onSuccess: (data) => {
      console.log("success fully liked");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleVote = (action, data1) => {
    console.log(action, "helooo;wlike");
    if (!user) {
      notify("Please register OR logIn  to perform this action.", ERROR);
    } else {
      const ambivalent = action === "like" ? 1 : 0;
      console.log(ambivalent, "ambivalent");
      const data = {
        video_id: videoid,
        login_id: user.login_id,
        role_id: user.role_id,
        ambivalent: ambivalent,
      };

      AllRagaVoteMutation.mutate(data, {
        onSuccess: () => {
          if (action === "like") {
            setLikeStatus(1);
            setCount(count + 1);
            setDislikeStatus("inactive");
          } else {
            setDislikeStatus(1);
            if (!likeNumber === 0) {
              setLikeStatus(1);
            } else {
              setlikeNumber(data1);
              setCount(count + 1);
            }

            setLikeStatus(null);
          }
        },
        onError: (error) => {
          console.error("Error posting vote:", error);
        },
      });
    }
  };

  console.log(mdndData, "mdndDataplease");
  //------------------------------ViewDetailInRagaBank---------------------------------------------------------------------
  // console.log(videoDetail,"videoDETAIL")

  // const viewChangesCode = async (data) => {
  //   try {
  //     const endPoint = await makePostRequest(UPDATE_VIDEOS_VIEW(), data);
  //     return endPoint;
  //   } catch (error) {
  //     throw new Error(`Failed to update view: ${error.message}`);
  //   }
  // };

  // const viewUpdateMutation = useMutation({
  //   mutationKey: ["viewDetail"],
  //   mutationFn: (viewData) => viewChangesCode(viewData),
  //   onSuccess: (data) => {
  //     if (data && data.status === 200) {
  //       console.log("View Changed successfully");
  //       console.log("Mutation Success Data:", data);
  //     }
  //   },
  //   onError: (error) => {
  //     console.error('Error updating view:', error);
  //   }
  // });

  // --------------------------------------------------------FilterRelateFunction----------------------------------------------------------------------------------------

  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  //Raga: ----->
  const RagaItemsApi = async () => await makeGetRequest(RAGA_ITEMS);

  const { data: RagaDataSource } = useQuery({
    queryKey: ["RagaItem"],
    queryFn: () => RagaItemsApi(),
    onError: () => {
      notify("No Raga Items", ERROR, DURATION);
    },
  });

  //Language:------->
  const LanguageApi = async () => await makeGetRequest(LANGUAGE_ITEMS);

  const { data: LanguageDataSource } = useQuery({
    queryKey: ["LanguageItem"],
    queryFn: () => LanguageApi(),
    onError: () => {
      notify("No Language Item");
    },
  });
  console.log(LanguageDataSource?.data, "LANGUAGEdata");

  //Vtype:---------->
  const VTypeApi = async () => await makeGetRequest(V_TYPE_ITEMS);
  const { data: VTypeDataSource } = useQuery({
    queryKey: ["VTypeItem"],
    queryFn: () => VTypeApi(),
    onError: () => {
      notify("No vType Item");
    },
  });

  //Composers:--------->
  const ComposersApi = async () => await makeGetRequest(COMPOSERS_ITEM);

  const { data: ComposerDataSource } = useQuery({
    queryKey: ["ComposersItem"],
    queryFn: () => ComposersApi(),
    onError: () => {
      notify("No Composer Item");
    },
  });
  //ArtistName:------------>
  const ArtistNamaApi = async () => await makeGetRequest(ALL_ARTIST_DETAIL());
  const { data: ArtistDataSource } = useQuery({
    queryKey: ["ArtisNameItem"],
    queryFn: () => ArtistNamaApi(),
    onError: () => {
      notify("No Artist Items");
    },
  });

  console.log(ArtistDataSource, "artinsfgsfdgdsgd");
  // --------------------------------------------------------FilterRelateFunction----------------------------------------------------------------------------------------

  return (
    <div className="videoOverflow">
      <div className="videoPageMargin section-padding pt-lg-3">
        <div className="container-fluid">
          <div className="ragabank-pg">
            <div className="ragabank-ls d-flex flex-column gap-2 pb-3">
              <div className="bg-white">
                <VideoPath videoUrl={videoDetail && videoDetail?.video_link} />
                <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center">
                  <div className="w-100 mt-3 d-flex align-items-center justify-content-between gap-3">
                    <h5 className="m-0 P-0">{videoDetail?.song_title}</h5>
                    {/* <div className="next-play-button">
                      <i className="fa fa-step-forward" aria-hidden="true" />{" "}
                      Next
                    </div> */}
                  </div>

                  <div className="search mb-3 d-none">
                    <input
                      type="text"
                      className="searchTerm"
                      placeholder="Search Videos..."
                      id="input_text"
                    />
                    <button type="submit" className="searchButton">
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-1">
                <div className="profile-section gap-2 align-items-center">
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <div className="d-flex align-items-center gap-2">
                      <div className="flex-shrink-0 videoCommentImg">
                        <img src={mdndimage} />
                      </div>
                      <h6 className="mb-0">
                        <b>{videoDetail?.raaga_name}</b>
                      </h6>
                    </div>

                    <button className="border-button py-0">Follow</button>
                  </div>
                  <div className="d-sm-none">
                    <div id="container">
                      <div id="menu-wrap">
                        <input type="checkbox" className="toggler" />
                        <div className="dots">
                          <div></div>
                        </div>
                        <div className="menu p-1">
                          <div>
                            <ul className="p-0">
                              <li>
                                {/* <MdAddToQueue /> */}

                                <a
                                  href="#"
                                  className="link ms-2 d-flex align-items-center gap-2"
                                >
                                  <i className="fa-regular fa-thumbs-up"></i>
                                  Like
                                </a>
                              </li>
                              <li>
                                {/* <MdAddToQueue /> */}

                                <a
                                  href="#"
                                  className="link ms-2 d-flex align-items-center gap-2"
                                >
                                  <i className="fa-regular fa-thumbs-down"></i>
                                  Dislike
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  className="link ms-2 d-flex align-items-center gap-2"
                                >
                                  <IoShareSocial />
                                  Share
                                </a>
                              </li>
                              <li>
                                {/* <CiFlag1 /> */}
                                <a
                                  href="#"
                                  className="link ms-2 d-flex align-items-center gap-2"
                                >
                                  <FaRegComment />
                                  Comments
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center gap-3 d-none d-sm-block">
                    <div className="d-flex gap-2 align-items-center">
                      <div className="like_dislike" id="like_dislike">
                        <div
                          className={`like ${
                            videoDetail?.like_status === 1 && user.login_id
                              ? "fa-solid"
                              : "fa-regular"
                          } touch_feedback`}
                          id="like"
                          onClick={() => handleVote("like")}
                          data-status={likeStatus}
                        >
                          <i
                            id="like__icon"
                            className={`fa-regular fa-thumbs-up ${
                              videoDetail?.like_status === 1 && user.login_id
                                ? "like__animation"
                                : ""
                            }`}
                          ></i>
                          <span className="Likenumber" id="like-number">
                            {videoDetail?.likes}
                          </span>
                        </div>
                        <span className="divider"></span>
                        <div
                          className={`dislike ${
                            videoDetail?.like_status === 0 && user.login_id
                              ? "fa-solid"
                              : "fa-regular"
                          } touch_feedback`}
                          id="dislike"
                          onClick={() => handleVote("dislike")}
                          data-status={dislikeStatus}
                        >
                          <i
                            id="dislike__icon"
                            className={`fa-regular fa-thumbs-down ${
                              videoDetail?.like_status === 0 && user.login_id
                                ? "dislike__animation"
                                : ""
                            }`}
                          ></i>
                        </div>
                      </div>
                      <button
                        className="button d-flex align-items-center"
                        style={{ borderRadius: "4px", height: "36px" }}
                        variant="primary"
                        onClick={handleShow}
                      >
                        <i aria-hidden="true">
                          {" "}
                          <IoShareSocial />
                        </i>
                        <span className="Likenumber">Share</span>
                      </button>
                      <ShareModalRaga
                        show={show}
                        handleClose={handleClose}
                        currentUrl={currentUrl}
                        handleCopy={handleCopy}
                        inputRef={inputRef}
                        mlogo={mlogo}
                      />
                    </div>

                    {/* <button className="videoShareBtn d-flex align-items-center"> */}
                    {/* <LuIndianRupee className="me-2" /> */}
                    {/* <span className="Likenumber">Gift</span> */}
                    {/* </button> */}
                    <button className="border-button d-sm-none">
                      Comments
                    </button>
                  </div>
                </div>

                {/* <div className="bg-light commentBgBorder p-2 mt-2">
                  <p>
                    <b>
                      {videoDisplayData?.views} views{" "}
                      {formatTime(videoDisplayData?.created_at)}
                    </b>
                  </p>
                  <div>
                    <ReadMore text={videoDisplayData?.description} />
                  </div>
                </div> */}

                <div className="product-review-list d-none d-sm-block">
                  <ul className="list-inline m-0 p-0">
                    <li className="pb-3 mb-0">
                      <div className="d-flex flex-column gap-1">
                        <div className="d-flex gap-2 align-items-center justify-content-between mt-2">
                          <div className="videoCommentImg">
                            <img
                              src={`data:image/png;base64,${ProfileComment?.data?.profile_image}`}
                            />
                          </div>
                          <div className="w-100">
                            <OffCanvasExample
                              name="bottom"
                              show={showOffcanvas}
                              handleClose={handleCloseOffcanvas}
                              placement="bottom"
                            />

                            <textarea
                              className="autosize"
                              ref={textareaRef}
                              value={value}
                              onChange={handleChange}
                              rows={1}
                              placeholder="Add Comments"
                            />
                          </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-end align-items-center">
                          <button className="border-button">Cancel</button>
                          <button className="button">Submit</button>
                        </div>
                      </div>
                    </li>
                    <ul className="list-inline m-0 p-0">
                      {ragaBankComment &&
                        ragaBankComment
                          .slice()
                          .reverse()
                          .map((comment) => {
                            const profile = profileImages[comment.role_id];
                            const profilephoto = profile?.profile_image;
                            return (
                              <li
                                key={comment.id}
                                className="pb-3 d-noneSmallDevice"
                              >
                                <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center align-items-start gap-2 w-100">
                                  <div className="d-flex align-items-center gap-2">
                                    <div className="flex-shrink-0 videoCommentImg overflow-hidden d-flex align-items-center justify-content-center">
                                      <img
                                        src={`data:image/png;base64,${profilephoto}`}
                                        alt="user-image"
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="about-user">
                                      <div className="d-flex align-items-center flex-wrap gap-2">
                                        <h6 className="mb-0">
                                          {profile?.profile_name}
                                          {comment.login_id}
                                        </h6>
                                        <p className="p-0 m-0 published-date">
                                          {formatTime(comment?.created_at)}
                                        </p>
                                      </div>
                                      <p className="mt-0 mb-0">
                                        {comment.message}
                                      </p>
                                    </div>
                                  </div>
                                  <div id="container">
                                    <div id="menu-wrap">
                                      <input
                                        type="checkbox"
                                        className="toggler"
                                        // checked={openMenuId === comment.id}
                                        // onChange={() => toggleMenu(comment.id)}
                                      />
                                      <div className="dots">
                                        <div></div>
                                      </div>
                                      {/* {user && ( */}
                                      <div className="menu ">
                                        <div>
                                          <button
                                            onClick={() =>
                                              handleDelete(comment)
                                            }
                                            className="p-0"
                                          >
                                            <li>Delete</li>
                                          </button>
                                        </div>
                                      </div>
                                      {/* )} */}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                    </ul>
                  </ul>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2 d-none d-sm-block">
                  <p className="mb-0">
                    {/* <b>{commentsDetail.length} Comments</b> */}
                  </p>
                  <div className="linkBlueText">
                    <p
                      className="commentMobileView mb-0"
                      // onClick={handleShowOffcanvas}
                    >
                      Show Comments
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="ragabank-rs">
              <div
                className="accordion pb-3 d-flex justify-content-end d-sm-none d-lg-block"
                id="accordionFlushExample"
              >
                <div className="accordion-item w-100">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button ragaFilterBg p-2 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      <FaFilter className="me-2" />
                      Raga Bank Filters
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body p-2">
                      <SelectBox
                        dataSource={RagaDataSource?.data}
                        value={filters.ragaId}
                        className="mb-2"
                        label="Raga"
                        labelMode="floating"
                        displayExpr="raaga_name"
                        valueExpr="id"
                        onValueChanged={(e) =>
                          handleFilterChange("ragaId", e.value)
                        }
                      />

                      <SelectBox
                        dataSource={LanguageDataSource?.data}
                        displayExpr="song_language_name"
                        valueExpr="id"
                        label="Language"
                        labelMode="floating"
                        value={filters.languageId}
                        className="mb-2"
                        onValueChanged={(e) =>
                          handleFilterChange("languageId", e.value)
                        }
                      />
                      <SelectBox
                        dataSource={VTypeDataSource?.data}
                        displayExpr="vtype_name"
                        valueExpr="id"
                        className="mb-2"
                        label="Type"
                        labelMode="floating"
                        value={filters.vtypeId}
                        onValueChanged={(e) =>
                          handleFilterChange("vtypeId", e.value)
                        }
                      />
                      <SelectBox
                        dataSource={ComposerDataSource?.data}
                        value={filters.composerId}
                        className="mb-2"
                        displayExpr="composer_name"
                        valueExpr="id"
                        label="Composer"
                        labelMode="floating"
                        onValueChanged={(e) =>
                          handleFilterChange("composerId", e.value)
                        }
                      />
                      <SelectBox
                        dataSource={ArtistDataSource?.data}
                        value={filters.thalaId}
                        className="mb-2"
                        displayExpr="artist_name"
                        valueExpr="artistid"
                        label="Artist Name"
                        labelMode="floating"
                        onValueChanged={(e) =>
                          handleFilterChange("thalaId", e.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              {mdndData &&
                mdndData.length > 0 &&
                mdndData?.map((item, index) => (
                  <div className="iq-watching-block mb-lg-3 d-flex">
                    <div
                      className="block-images position-relative"
                      style={{
                        width: "200px",
                        height: "112px",
                      }}
                    >
                      <div
                        className="overly-images imgRadius"
                        style={{
                          width: "200px",
                          height: "112px",
                        }}
                      >
                        <img
                          src={`${RAGABANK_IMAGEPATH}/${item?.thumbnail_url}`}
                          alt={item?.video_title}
                          className="img-fluid object-cover w-100 d-block border-0 videoOuter"
                          onClick={(e) => handleSideVideo(e, item)}
                        />
                      </div>
                    </div>
                    {/* ----------------------------------------------------------------------------- */}
                    <div className="w-100 d-flex justify-content-between p-2">
                      <div
                        className="w-100 me-3"
                        //  onClick={(e)=>handleSideVideo(e,item)}
                      >
                        <h6 className="mb-0">
                          <b>{item.song_title}</b>
                        </h6>
                        <p className="mb-0">MDnD</p>
                        <p className="mb-0 videoMinuts">
                          {formatTime(item?.created_date)}
                        </p>
                      </div>
                      <div>
                        <div id="container">
                          <div id="menu-wrap">
                            <input type="checkbox" className="toggler" />
                            <div className="dots">
                              <div></div>
                            </div>
                            <div className="menu p-1">
                              <div>
                                <ul className="p-0">
                                  <li>
                                    {/* <MdAddToQueue /> */}
                                    <a href="#" className="link ms-2">
                                      Add to queue
                                    </a>
                                  </li>
                                  <li>
                                    <div className="d-flex align-items-center gap-2">
                                      <i
                                        className="fa fa-share"
                                        aria-hidden="true"
                                      ></i>
                                      Share
                                    </div>
                                    {/* </a> */}
                                  </li>
                                  <li>
                                    {/* <CiFlag1 /> */}
                                    <a href="#" className="link ms-2">
                                      Report
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoStore;
