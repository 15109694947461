import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import "./Styles/artist.css";
import { ArtistallImg } from "../../../Subpages/MainPage/constant.js";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { GET_ARTIST } from "../../../Api/api.js";
import {
  ERROR,
  SUCCESS,
  DURATION,
} from "../../../global/ToastMsgConstants.tsx";
import { useMutation } from "@tanstack/react-query";
import notify from "devextreme/ui/notify";
import { makePostRequest } from "../../../Api/requestMethods.js";
import { FaFilter } from "react-icons/fa";
import { Modal, Button, Form } from "react-bootstrap";
import { generateAlphabetCharacters } from "../../../utils/commonUtils.js";
import { MdKeyboardArrowRight } from "react-icons/md";

//newly added by vignesh
import { BASEPATH } from "../../../config/config.js";
import axios from "axios";
import {
  GET_COUNTRIES_ARTIST,
  GET_STATES_ARTIST,
  GET_CITIES_ARTIST,
  ARTIST_FILTER,
  FETCH_ALL_ARTIST_MAINCATEGORY,
  GET_ALL_ARTIST_SUBCATEGORY,
} from "../../../Api/api.js";
import { SelectBox } from "devextreme-react";

import NoDataFound from "../../../global/NoDataFound.js";
import "./ArtistProfile.css";
import _ from "lodash";
import Noimage from "../../../global/NoImage.js";
import { isValidImage, imageExists } from "../../../global/ImageCheck.js";


const ArtistProfile = () => {
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const doFilter = () => {
    setFilter(!filter);
  };

  //const { genreName } = useParams();
  //console.log(genreName,"genreName inside next artist page");

  const location = useLocation();
  const artistId = location?.state?.mainCatId;

  //console.log(artistId, "artistId");

  const mainCatName = location?.state?.mainCatName;
  const [CatName, setCatName] = useState("");

  //new code
  const [artistIdstate, setArtistIdstate] = useState("");

  useEffect(() => {
    if (artistId) {
      setArtistIdstate(Number(artistId));
    }
  }, [artistId]);

  useEffect(() => {
    if (mainCatName) {
      setCatName(mainCatName);
    } else {
      const params = new URLSearchParams(location?.search);

      setCatName(params.get("mainCatName") || "");
    }
  }, [mainCatName, location]);

  //console.log(CatName, "catName");

  //end of new code

  // const mainCatName = location?.state?.mainCatName;

  // console.log(mainCatName, "mainCatName");

  const [dataSource, setDataSource] = useState({
    allArtistData: "",
    //new code by vignesh
    selectedAge: "",
    country: "",
    state: "",
    city: "",
    selectedCountry: "",
    //selectedState: "",
    selectedCity: "",

    finalData: [],

    maincategory_all: "",
    selectedMainCategory: "",
    subCategory_all: "",
    selectedSubCategory: "",
  });

  //new code by vignesh...
  const [isFetching, setIsFetching] = useState(false);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const limit = 10;

  const loadMore = useCallback(async () => {
    //console.log("inside load more");

    if (isFetching || !hasMoreData) return;
    setIsFetching(true);
    setLoading(true);
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${ARTIST_FILTER}`,
        data: {
          artist_maincate: dataSource.selectedMainCategory
            ? dataSource.selectedMainCategory
            : "",
          artist_subcate: dataSource.selectedSubCategory
            ? dataSource.selectedSubCategory
            : "",
          country_id: dataSource.selectedCountry
            ? dataSource.selectedCountry
            : "",
          city_id: dataSource.selectedCity ? dataSource.selectedCity : "",
          age_status: dataSource.selectedAge ? dataSource.selectedAge : "",
          limit: limit,
          offset: currentOffset,
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      //console.log(response.data,"RD");
      if (response.data.status == "success") {
        //setsearchCollection((prevState) => ({ ...prevState, searchData: response.data.data }));
        setIsFetching(false);
        setLoading(false);

       
        // Update hasMoreData based on response data length
        setHasMoreData(response?.data?.data.length === limit);
        
        console.log(response.data.data,"response.data.data from backend");

        setDataSource((prevState) => {
         
          const existingArtistIds = new Set(prevState.finalData?.map(item => item.artist_id));
      
         
          const filteredNewData = response?.data?.data.filter(item => !existingArtistIds.has(item.artist_id));
      
         
          const isSameData = JSON.stringify(prevState?.finalData) === JSON.stringify(filteredNewData);
      
          return {
            ...prevState,
            finalData:
              currentOffset === 0
                ? response.data.data
              : currentOffset > 0  && !isSameData && hasMoreData
                ? [...prevState.finalData, ...filteredNewData]
              : dataSource.finalData,
                
          };
        });
        
      } 
      if (response?.data?.data?.length > 0) {
        setCurrentOffset((prevOffset) => prevOffset + limit);
        //setHasMoreData(false);
      }
      // else {
      //   setDataSource((prevState) => ({ ...prevState, finalData: [] }));
      //   setHasMoreData(false);
      //   setIsFetching(true);
      // }

      

      //setCurrentOffset((prevOffset) => prevOffset + limit);
    } catch (error) {
      setDataSource((prevState) => ({ ...prevState, finalData: [] }));
      setIsFetching(false);
      setHasMoreData(false);
      setLoading(false);
    }
    finally {
      setIsFetching(false);
    }
  }, [
    currentOffset,
    isFetching,
    dataSource.selectedCity,
    dataSource.selectedAge,
    dataSource.selectedSubCategory,
  ]);

  useEffect(() => {
    if (
      dataSource.selectedCity &&
      dataSource.selectedAge &&
      dataSource.selectedSubCategory
    ) {
      //console.log("check here");
      loadMore();
    } else {
      return;
    }
  }, [
    dataSource.selectedCity,
    dataSource.selectedAge,
    dataSource.selectedSubCategory,
    dataSource.selectedMainCategory,
  ]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 100 >=
          document.documentElement.scrollHeight &&
        !isFetching &&
        hasMoreData
      ) {
        loadMore();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isFetching, hasMoreData]);

  // end of new code

  const handleClick = (profileData) => {
    // navigate(`/artist/${profileData.artist_name}`, {
    //   state: { profileData, artistId: profileData.role_id },
    // });
    //console.log(profileData.role_id, "role id onCLick during filter");

    // navigate(`/artist/${profileData.profile_name}`, {
    //   state: { profileData, artistId: profileData.role_id },
    // });

    navigate(`/artist/${profileData.profile_name}?rid=${profileData.role_id}`, {
      state: { profileData, artistId: profileData.role_id },
    });
  };

  

  //new code by vignesh.................................................

  const token = localStorage.getItem("MDnD_auth");

  const handleAgelessthan20 = () => {
    setDataSource((prev) => ({ ...prev, selectedAge: "Junior" }));
    setHasMoreData(true);

    setIsFetching(false);
    setCurrentOffset(0);
    setDataSource((prevState) => ({ ...prevState, finalData: [] }));
  };

  const handleAgemorethan20 = () => {
    setDataSource((prev) => ({ ...prev, selectedAge: "Senior" }));

    setHasMoreData(true);
    setIsFetching(false);
    setCurrentOffset(0);
    setDataSource((prevState) => ({ ...prevState, finalData: [] }));
  };

  //console.log(dataSource, "dataSource");

  //country,state,city
  useEffect(() => {
    mutationCountry.mutate();
  }, []);

  const GetCountries = () => GET_COUNTRY(GET_COUNTRIES_ARTIST);

  const GET_COUNTRY = async (GET_COUNTRIES_ARTIST) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASEPATH}${GET_COUNTRIES_ARTIST}`,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationCountry = useMutation({
    mutationKey: ["get_Country"],
    mutationFn: () => GetCountries(),

    onSuccess: (data) => {
      //   queryClient.invalidateQueries({
      //     queryKey: ["fetchUser"],
      //   });

      //console.log(data, "DATA");

      if (data.status === "success") {

        const filteredCountries = data.data.filter(
          (country) => country.country_id != null && country.country_name != null
        );

        if(filteredCountries)
        {setDataSource((prevState) => ({ ...prevState, country: filteredCountries }));}
      } else {
        setDataSource((prevState) => ({ ...prevState, country: "" }));
      }
    },
    onError: (error) => {
      //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
      //setAddress((prevState) => ({ ...prevState, country: ""}));
    },
  });

  //senior, junior, selectedCountry, selectedState, selectedCity initial .........
  useEffect(() => {
    if (artistId && mainCatName) {
      {
        setDataSource((prevState) => ({
          ...prevState,
          selectedAge: "Junior",
        }));
      }
    } else {
      const params = new URLSearchParams(location?.search);

      setDataSource((prevState) => ({
        ...prevState,

        selectedAge: params.get("age") || "",
      }));
    }
  }, [location, mainCatName, artistId]);

  // initial COuntry

  useEffect(() => {
    if (artistId && mainCatName) {
      {
        setDataSource((prevState) => ({
          ...prevState,
          selectedCountry: 101,
        }));
      }
    } else {
      const params = new URLSearchParams(location?.search);

      setDataSource((prevState) => ({
        ...prevState,
        selectedCountry: Number(params.get("countryid")) || "",
      }));
    }
  }, [location, mainCatName, artistId]);

  

  //cities fetch..
  useEffect(() => {
    const GETCITIESDATA = async () => {
      try {
        const response = await axios.post(
          `${BASEPATH}${GET_CITIES_ARTIST}`,
          {
            country_id: dataSource.selectedCountry,
          },
          {
            headers: {
              MDnD_auth: `${token}`,
            },

            timeout: 60000,
            responseType: "json",
          }
        );

        if (response.data.status == "success") {

          const filteredCities = response?.data?.data.filter(
            (city) => city.city_id != null && city.city_name != null
          );


         if(filteredCities)
          {setDataSource((prevState) => ({
            ...prevState,
            city: response.data.data.sort((a, b) => {
             
              if (a.city_name && b.city_name) {
                return a.city_name.localeCompare(b.city_name);
              }
             
              return a.city_name ? -1 : 1;
            }),
          }));}


        } else {
          setDataSource((prevState) => ({ ...prevState, city: "" }));
        }
      } catch (error) {
        console.error("error fetching States for this country", error);
      }
    };
    if (dataSource.selectedCountry) {
      GETCITIESDATA();
    }
  }, [dataSource.selectedCountry]);

 
  // useEffect(() => {
  //   if (
  //     artistId &&
  //     mainCatName &&
  //     dataSource.city &&
  //     dataSource.city?.length > 0
  //   ) {
  //     setDataSource((prevState) => ({
  //       ...prevState,

  //       //selectedCity: dataSource?.city[0]?.city_id,
  //       selectedCity: 3659
  //     }));
  //   } else if (
  //     !artistId &&
  //     !mainCatName &&
  //     dataSource.city &&
  //     !dataSource.selectedCity
  //   ) {
  //     setDataSource((prevState) => ({
  //       ...prevState,

  //       selectedCity: dataSource?.city[0]?.city_id,
  //     }));
  //   } else if (dataSource.selectedCity && !mainCatName) {
  //     const params = new URLSearchParams(location?.search);

  //     setDataSource((prevState) => ({
  //       ...prevState,

  //       selectedCity: Number(params.get("cityid")) || "",
  //     }));
  //   }
  // }, [location, mainCatName, artistId, dataSource.city]);

   // setting initial city
  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    const urlCityId = Number(params.get("cityid"));
  
    if (urlCityId && !mainCatName && !dataSource.city) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedCity: urlCityId,
      }));
    } 
    else if (
      !artistId &&
      !mainCatName &&
      dataSource.city &&
      !dataSource.selectedCity
    ) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedCity: dataSource.city[0]?.city_id,
      }));
    } 


    else if (
      artistId &&
      mainCatName &&
      dataSource.city &&
      dataSource.city.length > 0
    ) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedCity: 3659,
      }));
    } 


    

    else if (dataSource.selectedCity && !mainCatName) {
      const params = new URLSearchParams(location?.search);
      setDataSource((prevState) => ({
        ...prevState,
        selectedCity: Number(params.get("cityid")) || "",
      }));
    }
  }, [location, mainCatName, artistId, dataSource.city]);

  const handleCountry = (e) => {
    //console.log("im inside");
    if (e.value) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedCountry: e.value,
      }));
      setDataSource((prevState) => ({ ...prevState, city: [] }));
      setDataSource((prevState) => ({
        ...prevState,
        // selectedState: "",
        selectedCity: "",
      }));
    }
    setHasMoreData(true);
    setIsFetching(false);
    setCurrentOffset(0);
    setDataSource((prevState) => ({ ...prevState, finalData: [] }));
  };
  // const handleState = (value) => {

  //   if (value) {
  //     setDataSource((prevState) => ({ ...prevState, selectedState: value }));
  //     setDataSource((prevState) => ({ ...prevState, city: [] }));
  //     setDataSource((prevState) => ({ ...prevState,  selectedCity: "", }));
  //   }
  //   setDataSource((prevState) => ({ ...prevState, finalData: [] }));
  // }

  const handleCity = (value) => {
    //console.log("check");
    if (value) {
      //console.log("inside handleCity function")
      setDataSource((prevState) => ({ ...prevState, selectedCity: value }));

      //setHasMoreData((prevHasMoreData) => !prevHasMoreData);
      setHasMoreData(true);
      setIsFetching(false);
      setCurrentOffset(0);

      //setDataSource((prevState) => ({ ...prevState, finalData: [] }));
    }
  };

  const handleMainCategory = (e) => {
    let artistName = {};
    let genreName;
    if (e) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedMainCategory: e.value,
      }));
      setDataSource((prevState) => ({ ...prevState, subCategory_all: [] }));
      setDataSource((prevState) => ({ ...prevState, selectedSubCategory: "" }));
    }
    let filteredArray =
      dataSource.maincategory_all &&
      dataSource.maincategory_all.filter(
        (item) => item.main_cate_id === e.value
      );
    //console.log(filteredArray, "filteredArray");

    if (filteredArray) {
      setCatName(filteredArray[0]?.main_cate_name);
    }

    // let filterData = dataSource.maincategory_all && dataSource.maincategory_all.filter((item) => item.main_cat_id === e.value);

    // //console.log(filterData, "filterData");
    // if (filterData) {
    //   artistName = filterData[0];
    //   genreName = filterData[0].main_cat_name;
    // }
    //console.log(artistName, "artistname");
    setHasMoreData(true);
    setIsFetching(false);
    setCurrentOffset(0);
    setDataSource((prevState) => ({
      ...prevState,
      finalData: [],
      selectedSubCategory: "",
    }));

    // if (artistName) {
    //   navigate(`/artists/${genreName}`, { state: { mainCatId: artistName.main_cat_id, mainCatName: artistName.main_cat_name } });
    // }
  };

  const handleSubCategory = (e) => {
    if (e) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedSubCategory: e.value,
      }));
      //setHasMoreData((prevHasMoreData) => !prevHasMoreData);
      setHasMoreData(true);
      setIsFetching(false);
      setCurrentOffset(0);
      setDataSource((prevState) => ({ ...prevState, finalData: [] }));
    }
  };

  //fetch for mainCategory and sub-Category......................................
  useEffect(() => {
    mutationMainCategory.mutate();
  }, []);

  const GetMainCategory = () => GET_MainCategory(FETCH_ALL_ARTIST_MAINCATEGORY);

  const GET_MainCategory = async (FETCH_ALL_ARTIST_MAINCATEGORY) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASEPATH}${FETCH_ALL_ARTIST_MAINCATEGORY}`,
        // headers: {
        //   'MDnD_auth': `${token}`,

        // },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationMainCategory = useMutation({
    mutationKey: ["get_maincategory_artist"],
    mutationFn: () => GetMainCategory(),
    onSuccess: (data) => {
      if (data.status === "success") {
        setDataSource((prevState) => ({
          ...prevState,
          maincategory_all: data.data,
        }));
      } else {
        setDataSource((prevState) => ({ ...prevState, maincategory_all: "" }));
      }
    },
    onError: (error) => {
      setDataSource((prevState) => ({ ...prevState, maincategory_all: "" }));
    },
  });

  // setting initial maincategory  when the page loads
  useEffect(() => {
    if (mainCatName && artistId) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedMainCategory: Number(artistId),
      }));
    } else {
      //console.log("MAINCategory param set");
      const params = new URLSearchParams(location.search);

      setDataSource((prevState) => ({
        ...prevState,
        selectedMainCategory: Number(params.get("mainCategory")) || "",
      }));
    }
  }, [artistId, location, mainCatName]);

  //fetch sub-category

  useEffect(() => {
    const GETSUBCATEGORIES = async () => {
      try {
        const response = await axios.post(
          `${BASEPATH}${GET_ALL_ARTIST_SUBCATEGORY}`,
          {
            maincategory_id: dataSource.selectedMainCategory,
          },
          {
            headers: {
              MDnD_auth: `${token}`,
            },

            timeout: 60000,
            responseType: "json",
          }
        );

        if (response.data.status == "success") {
          setDataSource((prevState) => ({
            ...prevState,
            subCategory_all: response.data.data,
          }));
        } else {
          setDataSource((prevState) => ({ ...prevState, subCategory_all: "" }));
        }
      } catch (error) {
        console.error("error fetching States for this country", error);
      }
    };

    //mutationGetStates.mutate();
    if (dataSource.selectedMainCategory) {
      GETSUBCATEGORIES();
    }
  }, [dataSource.selectedMainCategory]);

  // set initial subCategory when the page loads...
  useEffect(() => {
    if (
      mainCatName &&
      artistId &&
      dataSource.subCategory_all &&
      dataSource.subCategory_all?.length > 0
    ) {
      setDataSource((prevState) => ({
        ...prevState,
        selectedSubCategory: dataSource.subCategory_all[0].sub_cat_id,
      }));
    } else if (dataSource.selectedSubCategory && !mainCatName) {
      //console.log("subCategory param set here");
      const params = new URLSearchParams(location?.search);

      setDataSource((prevState) => ({
        ...prevState,
        selectedSubCategory: Number(params.get("subCategory")) || "",
      }));
    } else if (
      !mainCatName &&
      !artistId &&
      dataSource.subCategory_all &&
      !dataSource.selectedSubCategory
    ) {
      //console.log("im here");
      setDataSource((prevState) => {
        let newSelectedSubCategory = Number(
          dataSource?.subCategory_all[0]?.sub_cat_id
        );

        return {
          ...prevState,
          selectedSubCategory: newSelectedSubCategory,
        };
      });
    }
  }, [dataSource.subCategory_all, location, mainCatName, artistId]);

  //dynamicaly changing the URL
  useEffect(() => {
    if (
      dataSource.selectedCountry &&
      dataSource.selectedMainCategory &&
      dataSource.selectedAge &&
      CatName &&
      dataSource.selectedSubCategory &&
      dataSource.subCategory_all &&
      dataSource.city &&
      dataSource.selectedCity
    ) {
      const queryParams = new URLSearchParams({
        countryid: Number(dataSource.selectedCountry),
        cityid: dataSource.selectedCity ? Number(dataSource.selectedCity) : "",
        age: dataSource.selectedAge,
        mainCategory: dataSource.selectedMainCategory
          ? Number(dataSource.selectedMainCategory)
          : "",
        subCategory: dataSource.selectedSubCategory
          ? Number(dataSource.selectedSubCategory)
          : "",
        mainCatName: CatName,
      });

      const newUrl = `/artists/${CatName}?${queryParams}`;
      navigate(newUrl);
    } else {
      return;
    }
  }, [
    dataSource.selectedCountry,
    dataSource.selectedCity,
    dataSource.selectedAge,
    CatName,
    dataSource.selectedMainCategory,
    dataSource.selectedSubCategory,
    navigate,
    dataSource.subCategory_all,
    dataSource.city,
  ]);

  //newly added..
  const [imageErrorHeader, setImageErrorHeader] = useState(false);

  const handleImageError_Header = () => {
    setImageErrorHeader(true);
  };

 //********************BREAD CRUMBS ******************** */

  const[breadCrumbCollection, setbreadCrumbCollection] = useState({
    breadCrumbCountry:"",
    breadCrumbCity:"",
    breadCrumbAge:"",
    breadCrumbSubCategory:"",
    breadCrumbMainCategory: "",
    breadCrumbCurrent:"",

  })

  

  useEffect(()=>{
    if(dataSource?.selectedCountry && dataSource?.selectedCountry!= '' && dataSource.country ){
      const filteredData = dataSource.country && dataSource.country.filter((item)=> item.country_id === Number(dataSource.selectedCountry) )
      
      
      if(filteredData && filteredData.length > 0 )
      {
        //setbreadcrumbCountry(filteredData[0]?.country_name)
      
        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCountry : filteredData[0]?.country_name,
          breadCrumbCurrent:"Country",
          
        }));
      }
      else{
        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCountry : '',
          
        }));
      }
      
    }
    

  },[dataSource?.selectedCountry,dataSource.country])

  useEffect(()=>{
    if(dataSource?.selectedCity && dataSource?.selectedCity !== '' && dataSource?.city){
      const cityData= dataSource?.city && dataSource?.city.filter((item)=> item?.city_id === dataSource.selectedCity )

      
      if(cityData && cityData.length > 0){
        //setbreadCrumbCity(cityData[0].city_name);

        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCity : cityData[0].city_name,
          breadCrumbCurrent:"City",

          
        }));
      }
      else{
        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCity : '',
          
        }));
      }
     
    }

  },[dataSource?.selectedCity,dataSource?.city])


  useEffect(()=>{
    if(dataSource.selectedSubCategory && dataSource.selectedSubCategory !== '' && dataSource.subCategory_all){
      const subcategoryData= dataSource?.subCategory_all && dataSource?.subCategory_all.filter((item)=> item?.sub_cat_id === dataSource.selectedSubCategory )

      
      if(subcategoryData && subcategoryData.length > 0){
        //setbreadCrumbCity(cityData[0].city_name);

        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbSubCategory: subcategoryData[0].sub_cat_name,
          breadCrumbCurrent:"SubCategory",

          
        }));
      }
      else{
        setbreadCrumbCollection((prevState) => ({
          ...prevState,
          breadCrumbCity : '',
          
        }));
      }
     
    }


  },[dataSource.selectedSubCategory,dataSource.subCategory_all ])

  console.log(dataSource.subCategory_all,"dataSource.subCategory_all");

  useEffect(()=>{
    if(dataSource.selectedAge){
      //setbreadCrumbEventMode(dataSource.selectedEventMode);

      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbAge : dataSource.selectedAge,
        breadCrumbCurrent:"Age",
        
      }));
    }
    else{
      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbAge : '',
        
      }));
    }

  },[dataSource.selectedAge])


 useEffect(()=>{
  if(CatName){
   

    setbreadCrumbCollection((prevState) => ({
      ...prevState,
      breadCrumbMainCategory: CatName,
      breadCrumbCurrent: "Main Category",
      
    }));

  }
  else{
    setbreadCrumbCollection((prevState) => ({
      ...prevState,
      breadCrumbMainCategory: '',
      
    }));
  }

 },[CatName])



 

  return (
    <>
      <div className="filter-page iq-breadcrumb eventsBanner ">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb" className="text-center">
                <h2 className="mt-4 mt-lg-0 header-title text-white">
                  {CatName}
                </h2>
                <h4 className="mt-4 mt-lg-0 text-white">
                  Discover {CatName} artists all over the world
                </h4>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="section-padding pt-4">
        <div className="container-fluid">
          <div className="row">
            <div
              className="w-100 filter-sticky d-lg-none d-flex flex-nowrap align-items-center gap-3 justify-content-between"
              onClick={doFilter}
            >
              {/*Mobile breadcrumb */}
              <div
                className="w-100 d-flex align-items-center hide-scrollbar"
                style={{ gap: "2px", overflow: "scroll" }}
              >
                
                <p className="m-0 p-0"
                      style={{ color: breadCrumbCollection.breadCrumbCurrent === "Country" ? "#3F00FF" : undefined }}
                      >{breadCrumbCollection.breadCrumbCountry}</p>
                      <i style={{ fontSize: "16px" }}>
                        <MdKeyboardArrowRight />
                      </i>
                      <p className="m-0 p-0" 
                      style={{ color: breadCrumbCollection.breadCrumbCurrent === "City" ? "#3F00FF" : undefined }}
                      //style={{ color: "#3F00FF" }}
                      >
                      {breadCrumbCollection.breadCrumbCity}
                      </p>

                      <i style={{ fontSize: "16px" }}>
                        <MdKeyboardArrowRight />
                      </i>
                      <p className="m-0 p-0" 
                       style={{ color: breadCrumbCollection.breadCrumbCurrent === "Age" ? "#3F00FF" : undefined }}
                       //style={{ color: "#3F00FF" }}
                      >
                      {breadCrumbCollection.breadCrumbAge}
                      </p>

                      <i style={{ fontSize: "16px" }}>
                        <MdKeyboardArrowRight />
                      </i>

                      <p className="m-0 p-0" 
                      style={{ color: breadCrumbCollection.breadCrumbCurrent === "Main Category" ? "#3F00FF" : undefined }}
                      //style={{ color: "#3F00FF" }}
                      >
                      {breadCrumbCollection.breadCrumbMainCategory}
                      </p>

                      <i style={{ fontSize: "16px" }}>
                        <MdKeyboardArrowRight />
                      </i>
                      <p className="m-0 p-0" 
                       style={{ color: breadCrumbCollection.breadCrumbCurrent === "SubCategory" ? "#3F00FF" : undefined }}
                       //style={{ color: "#3F00FF" }}
                      >
                      {breadCrumbCollection.breadCrumbSubCategory}
                      </p>

                     


              </div>
              <div
                className="filter-button align-items-center"
                onClick={doFilter}
              >
                <FaFilter />
                Filters
              </div>
            </div>
            <div className="col-lg-3 col-xl-2 sideBarFilter">
              {loading ? (
                <>
                  <Skeleton height={40} className="me-1" />
                  <Skeleton height={40} className="me-1" />
                  <Skeleton height={40} className="me-1" />
                  <Skeleton height={40} className="me-1" />
                  <Skeleton height={40} className="me-1" />
                  <Skeleton height={40} className="me-1" />
                  <Skeleton height={40} className="me-1" />
                  <Skeleton height={40} className="me-1" />
                  <Skeleton height={40} className="me-1" />
                  <Skeleton height={40} />
                </>
              ) : (
                <>
                  <div className="filterBg pb-2">
                    <div className="">
                      <h5>
                        <FaFilter /> Filters
                      </h5>
                    </div>

                    <div className="w-auto">
                      <h6 className="mb-2">Age </h6>
                      <div className="w-auto d-flex gap-2">
                        <button
                          onClick={handleAgemorethan20}
                          className={
                            dataSource.selectedAge === "Senior"
                              ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                              : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                          }
                        >
                          {" "}
                          Above 20 years
                        </button>
                        <button
                          onClick={handleAgelessthan20}
                          className={
                            dataSource.selectedAge === "Junior"
                              ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                              : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                          }
                        >
                          Upto 20 years
                        </button>
                      </div>
                    </div>
                    <div className="mt-4">
                      <h6 className="mb-2">Location</h6>

                      <SelectBox
                        dataSource={dataSource.country || []}
                        displayExpr="country_name"
                        valueExpr="country_id"
                        label="Select Country"
                        searchEnabled={true}
                        onValueChanged={handleCountry}
                        labelMode="floating"
                        value={dataSource.selectedCountry}
                      />

                      <SelectBox
                        dataSource={dataSource.city || []}
                        label="Select City"
                        labelMode="floating"
                        displayExpr="city_name"
                        valueExpr="city_id"
                        searchEnabled={true}
                        onValueChanged={(e) => handleCity(e.value)}
                        value={dataSource.selectedCity}
                      />
                    </div>

                    <div className="mt-4">
                      <h6 className="mt-4 mb-2">Categories</h6>
                      <div className="accordion mt-0" id="accordionExample">
                        <SelectBox
                          dataSource={dataSource.maincategory_all || []}
                          displayExpr="main_cate_name"
                          valueExpr="main_cate_id"
                          label="Change Category"
                          searchEnabled={true}
                          onValueChanged={handleMainCategory}
                          labelMode="floating"
                          value={dataSource.selectedMainCategory}
                        />
                      </div>

                      <h6 className="mt-4 mb-2">Sub-Categories</h6>

                      <div className="accordion mt-0" id="accordionExample">
                        <SelectBox
                          dataSource={dataSource.subCategory_all || []}
                          displayExpr="sub_cat_name"
                          valueExpr="sub_cat_id"
                          label="Select Sub-Category"
                          searchEnabled={true}
                          onValueChanged={handleSubCategory}
                          labelMode="floating"
                          value={dataSource.selectedSubCategory}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col-xl-10 col-lg-9 col-12">
              <div>
                {/* ------------------------------------------------------------ */}
                <Modal
                  className="filterPopUp"
                  show={filter}
                  onHide={doFilter}
                  style={{ zIndex: "10001" }}
                >
                  <Modal.Header
                    closeButton
                    className="m-0 d-flex align-items-center text-center border-light"
                    style={{ padding: "10px 10px" }}
                  >
                    <div className="w-100 filterBg pb-2">
                      <h5 className="d-flex align-items-center gap-2">
                        <FaFilter /> Filters
                      </h5>
                    </div>
                  </Modal.Header>
                  <Modal.Body className="p-3">
                    <div className=" pb-2">
                      <div className="w-auto">
                        <h6 className="mb-2">Age </h6>
                        <div className="w-auto d-flex gap-2">
                          <button
                            onClick={handleAgemorethan20}
                            className={
                              dataSource.selectedAge === "Senior"
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                          >
                            {" "}
                            20 years
                          </button>
                          <button
                            onClick={handleAgelessthan20}
                            className={
                              dataSource.selectedAge === "Junior"
                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                            }
                          >
                            Upto 20 years
                          </button>
                        </div>
                      </div>
                      <div className="mt-4">
                        <h6 className="mb-2">Location</h6>

                        <SelectBox
                          dataSource={dataSource.country || []}
                          displayExpr="country_name"
                          valueExpr="country_id"
                          label="Select Country"
                          searchEnabled={true}
                          onValueChanged={handleCountry}
                          labelMode="floating"
                          value={dataSource.selectedCountry}
                          dropDownOptions={{ container: ".modal" }}
                        />

                        <SelectBox
                          dataSource={dataSource.city || []}
                          label="Select City"
                          labelMode="floating"
                          displayExpr="city_name"
                          valueExpr="city_id"
                          searchEnabled={true}
                          onValueChanged={(e) => handleCity(e.value)}
                          value={dataSource.selectedCity}
                          dropDownOptions={{ container: ".modal" }}
                        />
                      </div>

                      <div className="mt-4">
                        <h6 className="mt-4 mb-2">Categories</h6>
                        <div className="accordion mt-0" id="accordionExample">
                          <SelectBox
                            dataSource={dataSource.maincategory_all || []}
                            displayExpr="main_cate_name"
                            valueExpr="main_cate_id"
                            label="Change Category"
                            searchEnabled={true}
                            onValueChanged={handleMainCategory}
                            labelMode="floating"
                            value={dataSource.selectedMainCategory}
                            dropDownOptions={{ container: ".modal" }}
                          />
                        </div>

                        <h6 className="mt-4 mb-2">Sub-Categories</h6>

                        <div className="accordion mt-0" id="accordionExample">
                          <SelectBox
                            dataSource={dataSource.subCategory_all || []}
                            displayExpr="sub_cat_name"
                            valueExpr="sub_cat_id"
                            label="Select Sub-Category"
                            searchEnabled={true}
                            onValueChanged={handleSubCategory}
                            labelMode="floating"
                            value={dataSource.selectedSubCategory}
                            dropDownOptions={{ container: ".modal" }}
                          />
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="py-1 px-2 border-0">
                    <button className="button" onClick={doFilter}>
                      Apply
                    </button>
                  </Modal.Footer>
                </Modal>
                {/* ---------------------------------------------------------- */}
              </div>
              {loading ? (
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 ">
                  {Array.from({ length: 10 }).map((_, index) => (
                    <span className="col " key={index}>
                      <Skeleton height={250} />
                    </span>
                  ))}
                </div>
              ) : (
                <div className="d-flex flex-column gap-2">
                  {/* Website breadcrumb */}
                  <div className="d-none d-lg-block">
                    <div
                      className="w-100 d-flex flex-row align-items-center hide-scrollbar"
                      style={{ gap: "2px", overflow: "scroll" }}
                    >



            <p className="m-0 p-0"
                      style={{ color: breadCrumbCollection.breadCrumbCurrent === "Country" ? "#3F00FF" : undefined }}
                      >{breadCrumbCollection.breadCrumbCountry}</p>
                      <i style={{ fontSize: "16px" }}>
                        <MdKeyboardArrowRight />
                      </i>
                      <p className="m-0 p-0" 
                      style={{ color: breadCrumbCollection.breadCrumbCurrent === "City" ? "#3F00FF" : undefined }}
                      //style={{ color: "#3F00FF" }}
                      >
                      {breadCrumbCollection.breadCrumbCity}
                      </p>

                      <i style={{ fontSize: "16px" }}>
                        <MdKeyboardArrowRight />
                      </i>
                      <p className="m-0 p-0" 
                       style={{ color: breadCrumbCollection.breadCrumbCurrent === "Age" ? "#3F00FF" : undefined }}
                       //style={{ color: "#3F00FF" }}
                      >
                      {breadCrumbCollection.breadCrumbAge}
                      </p>

                      <i style={{ fontSize: "16px" }}>
                        <MdKeyboardArrowRight />
                      </i>

                      <p className="m-0 p-0" 
                      style={{ color: breadCrumbCollection.breadCrumbCurrent === "Main Category" ? "#3F00FF" : undefined }}
                      //style={{ color: "#3F00FF" }}
                      >
                      {breadCrumbCollection.breadCrumbMainCategory}
                      </p>

                      <i style={{ fontSize: "16px" }}>
                        <MdKeyboardArrowRight />
                      </i>
                      <p className="m-0 p-0" 
                       style={{ color: breadCrumbCollection.breadCrumbCurrent === "SubCategory" ? "#3F00FF" : undefined }}
                       //style={{ color: "#3F00FF" }}
                      >
                      {breadCrumbCollection.breadCrumbSubCategory}
                      </p>





                    </div>
                  </div>
                  <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 filtermh">
                    {dataSource.finalData && dataSource.finalData.length > 0 ? (
                      dataSource.finalData.map((artist) => (
                        <div className="col" key={artist.artist_id}>
                          <div
                            className="rounded overflow-hidden"
                            onClick={() => handleClick(artist)}
                          >
                            <div
                              className="img-container"
                              style={{
                                backgroundColor: artist.artist_photo
                                  ? "transparent"
                                  : "#3f00ff",
                                width: "100%",
                                height: "250px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {artist.artist_photo && !imageErrorHeader ? (
                                <img
                                  src={`${ArtistallImg}/${artist.artist_photo}`}
                                  className="img-fluid object-cover"
                                  onError={handleImageError_Header}
                                  alt="person"
                                  style={{ objectFit: "cover", height: "100%" }}
                                />
                              ) : (
                                generateAlphabetCharacters(artist?.artist_name)
                              )}
                            </div>

                            <div className="card-img-overlay artistTextBg position-relative">
                              <p className="cast-title fw-500 text-black mb-0">
                                {artist.artist_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <NoDataFound />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ArtistProfile;
