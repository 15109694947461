import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';

function VideoPlayer({ videoUrl }) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (Hls.isSupported() && videoUrl) {
      const hls = new Hls();
      hls.loadSource(videoUrl);
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MANIFEST_PARSED, function() {
        videoRef.current.play();
      });
    } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.src = videoUrl;
      videoRef.current.play();
    }
  }, [videoUrl]);

  return (
    <video ref={videoRef} controls autoPlay style={{ width: '100%' }}>
      Your browser does not support the video tag.
    </video>
  );
}

export default VideoPlayer;
