import React from "react";
import feedImg from "../../../assets/images/genre/01.webp";
import IMAGE3 from "../../../assets/images/genre/02.webp";
import AuthContext from "../../../context/AuthContext";
import { useState, useEffect, useContext, useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BASEPATH } from "../../../config/config";
import axios from "axios";
import { GET_IMAGES_PROFILE } from "../../../Api/api";
import { GET_FEED, REPORT_POST, GET_LIKES, GET_LIKES_BY_USER, LIKE_SINGLE_POST, DELETECOMMENT_SINGLE_POST, EDITCOMMENT_SINGLE_POST, GET_POSTS_COMMENTS, COMMENT_SINGLE_POST, GET_USERPROFILE, GET_WHOTOFOLLOW, FOLLOW_A_USER } from "../../../Api/api";
import ReactPlayer from "react-player";
import SuccessNewToaster from "../../../Toaster/Success/SuccessNewToaster";
import HandleErrorToast from "../../../Toaster/Failure/Failure";
import { Modal } from "react-bootstrap";
import { IMAGE_URL } from "../../../Subpages/MainPage/constant";
import { BsFillSendFill } from "react-icons/bs";
import { ArtistallImg } from "../../../Subpages/MainPage/constant";
import mlogo from "./mlogo.png";
import { useNavigate } from "react-router-dom";
import "./Feed.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Feed = () => {

  const { user, setUser, authTokens, setAuthTokens, logoutUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const token = localStorage.getItem("MDnD_auth");
  const [counteruserprofile_follow, setCounterUserProfile_follow] = useState(0);
  const [checkinitialFollow, setCheckInitialFollow] = useState("");
  const [counterforFollow, setcounterforFollow] = useState(0);


  const [feedCollection, setFeedCollection] = useState({
    feedData: "",
    postid_for_report: "",
    reporttext: "",
    validationmsg_for_reporttext: "",
    CommentsData: "",
    newfilteredGridData: "",
    whotoFollow: ""
  })

  const [postCollection, setPostCollection] = useState({
    comment_text: "",
    validation_for_comments: ""

  })
  const [arrayoflikenames, setArrayofLikeNames] = useState([]);

  const [counter, setCounter] = useState(0);
  const [counterlike, setCounterLike] = useState(0);
  const [likenamescounter, setlikenamescounter] = useState(0);
  const [namesofLikesforpostid, setNamesofLikesforPostid] = useState({});
  const [hoveredPostId, setHoveredPostId] = useState(null);
  const [newlikedstatus, setnewLikedStatus] = useState("");
  const [postLikeStatus, setPostLikeStatus] = useState({});


  //comments..
  const [updatedComments, setUpdatedComments] = useState([]);
  const [deleteComment, setDeleteComment] = useState([]);
  const [editedComment, setEditedComment] = useState([]);
  const [newupdatedComments, setNewUpdatedComments] = useState([]);
  const [checkereditComment, setCheckerEditComment] = useState(0);
  const [checkerforMenuClassname, setcheckerforMenuClassname] = useState(true);

  const [photoOrganiser, setPhotoOrganiser] = useState({});

  const [popup, setPopup] = useState({
    popupVisible: false,
    deleteornot: false,
    commentbox_visible: false,
    report_visible: false,
    anon_goestologin: false,
    names_likes: false
    //popupDetails: "",
  });


  // throttling 
  const [isFetching, setIsFetching] = useState(false);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const limit = 5;
  const [spinner, setSpinner] = useState(false);

  const loadMore = useCallback(async () => {

    //console.log("inside load more");

    if (isFetching || !hasMoreData) {

      return;
    }

    setIsFetching(true);
    setSpinner(true);
    try {

      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_FEED}`,
        data: {

          roleid: user ? user.role_id : "",

          limit: limit,
          offset: currentOffset,

        },
        headers: {
          'MDnD_auth': `${token}`,

        },

        timeout: 60000,
        responseType: 'json',


      })

      //console.log(response.data,"RD");
      if (response.data.Status == "success") {


        setFeedCollection((prevState) => ({
          ...prevState,
          feedData: currentOffset > 0 ? [...prevState.feedData, ...response.data.data] : response.data.data,
        }));




        setHasMoreData(response.data.data.length === limit);


      }
      else {

        setFeedCollection((prevState) => ({ ...prevState, feedData: [] }));
        setHasMoreData(false);
      }


      setCurrentOffset((prevOffset) => prevOffset + limit);
    } catch (error) {

    } finally {
      setIsFetching(false);
      setSpinner(false);
    }
  }, [currentOffset, isFetching,]);


  useEffect(() => {


    if (user) {

      loadMore();

    }

    else {
      return;
    }

  }, [counter, counterlike]);

  //console.log(currentOffset, "current offset");
  //console.log(hasMoreData, "hasMoreData");

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop + 100 >= document.documentElement.scrollHeight && !isFetching &&
        hasMoreData) {
        loadMore();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isFetching, hasMoreData]);


  // useEffect(() => {
  //   if (user) { mutationfetchFeed.mutate(); }

  // }, [counter, counterlike]);

  // const fetchSingleFeed = () => GETFEED(GET_FEED);

  // const mutationfetchFeed = useMutation({
  //   mutationKey: ["fetchFeed"],
  //   mutationFn: () => fetchSingleFeed(),
  //   onSuccess: (data) => {

  //     if (data.Status === "success") {
  //       setFeedCollection((prev) => ({ ...prev, feedData: data.data }));
  //     }
  //     else {
  //       setFeedCollection((prev) => ({ ...prev, feedData: "" }));
  //     }
  //   },
  //   onError: (error) => {
  //     console.error("Error fetching FEED:", error);
  //     setFeedCollection((prev) => ({ ...prev, feedData: "" }));
  //   },
  // });

  // const GETFEED = async (GET_FEED) => {
  //   try {
  //     const response = await axios({
  //       method: "POST",
  //       url: `${BASEPATH}${GET_FEED}`,

  //       data: {
  //         roleid: user ? user.role_id : "",
  //       },
  //       headers: {
  //         'MDnD_auth': `${token}`,

  //       },

  //       timeout: 60000,
  //       responseType: 'json',
  //     });

  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // };







  function formatDate(timestamp) {
    const date = new Date(timestamp);

    // Extract day, month, and year
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    // Determine the suffix for the day
    const suffix = (day) => {
      if (day > 3 && day < 21) return "th"; // All days between 4 and 20 get 'th'
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${suffix(day)} ${month}, ${year}`;
  }

  //REPORT A POST............................................
  const handleReport = (postid) => {
    if (postid) {
      setFeedCollection((prevState) => ({
        ...prevState,
        postid_for_report: postid,
      }));

      setPopup((prev) => ({ ...prev, report_visible: true }));
    } else {
      return;
    }
  };

  const handleReportTextbox = (e) => {
    setFeedCollection((prevState) => ({
      ...prevState,
      reporttext: e.target.value,
    }));

    if (e.target.value.length > 0) {
      setFeedCollection((prev) => ({
        ...prev,
        validationmsg_for_reporttext: "",
      }));
    }
  };

  const handleClose_reportpopup = () => {
    setFeedCollection((prevState) => ({
      ...prevState,
      reporttext: "",
      validationmsg_for_reporttext: "",
      postid_for_report: "",
    }));
    setPopup((prevState) => ({ ...prevState, report_visible: false }));
  };

  const handleReportSubmitButton = () => {
    if (feedCollection.reporttext === "") {
      setFeedCollection((prevState) => ({
        ...prevState,
        validationmsg_for_reporttext: "Enter something!",
      }));
    } else {
      setFeedCollection((prevState) => ({
        ...prevState,
        validationmsg_for_reporttext: "",
      }));
      mutationReportPost.mutate(feedCollection.postid_for_report);
    }
  };

  const postReport = (postid_report) =>
    postReportBackend(REPORT_POST, postid_report);

  const postReportBackend = async (REPORT_POST, postid_report) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${REPORT_POST}`,

        data: {
          postid: postid_report,
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          reporttext: feedCollection.reporttext
            ? feedCollection.reporttext
            : "",
        },
        headers: {
          'MDnD_auth': `${token}`,

        },

        timeout: 60000,
        responseType: 'json',
      });
      //console.log(response, "Response");

      return response.data;
    } catch (error) {
      console.error("error is", error);
    }
  };

  const mutationReportPost = useMutation({
    mutationKey: ["PostReport"],
    mutationFn: (postid_report) => {
      setLoading(true); // Start loading
      return postReport(postid_report);
    },
    onSuccess: (data) => {
      setLoading(false); // Stop loading on success
      if (data.status === "success") {
        SuccessNewToaster("success", "Reported Successfully", 3000);
  
        setFeedCollection((prev) => ({
          ...prev,
          reporttext: "",
          validationmsg_for_reporttext: "",
          postid_for_report: "",
        }));
        setPopup((prev) => ({ ...prev, report_visible: false }));
        setCounter(counter + 1);
      } else {
        // Handle any other success status
      }
    },
    onError: (error) => {
      setLoading(false); // Stop loading on error
      console.error("Error fetching single profile:", error);
      setFeedCollection((prev) => ({
        ...prev,
        reporttext: "",
        validationmsg_for_reporttext: "",
        postid_for_report: "",
      }));
    },
  });
  


  // END OF REPORT A POST..................................


  //LIKE BUTTON...............................................START

  const handleLikePost = async (postId, postlikestatus) => {
    //console.log("inside handleLikePOst");
    if (user) {
      if (postId) {
        mutationLikePost.mutate({ postId, postlikestatus });
      } else {
        return;
      }
    }


    else if (!user) {
      setPopup((prevState) => ({ ...prevState, anon_goestologin: true }));
      //navigate("/Login");
      return;
    } else {
      return;
    }
  };

  const LikeProfile = (postId, postlikestatus) => LIKEONEPOST(LIKE_SINGLE_POST, postId, postlikestatus);

  const LIKEONEPOST = async (LIKE_SINGLE_POST, postId, postlikestatus) => {
    // gopi likes gopi's post
    let NLS;
    let isLiked;
    let checker_status = postlikestatus;

    //console.log(checker_status, "checker status");

    isLiked =
      checker_status === "Y"
        ? true
        : checker_status === "N"
          ? false
          : undefined;


    NLS = isLiked === true ? "N" : "Y";

    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${LIKE_SINGLE_POST}`,
        headers: {
          'MDnD_auth': `${token}`,

        },

        timeout: 60000,
        responseType: 'json',

        data: {
          postid: postId ? postId : "",
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          likestatus: NLS,
        },
      });


      return response;
    } catch (error) {
      throw error;
    }
  };

  const mutationLikePost = useMutation({
    mutationKey: ["LikeAPost"],
    mutationFn: ({ postId, postlikestatus }) => LikeProfile(postId, postlikestatus),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchPostsProfile"],
      });


      if (data.data.status === "success") {

        setnewLikedStatus("");

        setFeedCollection(prevState => {

          const updatedPosts = prevState.feedData.reduce((acc, post) => {
            if (post.postid === data.data.data.post_id) {

              const isLiked = data.data.data.status === "Y";


              acc.push({
                ...post,
                postlikestatus: (data.data.data.status).toString(),
                countpostlike: isLiked ? post.countpostlike + 1 : post.countpostlike - 1,
              });
            } else {

              acc.push(post);
            }
            return acc;
          }, []);



          return {
            ...prevState,
            feedData: updatedPosts,
          };
        });

        //setCounterLike(counterlike + 1);
        setlikenamescounter(likenamescounter + 1);


      }
    },
    onError: (error) => {
      HandleErrorToast(
        "error",
        "Could Not Like the Post. Something Went Wrong!",
        3000
      );

    },
  });



  // names of all those who Liked...

  // useEffect(() => {
  //   const fetchAllLikeNames = async () => {
  //     //console.log("x");
  //     let like_names = {};

  //     for (const item of feedCollection.feedData) {
  //       let names = await namesofLikes(item.postid);
  //       like_names[item.postid] = names;
  //     }
  //     if (like_names) {
  //       setNamesofLikesforPostid(like_names);
  //     } else {
  //       setNamesofLikesforPostid("");
  //     }
  //   };
  //   if (feedCollection.feedData.length > 0) {
  //     fetchAllLikeNames();
  //   }
  // }, [feedCollection.feedData, likenamescounter]);

  // const namesofLikes = async (postid) => {
  //   try {
  //     const response = await axios({
  //       method: "POST",
  //       url: `${BASEPATH}${GET_LIKES}`,
  //       data: {
  //         postid: Number(postid),
  //       },
  //     });

  //     if (response?.data.status === "success") {


  //       let result = response?.data.data[0];
  //       let filterednames =
  //         result &&
  //         result.map((item) => ({
  //           name: item.artist_name,
  //         }));


  //       let all_names_string =
  //         filterednames && filterednames.map((item) => item.name).join(", ");

  //       return all_names_string;
  //     } else {
  //       return "";
  //     }
  //   } catch (error) {
  //     console.error("Error fetching detailed data:", error);
  //     return "error";
  //   }
  // };

  const handleNamesofAllThoseLiked = async (postid) => {
    //console.log(postid, "postid");
    setPopup((prevState) => ({ ...prevState, names_likes: true }));

    const namesofLikes = async (postid) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${BASEPATH}${GET_LIKES}`,
          data: {
            postid: Number(postid),
          },
          headers: {
            'MDnD_auth': `${token}`,

          },

          timeout: 60000,
          responseType: 'json',
        });

        if (response?.data.status === "success") {
          let result = response?.data.data[0];
          let filterednames = result && result.map((item) => ({
            name: item.artist_name,
            photo: item.profile_photo,
            role_name: item.role_name,
            role_id: item.role_id,
            profile_name: item.profile_name
          }));

          if (filterednames) { setArrayofLikeNames(filterednames) }

          let all_names_string = filterednames && filterednames.map(item => item.name).join(', ');
          return all_names_string;
        } else {
          return "";
        }
      } catch (error) {
        console.error("Error fetching detailed data:", error);
        return "error";
      }
    };


    let names = await namesofLikes(postid);

    let like_names = {};
    like_names[postid] = names;


    setNamesofLikesforPostid(like_names);


  };


  const handleClose_namesLikes = () => {
    setPopup((prevState) => ({ ...prevState, names_likes: false }));
    setNamesofLikesforPostid({});
    setArrayofLikeNames([]);
  }

  const handleLikeNameLeadstoProfile = (profileData) => {



    if (profileData.role_name === "artist") {
      navigate(`/artist/${profileData.profile_name}`, {
        state: { artistId: profileData.role_id }
      });
    }
    else if (profileData.role_name === "organiser") {
      navigate(`/organiser/${profileData.profile_name}`, { state: { organiserId: profileData.role_id } });
    }
    else {
      return;
    }

  }

  const getDisplayNames = (names) => {
    if (!names) return "";
    const nameArray = names.split(", ");
    if (nameArray.length > 0) {
      return `${nameArray[0]} ...`;
    }
    return names;
  };




  //END OF LIKE BUTTON.......................................


  //start of COMMENTS SECTION..........................

  const handleCommentSubmitButton = () => {
    if (postCollection.comment_text === "") {
      setPostCollection((prevState) => ({
        ...prevState,
        validation_for_comments: "Please write a comment",
      }));
    } else {
      setPostCollection((prevState) => ({
        ...prevState,
        validation_for_comments: "",
      }));
      mutationCommentprofile.mutate(postCollection.id_for_comment);
    }
  };

  const handleComment = (pmeter1) => {
    //console.log(pmeter1, "pmeter1 comment");

    if (user) {
      setPostCollection((prevState) => ({
        ...prevState,
        id_for_comment: pmeter1,
      }));

      setPopup((prevState) => ({ ...prevState, commentbox_visible: true }));

      if (pmeter1) {
        mutationgetComment.mutate(pmeter1);
      } else {
        return;
      }
    } else {
      setPopup((prevState) => ({ ...prevState, anon_goestologin: true }));
      //navigate("/Login");
      //handlegotoLogin();
      return;
    }
  };

  //fetch comments from backend- tanstack

  const fetchComments = (postid_comment) =>
    GETCOMMENTS(GET_POSTS_COMMENTS, postid_comment);

  const GETCOMMENTS = async (GET_POSTS_COMMENTS, postid_comment) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_POSTS_COMMENTS}`,

        data: {
          postid: postid_comment,
        },
        headers: {
          'MDnD_auth': `${token}`,

        },

        timeout: 60000,
        responseType: 'json',
      });
      //console.log(response, "Response");

      return response.data;
    } catch (error) {
      console.error("error is", error);
    }
  };

  const mutationgetComment = useMutation({
    mutationKey: ["fetchComments"],
    mutationFn: (postid_comment) => fetchComments(postid_comment),
    onSuccess: (data) => {
      if (data.status === "success") {
        //console.log(data.data, "data.data");

        setFeedCollection((prev) => ({
          ...prev,
          CommentsData: data.data,
        }));
        setCounter(counter + 1);
      } else {
        setFeedCollection((prev) => ({ ...prev, CommentsData: "" }));
      }
    },
    onError: (error) => {
      console.error("Error fetching single profile:", error);
    },
  });

  // const handleCommentTextbox = (e) => {
  //   //console.log(e, "EVENT");
  //   if (e) {
  //     setPostCollection((prevState) => ({
  //       ...prevState,
  //       comment_text: e.target.value,
  //     }));
  //     if (e.value.length > 0) {
  //        setPostCollection((prev) => ({ ...prev, validation_for_comments: "" }));
  //     }
  //   } else {
  //     return;
  //   }
  // };


  const handleCommentTextbox = (e) => {
    setPostCollection((prevState) => ({
      ...prevState,
      comment_text: e?.target.value,
    }));

    if (e?.value?.length > 0) {
      setPostCollection((prev) => ({ ...prev, validation_for_comments: "" }));
    }

  }

  const handleCommentReset = () => {
    // setPostCollection((prevState) => ({
    //   ...prevState,
    //   comment_text: "",
    //   id_for_comment: "",
    //   validation_for_comments: "",
    // }));

    setPopup((prevState) => ({ ...prevState, commentbox_visible: false }));
  };



  const CommentSingleProfile = (commentid) =>
    COMMENTONEPOST(COMMENT_SINGLE_POST, commentid);

  const COMMENTONEPOST = async (COMMENT_SINGLE_POST, commentid) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${COMMENT_SINGLE_POST}`,

        data: {
          postid: commentid,
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          comments: postCollection.comment_text
            ? postCollection.comment_text
            : "",
        },
        headers: {
          'MDnD_auth': `${token}`,

        },

        timeout: 60000,
        responseType: 'json',
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationCommentprofile = useMutation({
    mutationKey: ["CommentSingleProfile"],
    mutationFn: (commentid) => CommentSingleProfile(commentid),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchPostsProfile"],
      });
      //console.log(data, "DATA from Comments");

      if (data.status === "success") {
        //SuccessToaster('success', 'Post Created Successfully', 3000);
        SuccessNewToaster("success", "Comment Posted Successfully!", 3000);

        setFeedCollection(prevState => {
          const updatedPosts = prevState.feedData.reduce((acc, post) => {
            if (post.postid === data.data.post_id) {
              // Update the specific post
              acc.push({
                ...post,
                countpostcomment: post.countpostcomment + 1,
              });
            } else {
              // Add the post as is
              acc.push(post);
            }
            return acc;
          }, []);

          //console.log(updatedPosts, "updatedPosts");


          return {
            ...prevState,
            feedData: updatedPosts,
          };
        });

        setPostCollection((prevState) => ({
          ...prevState,
          id_for_comment: "",
          comment_text: "",
        }));



        setPopup((prevState) => ({ ...prevState, commentbox_visible: false }));
        //setCounter(counter + 1);

        //alert("successfully updated by vicki");
        //handleCloseafterSubmit();
      }
    },
    onError: (error) => {
      HandleErrorToast("error", "Comment could not be Posted", 3000);
      setPostCollection((prevState) => ({
        ...prevState,
        id_for_comment: "",
        comment_text: "",
      }));
      setPopup((prevState) => ({ ...prevState, commentbox_visible: false }));

      //alert("Did not post profile");
      //handleCloseafterSubmit();
    },
  });

  //console.log(postCollection, "postCollection");
  //console.log(displayProfilePosts.gridData, "displayProfilePosts.gridData");
  //console.log(updatedComments,"updatedComments");

  // new changes





  // console.log(commentstable, "commentstable");

  //let newfilteredGridData = displayProfilePosts.gridData && displayProfilePosts.gridData[0];

  useEffect(() => {
    if (feedCollection.feedData) {
      setFeedCollection((prevState) => ({
        ...prevState,
        newfilteredGridData: feedCollection.feedData,
      }));
    }
  }, [feedCollection.feedData]);




  useEffect(() => {
    if (feedCollection.CommentsData) {
      let comments_for_that_id =
        feedCollection.CommentsData && feedCollection.CommentsData[0];
      setNewUpdatedComments(comments_for_that_id);
    }
  }, [feedCollection.CommentsData]);

  const handleCommentsEditing = (e, commentId) => {
    const index =
      newupdatedComments &&
      newupdatedComments.findIndex(
        (comment) => comment.post_comm_id === commentId
      );

    if (index !== -1) {
      const updated = [...newupdatedComments];
      updated[index] = { ...updated[index], comments_text: e.target.value };
      setNewUpdatedComments(updated);
    }
  };

  function formatDate(timestamp) {
    const date = new Date(timestamp);

    // Extract day, month, and year
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    // Determine the suffix for the day
    const suffix = (day) => {
      if (day > 3 && day < 21) return "th"; // All days between 4 and 20 get 'th'
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${suffix(day)} ${month}, ${year}`;
  }



  const handleEdit = (pmeter1) => {

    //setCheckerEditComment(true);
    let Comment_Edit =
      newupdatedComments &&
      newupdatedComments.filter((item) => item.post_comm_id == pmeter1);

    //console.log(Comment_Edit, "comment_edit");

    setCheckerEditComment(Comment_Edit[0].post_comm_id);
    setcheckerforMenuClassname(false);



  }

  const handleCancelforCommentEdit = () => {

    setCheckerEditComment(0);
    setcheckerforMenuClassname(false);

  }

  const handlemenuchange = () => {
    setcheckerforMenuClassname(true);

  }
  const commentsComponent = (postid) => {

    //console.log(newupdatedComments,"newupdatedComments");
    return (
      <div>
        {loading ? (
          // Render SkeletonLoader for each item when loading
          newupdatedComments.map((_, index) => (
            <Skeleton height={500} />
          ))
        ) : (
          // Render actual comments when not loading
          newupdatedComments &&
          newupdatedComments.map((comment) => (
            <div key={comment.post_comm_id}>
              <ul className="list-inline m-0 p-0">
                <li className="py-2 d-noneSmallDevice">
                  <div className="d-flex flex-sm-row flex-column align-items-sm-center align-items-start borderLine">
                    <div className="flex-shrink-0">
                      <img
                        src={
                          comment.role_name === "artist"
                            ? comment?.profile_photo !== ""
                              ? `${ArtistallImg}/${comment?.profile_photo}`
                              : `${IMAGE_URL}/noimage.png`
                            : comment.role_name === "organiser"
                              ? comment?.profile_photo !== ""
                                ? `${IMAGE_URL}/${comment?.profile_photo}`
                                : `${IMAGE_URL}/noimage.png`
                              : `${IMAGE_URL}/noimage.png`
                        }
                        className="commentImg comment-profile-image"
                        alt="user-profile"
                      />
                    </div>
                    <div className="about-user w-100 ms-2 commentPopText">
                      <div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
                        <div className="d-flex">
                          <div>
                            <p className="mb-0 commentPopName">
                              <b>{comment.artist_name}</b>
                              <span className="commentDayText ms-2">2d</span>
                            </p>
                            <p className="destText mb-0" style={{ fontSize: '12px' }}>
                              {comment.role_name}
                            </p>
                          </div>
                        </div>
                        <div id="container">
                          <div id="menu-wrap">
                            <input type="checkbox" className="toggler" />
                            <div className="dots" onClick={handlemenuchange}>
                              <div></div>
                            </div>

                            {user?.login_id === comment.login_id &&
                              user?.role_id === comment.role_id && (
                                <div className="menu p-1">
                                  <ul className="p-0">
                                    <li>
                                      <button
                                        onClick={() =>
                                          handleEdit(comment.post_comm_id)
                                        }
                                      >
                                        Edit
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        onClick={() =>
                                          handleDeleteComment(comment.post_comm_id)
                                        }
                                      >
                                        Delete
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="mb-0 commentText">
                          {checkereditComment !== comment.post_comm_id ? (
                            comment.comments_text
                          ) : (
                            <div>
                              <input
                                type="text"
                                placeholder="Write a Comment!"
                                value={comment.comments_text}
                                onChange={(e) => {
                                  if (
                                    user?.login_id === comment.login_id &&
                                    user?.role_id === comment.role_id
                                  ) {
                                    handleCommentsEditing(e, comment.post_comm_id);
                                  }
                                }}
                              />
                              {user?.login_id === comment.login_id &&
                                user?.role_id === comment.role_id && (
                                  <button
                                    onClick={() =>
                                      handleSaveComment(comment.post_comm_id)
                                    }
                                  >
                                    Save
                                  </button>
                                )}
                              <button
                                className="border-button"
                                onClick={handleCancelforCommentEdit}
                              >
                                Cancel
                              </button>
                            </div>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          ))
        )}
      </div>

    );
  };

  const handleSaveComment = (pmeter1) => {
    let EditC =
      newupdatedComments &&
      newupdatedComments.filter((item) => item.post_comm_id == pmeter1);

    //console.log(EditC, "Edit this Comments");

    if (EditC) {
      setEditedComment(EditC);

      mutationEditComment.mutate(editedComment);
    } else {
      return;
    }
  };

  const EditCommentSingleProfile = (editedComment) =>
    EDITCOMMENTONEPOST(EDITCOMMENT_SINGLE_POST, editedComment);

  const EDITCOMMENTONEPOST = async (EDITCOMMENT_SINGLE_POST, editedComment) => {
    //console.log(editedComment, "editedComment");
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${EDITCOMMENT_SINGLE_POST}`,

        data: {
          postid: editedComment ? editedComment[0].postid : "",
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          comments: editedComment ? editedComment[0].comments_text : "",
          postcommentid: editedComment ? editedComment[0].post_comm_id : "",
        },
        headers: {
          'MDnD_auth': `${token}`,

        },

        timeout: 60000,
        responseType: 'json',
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationEditComment = useMutation({
    mutationKey: ["EditComment"],
    mutationFn: () => EditCommentSingleProfile(editedComment),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchPostsProfile"],
      });
      //console.log(data, "DATA from EDITING A COMMENT");

      if (data.status === "success") {
        //SuccessToaster('success', 'Post Created Successfully', 3000);
        SuccessNewToaster("success", "Comment Edited Successfully", 3000);

        setEditedComment("");

        setCounter(counter + 1);
        handleCancelforCommentEdit();

        //alert("successfully updated by vicki");
        //handleCloseafterSubmit();
      }
    },
    onError: (error) => {
      HandleErrorToast(
        "error",
        "Could Not Edit the Comment. Something Went Wrong!",
        3000
      );
      setEditedComment("");

      //alert("Did not post profile");
      //handleCloseafterSubmit();
    },
  });

  const handleDeleteComment = (pmeter1) => {
    let DeleteC =
      updatedComments &&
      updatedComments.filter((item) => item.postcommandid == pmeter1);

    //console.log(DeleteComment,"Delete this Comment");

    if (DeleteC) {
      setDeleteComment(DeleteC);
      mutationDeleteComment.mutate(deleteComment);
    } else {
      return;
    }
  };

  const DeleteCommentSingleProfile = (deleteComment) =>
    DELETECOMMENTONEPOST(DELETECOMMENT_SINGLE_POST, deleteComment);

  const DELETECOMMENTONEPOST = async (
    DELETECOMMENT_SINGLE_POST,
    deleteComment
  ) => {
    //console.log(deleteComment, "deleteComment");
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${DELETECOMMENT_SINGLE_POST}`,

        data: {
          postid: deleteComment ? deleteComment[0].postid : "",
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          status: "N",
          postcommentid: deleteComment ? deleteComment[0].post_comm_id : "",
        },
        headers: {
          'MDnD_auth': `${token}`,

        },

        timeout: 60000,
        responseType: 'json',
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationDeleteComment = useMutation({
    mutationKey: ["DeleteComment"],
    mutationFn: () => DeleteCommentSingleProfile(deleteComment),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchPostsProfile"],
      });
      //console.log(data, "DATA from EDITING A COMMENT");

      if (data.status === "success") {
        //SuccessToaster('success', 'Post Created Successfully', 3000);
        SuccessNewToaster("success", "Comment deleted Successfully!", 3000);

        setDeleteComment("");

        setCounter(counter + 1);

        //alert("successfully updated by vicki");
        //handleCloseafterSubmit();
      }
    },
    onError: (error) => {
      HandleErrorToast(
        "error",
        "Could Not Delete the Comment. Something Went Wrong!",
        3000
      );
      setDeleteComment("");

      //alert("Did not post profile");
      //handleCloseafterSubmit();
    },
  });

  const commentsCount = (postid) => {
    let postComments_main =
      updatedComments &&
      updatedComments.filter((comment) => comment.postcommandpostid === postid);
    let postCommentsCount = postComments_main ? postComments_main.length : 0;

    return postCommentsCount;
  };

  const GridPostHeader = (postid) => {
    let filteredGridData_popup =
      feedCollection.newfilteredGridData &&
      feedCollection.newfilteredGridData.filter(
        (item) => item.postid === postid
      );

    //console.log(organiserData.data,"GOPI");

    return (
      <div className="headers left-info d-flex justify-content-start align-items-center gap-2 ">
        <div className="thumbnail">
          {/* <img
            //src={`${IMAGE_URL}/${organiserData?.result?.[0]?.profile_photo}`}
            alt
          /> */}
          <div className="cast-box position-relative commentPopImg text-center">
            <img
              src={
                photoOrganiser && photoOrganiser.photo != ""
                  ? `${IMAGE_URL}/${photoOrganiser.photo}`
                  : `${IMAGE_URL}/noimage.png`
              }
              className="img-fluid object-cover"
              alt="person"
            //loading="lazy"
            //onMouseEnter={() => setIsHovered(true)}
            //onMouseLeave={() => setIsHovered(false)}
            />
          </div>
        </div>
        <div className="name-info">
          <div className="name">
            {/* <a href="">{organiserData?.result?.[0]?.organiser_name}</a> */}
          </div>
          <div className="time">
            {formatDate(filteredGridData_popup[0].postcreateddate)}
            <i className="global-icon"></i>
          </div>
        </div>
      </div>
    );
  };

  const GridPostDisplayinPopup = (postid) => {
    // let filteredGridData_popup =
    //   displayProfilePosts.gridData &&
    //   displayProfilePosts.gridData.filter((item) => item.postid === postid);

    let filteredGridData_popup =
      feedCollection.newfilteredGridData &&
      feedCollection.newfilteredGridData.filter(
        (item) => item.postid === postid
      );

    return (
      <div>
        {filteredGridData_popup && filteredGridData_popup.length > 0 && (
          <>
            <div className="content pb-2">
              <p className="">{filteredGridData_popup[0].postdescr}</p>

              {filteredGridData_popup[0].postimage && (
                <img
                  src={`${BASEPATH}${GET_IMAGES_PROFILE}${filteredGridData_popup[0].postimage}`}
                  alt={filteredGridData_popup[0].postdescr}
                  className="w-100"
                />
              )}

              {filteredGridData_popup[0].postvideo && (
                <ReactPlayer
                  url={filteredGridData_popup[0].postvideo}
                  className="w-100"
                  controls={true}
                />
              )}

              {filteredGridData_popup[0].postaudio && (
                <ReactPlayer
                  url={filteredGridData_popup[0].postaudio}
                  className="w-100"
                  controls={true}
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const handleClose_commentpopup = () => {
    setPopup((prevState) => ({ ...prevState, commentbox_visible: false }));
    setPostCollection((prevState) => ({
      ...prevState,
      comment_text: "",
    }));
  };


  //END OF COMMENTS SECTION.................................

  // TO GET THE IMAGE FOR EACH POST..........

  // who to FOLLOW CODE....
  useEffect(() => {
    if (user) { mutationwhotoFollow.mutate(); }

  }, [counterforFollow]);

  const fetchWhotoFollow = () => GETWHOTOFOLLOW(GET_WHOTOFOLLOW);

  const mutationwhotoFollow = useMutation({
    mutationKey: ["fetchWhotoFollow"],
    mutationFn: () => fetchWhotoFollow(),
    onSuccess: (data) => {

      if (data.Status === "success") {
        setFeedCollection((prev) => ({ ...prev, whotoFollow: data.data }));
      }
      else {
        setFeedCollection((prev) => ({ ...prev, whotoFollow: "" }));
      }
    },
    onError: (error) => {
      console.error("Error fetching who to follow:", error);
      setFeedCollection((prev) => ({ ...prev, whotoFollow: "" }));
    },
  });

  const GETWHOTOFOLLOW = async (GET_WHOTOFOLLOW) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_WHOTOFOLLOW}`,

        data: {
          role_id: user ? user.role_id : "",
        },
        headers: {
          'MDnD_auth': `${token}`,

        },

        timeout: 60000,
        responseType: 'json',
      });

      return response.data;
    } catch (error) {
      console.error("error is", error);
    }
  };



  //FOLLOW/UNFOLLOW...

  const handleFollow = (item) => {

    mutationFollow.mutate(item);


  };

  const Follow_function = (item) => FollowtoBackend(FOLLOW_A_USER, item);

  const FollowtoBackend = async (FOLLOW_A_USER, item) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${FOLLOW_A_USER}`,

        data: {
          user1_loginid: user ? Number(user.login_id) : "",
          user2_loginid: item.login_id
            ? Number(item.login_id)
            : "",


          user1_roleid: user ? Number(user.role_id) : "",
          user2_roleid: item.role_id
            ? Number(item.role_id)
            : "",
          follower_roleid: user ? Number(user.role_id) : "",
          following_roleid: item.role_id
            ? Number(item.role_id)
            : "",
        },
        headers: {
          'MDnD_auth': `${token}`,

        },

        timeout: 60000,
        responseType: 'json',
      });
      //console.log(response, "Response");

      return response.data;
    } catch (error) {
      console.error("error is", error);
    }
  };

  const mutationFollow = useMutation({
    mutationKey: ["FollowUser"],
    mutationFn: (item) => Follow_function(item),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchWhotoFollow"],
      });
      if (data.status === "success") {
        //console.log(data, "data for follow");
        //SuccessNewToaster("success", "Followed Succesfully", 3000);
        setcounterforFollow(counterforFollow + 1);
        setCounterUserProfile_follow(counteruserprofile_follow + 1);
        queryClient.invalidateQueries({
          queryKey: ["FollowIniitalStatus"],
        });

        //setDisplayProfilePosts((prev) => ({ ...prev, reporttext: "", validationmsg_for_reporttext: "", postid_for_report: "" }));
        //setPopup((prev) => ({ ...prev, report_visible: false }));
        //setCounter(counter + 1);
      } else {
        //setDisplayProfilePosts((prev) => ({ ...prev, CommentsData: "" }));
      }
    },
    onError: (error) => {
      console.error("Error fetching single profile:", error);
      //setDisplayProfilePosts((prev) => ({ ...prev, reporttext: "", validationmsg_for_reporttext: "", postid_for_report: "" }));
    },
  });


  //console.log(feedCollection, "FeedCollection");


  return (
    <div>

      <div className="row m-0 p-0">
        <div className="col-lg-7 order-lg-1 order-2 p-2 col-12 mt-3 mt-lg-0">
          <div id="app" className="w-100 d-flex flex-column gap-3 border-0 mt-3">
            {loading
              ? Array.from({ length: 2 }).map((_, index) => (
                <Skeleton key={index} height={600}/> 
              ))
              : feedCollection.feedData &&
              feedCollection.feedData.map((item) => (
                <div key={item.postid} className="border rounded border-light pb-3">
                  <div className="headers">
                    <div className="left-info">
                      <div className="thumbnail">
                        <img src={feedImg} alt="" />
                      </div>
                      <div className="name-info">
                        <div className="name">
                          <a href="">
                            {/* {organiserData?.result?.[0]?.organiser_name} */}
                          </a>
                        </div>
                        <div className="time d-flex gap-1">
                          {formatDate(item.postcreateddate)}
                          <i className="global-icon"></i>
                        </div>
                      </div>
                    </div>
                    <div id="container" className="d-flex align-items-center">
                      <div id="menu-wrap">
                        <input type="checkbox" className="toggler" />
                        <div className="dots">
                          <div></div>
                        </div>
                        <div className="menu p-1">
                          <div>
                            <ul className="p-0">
                              <li>
                                {user && (
                                  <div>
                                    <button
                                      onClick={() => handleReport(item.postid)}
                                      className="text-nowrap"
                                    >
                                      Report this post
                                    </button>
                                  </div>
                                )}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="feed-content">{item.postdescr} </div>
                  {item.postimage && (
                    <img
                      className="w-100"
                      src={`${BASEPATH}${GET_IMAGES_PROFILE}${item.postimage}`}
                      alt={item.postdescr}
                    />
                  )}
                  {item.postvideo && (
                    <ReactPlayer
                      className="w-100"
                      url={item.postvideo}
                      controls={true}
                    />
                  )}
                  {item.postaudio && (
                    <ReactPlayer
                      className="w-100"
                      url={item.postaudio}
                      controls={true}
                    />
                  )}
                  <div className="feedback-info">
                    <div className="threads-and-share">
                      <div className="threads">
                        <button
                          onClick={() =>
                            handleNamesofAllThoseLiked(
                              item.postid,
                              item.postlikestatus
                            )
                          }
                          className="like-button"
                        >
                          {item.countpostlike ? item.countpostlike : 0} Likes
                        </button>
                        ,{item.countpostcomment ? item.countpostcomment : 0} Comments
                      </div>
                    </div>
                  </div>
                  <div className="feedback-action mt-3">
                    <div className="fb-wrapper">
                      <button
                        onClick={() =>
                          handleLikePost(item.postid, item.postlikestatus)
                        }
                      >
                        {item.postlikestatus === "Y" && (
                          <i className="fa-solid fa-heart"></i>
                        )}
                        {item.postlikestatus === "N" && (
                          <i className="fa-regular fa-heart"></i>
                        )}
                        {(item.postlikestatus === null ||
                          item.postlikestatus === undefined) && (
                            <i className="fa-regular fa-heart"></i>
                          )}
                      </button>
                    </div>
                    <div className="fb-wrapper">
                      <i className="fb-icon response far fa-comment-alt"></i>
                      <button onClick={() => handleComment(item.postid)}>
                        Comment
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="col-lg-5 order-lg-2 order-1 col-12 px-lg-3 p-0 ">
          <div className="p-0 whoFollowDesk whoFollowImg">
            <div className="col-lg-12 p-lg-2 followerScroll shadow-sm rounded p-2 py-3">
              <h5 className="ps-2 my-2">Follow people</h5>
              <div style={{ maxHeight: "90vh" }} className="d-flex flex-row flex-lg-column justify-content-between align-items-center followerImg shadow-sm rounded p-2 m-2 mt-3 gap-3 overflow-auto">

                {/*begin map function */}
                {feedCollection.whotoFollow && feedCollection.whotoFollow.map((item) => (
                  <div className="d-flex flex-column flex-lg-row w-100 align-items-center justify-content-center gap-1 gap-lg-0 my-1 my-lg-0 ">
                    <img src={IMAGE3} className="rounded-circle" />
                    <div className="ms-lg-2 mt-2 mt-lg-0 w-100 d-flex flex-column justify-content-center">
                      <p className="mb-0 text-center text-lg-start " style={{ height: '20px', overflow: 'hidden' }}>
                        <b>{item.username}</b>
                      </p>
                      <p className="mb-0 w-100 text-center text-lg-start">{item.role_name}</p>
                    </div>
                    <div className="iq-button">
                      <button className="button px-3 py-1" onClick={() => handleFollow(item)}> Follow </button>
                    </div>
                  </div>))}
                {/* end of map function */}

              </div>
            </div>


          </div>
        </div>


      </div>
    </div>

  );
};

export default Feed;
