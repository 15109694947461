import React, { useState, useEffect, useContext, useRef } from "react";
import DataGrid, {
  Column,
  SearchPanel,
  Selection,
  MasterDetail,
} from "devextreme-react/data-grid";
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { generateReverseSerialNumber } from "../../../utils/commonUtils";
import CreateEvents from "./createEvents";
import { getDraftsDetails } from "./EventsFetch";
import AuthContext from "../../../context/AuthContext";
import { SUCCESS } from "../../../global/ToastMsgConstants.tsx";
import { GlobalContext } from "../../../global/provider.js";
import { GrRevert } from "react-icons/gr";
import Skeleton from "react-loading-skeleton"; 

const SavedDrafts = () => {
  const savedDraftRef = useRef(null);
  const { user } = useContext(AuthContext);
  const { eventsDatasource } = useContext(GlobalContext);

  const [dataSource, setDataSource] = useState({
    draftDataSource: "",
  });
  const [loading, setLoading] = useState(true); 

  const deleteEvent = () => {};

  const fetchDraftDetails = async () => {
    try {
      const body = {
        role_id: user?.role_id,
        draft_from: "event",
      };
      setLoading(true); // Set loading to true when fetching starts
      const response = await getDraftsDetails(body);
      if (response && response.status === SUCCESS) {
        const parsedDrafts =
          response &&
          response?.data?.map((draft) => {
            try {
              return {
                ...draft,
                draft_id: draft.draft_id,
                draft_data: JSON.parse(draft.draft_data),
              };
            } catch (e) {
              console.log(e, "e");
              console.error(
                `Error parsing draft_data for draft_id ${draft.draft_id}:`,
                e
              );
              return { ...draft, draft_data: null };
            }
          });
        setDataSource((prev) => ({
          ...prev,
          draftDataSource: parsedDrafts,
        }));
      }
    } catch (e) {
      console.error("Error fetching draft details:", e);
    } finally {
      setLoading(false); // Set loading to false when fetching is done
    }
  };

  useEffect(() => {
    if (user && user?.role_id) {
      fetchDraftDetails();
    } else {
      console.error("Role id is missing while creating Drafts");
    }
  }, [user?.role_id]);

  const DraftEventsWrapper = (props) => {
    const isEditEvent = { isEditEvent: false };
    return <CreateEvents {...props} {...isEditEvent} />;
  };

  useEffect(() => {
    if (eventsDatasource.saveDraftRefetchTrigger !== 0) {
      if (savedDraftRef.current) {
        savedDraftRef.current.instance.collapseAll(-1);
        fetchDraftDetails();
      }
    }
  }, [eventsDatasource.saveDraftRefetchTrigger]);

  const [isRowOpened, setRowOpened] = useState(false);

  const handleEditClick = (data) => {
    if (savedDraftRef.current) {
      const dataGridInstance = savedDraftRef.current.instance;
      const selectedKey = dataGridInstance.getKeyByRowIndex(data.rowIndex);

      if (!dataGridInstance.isRowExpanded(selectedKey)) {
        dataGridInstance.expandRow(selectedKey);
        setRowOpened(true);
      } else {
        dataGridInstance.collapseRow(selectedKey);
        setRowOpened(false);
      }
    }
  };

  return (
    <div>
      {loading ? ( // Show skeleton loader when loading
        <Skeleton count={10} height={40} /> // Adjust count and height as needed
      ) : (
        <DataGrid
          dataSource={dataSource.draftDataSource || []}
          showBorders={true}
          columnAutoWidth={true}
          selectionFilter={true}
          columnHidingEnabled={true}
          rowAlternationEnabled={true}
          allowSorting={false}
          ref={savedDraftRef}
          onRowExpanding={(event) => {
            event.component.collapseAll(-1);
          }}
        >
          <MasterDetail enabled={true} component={DraftEventsWrapper} />
          <Selection mode="single" />
          <SearchPanel visible={true} width={240} placeholder="Search..." />
          <Column
            width={60}
            dataField="ID"
            caption="S.no"
            calculateCellValue={(rowData) =>
              generateReverseSerialNumber(rowData, dataSource.draftDataSource)
            }
          />
          <Column dataField="created_at" width={120} caption="Created on" />
          <Column
            dataField="event_title"
            caption="Title"
            cellRender={(e) => {
              if (e && e.row && e?.data && e?.data?.draft_data) {
                return <span>{e.data.draft_data.event_title}</span>;
              }
            }}
          />
          <Column
            caption={!isRowOpened ? "Edit" : "Cancel"}
            cellRender={({ data, rowIndex }) => (
              <div>
                {!isRowOpened ? (
                  <MdModeEditOutline
                    className="EditIcon"
                    onClick={() => handleEditClick({ rowIndex })}
                  />
                ) : (
                  <GrRevert
                    className="EditIcon"
                    onClick={() => handleEditClick({ rowIndex })}
                  />
                )}
              </div>
            )}
            width={80}
          />
          <Column
            caption="Delete"
            cellRender={({ data, key }) => (
              <div>
                <RiDeleteBinLine
                  className="DeleteIcon"
                  onClick={() => deleteEvent(data, key)}
                />
              </div>
            )}
            width={80}
          />
        </DataGrid>
      )}
    </div>
  );
};

export default SavedDrafts;
