import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { customEncrypt } from "../utils/encryptAndDecrypt";

const PreviewEvent = ({ show, onClose, message, imageSource }) => {
  const navigate = useNavigate();
  const navigatePreview = () => {
    try {
      if (message && message[0]) {
        const { country_id, event_id } = message[0];
        const encyptedData = customEncrypt("yes");
        navigate(
          `/events/ProductDetails?ci=${country_id}&e=${event_id}&editEvent=${encyptedData}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal show={show} onHide={onClose} centered>
      <div className="mx-auto warningPopupWidth">
        <Modal.Body className="text-center">
          <img src={imageSource} className="w-100"></img>
          <div className="d-flex justify-content-end mt-3">
            <button
              className={
                "btn py-1 px-3 filterBtn m-0 text-nowrap me-2 brandcolor"
              }
              onClick={() => onClose()}
            >
              Cancel
            </button>

            <button className="button" onClick={(e) => navigatePreview(e)}>
              Preview Event
            </button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default PreviewEvent;
