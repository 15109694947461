import React, { useState, useEffect, useContext } from "react";
import notify from "devextreme/ui/notify";
import SeasonEvents from "./SeasonEvents";
import { GlobalContext } from "../../../global/provider";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import { ERROR, DURATION, SUCCESS } from "../../../global/ToastMsgConstants.tsx";
import { editEventsFetch, viewEventsFetch } from "../Events/EventsFetch";
import { APPLICABLE, NOT_APPLICABLE } from "../../../global/constants";
import { GET_COUNTRIES } from "../../../Api/api";
import { generateReverseSerialNumber, ImageRender } from "../../../utils/commonUtils";
import { convertDateFormatDDMMYYYY } from "../../../utils/dateFormatUtils";
import AuthContext from "../../../context/AuthContext";
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import DataGrid, {
  Column,
  MasterDetail,
  SearchPanel,
} from "devextreme-react/data-grid";
import { makeGetRequest } from "../../../Api/requestMethods.js";
import { SelectBox } from "devextreme-react";
import { ViewEventImagePath } from "../../../config/config.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ViewSeasonEvents = () => {
  const { user } = useContext(AuthContext);
  const { userDetails } = useContext(GlobalContext);
  const queryClient = useQueryClient();

  const [dataSouce, setDataSource] = useState({
    viewEvent: "",
    viewEventTrigger: 0,
    selectedCountry: 101,
  });

  const viewDataMutation = useMutation({
    mutationFn: (state_id) => viewEventsFetch(state_id),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, viewEvent: data?.data }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  const editDataMutation = useMutation({
    mutationFn: (data) => editEventsFetch(data),
    onSuccess: (data) => {
      setDataSource((prev) => ({
        ...prev,
        viewEventTrigger: prev.viewEventTrigger + 1,
      }));
      notify(data.message, SUCCESS, DURATION);
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  useEffect(() => {
    if (user && user.role_id !== null) {
      const body = {
        role_id: user?.role_id,
        country_id: 101,
        is_season_event: "Y",
      };
      viewDataMutation.mutate(body);
    } else {
      // notify("Profile details not found", ERROR, DURATION);
    }
  }, [userDetails, dataSouce.viewEventTrigger]);

  const showToPublicEvent = (e, row) => {
    const event_id = row && row?.data?.event_id;
    const body = {
      showtopublic: e.target.checked ? APPLICABLE : NOT_APPLICABLE,
      country_id: 101,
      event_id: event_id,
      role_id: user?.role_id,
    };
    editDataMutation.mutate(body);
  };

  const switchElement = (row) => {
    const isApplicable = row.value === APPLICABLE ? true : false;
    return (
      <div className="form-check form-switch d-flex">
        <input
          className="form-check-input m-auto"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckChecked"
          checked={isApplicable}
          onChange={(e) => showToPublicEvent(e, row)}
        />
      </div>
    );
  };

  const countryFetch = async () => await makeGetRequest(GET_COUNTRIES);
  const { data: countryData, isLoading: isCountryLoading } = useQuery({
    queryKey: ["__Edit_countryFetch_Season___"],
    queryFn: () => countryFetch(),
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  const EditEventsWrapper = (props) => {
    const isEditEvent = {
      isEditEvent: true,
      country_id: dataSouce.selectedCountry,
    };
    return <SeasonEvents {...props} {...isEditEvent} />;
  };

  const handleCountryEvent = (e) => {
    setDataSource((prev) => ({ ...prev, selectedCountry: e.value }));
  };

  const onEditButtonClick = () => {};

  // Check if data is loading
  const isDataLoading = viewDataMutation.isLoading || isCountryLoading;

  return (
    <>
      {isDataLoading ? (
        <Skeleton
          height={50} 
          count={10} 
        />      ) : (
        <>
          <div className="col-lg-3 Viewevents">
            <SelectBox
              dataSource={countryData?.data || []}
              displayExpr="country_name"
              valueExpr="country_id"
              label="country"
              labelMode="floating"
              defaultValue={101}
              searchEnabled={true}
              onValueChanged={handleCountryEvent}
              value={dataSouce?.selectedCountry || null}
            />
          </div>

          <DataGrid
            dataSource={dataSouce.viewEvent || []}
            showBorders={true}
            columnAutoWidth={true}
            columnHidingEnabled={true}
            selectionFilter={true}
            rowAlternationEnabled={true}
          >
            <MasterDetail enabled={true} component={EditEventsWrapper} />
            <SearchPanel visible={true} width={240} placeholder="Search..." />
            <Column width={80}
              dataField="ID"
              caption="S.no"
              calculateCellValue={(rowData) =>
                generateReverseSerialNumber(rowData, dataSouce.viewEvent)
              }
            />
            <Column
              dataField="event_date"
              caption="Event date"
              calculateCellValue={(rowData) =>
                convertDateFormatDDMMYYYY(rowData.event_date)?.fromDate
              }
            />
            <Column dataField="season_event_title" caption="Event title" />
            <Column dataField="event_mode" caption="Event mode" />

            <Column
              dataField="event_image"
              caption="Image"
              cellRender={(data) =>
                ImageRender(ViewEventImagePath, data, "event_image")
              }
            />

            <Column
              dataField="show_to_public"
              cellRender={switchElement}
              caption="Show to public"
              editorType="dxSwitch"
            />
            <Column
              dataField="created_date"
              caption="Created on"
              calculateCellValue={(rowData) =>
                convertDateFormatDDMMYYYY(rowData.created_date)?.fromDate
              }
            />
            <Column
              caption="Edit"
              cellRender={({ data }) => (
                <div>
                  <MdModeEditOutline
                    className="EditIcon"
                    onClick={() => onEditButtonClick(data)}
                  />
                </div>
              )}
              width={80}
            />
            <Column
              caption="Delete"
              cellRender={({ data }) => (
                <div>
                  <RiDeleteBinLine
                    className="DeleteIcon"
                    onClick={() => onEditButtonClick(data)}
                  />
                </div>
              )}
              width={80}
            />
          </DataGrid>
        </>
      )}
    </>
  );
};

export default ViewSeasonEvents;
