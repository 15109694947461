import { RAZORPAY_API_KEY } from "../../global/constants";
import { MDnD_Logo } from "../../global/constants";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useImperativeHandle, forwardRef } from "react";

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const RazorpayPayment = forwardRef((props, ref) => {
  const navigate = useNavigate();
  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  }, []);

  const handlePayment = async (paymentData) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const options = {
      key: RAZORPAY_API_KEY,
      currency: "INR",
      name: "MDnD",
      description: paymentData?.description,
      image: MDnD_Logo,
      handler: function (response) {
        if (response.razorpay_payment_id) {
          navigate(paymentData.success_url);
        }
      },
      prefill: {
        name: paymentData?.user_name,
        email: paymentData?.user_email,
      },
      theme: {
        color: "#3399cc",
      },
      order_id: paymentData?.order_id,
      modal: {
        ondismiss: function (data) {
          paymentData.success_url === "/Success"
            ? navigate("/Failed")
            : navigate("/Ads");
        },
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  useImperativeHandle(ref, () => ({
    triggerPayment: (paymentData) => handlePayment(paymentData),
  }));

  return <div />;
});

export default RazorpayPayment;
