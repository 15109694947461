import React, { useState, useEffect } from "react";
import { TagBox } from "devextreme-react/tag-box";

const DynamicSeatSelection = ({
  row,
  floorName,
  rowLabel,
  onSelectionChange,
}) => {
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    updateDataSource();
  }, [row, selectedSeats]);

  const updateDataSource = () => {
    const newDataSource = row.map((seat, columnIndex) => ({
      id: columnIndex,
      rowName: `${seat.row_label}-${seat.seat}`,
      disabled:
        selectedSeats.length === row.length - 1 &&
        !selectedSeats.includes(columnIndex),
    }));
    setDataSource(newDataSource);
  };

  const handleValueChanged = (e) => {
    setSelectedSeats(e.value);
    onSelectionChange(floorName, rowLabel, e.value, e);
  };

  return (
    <TagBox
      className="mb-2 me-2 hide-select-all"
      dataSource={dataSource}
      displayExpr="rowName"
      valueExpr="id"
      showSelectionControls={true}
      placeholder="Select seats"
      onValueChanged={handleValueChanged}
      value={selectedSeats}
      width="250px"
      showClearButton={true}
      selectAllMode={false}
    />
  );
};

export default DynamicSeatSelection;
