import React, { useEffect, useState } from 'react';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { makePostRequest } from '../../../Api/requestMethods';
import notify from 'devextreme/ui/notify';
import { DURATION, ERROR, SUCCESS } from '../../../global/ToastMsgConstants.tsx';
import { useMutation } from '@tanstack/react-query';
import { DELETE_PROMO_CARD, GET_PROMOCODE_CATEGORIES, UPDATE_PROMO_CARD } from '../../../Api/api.js';
import { Button } from 'devextreme-react/button';
import { Modal } from "react-bootstrap";
import { SelectBox, TextBox } from 'devextreme-react';
import { DiscountType } from '../../../global/constants.js';
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ViewPromoCode = () => {
  const [promoCodeData, setPromoCodeData] = useState([]);
  const [deleteData, setDeleteData] = useState([]);
  const [promoCodeId, setPromoCodeid] = useState();
  const [selectedOption, setSelectOption] = useState();
  const [category, setCategory] = useState({
    addCategory: "",
    updateCategory: "",
    openThePopup: false,
    selectallData: ""
  });
  const [popUpData, setPopUpData] = useState({
    promo_code: "",
    description: "",
    discount_type: "",
    discount_value: ""
  });
  const [loading, setLoading] = useState(true);

  const viewPromoCodeMutation = useMutation({
    mutationKey: 'promoCode',
    mutationFn: (role_id) => makePostRequest(GET_PROMOCODE_CATEGORIES(), { role_id }),
    onSuccess: (data) => {
      setPromoCodeData(data.data);
      setLoading(false);
      if (data.status === 'failed') {
        // notify(data.message, ERROR, DURATION);
      } else if (data.status === 'success') {
        // notify(data.message, SUCCESS);
      }
    },
    onError: (error) => {
      setLoading(false);
      // notify(error.message, ERROR, DURATION);
      console.log(error);
    }
  });

  useEffect(() => {
    const role_id = 37;
    viewPromoCodeMutation.mutate(role_id);
  }, []);

  // ---------------------------------------------------------------------------------------
  const onEditButtonClick = (data) => {
    console.log(data, "thiss is data for id ");
    setPromoCodeid(data.id);
    setCategory(prev => ({ ...prev, openThePopup: true }));
    const { promo_code, description, discount_type, discount_value } = data;
    setPopUpData({
      promo_code: promo_code,
      description: description,
      discount_type: discount_type,
      discount_value: discount_value
    });
  };

  // -----------------------------------------------------------------------------------------------
  const ExitPopup = () => {
    setCategory(prev => ({ ...prev, openThePopup: false }));
  };

  //------------------------HandleUpdate-------------------------------------------------------------------------  
  const promocodeUpdatedFunction = async (data) => {
    console.log(data, "din");
    try {
      const response = await makePostRequest(UPDATE_PROMO_CARD(), data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const promoCardDetailsUpdate = useMutation({
    mutationKey: ["updateprom"],
    mutationFn: (promeUpdatedData) => promocodeUpdatedFunction(promeUpdatedData),
    onSuccess: (data) => {
      if (data.status === 'failed') {
        notify(data.message, ERROR, DURATION);
      }
      if (data.status === 'success') {
        notify(data.message, SUCCESS);
      }
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
    }
  });

  const handleUpdatRowData = async () => {
    console.log(popUpData, "formdataa1123");
    const updateValues = {
      ...popUpData,
      promocode_id: promoCodeId,
      role_id: 37,
    };
    try {
      await promoCardDetailsUpdate.mutateAsync(updateValues);
    } catch (error) {
      console.log("error", error);
    } finally {
      console.log("finally is runninng");
    }
    setCategory(prev => ({ ...prev, openThePopup: false }));
  };

  //-------------------------------------------------------------------------------------------------  
  const handleOptionChange = (e) => {
    console.log(e, "this is the option value");
    setSelectOption(e);
    setPopUpData(((prev) => ({ ...prev, discount_type: e })));
  };

  // --------------------------------------------Delete----------------------------
  const onDeleteButtonClick = async (data) => {
    console.log(data, "thsi is se  eeeee eee e e e eee  ");
    const deletevalu = {
      promocode_id: data.id,
      role_id: data.role_id,
      status: 'N',
    };
    try {
      await promoCardDetailsDelete.mutateAsync(deletevalu);
    } catch (error) {
      console.log("error", error);
    } finally {
      console.log("finally is runninng");
    }
  };

  const promocodeDeletedFunction = async (data) => {
    console.log(data, "din");
    try {
      const response = await makePostRequest(DELETE_PROMO_CARD(), data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const promoCardDetailsDelete = useMutation({
    mutationKey: ["deletepromo"],
    mutationFn: (promeUpdatedData) => promocodeDeletedFunction(promeUpdatedData),
    onSuccess: (data) => {
      if (data.status === 'failed') {
        notify(data.message, ERROR, DURATION);
      }
      if (data.status === 'success') {
        notify(data.message, SUCCESS);
      }
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
    }
  });

  //------------------------------------------------------------------------------
  return (
    <div>
      {loading ? (
        <Skeleton count={10} height={40} />
      ) : (
        <DataGrid
          id=""
          dataSource={promoCodeData}
          showBorders={true}
          columnAutoWidth={true}
          columnHidingEnabled={true}
          rowAlternationEnabled={true}
          onRowUpdated={(e) => handleUpdatRowData(e)}
        >
          <Column width={80} dataField="ID" caption='S.no' />
          <Column dataField="promo_code" caption="Promocode" />
          <Column dataField="description" caption='description' />
          <Column dataField="discount_type" caption="Discount Type" />
          <Column dataField="discount_value" caption="Discount Value" />
          <Column
            caption="Edit"
            cellRender={({ data }) => (
              <div>
                <MdModeEditOutline
                  className="EditIcon"
                  onClick={() => onEditButtonClick(data)}
                />
              </div>
            )}
            width={60}
          />
          <Column
            caption="Delete"
            cellRender={({ data }) => (
              <div>
                <RiDeleteBinLine
                  className="DeleteIcon"
                  onClick={() => onDeleteButtonClick(data)}
                />
              </div>
            )}
          />
        </DataGrid>
      )}
      <Modal
        size="lg"
        show={category.openThePopup}
        centered
      >
        <Modal.Header
          className="m-0 d-flex align-items-center text-center border-light"
          style={{ padding: "10px 10px", }}
        >
          <h5 className="text-nowrap text-center m-auto"> Edit view promo code</h5>
        </Modal.Header>

        <Modal.Body>
          <div className="row mt-0 pb-0">
            <div className="col-lg-6">
              <TextBox
                label='Promo_code'
                labelMode='floating'
                onValueChanged={(e) => setPopUpData((prev) => ({ ...prev, promo_code: e.value }))}
                value={popUpData.promo_code}
              />
            </div>

            <div className="col-lg-6">
              <TextBox
                label='Promo Code Description'
                labelMode='floating'
                onValueChanged={(e) => setPopUpData((prev) => ({ ...prev, description: e.value }))}
                value={popUpData.description}
              />
            </div>

            <div className="col-lg-6">
              <SelectBox
                dataSource={DiscountType}
                displayExpr="type"
                valueExpr="value"
                labelMode='floating'
                label="Discount Type"
                value={popUpData.discount_type}
                onValueChanged={(e) => { handleOptionChange(e.value) }}
              />
            </div>
            <div className="col-lg-6">
              <TextBox
                label='Discount Value'
                labelMode='floating'
                onValueChanged={(e) => setPopUpData((prev) => ({ ...prev, discount_value: e.value }))}
                value={popUpData.discount_value}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='CancelBtn' variant="secondary" onClick={() => ExitPopup()}>
            Cancel
          </button>
          <button className='button' variant="secondary" onClick={handleUpdatRowData}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </div >
  );
};

export default ViewPromoCode;
