import React from "react";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import Button from "devextreme-react/button";
import { useFormik } from "formik";
import { FORGOT_SCHEMA } from "../Schemas/Validations/Auth";
import { useMutation } from "@tanstack/react-query";
import { makePostRequest } from "../Api/requestMethods";
import notify from "devextreme/ui/notify";
import { UPDATEPASSWORD, PROFILE_URL } from "../Api/api";
import { DURATION, ERROR, SUCCESS } from "../global/ToastMsgConstants.tsx";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import { makeGetRequest } from "../Api/requestMethods";
import Loading from "../components/Loading.js";
import PopOver from "../components/PopOver.js";
import { FaInfoCircle } from "react-icons/fa";
import { PASSWORD_LENGTH } from "../Schemas/errorMessageConstants.js";
import { SOMETHING_WENT_WRONG } from "../Schemas/errorMessageConstants.js";


const initialValues = {
  email: "",
  password: "",
  confirmpassword: "",
};

export default function UpdatePassword() {
  const location = useLocation();
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search);
  //const loginid = searchParams.get("loginId");
  const loginid = searchParams.get("loginid");

  const [passwordMode, setPasswordMode] = useState("password");

  ///new code.....................
  const [xpasswordMode, setXPasswordMode] = useState("password");


  //end of new code................

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    setFieldValue
  } = useFormik({
    initialValues: initialValues,
    validationSchema: FORGOT_SCHEMA,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      const data = { ...values, loginid: loginid };
      await updatepasswordMutation.mutateAsync(data);
      setSubmitting(true);
    },
  });



  const passwordButton = useMemo(
    () => ({
      icon: passwordMode === "password" ? "eyeclose" : "eyeopen",
      stylingMode: "text",
      onClick: () => {
        setPasswordMode((prevPasswordMode) =>
          prevPasswordMode === "text" ? "password" : "text"
        );
      },
    }),
    [passwordMode, setPasswordMode]
  );

  //new code..
  const xPasswordButton = useMemo(
    () => ({
      icon: xpasswordMode === 'password' ? 'eyeclose' : 'eyeopen',
      stylingMode: 'text',
      onClick: () => {
        setXPasswordMode((prevMode) =>
          prevMode === 'text' ? 'password' : 'text'
        );
      },
    }),
    [xpasswordMode, setXPasswordMode]
  );




  const handlepasswordApi = async (data) => {
    try {
      const response = await makePostRequest(UPDATEPASSWORD, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const profileUrlEvent = async (loginid) =>
    await makeGetRequest(PROFILE_URL(loginid));
  const profileUrlMutation = useMutation({
    mutationFn: (loginid) => profileUrlEvent(loginid),
    onSuccess: (data) => {
      setFieldValue("email", data?.data?.email);
    },
    onError: (error) => {
      notify(
        error?.response?.data?.message || SOMETHING_WENT_WRONG,
        ERROR,
        DURATION
      );
      navigate("/");
    },
  });

  useEffect(() => {
    if (loginid) {
      profileUrlMutation.mutate(loginid);
    }
  }, [loginid]);

  const updatepasswordMutation = useMutation({
    mutationKey: ["updatepassword"],
    mutationFn: (updatepasswordData) => handlepasswordApi(updatepasswordData),
    onSuccess: (data) => {
      if (data.status === "failed") {
        notify(data.message, ERROR, DURATION);
      }
      if (data.status === "success") {
        notify(data.message, SUCCESS);
      }
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
    },
  });

  return (
    <>
      <div className="row justify-content-center align-items-center" style={{padding: '40px 0 0 0'}}>
        <div className="col-lg-4 col-md-12 align-self-center">
          <div className="text-center">
            <div className="p-2 mb-2 text-white">
              <form className="form-control" onSubmit={handleSubmit}>
                <h4 className="p-2">Enter your new password</h4>
                <div className="mb-3">
                  {/* <TextBox
                    type="text"
                    name="email"
                    label="email"
                    labelMode="floating"
                    value={values.email}
                    readOnly={true}
                    className="dx-field-item-required"
                  /> */}
                </div>
                <div className="mb-3">
                  <TextBox
                    type="password"
                    value={values.password}
                    name="xpassword"
                    className="dx-field-item-required"
                    label="Password"
                    labelMode="floating"
                    mode={xpasswordMode}
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "password", value: e.value },
                      })
                    }
                    onValueChanged={(e) =>
                      handleChange({
                        target: { name: "password", value: e.value },
                      })
                    }
                  >
                    <TextBoxButton
                      name="xpassword"
                      location="after"
                      options={xPasswordButton}
                    />
                  </TextBox>
                  {errors.password && touched.password && (
                    <p className="error-message mt-1">{errors.password}</p>
                  )}
                </div>
                {/* <FaInfoCircle id="link4"></FaInfoCircle> */}
                <div className="mb-3">
                  <TextBox
                    type="password"
                    value={values.confirmpassword}
                    mode={passwordMode}
                    name="confirmpassword"
                    className="dx-field-item-required"
                    label="Confirm Password"
                    labelMode="floating"
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "confirmpassword", value: e.value },
                      })
                    }
                    onValueChanged={(e) =>
                      handleChange({
                        target: { name: "confirmpassword", value: e.value },
                      })
                    }
                  >
                    <TextBoxButton
                      name="password"
                      location="after"
                      options={passwordButton}
                    />
                  </TextBox>
                  {errors.confirmpassword && touched.confirmpassword && (
                    <p className="error-message mt-1">{errors.confirmpassword}</p>
                  )}
                </div>
                <div className="full-button">
                  <div className="iq-button">
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn position-relative h-auto"
                      text={isSubmitting ? "" : "Submit"}
                      onClick={handleSubmit}
                    //onClick={ handleSubmit1}
                    >
                      {isSubmitting && <Loading />}
                    </Button>
                  </div>
                  <PopOver target={"#link4"} message={PASSWORD_LENGTH} />

                  <p className="mt-3">
                    Back to {""}
                    <Link to="/login" style={{ color: "#3f00ff" }}>
                      Login
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
