import React from "react";
import DataGrid, {
  Scrolling,
  Column,
  Paging,
  Sorting,
  FilterRow,
  HeaderFilter,
  Pager,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { ERROR, DURATION } from "../../global/ToastMsgConstants.tsx";
import { viewTicketDetailsApi } from "../../Api/api";
import { makeGetRequest } from "../../Api/requestMethods";
import { useQuery } from "@tanstack/react-query";
import notify from "devextreme/ui/notify";
import { useSearchParams } from "react-router-dom";
import ChartView from "./viewTicketDetailsChart.js";
import { useState } from "react";
import { ViewEventImagePath } from "../../config/config.js";
import { BASEPATH } from "../../config/config.js";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

const ViewTicketDetails = () => {
  const [searchParams] = useSearchParams();
  const event_id = searchParams.get("event_id");
  const countryId = searchParams.get("country_id");
  const validCountryId = countryId || "";
  const validEventId = event_id ? event_id.split("?")[0] : "";
  const [isChartView, setIsChartView] = useState(false);

  const allowedPageSizes = [10, 25, 50, 100, "all"];

  const handleToggleChange = (e) => {
    setIsChartView(e.target.checked);
  };

  const getViewTicketDetails = async () => {
    try {
      return await makeGetRequest(
        viewTicketDetailsApi(validCountryId, validEventId)
      );
    } catch (error) {
      notify(error.response.data.message, ERROR, DURATION);
      throw error;
    }
  };
  const { data: dataSource, isLoading } = useQuery({
    queryKey: ["__Ticket_details__"],
    queryFn: getViewTicketDetails,
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  // Updated cell renderer function
  const AmountDetailsCellRender = (cellInfo, columnAmount) => {
    const { data } = cellInfo;
    console.log(data, "data");
    if (!data) return <p>0</p>;
    let qty = 0;
    try {
      const amountDetails = JSON.parse(data.booked_tickets);
      const matchingItem = amountDetails.find(
        (item) => item.amount === columnAmount
      );
      qty = matchingItem ? matchingItem.qty : 0;
    } catch (error) {
      console.error("Error in parsing booked_tickets:", error);
    }
    return <p>{qty || 0}</p>;
  };

  const generateGrossAmount = (cellInfo) => {
    try {
      const { data } = cellInfo;
      if (!data) return <p>0</p>;
      try {
        const amountDetails = JSON.parse(data?.booked_tickets);
        const totalAmount = amountDetails[0]?.event_total_amount || 0;
        const formattedAmount = new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(totalAmount);
        return <p>{formattedAmount}</p>;
      } catch (error) {
        console.error("Error in parsing booked_tickets:", error);
      }
    } catch (error) {
      console.log(error);
    }

    return <p>0</p>; // Default fallback if something fails
  };

  const bookedTicketsCellRender = (cellInfo) => {
    const { value } = cellInfo;
    if (!value) return "";
    try {
      const bookedTickets = JSON.parse(value);
      if (Array.isArray(bookedTickets) && bookedTickets.length > 0) {
        const headers = Object.keys(bookedTickets[0]);
        return (
          <table>
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th key={`header-${index}`}>
                    {header.charAt(0).toUpperCase() + header.slice(1)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {bookedTickets.map((ticket, rowIndex) => (
                <tr key={`row-${rowIndex}`}>
                  {headers.map((header, colIndex) => (
                    <>
                      <p>{header}</p>
                      <td key={`col-${rowIndex}-${colIndex}`}>
                        {ticket[header]}
                      </td>
                    </>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
      }
    } catch (error) {
      console.error("Failed to parse booked_tickets:", error);
    }

    return "";
  };

  const NonNumberedDetails =
    dataSource &&
      dataSource &&
      dataSource.data[1][0].event_details.non_numbered_details
      ? dataSource.data[1][0]?.event_details.non_numbered_details
      : [];

  const EventDetails =
    dataSource && dataSource && dataSource.data[1][0].event_details
      ? dataSource.data[1][0]?.event_details
      : [];

  const bookedTickets =
    dataSource && dataSource.data[0][0]?.ticket_orders
      ? dataSource.data[0][0]?.ticket_orders
      : [];

  const parsedBookedTickets = bookedTickets.map((data) => {
    try {
      return [...JSON.parse(data.booked_tickets)];
    } catch (error) {
      console.error("Failed to parse booked_tickets:", error);
      return { ...data, ParsedData: [] };
    }
  });

  let maxArray = [];
  if (parsedBookedTickets && parsedBookedTickets.length > 0) {
    maxArray = parsedBookedTickets.reduce((acc, cur) =>
      cur.length > acc.length ? cur : acc
    );
  }

const serialNumberCellRender = (cellInfo) => {
  const pageIndex = cellInfo.component.pageIndex(); 
  const pageSize = cellInfo.component.pageSize();
  const serialNumber = pageIndex * pageSize + cellInfo.rowIndex + 1; 
  return <p>{serialNumber}</p>;
};

  return (
    <div className="container-fluid my-4">
      <div className="row tabBodyBg mt-2 d-flex mx-lg-1">
        <div className="col-12 col-lg-1">
        {isLoading ? (
            <Skeleton height={100} />
          ) : (
            <img
              className="img-fluid product-detail-image rounded"
              alt=""
              src={`${BASEPATH}${ViewEventImagePath}${EventDetails?.event_image}`}
            />
          )}
        </div>
        <div className="col-12 mb-2 col-lg-11">
        <h4>{isLoading ? <Skeleton width={200} /> : EventDetails?.event_title}</h4>
        <div className="d-flex mt-2 gap-2 gap-lg-5">
            <div className="despWidth">
            {isLoading ? <Skeleton width={150} /> : <b>Date </b>}
            </div>
            <p className="despSecondWidth">
            {isLoading ? <Skeleton width={150} /> : `${EventDetails?.event_date} - ${EventDetails?.display_time}`}
            </p>
          </div>
          <div className="d-flex gap-2 gap-lg-5">
            <div className="despWidth">
            {isLoading ? <Skeleton width={150} /> : <b>venue </b>}
            </div>
            <p className="despSecondWidth"> {isLoading ? <Skeleton width={150} /> : EventDetails?.venue_name}
            </p>
          </div>
          <div className="d-flex gap-2 gap-lg-5">
            <div className="despWidth">
            {isLoading ? <Skeleton width={150} /> : <b>Event type </b>}
            </div>
            <p className="despSecondWidth">
            {isLoading ? <Skeleton width={200} /> : `${EventDetails?.main_cate_name} - ${EventDetails?.sub_cate_name}`}
            </p>
          </div>
        </div>
      </div>

      <div>
      {isLoading ? (
          <Skeleton count={10} height={40} />
        ) : (
        <DataGrid
          columnAutoWidth={true}
          dataSource={
            dataSource && dataSource.data[0][0]?.ticket_orders
              ? dataSource.data[0][0]?.ticket_orders
              : []
          }
          showBorders={true}
          alignment="right"
          rowAlternationEnabled={true}
          onRowExpanding={(event) => {
            event.component.collapseAll(-1);
          }}
        >
          <Paging defaultPageSize={10} />
          <Pager
            visible={true}
            allowedPageSizes={allowedPageSizes}
            displayMode={"full"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Sorting mode="multiple" />
          <Scrolling
            className="text-start"
            useNative={true}
            scrollByContent={true}
            scrollByThumb={true}
            showScrollbar="onHover"
          />
          <Column
            caption="S.No"
            alignment="left"
            cellRender={serialNumberCellRender}
            width={80}
          />

          <Column
            dataField="payment_on"
            caption="Date"
            dataType="date"
            alignment="left"
          />
          <Column
            dataField="customer_name"
            caption="Customer Name"
            alignment="left"
          />
          <Column
            dataField="customer_email"
            caption="Customer Email"
            alignment="left"
          />
          <Column
            dataField="customer_mobile"
            caption="Mobile"
            alignment="left"
          />
          <Column
            dataField="ticket_pass"
            caption="Ticket Number"
            alignment="left"
          />
          <Column
            dataField="payment_response_id"
            caption="Payment Id"
            alignment="left"
          />
          <Column dataField="discount" caption="Discount" alignment="left" />
          {maxArray.map((slugArray, index) => (
            <Column
              key={`${slugArray.id}_${slugArray.amount}_${index}`}
              caption={slugArray.amount}
              alignment="right"
              cellRender={(cellInfo) =>
                AmountDetailsCellRender(cellInfo, slugArray.amount)
              }
              width={80}
            />
          ))}
          <Column
            dataField="Gross"
            caption="Gross"
            alignment="right"
            format={{ type: "fixedPoint", precision: 2 }}
            cellRender={generateGrossAmount}
          />

          <Column dataField="discount" caption="Discount" alignment="left" />
          <Column dataField="tax_amount" caption="GST" alignment="left" />
          <Column
            dataField="hc_charge"
            caption="Handling Charges"
            alignment="right"
          />
          <Column
            dataField="hc_tax"
            caption="Handling Charges GST"
            alignment="right"
          />
          <Column
            dataField="total_amount"
            caption="Total"
            alignment="right"
            format={{ type: "fixedPoint", precision: 2 }}
          />

          <Paging defaultPageSize={7} />
        </DataGrid>
         )}
      </div>

      <div className="pb-5">
        <div className="d-flex justify-content-between mt-lg-3">
        {isLoading ? <Skeleton width={200} /> :<h4 className="col-lg-10 col-2">Summary</h4>}
          <div className="form-check col-12 col-lg-2 form-switch d-flex align-items-baseline justify-content-between">
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckChecked"
            >
              {isChartView ? "Switch to Table View" : "Switch to Chart View"}
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={isChartView}
              onChange={handleToggleChange}
            />
          </div>
        </div>

        {isLoading ? (
          <Skeleton height={200} />
        ) : isChartView ? (
          <ChartView NonNumberedDetails={NonNumberedDetails} />
        ) : (
          <DataGrid
            columnAutoWidth={true}
            dataSource={NonNumberedDetails || []}
            showBorders={true}
            alignment="right"
            rowAlternationEnabled={true}
            onRowExpanding={(event) => {
              event.component.collapseAll(-1);
            }}
          >
            <Paging defaultPageSize={10} />
            <Pager
              visible={true}
              allowedPageSizes={allowedPageSizes}
              displayMode={"full"}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Sorting mode="multiple" />
            <Scrolling
              useNative={true}
              scrollByContent={true}
              scrollByThumb={true}
              showScrollbar="onHover"
            />
            <Column
              dataField="row_name"
              caption="Category"
              dataType="date"
              alignment="right"
            />
            <Column
              dataField="amount"
              caption="Amount"
              alignment="right"
              format={{ type: "fixedPoint", precision: 2 }}
            />

            <Column
              dataField="total_quantity"
              caption="Total Quantity"
              alignment="right"
            />

            <Column
              caption="Ticket Sold"
              alignment="right"
              calculateCellValue={(rowData) => {
                const totalQuantity = parseInt(rowData.total_quantity, 10) || 0;
                const balanceTickets =
                  parseInt(rowData.balance_tickets, 10) || 0;
                return totalQuantity - balanceTickets;
              }}
            />

            <Column
              dataField="balance_tickets"
              caption="Balance Tickets"
              alignment="right"
            />

            <Column
              caption="Gross"
              alignment="right"
              calculateCellValue={(rowData) => {
                const totalQuantity = parseInt(rowData.total_quantity, 10) || 0;
                const balanceTickets =
                  parseInt(rowData.balance_tickets, 10) || 0;
                const ticketSold = totalQuantity - balanceTickets; // Calculate Ticket Sold
                const amount = parseFloat(rowData.amount) || 0; // Parse amount
                return ticketSold * amount; // Calculate Gross
              }}
              format={{ type: "fixedPoint", precision: 2 }} // Format for 2 decimal precision
            />
            <Summary>
              <TotalItem
                column="amount"
                summaryType="sum"
                displayFormat="Total: {0}"
                valueFormat={{ type: "currency", currency: "INR" }}
                alignment="right"
              />
              <TotalItem
                column="total_quantity"
                summaryType="sum"
                displayFormat="Total: {0}"
                alignment="right"
              />
              <TotalItem
                column="Ticket Sold"
                summaryType="sum"
                displayFormat="Total: {0}"
                alignment="right"
              />
              <TotalItem
                column="Gross"
                summaryType="sum"
                displayFormat="Total: {0}"
                valueFormat={{
                  type: "fixedPoint",
                  precision: 2,
                  currency: "INR",
                }}
                alignment="right"
              />
            </Summary>

            <Paging defaultPageSize={7} />
          </DataGrid>
        )}
      </div>
    </div>
  );
};

export default ViewTicketDetails;
