import React, { useEffect, useState, useContext } from "react";
import { NumberBox } from "devextreme-react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { GlobalContext } from "../../../global/provider";
import { freewithregistration } from "../../../global/constants";
import { countryIdIndia } from "../../../global/constants";

const OfflineDenomination = ({
  dataSource,
  formData,
  ticketLimitationEvent,
  capitalizeFirstLetter,
  EventModeOnline,
}) => {
  const [searchParams] = useSearchParams();
  const countryId = searchParams.get("ci");
  const { setEventDataSource, eventsDatasource } = useContext(GlobalContext);
  const [grandTotal, setGrandTotal] = useState(0);
  const [rowTotals, setRowTotals] = useState([]);

  const calculateGrandTotal = (formData) => {
    let total = 0;
    if (formData) {
      formData.forEach((data) => {
        total += (parseFloat(data.amount) || 0) * (data.no_of_tickets || 0);
      });
    }
    setEventDataSource((prev) => ({
      ...prev,
      GrandTotal: total,
    }));

    setGrandTotal(total);
  };

  useEffect(() => {
    if (formData) {
      const initialRowTotals = formData.map(
        (data) => (data.amount || 0) * (data.no_of_tickets || 0)
      );
      setEventDataSource((prev) => ({
        ...prev,
        RowTotal: initialRowTotals,
      }));
      setRowTotals(initialRowTotals);
      calculateGrandTotal(formData);
    }
  }, [formData]);

  const handleQuantityChange = (index, value) => {
    const newFormData = [...formData];
    newFormData[index].no_of_tickets = value || 0;
    const newRowTotals = [...rowTotals];
    newRowTotals[index] = (newFormData[index].amount || 0) * value;
    setRowTotals(newRowTotals);
    setEventDataSource((prev) => ({
      ...prev,
      RowTotal: newRowTotals,
    }));

    calculateGrandTotal(newFormData);
    ticketLimitationEvent(
      index,
      value,
      dataSource.gridData[index].ticket_limitations,
      dataSource.gridData[index].row_name
        ? dataSource.gridData[index].row_name
        : capitalizeFirstLetter(EventModeOnline),
      dataSource.gridData[index].balance_tickets
    );
  };

  const conditionCheck =
    dataSource && dataSource.eventDetails && dataSource.eventDetails[0];


  return (
    <table
      id="example1"
      className="table table-bordered table-striped text-center mt-2"
    >
      <thead>
        <tr>
          <th style={{ width: "30%" }}>Row name</th>
          {conditionCheck &&
            conditionCheck?.ticketing_option !== freewithregistration &&
            formData &&
            formData[0]?.amount !== null &&
            formData[0]?.amount !== "" &&
            formData[0]?.amount !== 0 && <th style={{ width: "30%" }}>₹</th>}

          <th style={{ width: "20%" }}>Qty</th>
          {conditionCheck &&
            conditionCheck?.ticketing_option !== freewithregistration && (
              <th className="w-auto">Total</th>
            )}
        </tr>
      </thead>
      <tbody>
        {dataSource && dataSource.gridData && dataSource.gridData.length > 0
          ? dataSource.gridData.map((data, index) => {
              if (data.balance_tickets === 0 || data.balance_tickets === "0") {
                return (
                  <tr style={{height: "50px"}}>
                    {data.row_name && <td>{data.row_name}</td>}
                    <td
                      colSpan={
                        conditionCheck?.event_mode === EventModeOnline ? 2 : 3
                      }
                      style={{ color: "red" }}
                    >
                      Sold Out
                    </td>
                  </tr>
                );
              }
              return (
                <tr key={index}>
                  {/* Row Name */}
                  {data.row_name && <td>{data?.row_name}</td>}

                  {/* Amount */}
                  {conditionCheck &&
                    conditionCheck?.ticketing_option !==
                      freewithregistration && (
                      <>
                        {formData &&
                          formData[0]?.amount !== null &&
                          formData[0]?.amount !== "" &&
                          formData[0]?.amount !== 0 && (
                            <td>
                              <NumberBox
                                min={0}
                                readOnly={true}
                                value={formData[index]?.amount || 0}
                              />
                            </td>
                          )}
                      </>
                    )}

                  {/* Number of Tickets */}
                  <td>
                    <NumberBox
                      min={0}
                      showSpinButtons={true}
                      onValueChanged={(e) =>
                        handleQuantityChange(index, e.value)
                      }
                      value={formData[index]?.no_of_tickets || 0}
                    />
                  </td>

                  {/* Row Totals */}
                  {conditionCheck &&
                    conditionCheck.ticketing_option !==
                      freewithregistration && (
                      <td>
                        <NumberBox
                          className="textAlignNumberBox"
                          readOnly={true}
                          value={rowTotals[index] || 0}
                        />
                      </td>
                    )}
                </tr>
              );
            })
          : null}

        {/* Grand Total Row */}
        <tr>
          {conditionCheck &&
            conditionCheck?.ticketing_option !== freewithregistration && (
              <>
                <td
                  className="text-start"
                  colSpan={
                    conditionCheck?.event_mode === EventModeOnline ? 2 : 3
                  }
                >
                  Grand Total
                </td>
                <td style={{ textAlign: "right" }}>
                  <NumberBox
                    alignment="right"
                    readOnly={true}
                    value={grandTotal}
                  />
                </td>
              </>
            )}
        </tr>
      </tbody>
    </table>
  );
};

export default OfflineDenomination;
