import axios from 'axios';
import { getAPIKey } from './getApiKey';
import { BASEPATH } from '../config/config';



const appAxios = axios.create({
    baseURL: BASEPATH,  
    timeout: 60000,
    responseType: 'json',
    
});


// appAxios.interceptors.request.use(async (config) => {
//     const apiKey = getAPIKey();
//      if (apiKey) {
//        config.headers["mdnd_auth"] = apiKey;
//      }
//     return config;
// }, error => {
//     //return Promise.reject(error);
// });

appAxios.interceptors.request.use(async (config) => {
    const apiKey = getAPIKey();
    if (apiKey) {
      config.headers["MDnD_auth"] = apiKey;
    }
    console.log()
    // if (config.data) {
    //   config.data = await encrypt(JSON.stringify(config.data));
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default appAxios;
