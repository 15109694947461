import React from "react";

const Cancellation = () => {
  return (
    <div>
      <div className="iq-breadcrumb organBanner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb" className="text-center">
                <h2 className="title text-white">Cancellation Policy</h2>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="section-padding py-lg-3">
          <p className="fs-6">
          At www.mdnd.in while we strive hard to satisfy every customer’s needs, we do understand that you may sometimes want to cancel your order. If unfortunately, you have to cancel an order, please do so within the following deadlines
          </p>
          <div className="col-lg-7 mx-auto">
          <table className="table table-striped border text-center">
            <thead>
              <tr>
                <th>Description of product</th>
                <th>Deadline for cancellation</th>
                <th>Timeline for refund</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>For Tickets to online events</td>
                <td>Cancellation policy is defined by the event organiser. Please refer the event description for cancellation and refund policy.</td>
                <td>Within 2 days from the time of cancellation</td>
              </tr>
              <tr>
                <td>For Tickets to offline events	</td>
                <td>Cancellation policy is defined by the event organiser. Please refer the event description for cancellation and refund policy.</td>
                <td>Within 2 days from the time of cancellation</td>
              </tr>
              <tr>
                <td>For Books, instruments, and other products</td>
                <td>Before despatch of the products</td>
                <td>Within 2 days from the time of cancellation</td>
              </tr>
              
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cancellation;
