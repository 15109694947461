import React, { useState,useEffect } from "react";
import "./Landing.css";
import {
  landingPageImagesCarosal,
  LandingPageBannerCarosal,
} from "../global/constants";
import { Helmet } from "react-helmet";
import { Carousel } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
const Landing = () => {
  const [loadedImages, setLoadedImages] = useState({});
  const [bannerImages, setBannerImages] = useState({});

  const handleImageLoad = (index) => {
    setLoadedImages((prev) => ({ ...prev, [index]: true }));
  };

  const handleLandingBanner = (index) => {
    setBannerImages((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };
// -----------------------------------------------------------------------------------------------------------
    // const location = useLocation();
    // const query = new URLSearchParams(location.search);
    // const  ci = query.get('ci');
    // const c = query.get('c');
    // let metaTitle = 'Explore the Finest Indian Music, Dance, and Drama Events|Free and Ticketed Near You';

    // if (ci === '101' && c === '3659') {
    //   metaTitle = 'Explore the Finest Indian Music, Dance, and Drama Events|Free and Ticketed Near You';
    // } else if (location.pathname === '/events/ProductDetails') {
    //   const eventId = query.get('editEvent');
    //   metaTitle = `Event Name ${eventId}`;  // Customize based on the event data
    // }
    // useEffect(() => {
    //   document.title = metaTitle;
    // }, [metaTitle]);
// -----------------------------------------------------------------------------------------------------------

  const navigate = useNavigate();
  const handleClick = (path, index) => {
    if (path.startsWith("http")) {
      window.open(path, "_blank");
    } else if (path === "/SeasonTickets" || path === `/organisers`) {
      navigate(path, { state: { MID: "hello" } });
    } else {
      navigate(path);
    }
  };

  return (
    <>
      <Helmet>
        <title>MDnD | Discover the world of Indian performing arts.</title>
        <meta
          name="description"
          content="Explore Indian performing arts including music, dance, drama, and more."
        />
      </Helmet>
      <div
        className="section-padding carouselMobDots p-0"
        style={{ minHeight: "196px" }}
      >
        <Carousel style={{ minHeight: "196px" }}>
          {LandingPageBannerCarosal.map((data, index) => (
            <Carousel.Item
              key={index}
              interval={6000}
              style={{ minHeight: "196px" }}
            >
              {/* If the image has not loaded yet, show the skeleton */}
              {!bannerImages[index] ? (
                <Skeleton
                  className="skeleton-responsive"
                  width="100%"
                  baseColor="#ddd"
                  highlightColor="#ffffff"
                />
              ) : (
                <>
                  {/* Once the image has loaded, display it */}
                  <img
                    src={data.src}
                    alt={`Slide ${index + 1}`}
                    className="d-block w-100"
                    style={{ minHeight: "196px", objectFit: "cover" }}
                    onLoad={() => handleLandingBanner(index)}
                  />

                  <div className="landing-caption">
                    <p
                      className="text-white d-sm-none text-center"
                      style={{ paddingTop: "40px" }}
                    >
                      {/* {data.content} */}Welcome! We have just migrated to a
                      new tech stack & design. Events, Season Tickets and MDnD
                      Store are up and running. The other features will be up in
                      a few days. Please bear with us.
                    </p>
                    <p
                      className="text-white d-none d-sm-block d-lg-none h5 text-center"
                      style={{ paddingTop: "40px" }}
                    >
                      {/* {data.content} */}Welcome! We have just migrated to a
                      new tech stack & design. Events, Season Tickets and MDnD
                      Store are up and running. The other features will be up in
                      a few days. Please bear with us.
                    </p>
                    <h4 className="text-white d-lg-block d-none d-xl-none text-center">
                      {/* {data.content} */}Welcome! We have just migrated to a
                      new tech stack & design. Events, Season Tickets and MDnD
                      Store are up and running. The other features will be up in
                      a few days. Please bear with us.
                    </h4>
                    <h3 className="text-white d-xl-block d-none text-center">
                      {/* {data.content} */}Welcome! We have just migrated to a
                      new tech stack & design. Events, Season Tickets and MDnD
                      Store are up and running. The other features will be up in
                      a few days. Please bear with us.
                    </h3>
                  </div>
                </>
              )}

              {/* Preload the image in the background */}
              <img
                src={data.src}
                alt={`Preload Slide ${index + 1}`}
                style={{ display: "none" }}
                onLoad={() => handleLandingBanner(index)}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      <section className="section-padding bg-white mt-0 pt-lg-5 pt-3">
        <div className="container-fluid">
          <div className="row row-cols-2 row-cols-sm-3 row-cols-md-3 row-cols-lg-5">
            {landingPageImagesCarosal.map(({ src, path, title }, index) => (
              <div className="col mb-2 mb-lg-4 px-2" key={index}>
                <div
                  className="iq-card-geners card-hover-style-two overflow-hidden"
                  style={{ borderRadius: "8px" }}
                >
                  <div className="blockImages position-relative w-100">
                    <div
                      className="img-box rounded position-relative border-0 p-0 bg-transparent"
                      onClick={() => handleClick(path, index)}
                      style={{ cursor: "pointer" }}
                    >
                      {!loadedImages[index] && (
                        <Skeleton
                          className="skeleton-loader-box"
                          width="100%"
                          baseColor="#ddd"
                          highlightColor="#ffffff"
                        />
                      )}
                      <img
                        src={src}
                        alt={`${title.toLowerCase()}-img`}
                        className={`img-fluid object-cover w-100 rounded ${
                          loadedImages[index] ? "" : "d-none"
                        }`}
                        onLoad={() => handleImageLoad(index)}
                      />
                      <div className="blog-description">
                        <h5
                          className="w-100 mb-0 text-white text-wrap"
                          style={{ fontWeight: "400", marginTop: "4px" }}
                        >
                          {title}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Landing;
