import React from "react";
import { EVENTS_IMAGE_PATH } from "../global/constants";
import { MdImageNotSupported } from "react-icons/md";

function TagItem({ name, image }) {
  return (
    <div className="organizer-item">
      {/* {image ? (
        <img
          src={`${EVENTS_IMAGE_PATH}${image}`}
          className="TaxBoxImage me-2 rounded-circle"
        />
      ) : (
        <MdImageNotSupported className="pl-2" />
      )} */}
      <span>{name}</span>
    </div>
  );
}

function Tag({ name, image }) {
  return (
    <React.Fragment>
      <div className={`dx-tag-content`}>
        {image ? (
          <>
            {/* <img
              src={`${EVENTS_IMAGE_PATH}${image}`}
              className="selectedTagImage me-2"
            /> */}
            <span>{name}</span>
          </>
        ) : (
          <>
            {/* <MdImageNotSupported /> */}
            <span>{name}</span>
          </>
        )}
        <div className="dx-tag-remove-button"></div>
      </div>
    </React.Fragment>
  );
}

export { Tag, TagItem };
