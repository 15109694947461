import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { IMAGE_URL, ORGANISER_URL } from "../../Subpages/MainPage/constant";
import { BASEPATH } from "../../config/config";
import { FaFilter } from "react-icons/fa";
import { useMutation } from "@tanstack/react-query";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Noimage from "../../global/NoImage.js";
import {
  GET_COUNTRIES_ORGANISER,
  GET_STATES_ORGANISER,
  GET_CITIES_ORGANISER,
  ORGANISER_FILTER,
} from "../../Api/api";
import { SelectBox } from "devextreme-react";
import { Modal, Button, Form } from "react-bootstrap";
import { MdKeyboardArrowRight } from "react-icons/md";

import NoDataFound from "../../global/NoDataFound";
import { generateAlphabetCharacters } from "../../utils/commonUtils.js";
import { generateRandomColor } from "../../utils/commonUtils.js";

const Organisers = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("MDnD_auth");
  const location = useLocation();
  const MID = location?.state?.MID;

  //console.log(MID, "MID");

  const [organiserCollection, setOrganiserCollection] = useState({
    finalData: [],
    country: "",
    selectedCountry: "",
    state: "",
    //selectedState:"",
    city: "",
    selectedCity: "",
  });
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const doFilter = () => {
    setFilter(!filter);
  };

  const handleClick = (profileData, role) => {
    navigate(`/organiser/${profileData.profile_name}?rid=${profileData.role_id}`, {
      state: {
        profileData,
        userId: profileData.organiser_id,
        role,
        roleId: profileData.role_id,
      },
    });
  };

 

  // new code added by vignesh....................

  const [isFetching, setIsFetching] = useState(false);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const limit = 5;

  const loadMore = useCallback(async () => {
    console.log("inside load more");

    if (isFetching || !hasMoreData) {
      console.log("1st condition");
      return;
    }

    setIsFetching(true);
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${ORGANISER_FILTER}`,
        data: {
          country_id: organiserCollection.selectedCountry
            ? organiserCollection.selectedCountry
            : "",
          city_id: organiserCollection.selectedCity
            ? organiserCollection.selectedCity
            : "",

          limit: limit,
          offset: currentOffset,
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      //console.log(response.data,"RD");
      if (response.data.status == "success") {
        // setOrganiserCollection((prevState) => ({
        //   ...prevState,
        //   finalData:
        //     currentOffset > 0
        //       ? [...prevState.finalData, ...response.data.data]
        //       : response.data.data,
        // }));

        setOrganiserCollection((prevState) => {
          // Extract existing organiser IDs from prevState.finalData
          const existingOrganiserIds = new Set(prevState.finalData?.map(item => item.organiser_id));
      
          // Filter out new data to remove any duplicates
          const filteredNewData = response?.data.data.filter(item => !existingOrganiserIds.has(item.organiser_id));
      
          const isSameData = JSON.stringify(prevState?.finalData) === JSON.stringify(filteredNewData);
          return {
            ...prevState,
            finalData:
              currentOffset === 0
                ? response.data.data
                : currentOffset > 0 && !isSameData && hasMoreData
                ? [...prevState.finalData, ...filteredNewData]
                : organiserCollection.finalData,
          };
        });

        setHasMoreData(response.data.data.length === limit);
        setIsFetching(false);

        if (response?.data?.data?.length > 0) {
          setCurrentOffset((prevOffset) => prevOffset + limit);
        }

      } else {
        setOrganiserCollection((prevState) => ({
          ...prevState,
          finalData: [],
        }));
        setHasMoreData(false);
        setIsFetching(true);
      }

      

      //setCurrentOffset((prevOffset) => prevOffset + limit);
    } catch (error) {
      setOrganiserCollection((prevState) => ({ ...prevState, finalData: [] }));
    }
    finally {
      setIsFetching(false);
      setLoading(false); // Set loading to false once data is fetched
    }
  }, [currentOffset, isFetching, organiserCollection.selectedCity]);

  useEffect(() => {
    if (organiserCollection.selectedCity) {
      console.log("check here");
      loadMore();
    } else {
      return;
    }
  }, [organiserCollection.selectedCity]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 100 >=
        document.documentElement.scrollHeight &&
        !isFetching &&
        hasMoreData
      ) {
        loadMore();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isFetching, hasMoreData]);

  //country, state, city...
  useEffect(() => {
    mutationCountry.mutate();
  }, []);

  const GetCountries = () => GET_COUNTRY(GET_COUNTRIES_ORGANISER);

  const GET_COUNTRY = async (GET_COUNTRIES_ORGANISER) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASEPATH}${GET_COUNTRIES_ORGANISER}`,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationCountry = useMutation({
    mutationKey: ["get_Country_organiser"],
    mutationFn: () => GetCountries(),

    onSuccess: (data) => {
      //   queryClient.invalidateQueries({
      //     queryKey: ["fetchUser"],
      //   });

      //console.log(data, "DATA");

      if (data.status === "success") {

      let filteredArray= data?.data.filter((item) => item.country_id !== 0 && item.country_id !== null);

       if(filteredArray)
        {setOrganiserCollection((prevState) => ({
          ...prevState,
          country: filteredArray,
        }));}

      } else {
        setOrganiserCollection((prevState) => ({ ...prevState, country: "" }));
      }
    },
    onError: (error) => {
      //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
      //setAddress((prevState) => ({ ...prevState, country: ""}));
    },
  });

  //setting initial selected...
  // useEffect(() => {
  //   {
  //     setOrganiserCollection((prevState) => ({
  //       ...prevState,
  //       selectedCountry: 101,
  //       //selectedState: 35,
  //       selectedCity: 3659,
  //     }));
  //   }
  // }, []);

  useEffect(() => {
    if (MID) {
      setOrganiserCollection((prevState) => ({
        ...prevState,
        selectedCountry: 101,
      }));
    } else {
      const params = new URLSearchParams(location?.search);

      setOrganiserCollection((prevState) => ({
        ...prevState,

        selectedCountry: Number(params.get("countryid")) || "",
      }));
    }
  }, [location, MID]);

  useEffect(() => {
    if (MID) {
      setOrganiserCollection((prevState) => ({
        ...prevState,
        selectedCity: 3659,
      }));
    } else {
      const params = new URLSearchParams(location?.search);

      setOrganiserCollection((prevState) => ({
        ...prevState,

        selectedCity: Number(params.get("cityid")) || "",
      }));
    }
  }, [location, MID]);

  //states fetch

  //cities fetch..
  useEffect(() => {
    const GETCITIESDATA = async () => {
      try {
        const response = await axios.post(
          `${BASEPATH}${GET_CITIES_ORGANISER}`,
          {
            country_id: organiserCollection.selectedCountry,
          },
          {
            headers: {
              MDnD_auth: `${token}`,
            },

            timeout: 60000,
            responseType: "json",
          }
        );

        if (response.data.status == "success") {

          let filteredArray= response?.data?.data.filter((item) => item.city_id !== 0 && item.city_id !== null);

          if(filteredArray)
          {setOrganiserCollection((prevState) => ({
            ...prevState,
            city: response.data.data,
          }));}

        } else {
          setOrganiserCollection((prevState) => ({ ...prevState, city: "" }));
        }
      } catch (error) {
        console.error("error fetching States for this country", error);
      }
    };
    if (organiserCollection.selectedCountry) {
      GETCITIESDATA();
    }
  }, [organiserCollection.selectedCountry]);

  //onValueChanged functions..................................

  const handleCountry = (e) => {
    if (e) {
      setOrganiserCollection((prevState) => ({
        ...prevState,
        selectedCountry: e.value,
      }));
      setOrganiserCollection((prevState) => ({ ...prevState, city: [] }));
      setOrganiserCollection((prevState) => ({
        ...prevState,
        //selectedState: "",
        selectedCity: "",
      }));
    }
    setHasMoreData(true);
    setIsFetching(false);
    setCurrentOffset(0);
    setOrganiserCollection((prevState) => ({ ...prevState, finalData: [] }));
  };

  // const handleState = (value) => {

  //   if (value) {
  //     setOrganiserCollection((prevState) => ({ ...prevState, selectedState: value }));
  //     setOrganiserCollection((prevState) => ({ ...prevState, city: [] }));
  //     setOrganiserCollection((prevState) => ({ ...prevState, selectedCity: "", }));

  //   }
  //   setOrganiserCollection((prevState) => ({ ...prevState, finalData: [] }));
  // }

  const handleCity = (value) => {
    if (value) {
      setOrganiserCollection((prevState) => ({
        ...prevState,
        selectedCity: value,
      }));

      setHasMoreData(true);
      setIsFetching(false);

      setCurrentOffset(0);

      setOrganiserCollection((prevState) => ({ ...prevState, finalData: [] }));
    }
  };

  //dynamicaly changing the URL
  useEffect(() => {
    if (
      organiserCollection.selectedCountry &&
      organiserCollection.selectedCity
    ) {
      const queryParams = new URLSearchParams({
        countryid: Number(organiserCollection.selectedCountry),
        //stateid: organiserCollection.selectedState ? Number(organiserCollection.selectedState) : "",
        cityid: organiserCollection.selectedCity
          ? Number(organiserCollection.selectedCity)
          : "",
      });

      const newUrl = `/organisers?${queryParams}`;
      navigate(newUrl);
    } else {
      return;
    }
  }, [
    organiserCollection.selectedCountry,
    //organiserCollection.selectedState,
    organiserCollection.selectedCity,
    navigate,
  ]);

  console.log(organiserCollection, "organiserCollection");

  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

//********************BREAD CRUMBS ******************** */

const[breadCrumbCollection, setbreadCrumbCollection] = useState({
  breadCrumbCountry:"",
  breadCrumbCity:"",
 
  breadCrumbCurrent:"",

})



useEffect(()=>{
  if(organiserCollection?.selectedCountry && organiserCollection?.selectedCountry!= '' && organiserCollection.country ){
    const filteredData = organiserCollection.country && organiserCollection.country.filter((item)=> item.country_id === Number(organiserCollection.selectedCountry) )
    
    
    if(filteredData && filteredData.length > 0 )
    {
      //setbreadcrumbCountry(filteredData[0]?.country_name)
    
      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbCountry : filteredData[0]?.country_name,
        breadCrumbCurrent:"Country",
        breadCrumbCity : ""
        
      }));
    }
    else{
      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbCountry : '',
        
      }));
    }
    
  }
  

},[organiserCollection?.selectedCountry,organiserCollection.country])

useEffect(()=>{
  if(organiserCollection?.selectedCity && organiserCollection?.selectedCity !== '' && organiserCollection?.city){
    const cityData= organiserCollection?.city && organiserCollection?.city.filter((item)=> item?.city_id === organiserCollection.selectedCity )

    
    if(cityData && cityData.length > 0){
      //setbreadCrumbCity(cityData[0].city_name);

      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbCity : cityData[0].city_name,
        breadCrumbCurrent:"City",

        
      }));
    }
    else{
      setbreadCrumbCollection((prevState) => ({
        ...prevState,
        breadCrumbCity : '',
        
      }));
    }
   
  }

},[organiserCollection?.selectedCity,organiserCollection?.city])



  return (
    <>
      <div className="filter-page iq-breadcrumb Organiser-Banner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb" className="text-center">
                <h2 className="mt-4 mt-lg-0 header-title text-white">
                  Organisers
                </h2>
                <h2 className="mt-4 mt-lg-0 header-title text-white">
                  All organiser profiles will be up in a few days. Thank you for
                  your patience.
                </h2>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* <section className="section-padding pt-4">
        <div className="container-fluid">
          <div className="row">
            <div
              className="w-100 filter-sticky d-lg-none d-flex flex-nowrap align-items-center gap-3 justify-content-between"
              onClick={doFilter}
            >

            <div
                className="w-100 d-flex align-items-center hide-scrollbar"
                style={{ gap: "2px", overflow: "scroll" }}
              >
                
                <p className="m-0 p-0"
                      style={{ color: breadCrumbCollection.breadCrumbCurrent === "Country" ? "#3F00FF" : undefined }}
                      >{breadCrumbCollection.breadCrumbCountry}</p>
                      <i style={{ fontSize: "16px" }}>
                        <MdKeyboardArrowRight />
                      </i>
                      <p className="m-0 p-0" 
                      style={{ color: breadCrumbCollection.breadCrumbCurrent === "City" ? "#3F00FF" : undefined }}
                      //style={{ color: "#3F00FF" }}
                      >
                      {breadCrumbCollection.breadCrumbCity}
                      </p>

              </div>
              <div
                className="filter-button align-items-center"
                onClick={doFilter}
              >
                <FaFilter />
                Filters
              </div>
            </div>
            <div className="col-lg-3 col-xl-2 sideBarFilter">
              <div className="filterBg pb-2">
                <div className="">
                  <h5>
                    <FaFilter /> Filters
                  </h5>
                </div>
                <div className="mt-4 d-flex flex-column gap-2">
                  {loading ? (
                    <>
                      <Skeleton height={40} className="mb-2" />
                      <Skeleton height={40} />
                    </>
                  ) : (
                    <>
                      <h6 className="mb-2">Location</h6>
                      <SelectBox
                        dataSource={organiserCollection.country}
                        displayExpr="country_name"
                        valueExpr="country_id"
                        label="Select Country"
                        searchEnabled={true}
                        onValueChanged={handleCountry}
                        labelMode="floating"
                        value={organiserCollection.selectedCountry}
                      />


                      <SelectBox
                        dataSource={organiserCollection.city || []}
                        label="Select City"
                        labelMode="floating"
                        displayExpr="city_name"
                        valueExpr="city_id"
                        searchEnabled={true}
                        onValueChanged={(e) => handleCity(e.value)}
                        value={organiserCollection.selectedCity}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-10 col-lg-9 col-12">
              <div>
                <Modal
                  className="filterPopUp"
                  show={filter}
                  onHide={doFilter}
                  style={{ zIndex: "10001" }}
                >
                  <Modal.Header className="py-1 px-2 border-0" closeButton>
                    <Modal.Title className="ms-2">Filters</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="p-3">
                    <div className="mt-4 d-flex flex-column gap-2">
                      <h6 className="mb-2">Location</h6>

                      <SelectBox
                        dataSource={organiserCollection.country}
                        displayExpr="country_name"
                        valueExpr="country_id"
                        label="Select Country"
                        searchEnabled={true}
                        onValueChanged={handleCountry}
                        labelMode="floating"
                        value={organiserCollection.selectedCountry}
                        dropDownOptions={{ container: ".modal" }}

                      />

                      <SelectBox
                        dataSource={organiserCollection.city || []}
                        label="Select City"
                        labelMode="floating"
                        displayExpr="city_name"
                        valueExpr="city_id"
                        searchEnabled={true}
                        onValueChanged={(e) => handleCity(e.value)}
                        value={organiserCollection.selectedCity}
                        dropDownOptions={{ container: ".modal" }}

                      //ref={element => inputRefs.current[key] = element}
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="py-1 px-2 border-0">
                    <Button
                      className="px-3 py-1 bg-green text-white btn border-0"
                      onClick={doFilter}
                    >
                      Apply
                    </Button>
                  </Modal.Footer>
                </Modal>

                </div>

                <div className="d-none d-lg-block mb-2">
                <div
                  className="w-100 d-flex flex-row align-items-center hide-scrollbar"
                  style={{ gap: "2px", overflow: "scroll" }}
                >
                 
                 <p className="m-0 p-0"
                      style={{ color: breadCrumbCollection.breadCrumbCurrent === "Country" ? "#3F00FF" : undefined }}
                      >{breadCrumbCollection.breadCrumbCountry}</p>
                      <i style={{ fontSize: "16px" }}>
                        <MdKeyboardArrowRight />
                      </i>
                      <p className="m-0 p-0" 
                      style={{ color: breadCrumbCollection.breadCrumbCurrent === "City" ? "#3F00FF" : undefined }}
                      //style={{ color: "#3F00FF" }}
                      >
                      {breadCrumbCollection.breadCrumbCity}
                      </p>


                </div>
              </div>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 filtermh">
                {loading ? (
                  Array.from({ length: 8 }).map((_, index) => (
                    <div className="col" key={index}>
                      <Skeleton height={300} className="w-100" />
                    </div>
                  ))
                ) : organiserCollection.finalData &&
                  organiserCollection.finalData.length > 0 ? (
                  organiserCollection.finalData.map((person, index) => (
                    <div className="col" key={index}>
                      <div
                        className="iq-cast rounded overflow-hidden"
                        onClick={() => handleClick(person, "organiser")}
                      >

                      <div
                          className="img-container"
                          style={{
                            backgroundColor: person.organiser_photo ? 'transparent' : '#3f00ff', 
                            width: '100%',
                            color: '#ffffff',
                            height: '250px', 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          {person.organiser_photo && !imageError ? (
                            <img
                              src={`${ORGANISER_URL}/${person.organiser_photo}`}
                              onError={handleImageError}
                              className="img-fluid w-100"
                              alt={person.organiser_name}
                              style={{ objectFit: 'cover', height: '100%' }} 
                            />
                          ) : (
                            <h1 style={{ color: '#ffffff', fontSize: '4rem' }}>
                              {generateAlphabetCharacters(person?.organiser_name)}
                            </h1>
                          )}
                        </div>

                        <div className="card-img-overlay artistTextBg position-relative">
                          <p className="cast-title fw-500 text-black mb-0">
                            {person.organiser_name}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <NoDataFound />
                )}


              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Organisers;
