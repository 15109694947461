import React, { useState } from "react";
import {
  Chart,
  Series,
  ArgumentAxis,
  ValueAxis,
  Legend,
  Tooltip,
  CommonSeriesSettings,
} from "devextreme-react/chart";
import SelectBox from "devextreme-react/select-box";

const chartTypes = ["bar", "line", "area"];

const ChartView = ({ NonNumberedDetails }) => {
  const [chartType, setChartType] = useState("bar");

  const handleChartTypeChange = (e) => {
    setChartType(e.value);
  };

  // Add "Ticket Sold" to each data point in NonNumberedDetails
  const enhancedData = NonNumberedDetails.map((item) => {
    const totalQuantity = parseInt(item.total_quantity, 10) || 0;
    const balanceTickets = parseInt(item.balance_tickets, 10) || 0;
    const ticketSold = totalQuantity - balanceTickets;
    return { ...item, ticket_sold: ticketSold };
  });

  const chartOptions = {
    type: chartType,
    argumentField: "row_name",
    valueField: "amount",
  };

  return (
    <div>
      <SelectBox
      className="my-4 ms-5"
        items={chartTypes}
        value={chartType}
        onValueChanged={handleChartTypeChange}
        width={200}
      />
      <Chart id="chart" dataSource={enhancedData}>
        <CommonSeriesSettings argumentField="row_name" type={chartType} />
        <Series
          valueField="amount"
          name="Amount"
          {...(chartType === "pie" && {
            smallValuesGrouping: { mode: "topN", topCount: 5 },
          })}
        />
        <Series valueField="balance_tickets" name="Balance Tickets" />
        <Series valueField="total_quantity" name="Total Quantity" />
        <Series valueField="ticket_sold" name="Ticket Sold" />
        <ArgumentAxis />
        <ValueAxis
          label={{ format: "fixedPoint", precision: 2 }}
          // Adjust visualRange or other settings if needed
        />
        <Legend verticalAlignment="bottom" horizontalAlignment="center" />
        <Tooltip enabled={true} />
      </Chart>
    </div>
  );
};

export default ChartView;
