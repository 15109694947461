import React from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../global/constants";
import locationPng from "../../assets/Icons/MDNDLOCATION.png"

const MapComponent = ({ selectedLocation }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
  });
  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);
  if (loadError) return "Error";
  if (!isLoaded) return "Maps";

  return (
    <div style={{ marginTop: "50px" }}>
      <GoogleMap
        mapContainerStyle={{
height: "400px",
          width: "680px"
        }}          
        center={selectedLocation}
        zoom={13}
        onLoad={onMapLoad}
      >
        <MarkerF
          position={selectedLocation}
          icon={{
            url: locationPng,
            scaledSize: new window.google.maps.Size(40, 50),
          }}
        />
      </GoogleMap>
    </div>
  );
};

export default MapComponent;
