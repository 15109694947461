import React, { useMemo } from "react";

const FirstLetterAvatar = ({ data }) => {
  const backgroundColor = useMemo(() => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }, []);

  const firstLetter = data?.name?.charAt(0).toUpperCase();
  const avatarStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: "#3f00ff",
    color: "white",
    fontSize: "20px",
    fontWeight: "bold",
  };

  return <div style={avatarStyle}>{firstLetter}</div>;
};

export default FirstLetterAvatar;
