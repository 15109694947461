// export const BASEPATH = `https://api.evrythingez.com/`
export const BASEPATH = `https://api.mdnd.in/`;
// export const BASEPATH = `http://192.168.0.183:3001/`
export const ADMIN_BACK_BASEPATH = `http://adminapi.evrythingez.com/`;
export const RAGABANK_IMAGEPATH = `${ADMIN_BACK_BASEPATH}public/images/ragabank_thumbnails`;
export const ADMIN_BASEPATH = `http://192.168.0.42:8002/`;
const ViewEventImagePath = `public/images/event_images/`;

// No need slash at end of the URL
// export const SHARE_BASEPATH = `https: // rewrite.evrythingez.com`
export const SHARE_BASEPATH = `https://mdnd.in`;

export const LogoPath = `${BASEPATH}public/qrcode/`;

// CDN
const MDnDUploadRecordsCDN = `https://origin.mdndrecords.in/upload`;

export { ViewEventImagePath, MDnDUploadRecordsCDN };

